import { Resource } from "../../types";
import {
  ResourceHiddenInput,
  ResourceHiddenInputProps,
} from "./ResourceHiddenInput";

export const ResourceHiddenNumberInput = <TResource extends Resource>(
  props: ResourceHiddenInputProps<TResource>
) => (
  <ResourceHiddenInput
    options={{ valueAsNumber: true }}
    defaultValue="0"
    {...props}
  />
);
