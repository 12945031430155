import React, { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Nullable from "../../Nullable";
import { ControlledSimpleSelect } from "../../components/SimpleSelect";
import { DeliveryZonesSelect } from "../../components/selects/DeliveryZonesSelect";
import { ControlledTransportersSelect } from "../../components/selects/TransportersSelect";
import DeliveryMode, { DeliveryModeType } from "../../models/DeliveryMode";
import DeliveryModeDeliveryZone from "../../models/DeliveryModeDeliveryZone";
import { ResourceHiddenNumberInput } from "../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceInput } from "../../resources/components/inputs/ResourceInput";
import { useResourcesSelectFieldArray } from "../../resources/components/selects/hooks/useResourcesSelectFieldArray";

const EditDeliveryModeForm = () => {
  const { getValues, watch, setValue } = useFormContext<DeliveryMode>();
  const deliveryModeType = Number(watch("deliveryModeType"));

  useEffect(() => {
    if (
      getValues() != null &&
      deliveryModeType != null &&
      deliveryModeType === DeliveryModeType.Store
    ) {
      setValue("transporterId", undefined);
      setValue("deliveryModesZones", undefined);
    }
  }, [deliveryModeType, getValues, setValue]);

  const convertToResource = useCallback(
    (p: number) => ({ deliveryZoneId: p } as DeliveryModeDeliveryZone),
    []
  );

  const convertToResourceId = useCallback(
    (f: DeliveryModeDeliveryZone) => f.deliveryZoneId,
    []
  );

  const { fields, replaceResources, resourceIds } =
    useResourcesSelectFieldArray<DeliveryMode, number>({
      convertToResource,
      convertToResourceId,
      // keyName: "_id" as any,
      name: "deliveryModesZones",
      rules: {
        minLength: { value: 0, message: "Au moins une zone de livraison" },
      },
    });

  return (
    <>
      <ResourceHiddenNumberInput<DeliveryMode> name="id" />
      <ResourceInput<DeliveryMode>
        label="Nom*"
        name="label"
        options={{
          required: "Le nom du mode de livraison est obligatoire",
          maxLength: {
            value: 50,
            message:
              "Le nom du mode de livraison ne doit pas dépasser 50 caractères",
          },
        }}
      />
      <ResourceInput<DeliveryMode>
        label="Code*"
        name="code"
        options={{
          required: "Le code du mode de livraison est obligatoire",
          maxLength: {
            value: 50,
            message:
              "Le code du mode de livraison ne doit pas dépasser 50 caractères",
          },
        }}
      />
      <ResourceInput<DeliveryMode>
        label="Montant des frais de port (TTC)*"
        name="price"
        options={{
          required: "Le montant des frais de port est obligatoire",
          min: {
            value: 0,
            message:
              "Le montant des frais de port doit être supérieur ou égal à 0",
          },
          valueAsNumber: true,
        }}
        type="number"
        step="any"
      />
      <div className="row">
        <div className="col">
          <ResourceInput<DeliveryMode>
            label="Délai de livraison en jour"
            name="dayBeforeDelivery"
            options={{
              min: {
                value: 0,
                message:
                  "Le délai de livraison doit être supérieur ou égal à 0",
              },
              valueAsNumber: true,
            }}
            type="number"
          />
        </div>
        <div className="col">
          <ResourceInput<DeliveryMode>
            label="Heure limite de commande"
            name="orderMaxHour"
            options={{
              min: {
                value: 0,
                message:
                  "L'heure limite de commande ne doit être inférieure à 0",
              },
              max: {
                value: 23,
                message:
                  "L'heure limite de commande ne doit être supérieure à 23",
              },
              valueAsNumber: true,
            }}
            type="number"
          />
        </div>
        <div className="col">
          <ControlledSimpleSelect<DeliveryMode, number>
            label="Jours de livraison"
            name="deliverableDays"
            rules={{
              required: "Les jours de livraison sont obligatoires",
              minLength: {
                message: "Au moins 1 jour de livraison est obligatoire",
                value: 1,
              },
            }}
            options={[
              { value: 0, label: "Tous les jours" },
              { value: 1, label: "Jours ouvrés" },
              { value: 2, label: "Jours ouvrables" },
            ]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ResourceInput<DeliveryMode>
            label="Montant minimum (inclu) de la commande"
            name="cartMinPrice"
            options={{
              valueAsNumber: true,
              min: {
                message: "Le montant minimum doit être supérieur ou égal à 0",
                value: 0,
              },
              validate: (value) => {
                const cartMaxPrice = getValues("cartMaxPrice");
                if (isValidNumber(value) && isValidNumber(cartMaxPrice)) {
                  return (
                    value! < cartMaxPrice! ||
                    "Le montant minimum doit être inférieur au montant maximum"
                  );
                }
                return true;
              },
            }}
            type="number"
            step="any"
          />
        </div>
        <div className="col">
          <ResourceInput<DeliveryMode>
            label="Montant maximum (exclu) de la commande"
            name="cartMaxPrice"
            options={{
              valueAsNumber: true,
              validate: (value) => {
                const cartMinPrice = getValues("cartMinPrice");
                if (isValidNumber(value) && isValidNumber(cartMinPrice)) {
                  return (
                    value! > cartMinPrice! ||
                    "Le montant maximum doit être supérieur au montant minimum"
                  );
                }
                return true;
              },
            }}
            type="number"
            step="any"
          />
        </div>
        <div className="col">
          <ResourceInput<DeliveryMode>
            label="Poids minimum de la commande (en g)"
            name="cartMinWeight"
            options={{
              min: {
                value: 0,
                message: "Le poids minimum doit être supérieur ou égal à 0",
              },
              valueAsNumber: true,
              validate: (value) => {
                const cartMaxWeight = getValues("cartMaxWeight");
                if (isValidNumber(value) && isValidNumber(cartMaxWeight)) {
                  return (
                    value! < cartMaxWeight! ||
                    "Le poids minimum doit être inférieur au poids maximum"
                  );
                }
                return true;
              },
            }}
            type="number"
            step="any"
          />
        </div>
        <div className="col">
          <ResourceInput<DeliveryMode>
            label="Poids maximum de la commande (en g)"
            name="cartMaxWeight"
            options={{
              valueAsNumber: true,
              validate: (value) => {
                const cartMinWeight = getValues("cartMinWeight");
                if (isValidNumber(value) && isValidNumber(cartMinWeight)) {
                  return (
                    value! > cartMinWeight! ||
                    "Le poids maximum doit être supérieur au montant minimum"
                  );
                }
              },
            }}
            type="number"
            step="any"
          />
        </div>
      </div>
      <ControlledSimpleSelect<DeliveryMode, number>
        label="Type de livraison"
        name="deliveryModeType"
        rules={{
          required: "Le type de livraison est obligatoire",
        }}
        options={[
          { value: DeliveryModeType.Store, label: "Livré en magasin" },
          { value: DeliveryModeType.HomeDelivery, label: "Livré à domicile" },
        ]}
      />
      {deliveryModeType != null &&
        deliveryModeType === DeliveryModeType.HomeDelivery && (
          <>
            <ControlledTransportersSelect<DeliveryMode>
              label="Transporteur"
              isClearable
              name="transporterId"
            />
            {fields?.map((f, i) => (
              <React.Fragment key={f.id}>
                <ResourceHiddenNumberInput<DeliveryMode>
                  name={`deliveryModesZones.${i}.deliveryZoneId`}
                />
              </React.Fragment>
            ))}

            <DeliveryZonesSelect<true>
              isMulti
              idPropsValue={resourceIds}
              onIdPropsValueChanged={replaceResources}
              label="Zones de livraison"
              name="deliveryModesZones"
            />
          </>
        )}
    </>
  );
};

export default EditDeliveryModeForm;
function isValidNumber(value: Nullable<number>) {
  return typeof value === "number" && !Number.isNaN(value);
}
