import React, { useCallback } from "react";
import { CountriesSelect } from "../../components/selects/CountriesSelect";
import DeliveryZone from "../../models/DeliveryZone";
import DeliveryZoneCountry from "../../models/DeliveryZoneCountry";
import { ResourceHiddenNumberInput } from "../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceInput } from "../../resources/components/inputs/ResourceInput";
import { useResourcesSelectFieldArray } from "../../resources/components/selects/hooks/useResourcesSelectFieldArray";

const EditDeliveryZoneForm = () => {
  const convertToResource = useCallback(
    (p: number) => ({ countryId: p } as DeliveryZoneCountry),
    []
  );
  const convertToResourceId = useCallback(
    (p: DeliveryZoneCountry) => p.countryId,
    []
  );
  const { fields, replaceResources, resourceIds } =
    useResourcesSelectFieldArray<DeliveryZone, number>({
      convertToResource,
      convertToResourceId,
      name: "deliveryZonesCountries",
      rules: { minLength: { value: 0, message: "Au moins un pays" } },
    });

  return (
    <>
      <ResourceHiddenNumberInput<DeliveryZone> name="id" />
      <ResourceInput<DeliveryZone>
        name="name"
        label="Nom*"
        options={{
          required: "Le nom de la zone de livraison est obligatoire",
          maxLength: {
            value: 50,
            message:
              "Le nom de la zone de livraison ne doit pas dépasser 50 caractères",
          },
        }}
      />
      <ResourceInput<DeliveryZone>
        label="Expression régulière de validation des codes postaux"
        name="zipCodeRegEx"
        options={{ required: false }}
      />
      {fields?.map((f, i) => (
        <React.Fragment key={f.id}>
          <ResourceHiddenNumberInput<DeliveryZone>
            name={`deliveryZonesCountries.${i}.countryId`}
            value={f.countryId}
          />
        </React.Fragment>
      ))}
      <CountriesSelect<true>
        isMulti
        idPropsValue={resourceIds}
        onIdPropsValueChanged={replaceResources}
        name="deliveryZonesCountries"
        label="Pays de livraison"
      />
    </>
  );
};

export default EditDeliveryZoneForm;
