import {
  Controller,
  FieldPath,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import Input, { FormControl, InputProps } from "../../../components/Input";
import MediaPreviewer, {
  MediaType,
  useFileUrlPreview,
} from "../../../pages/Animations/AdsMedia/components/MediaPreviewer";
import { Resource } from "../../../resources/types";

type ResourceFileInputProps = {
  mediaType: MediaType;
};
export const ResourceFileInput = <
  TResource extends Resource,
  TFieldName extends FieldPath<TResource> = FieldPath<TResource>
>(
  props: Omit<InputProps, "name"> &
    ResourceFileInputProps & {
      name: TFieldName;
      rules?: Omit<
        RegisterOptions<TResource, TFieldName>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >;
    }
) => {
  const { name, rules, mediaType, ...otherProps } = props;

  const { watch } = useFormContext();
  const imageUrl = watch(name);

  const previewMediaFileUrl = useFileUrlPreview(
    typeof imageUrl === "string" ? undefined : imageUrl
  ); // Get a local url for the file input

  return (
    <div className="d-flex flex-column gap-2">
      <FormControl
        formControl={
          <Controller<TResource, TFieldName>
            name={name}
            rules={rules}
            render={({
              field: { onChange, value, ...otherRenderProps },
              formState: { isSubmitting, errors },
            }) => (
              <Input
                type="file"
                errorMessage={errors[name]?.message as string}
                isInvalid={errors[name] != null}
                disabled={isSubmitting}
                onChange={(e: any) => {
                  onChange(e.target.files);
                }}
                {...otherRenderProps}
                {...otherProps}
              />
            )}
          />
        }
      />

      <MediaPreviewer
        mediaUrl={previewMediaFileUrl ?? imageUrl}
        mediaType={mediaType}
      />
    </div>
  );
};
