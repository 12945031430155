import { useCallback, useMemo, useState } from "react";
import { PropsValue } from "react-select";
import { ListResourceFilters } from "../../../../models/filters/ListResourceFilters";
import { useSWRListResource } from "../../../../resources/hooks/useSWRListResource";
import { useSWRReadResource } from "../../../../resources/hooks/useSWRReadResource";
import { Resource } from "../../../../resources/types";
import { ResourceIdentifier } from "../../../../resources/types/resourceIdentifier";

type UseReadResourceFromSelectProps<
  TResourceName extends string,
  TListResourceFilters = unknown
> = {
  resourceName: TResourceName;
  filters?: TListResourceFilters;
};

export const useReadResourceFromSelect = <
  TResource extends Resource,
  TResourceName extends string = string,
  TResourceIdentifier extends ResourceIdentifier = ResourceIdentifier
>(
  props: UseReadResourceFromSelectProps<TResourceName>
) => {
  const { resourceName } = props;
  const [resourceId, setResourceId] = useState<
    TResourceIdentifier | undefined
  >();

  const onResourceIdChanged = useCallback(
    (s: PropsValue<TResourceIdentifier> | undefined) => {
      if (Array.isArray(s)) {
        const [first] = s;
        setResourceId(first);
      } else {
        setResourceId(s as TResourceIdentifier);
      }
    },
    []
  );

  const { data: { data } = {} } = useSWRReadResource<TResourceName, TResource>({
    resourceName,
    id: resourceId,
  });
  const resource = useMemo(
    () => (resourceId != null ? data : undefined),
    [data, resourceId]
  );
  return {
    resource: resourceId != null ? resource : undefined,
    onResourceIdChanged,
    resourceId,
    setResourceId,
  };
};

type ListResourceFiltersWithoutIds = Omit<ListResourceFilters, "ids">;
export const useReadResourcesFromSelect = <
  TResource extends unknown,
  TResourceName extends string = string,
  TResourceIdentifier extends ResourceIdentifier = ResourceIdentifier,
  TListResourceFilters extends ListResourceFiltersWithoutIds = ListResourceFiltersWithoutIds
>(
  props: UseReadResourceFromSelectProps<TResourceName, TListResourceFilters>
) => {
  const { resourceName, filters = {} } = props;
  const [resourceIds, setResourceIds] = useState<
    TResourceIdentifier[] | undefined
  >();

  const onResourceIdsChanged = useCallback(
    (s: PropsValue<TResourceIdentifier> | undefined) => {
      if (Array.isArray(s)) {
        setResourceIds(s as TResourceIdentifier[]);
      }
    },
    []
  );

  const { data: { data: resources } = {} } = useSWRListResource<
    TResourceName,
    TResource
  >({
    resourceName,
    filters: Object.assign(filters, { ids: resourceIds }),
    canFetch: resourceIds != null && resourceIds.length > 0,
  });

  return { resources, onResourceIdsChanged, resourceIds, setResourceIds };
};
