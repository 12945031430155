import { useMemo } from "react";
import { ArticleAttribute } from "../../../models/ArticleAttribute";
import { ArticleAttributesFilters } from "../../../models/filters/ArticleAttributesFilters";
import { ListView } from "../../../resources/components/lists/ListView";
import {
  ResourceColumn,
  ResourceTable,
} from "../../../resources/components/lists/ResourceTable";
import { ResourceName } from "../../../resources/types/resourceName";

type ArticleAttributesProps = {
  articleId?: number;
};
export const ArticleAttributes = ({ articleId }: ArticleAttributesProps) => {
  const columns: ResourceColumn<ArticleAttribute>[] = useMemo(
    () => [
      {
        accessor: (p) => p.attributeId,
        header: "Attribut (Code)",
        expand: "attributes",
        getCell: (d, v, expandedList) => {
          const attribute = expandedList?.data.items.find(
            (a) => a.id === d.attributeId
          );
          return attribute?.code;
        },
      },
      {
        accessor: (p) => p.attributeId,
        header: "Attribut (Nom)",
        expand: "attributes",
        getCell: (d, v, expandedList) => {
          const attribute = expandedList?.data.items.find(
            (a) => a.id === d.attributeId
          );
          return attribute?.name;
        },
      },
      {
        accessor: "value",
        header: "Valeur",
      },
    ],
    []
  );
  return (
    <ListView<ResourceName, ArticleAttribute, ArticleAttributesFilters>
      defaultFilters={{ articleId }}
      resourceName="article-attributes"
    >
      <ResourceTable<ArticleAttribute> columns={columns} />
    </ListView>
  );
};
