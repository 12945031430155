import { CreatePage } from "../../resources/components/CreatePage";
import EditDeliveryModeForm from "./EditDeliveryModeForm";

const CreateDeliveryModePage = () => {
  return (
    <CreatePage>
      <EditDeliveryModeForm />
    </CreatePage>
  );
};

export default CreateDeliveryModePage;
