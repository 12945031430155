import * as React from "react";

type FilterCardProps = {
  header: string;
  isInvalid?: boolean;
  children: React.ReactNode;
};
const FilterCard = (props: FilterCardProps) => {
  return (
    <div
      className={`card h-100 ${props.isInvalid ? "border-danger" : undefined}`}
    >
      <div className="card-header">{props.header}</div>
      <div className="card-body">
        <div className="card-text">{props.children}</div>
      </div>
    </div>
  );
};

export default FilterCard;
