import { LogisticOperationStatus } from "../../../models/LogisticOperation";

export const displayLogisticOperationStatus = (
  type: LogisticOperationStatus
): string => {
  return type === LogisticOperationStatus.Archived
    ? "Archivée"
    : type === LogisticOperationStatus.Deleted
    ? "Supprimée"
    : type === LogisticOperationStatus.Validated
    ? "Validée"
    : "";
};
