import { useIsAuthenticated } from "@azure/msal-react";
import { useContext } from "react";
import { Dropdown, Form, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { MdSettings } from "react-icons/md";
import { LoggedInUserContext } from "../../LoggedInBackOffice";
import image from "../../assets/cataleeze-white-23x26.png";
import { useLogout } from "../../hooks/useLogout";
import { CustomerLink } from "../navigation/CustomerLink";
import CustomerSelect from "../selects/CustomerSelect";
import ServerAdminTemplate from "./ServerAdminTemplate";
import TenantAdminTemplate from "./TenantAdminTemplate";

export const TopBar = () => {
  const userData = useContext(LoggedInUserContext);
  const isAuthenticated = useIsAuthenticated();

  const logout = useLogout();
  return (
    <Navbar variant="dark" className="bg-cataleeze sticky-top header">
      <div className="container-fluid">
        <CustomerLink to="/" className="navbar-brand">
          <span>
            <img alt="Cataleeze" src={image} className="me-2" />
            Cataleeze
          </span>
        </CustomerLink>

        <Navbar.Toggle />
        <Navbar.Collapse>
          <div className="me-auto" />
          <div className="col-md-4">
            <CustomerSelect
              onChange={(id) => userData.changeCurrentCustomer(id)}
              customers={userData.canManageCustomers}
              disabled={!userData.canChangeCustomer}
              value={userData.currentCustomer}
            />
          </div>
          <Nav>
            {isAuthenticated && (
              <NavDropdown
                align="end"
                title={<MdSettings />}
                id="basic-nav-dropdown"
              >
                <TenantAdminTemplate ignoreIsShowingTenantAdminMode>
                  <Dropdown.Item onClick={userData.toogleTenantAdminMode}>
                    <Form.Check
                      type="switch"
                      label="Mode Tenant Admin"
                      checked={userData.isShowingTenantAdminMode}
                    />
                  </Dropdown.Item>
                </TenantAdminTemplate>
                <ServerAdminTemplate ignoreIsShowingServerAdminMode>
                  <Dropdown.Item onClick={userData.toogleServerAdminMode}>
                    <Form.Check
                      type="switch"
                      label="Mode Server Admin"
                      checked={userData.isShowingServerAdminMode}
                    />
                  </Dropdown.Item>
                </ServerAdminTemplate>

                <Dropdown.Item onClick={logout}>Déconnexion</Dropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};
