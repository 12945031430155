import { ProviderProps, createContext, useContext } from "react";

type ResourceFormContextValue = {
  setExternalBusy: (isBusy: boolean) => void;
};

const ResourceFormContext = createContext<ResourceFormContextValue>({
  setExternalBusy() {},
});

export const ResourceFormContextProvider = ({
  children,
  value,
}: ProviderProps<ResourceFormContextValue>) => (
  <>
    {value ? (
      <ResourceFormContext.Provider value={value}>
        {children}
      </ResourceFormContext.Provider>
    ) : (
      children
    )}
  </>
);
export const useResourceFormContext = () => {
  const context = useContext(ResourceFormContext);
  return context;
};
