import { Controller } from "react-hook-form";
import HtmlEditor from "../../components/HtmlEditor";
import { ControlledCountriesSelect } from "../../components/selects/CountriesSelect";
import Store from "../../models/Store";
import { ResourceHiddenNumberInput } from "../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceInput } from "../../resources/components/inputs/ResourceInput";
import OpeningHoursFields from "./OpeningHoursFields";

const EditStoreForm = () => {
  return (
    <>
      <ResourceHiddenNumberInput<Store> name="id" />
      <ResourceInput<Store>
        label="Code*"
        name="code"
        options={{
          required: {
            message: "Le code du magasin est obligatoire",
            value: true,
          },
          maxLength: {
            message: "Le code du magasin ne peut pas dépasser 50 caractères",
            value: 50,
          },
        }}
      />

      <ResourceInput<Store>
        label="Nom*"
        name="name"
        options={{
          required: {
            message: "Le nom du magasin est obligatoire",
            value: true,
          },
          maxLength: {
            message: "Le nom du magasin ne peut pas dépasser 50 caractères",
            value: 50,
          },
        }}
      />

      <br />
      <ResourceInput<Store>
        label="Adresse"
        name="address"
        options={{
          maxLength: {
            message: "L'adresse du magasin ne peut pas dépasser 150 caractères",
            value: 150,
          },
        }}
      />

      <div className="row">
        <ResourceInput<Store>
          className="col-md-5"
          label="Ville"
          name="city"
          options={{
            maxLength: {
              message:
                "La ville du magasin ne peut pas dépasser 150 caractères",
              value: 150,
            },
          }}
        />
        <ResourceInput<Store>
          className="col-md-2"
          label="Code postal"
          name="zipCode"
          options={{
            maxLength: {
              message:
                "Le code postal du magasin ne peut pas dépasser 50 caractères",
              value: 50,
            },
          }}
        />
        <div className="col-md-5">
          <ControlledCountriesSelect<Store>
            name="countryId"
            label="Pays*"
            rules={{ required: "Le pays est obligatoire" }}
          />
        </div>
      </div>

      <div className="row">
        <ResourceInput<Store>
          className="col"
          label="Latitude"
          name="latitude"
          options={{
            min: -90,
            max: 90,
            valueAsNumber: true,
          }}
          step="any"
          min="-90"
          max="90"
          type="number"
        />

        <ResourceInput<Store>
          className="col"
          label="Longitude"
          name="longitude"
          options={{
            min: -180,
            max: 180,
            valueAsNumber: true,
          }}
          step="any"
          min="-180"
          max="180"
          type="number"
        />
      </div>

      <br />
      <ResourceInput<Store>
        label="Téléphone"
        name="phone"
        options={{
          maxLength: {
            message:
              "Le téléphone du magasin ne peut pas dépasser 50 caractères",
            value: 50,
          },
        }}
      />
      <div className="form-group">
        <label>Message d'accueil</label>
        <Controller
          name="welcomeMessage"
          render={({ field: { onChange, onBlur, value } }) => (
            <HtmlEditor
              wrapperClassName=""
              editorClassName="form-control"
              html={value}
              onBlur={onBlur}
              onHtmlChanged={(html) => onChange(html)}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "colorPicker",
                  // 'link',
                  // 'embedded',
                  // 'emoji',
                  // 'image',
                  "remove",
                  "history",
                ],
              }}
            />
          )}
        />
      </div>
      <br />

      <OpeningHoursFields />
    </>
  );
};

export default EditStoreForm;
