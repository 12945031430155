import { useCallback } from "react";
import { Spinner } from "react-bootstrap";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { Thumbnail } from "../../../components/Thumbnail";
export interface WorkflowItem {
  id: number;
  label?: string;
  thumbnailUrl: string;
  isLoading?: boolean;
  index?: number;
}
export interface WorkflowListItemProps {
  canSortLeft: boolean;
  canSortRight: boolean;
  onSortLeft: (item: WorkflowItem) => void;
  onSortRight: (item: WorkflowItem) => void;
  Item: WorkflowItem;
  Actions: JSX.Element;
}

const WorkflowListItem = (props: WorkflowListItemProps) => {
  const onSortLeft = useCallback(() => {
    props.onSortLeft(props.Item);
  }, [props]);

  const onSortRight = useCallback(() => {
    props.onSortRight(props.Item);
  }, [props]);

  return (
    <div className="card h-100">
      <div className="row g-0 align-items-stretch justify-content-center h-100">
        <div
          className="col-md mh-100"
          style={{
            minWidth: "5rem",
            minHeight: "5rem",
          }}
        >
          <Thumbnail url={props.Item.thumbnailUrl} className=" " />
        </div>
        <div className="col-md-auto text-truncate text-wrap">
          <div className="card-body h-100 d-flex flex-column align-content-end py-2 px-3">
            <h6 className="card-title flex-grow-1  ">
              {props.Item.isLoading && (
                <Spinner className="me-1" animation="border" size="sm" />
              )}
              {props.Item.label}
            </h6>
            <div className="card-text  flex-shrink-1">
              <div className="btn-group btn-group-sm">
                {props.Actions}

                {props.canSortLeft && (
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={onSortLeft}
                  >
                    <MdChevronLeft />
                  </button>
                )}

                {props.canSortRight && (
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={onSortRight}
                  >
                    <MdChevronRight />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowListItem;
