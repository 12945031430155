import { useFieldArray, useFormContext } from "react-hook-form";
import { MdDeleteForever } from "react-icons/md";
import { AttributeFilterContent } from "../types/AttributeFilterContent";
import { ExtendedAutomaticArticlesSelection } from "../types/ExtendedAutomaticArticlesSelection";
import { FilterContent } from "../types/FilterContent";
import FilterCard from "./FilterCard";

type ListFilterProps<T extends FilterContent | AttributeFilterContent> = {
  arrayName: "categories" | "brands" | "ex_attributes";
  header: string;
  text: string;
  renderInputCells: (t: T, index: number) => JSX.Element;
  renderAddRow: (append: (t: T) => void) => JSX.Element;
};
export function ListFilter<T extends FilterContent | AttributeFilterContent>(
  props: ListFilterProps<T>
) {
  const {
    formState: { isSubmitted, errors },
  } = useFormContext<ExtendedAutomaticArticlesSelection>();
  const { fields, remove, append } =
    useFieldArray<ExtendedAutomaticArticlesSelection>({
      name: props.arrayName,
    });

  return (
    <FilterCard
      header={props.header}
      isInvalid={isSubmitted && errors.query != null}
    >
      {props.text}
      <table className="table table-sm">
        <tbody>
          {fields.map((c, index) => {
            return (
              <tr key={c.id}>
                {props.renderInputCells(c as unknown as T, index)}
                <td>
                  <button
                    className="btn btn-outline-danger"
                    type="button"
                    onClick={() => remove(index)}
                  >
                    <MdDeleteForever onClick={() => remove(index)} />
                  </button>
                </td>
              </tr>
            );
          })}
          {props.renderAddRow(append)}
        </tbody>
      </table>
    </FilterCard>
  );
}
