import {
  ArticleQueryOperator,
  ArticlesQuery,
  SelectionFilter,
  SelectionFilterType,
} from "../../../../models/ArticlesSelection";
import { AttributeType } from "../../../../models/AttributeType";
import { AttributeFilterContent } from "../types/AttributeFilterContent";
import { ExtendedAutomaticArticlesSelection } from "../types/ExtendedAutomaticArticlesSelection";
import { FilterContent } from "../types/FilterContent";

export function getAutomaticSelection(filters?: SelectionFilter[]) {
  let minimum: number | undefined;
  let maximum: number | undefined;
  let isCentralOrderable = false;
  let isInStoreOrderable = false;
  const categories = new Array<FilterContent>();
  const brands = Array<FilterContent>();
  const attributes = new Array<AttributeFilterContent>();

  //chaque filter est un groupe du même type (stocks, cagegorie, attribut/valeur, marque)
  if (filters && filters.length > 0) {
    filters.forEach((groupFilter) => {
      if (
        groupFilter &&
        groupFilter.query &&
        groupFilter.query.filters &&
        groupFilter.query.filters.length > 0
      ) {
        //on est dans un groupe du même type (stocks, cagegorie, attribut/valeur, marque)
        const subFilters = groupFilter.query.filters;
        //le type du premier sous-filtre caractérise le groupe (même si les autres sous filtres ne sont pas du même type)
        const groupType = subFilters[0]?.type;

        //cas un groupe OU d'attributs avec plusieurs valeurs
        if (subFilters.length > 0 && subFilters.some((f) => f.query)) {
          if (attributes) {
            appendAttributeValues(subFilters, attributes);
          }
          return;
        }

        switch (groupType) {
          case SelectionFilterType.Price:
            const priceFilter = subFilters.find(
              (filter) => filter.type === SelectionFilterType.Price
            );
            if (priceFilter) {
              minimum = priceFilter.minimumValue;
              maximum = priceFilter.maximumValue;
            }
            break;
          case SelectionFilterType.CentralOrderable:
          case SelectionFilterType.InStoreOrderable:
            //groupe stocks
            isCentralOrderable = subFilters.some(
              (filter) => filter.type === SelectionFilterType.CentralOrderable
            );
            isInStoreOrderable = subFilters.some(
              (filter) => filter.type === SelectionFilterType.InStoreOrderable
            );
            break;
          case SelectionFilterType.Brand:
            subFilters.forEach((filter) => {
              if (filter.type === SelectionFilterType.Brand && filter.brandId) {
                brands.push({
                  value: filter.brandId,
                  label: filter.brandId,
                } as FilterContent);
              }
            });
            break;
          case SelectionFilterType.Category:
            if (categories) {
              subFilters.forEach((filter) => {
                if (
                  filter.type === SelectionFilterType.Category &&
                  filter.categoryId
                ) {
                  // const label = lodash.find(
                  //   allCategories,
                  //   (c: SearchSuggest) => c.id === filter.categoryId
                  // )?.label;

                  categories.push({
                    value: filter.categoryId,
                    // label: label ?? filter.categoryId,
                  } as FilterContent);
                }
              });
            }
            break;
          case SelectionFilterType.StringAttributeValue:
          case SelectionFilterType.BooleanAttributeValue:
          case SelectionFilterType.NumericAttributeValue:
            if (attributes) {
              appendAttributeValues(
                subFilters,
                // allAttributes ?? new Array<AttributeSearchSuggest>(),
                attributes
              );
            }
            break;
        }
      }
    });
  }
  return {
    isCentralOrderable,
    isInStoreOrderable,
    minimum,
    maximum,
    categories,
    brands,
    attributes,
  };
}

function appendAttributeValues(
  subFilters: SelectionFilter[],
  attributes: AttributeFilterContent[]
) {
  subFilters.forEach((filter) => {
    if (filter.query) {
      if (
        filter.query.operator === ArticleQueryOperator.Or &&
        filter.query.filters &&
        filter.query.filters.length > 0
      ) {
        //cas un groupe OU d'attributs avec plusieurs valeurs
        let attributeFilters = filter.query.filters.filter(
          (f) =>
            f.type === SelectionFilterType.StringAttributeValue ||
            f.type === SelectionFilterType.BooleanAttributeValue ||
            f.type === SelectionFilterType.NumericAttributeValue
        );
        if (attributeFilters && attributeFilters.length > 0) {
          // let attributeId = attributeFilters[0]?.attributeId;
          // const attribute = await readAttribute(attributeId)

          if (attributeFilters[0] != null) {
            let attributeFilter = attributeFilters[0];
            attributes.push({
              filterAttribute: {
                value:
                  attributeFilter.attributeId! /*, label: /*attribute.label*/,
              },
              // attributeType:  attributeFilter.type == SelectionFilterType.BooleanAttributeValue ? AttributeType.Boolean,
              filterAttributeValues: attributeFilters.map<FilterContent>(
                (f) => ({
                  value: f.attributeValue!,

                  // label:
                  //   attribute !== undefined &&
                  //   attribute.type === AttributeType.Boolean
                  //     ? f.attributeValue && JSON.parse(f.attributeValue)
                  //       ? "Oui"
                  //       : "Non"
                  //     : f.attributeValue ?? "",
                })
              ),
            });
          }
        }
      }
    } else {
      // cas d'un attribut seul
      if (
        (filter.type === SelectionFilterType.StringAttributeValue ||
          filter.type === SelectionFilterType.BooleanAttributeValue ||
          filter.type === SelectionFilterType.NumericAttributeValue) &&
        filter.attributeId &&
        filter.attributeValue
      ) {
        // const attribute = lodash.find(
        //   allAttributes,
        //   (c: AttributeSearchSuggest) => c.id === filter.attributeId
        // );
        // if (attribute) {
        attributes.push({
          filterAttribute: {
            value: filter.attributeId,
            // label: attribute?.label ?? filter.attributeId,
          },
          // attributeType:   attribute.type,
          filterAttributeValues: [
            {
              value: filter.attributeValue,
              // label:
              //   attribute !== undefined &&
              //   attribute.type === AttributeType.Boolean
              //     ? filter.attributeValue && JSON.parse(filter.attributeValue)
              //       ? "Oui"
              //       : "Non"
              //     : filter.attributeValue ?? "",
            } as FilterContent,
          ],
        });
        // }
      }
    }
  });
}

export function getQuery(s: ExtendedAutomaticArticlesSelection): ArticlesQuery {
  const filters = new Array<SelectionFilter>();
  appendPricesFilters(filters, s.minimum, s.maximum);
  appendStocksFilters(filters, s.isCentralOrderable, s.isInStoreOrderable);
  appendCategoriesFilters(filters, s.categories);
  appendBrandsFilters(filters, s.brands);
  appendAttributes(filters, s.ex_attributes);
  return {
    filters: filters,
    operator: ArticleQueryOperator.And,
  } as ArticlesQuery;
}
function appendAttributes(
  filters: SelectionFilter[],
  attributes?: AttributeFilterContent[]
) {
  if (attributes && attributes.length) {
    let subFilters: SelectionFilter[] = new Array<SelectionFilter>();
    attributes.forEach((a) => {
      if (a.filterAttributeValues.length > 1) {
        subFilters.push({
          query: {
            operator: ArticleQueryOperator.Or,
            filters: a.filterAttributeValues.map<SelectionFilter>((c) => ({
              attributeId: a.filterAttribute.value,
              attributeValue: c.value,
              type: getSelectionFilterType(a),
            })),
          },
        });
      } else if (a.filterAttributeValues.length === 1) {
        let c = a.filterAttributeValues[0];
        subFilters.push({
          attributeId: a.filterAttribute.value,
          attributeValue: c.value,
          type: getSelectionFilterType(a),
        });
      }
    });
    filters.push({
      query: {
        operator: ArticleQueryOperator.And,
        filters: subFilters,
      },
    });
  }
}

function getSelectionFilterType(
  a: AttributeFilterContent
): SelectionFilterType | undefined {
  let attributeType =
    typeof a.attributeType === "string"
      ? Number(a.attributeType)
      : a.attributeType;
  if (attributeType === AttributeType.Text) {
    return SelectionFilterType.StringAttributeValue;
  }
  if (attributeType === AttributeType.Boolean) {
    return SelectionFilterType.BooleanAttributeValue;
  }
  if (attributeType === AttributeType.Numeric) {
    return SelectionFilterType.NumericAttributeValue;
  }
}

function appendBrandsFilters(
  filters: SelectionFilter[],
  brands?: FilterContent[]
) {
  if (brands && brands.length) {
    filters.push({
      query: {
        operator: ArticleQueryOperator.Or,
        filters: brands.map<SelectionFilter>((c) => {
          return { brandId: c.value, type: SelectionFilterType.Brand };
        }),
      },
    });
  }
}
function appendCategoriesFilters(
  filters: SelectionFilter[],
  categories?: FilterContent[]
) {
  if (categories && categories.length) {
    filters.push({
      query: {
        operator: ArticleQueryOperator.Or,
        filters: categories.map<SelectionFilter>((c) => {
          return { categoryId: c.value, type: SelectionFilterType.Category };
        }),
      },
    });
  }
}

function appendStocksFilters(
  filters: SelectionFilter[],
  isCentralOrderable?: boolean,
  isInStoreOrderable?: boolean
) {
  if (isCentralOrderable || isInStoreOrderable) {
    const stockFilters = new Array<SelectionFilter>();
    if (isCentralOrderable) {
      stockFilters.push({ type: SelectionFilterType.CentralOrderable });
    }
    if (isInStoreOrderable) {
      stockFilters.push({ type: SelectionFilterType.InStoreOrderable });
    }
    filters.push({
      query: { operator: ArticleQueryOperator.Or, filters: stockFilters },
    });
  }
}
function isValidNumber(n: any) {
  return n !== undefined && !Number.isNaN(n) && (n as any) !== "";
}
function appendPricesFilters(
  filters: SelectionFilter[],
  minimumPrice?: number,
  maximumPrice?: number
) {
  if (isValidNumber(minimumPrice) || isValidNumber(maximumPrice)) {
    const pricesFilters = new Array<SelectionFilter>();
    const priceFilter: SelectionFilter = {
      type: SelectionFilterType.Price,
    };
    if (isValidNumber(minimumPrice)) {
      priceFilter.minimumValue = minimumPrice;
    }
    if (isValidNumber(maximumPrice)) {
      priceFilter.maximumValue = maximumPrice;
    }
    pricesFilters.push(priceFilter);
    filters.push({
      query: { operator: ArticleQueryOperator.Or, filters: pricesFilters },
    });
  }
}
