import { useMemo } from "react";
import { Alert } from "react-bootstrap";
import { FilterFieldData } from "../../../components/DynamicFieldData";
import { Thumbnail } from "../../../components/Thumbnail";
import { CustomerLink } from "../../../components/navigation/CustomerLink";
import AdMedia from "../../../models/AdMedia";
import { MediaFileType } from "../../../models/AnimationMedia";
import { AnimationMediasFilters } from "../../../models/filters/AnimationMediasFilters";
import { CreateResourceButton } from "../../../resources/components/buttons/CreateResourceButton";
import { ListPage } from "../../../resources/components/lists/ListPage";
import { PaginationListResource } from "../../../resources/components/lists/PaginationListResource";
import { useResourceUrlPathResolver } from "../../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../../resources/types/resourceName";

const AdMediaPage = () => {
  const mediaTypeOptions = useMemo(
    () => [
      { label: "Image", value: MediaFileType.Image },
      { label: "Vidéo", value: MediaFileType.Video },
    ],
    []
  );

  const filters: FilterFieldData<AnimationMediasFilters>[] = useMemo(
    () => [
      {
        fieldName: "label",
        type: "text",
        label: "Nom",
        placeholder: "Nom",
      },
      {
        fieldName: "mediaType",
        type: "number-select",
        label: "Type de média",
        placeholder: "Type de média",
        isClearable: true,
        options: mediaTypeOptions,
      },
    ],
    [mediaTypeOptions]
  );

  return (
    <ListPage
      filterFieds={filters}
      actions={<CreateResourceButton />}
      showChildWithoutFilters
    >
      <PaginationListResource<AdMedia>
        resources={(data) => (
          <>
            {data?.data?.items && (
              <>
                <div className="row g-2 row-cols-2 row-cols-sm-3  row-cols-md-4 row-cols-lg-5 row-cols-xl-6">
                  {data.data.items.map((c) => (
                    <div className="col" key={c.id}>
                      <AdMediaItem c={c} />
                    </div>
                  ))}
                </div>
                {data.data.items.length === 0 && (
                  <Alert variant="info">Aucune publicité.</Alert>
                )}
              </>
            )}
          </>
        )}
      />
    </ListPage>
  );
};

const AdMediaItem = (props: { c: AdMedia }) => {
  const { c } = props;
  const {
    resourceUrlPathResolver: { edit },
  } = useResourceUrlPathResolver<ResourceName>();

  return (
    <div className="card h-100 ">
      <div className="row g-0 align-items-stretch justify-content-center h-100">
        <div
          className="col-md"
          style={{
            minWidth: "5rem",
          }}
        >
          <Thumbnail
            url={c.thumbnailUrl}
            className="card-img-left card-thumbnails-left"
          />
        </div>
        <div className="col-md-auto">
          <div className="card-body py-2 px-3 h-100 ">
            <h6 className="card-title">{c.label}</h6>
            <p className="card-text">
              <small className="text-muted">
                {c.mediaType === MediaFileType.Image ? "IMAGE" : "VIDEO"}
              </small>
            </p>
            <CustomerLink
              className="card-link btn btn-sm btn-outline-primary "
              to={edit("animations/ads", c.id)}
            >
              Éditer
            </CustomerLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdMediaPage;
