import CreateCampaignPage from "../components/CreateCampaignPage";
import EditOrCreateAnimationMediasCampaign from "../components/EditOrCreateAnimationMediasCampaign";

const CreateHomeAdsCampaignPage = () => {
  return (
    <CreateCampaignPage>
      <EditOrCreateAnimationMediasCampaign />
    </CreateCampaignPage>
  );
};

export default CreateHomeAdsCampaignPage;
