import { useEffect, useState } from "react";

export interface BackOfficeConfiguration {
  InternalServicesUrl: string;
  AuthorityUrl: string;
}

const useConfig = function () {
  const [state, setState] = useState({
    config: undefined,
    error: undefined,
  } as {
    config?: BackOfficeConfiguration;
    error: any;
  });

  useEffect(() => {
    fetch("/api/config", { headers: {}, credentials: "same-origin" })
      .then((r) => r.json())
      .then((cfg) =>
        setState({ config: cfg as BackOfficeConfiguration, error: undefined })
      )
      .catch((e) => setState({ config: undefined, error: e }));
  }, []);

  return state;
};
export default useConfig;
