import { useFormContext } from "react-hook-form";
import Input from "../../../components/Input";
import ApplicationUser from "../../../models/ApplicationUser";

export interface EditApplicationUserFormProps {
  mode: "edit" | "create";
}
const EditApplicationUserForm = ({ mode }: EditApplicationUserFormProps) => {
  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext<ApplicationUser>();

  return (
    <>
      <input
        readOnly
        type="hidden"
        defaultValue="00000000-0000-0000-0000-000000000000"
        {...register("id")}
      />
      <Input
        label="Email*"
        errorMessage={errors.email?.message}
        isInvalid={errors.email !== undefined}
        disabled={isSubmitting}
        readOnly={mode === "edit"}
        {...register("email")}
      />
    </>
  );
};

export default EditApplicationUserForm;
