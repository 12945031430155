import { useMemo } from "react";
import { CategoryArticle } from "../../../models/CategoryArticle";
import { CategoryArticlesFilters } from "../../../models/filters/CategoryArticlesFilters";
import { ListView } from "../../../resources/components/lists/ListView";
import {
  ResourceColumn,
  ResourceTable,
} from "../../../resources/components/lists/ResourceTable";
import { useResourceUrlPathResolver } from "../../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../../resources/types/resourceName";

type ArticleCategoriesProps = {
  articleId?: number;
};

export const ArticleCategories = ({ articleId }: ArticleCategoriesProps) => {
  const {
    resourceUrlPathResolver: { edit },
  } = useResourceUrlPathResolver<ResourceName>();

  const columns: ResourceColumn<CategoryArticle>[] = useMemo(
    () => [
      {
        accessor: "categoryId",
        header: "Catégorie (Id)",
        navigationPath: (_o, value) =>
          value != null ? edit("categories", value) : undefined,
      },
      {
        accessor: (c) => c.category?.code,
        header: "Catégorie (Code)",
      },
      {
        accessor: (c) => c.category?.label,
        header: "Catégorie (Libellé)",
      },
      {
        accessor: "ranking",
        header: "Ranking",
      },
    ],
    [edit]
  );
  return (
    <ListView<ResourceName, CategoryArticle, CategoryArticlesFilters>
      defaultFilters={{ articleId }}
      resourceName="category-articles"
    >
      <ResourceTable<CategoryArticle> columns={columns} />
    </ListView>
  );
};
