import { useCallback, useMemo, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useFormContext } from "react-hook-form";
import { PropsValue } from "react-select";
import { CellProps, Column, TableState } from "react-table";
import { useCurrentCustomer } from "../../../../LoggedInBackOffice";
import PaginationTable from "../../../../components/PaginationTable";
import { StoresSelect } from "../../../../components/selects/StoresSelect";
import useTenantConfiguration from "../../../../hooks/tenantConfiguration/useTenantConfiguration";
import { useElasticSearchArticles } from "../../../../hooks/useElasticSearchArticles";
import { AutomaticArticlesSelection } from "../../../../models/ArticlesSelection";
import { HitResult } from "../../../../tools/elasticsearch/models/HitResult";

type ModalProps = {
  isShowing: boolean;
  hide: () => void;
};

const initialState: Partial<TableState<HitResult>> = {
  pageIndex: 0,
  pageSize: 20,
};

export const ArticlesPreviewerModal = ({ isShowing, hide }: ModalProps) => {
  const [storeId, setStoreId] = useState<number | undefined>();
  const onStoreIdChanged = useCallback((s: PropsValue<number> | undefined) => {
    if (Array.isArray(s)) {
      const [first] = s;
      setStoreId(first);
    } else {
      setStoreId(s as number);
    }
  }, []);

  const { getValues } = useFormContext<AutomaticArticlesSelection>();
  const [hitsSize, setHitsSize] = useState<number>(initialState.pageSize!);
  const [hitFrom, setHitFrom] = useState<number>(initialState.pageIndex!);

  const customer = useCurrentCustomer();
  const { tenantConfiguration } = useTenantConfiguration(customer);
  const articlesSelection = useMemo(() => getValues(), [getValues]);

  const { elasticResponse, isLoading } = useElasticSearchArticles(
    hitFrom,
    hitsSize,
    tenantConfiguration?.showAvailableProducts,
    tenantConfiguration?.showOnlyParentProducts,
    tenantConfiguration?.priceDisplay,
    articlesSelection,
    storeId
  );

  const columns: Column<HitResult>[] = useMemo(
    () => [
      {
        Header: " ",
        accessor: (hit) => [
          hit._source.syncedThumbnailImageUrl,
          hit._source.syncedImageUrl,
        ],
        Cell: ({ cell: { value } }: CellProps<HitResult>) => (
          <img
            className="img-thumbnail"
            style={{ width: "5em" }}
            src={value[0]}
            alt=""
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = value[1];
            }}
          />
        ),
      },
      {
        Header: "Référence",
        accessor: (hit) => hit._source.reference,
      },
      {
        Header: "Nom",
        accessor: (hit) => hit._source.name,
      },
    ],
    []
  );
  const hitsTotal = elasticResponse?.searchResult?.hits?.total?.value ?? 0;
  const pagesTotal = useMemo(
    () => Math.floor(hitsTotal / hitsSize) + 1,
    [hitsSize, hitsTotal]
  );
  const onPageIndexChanged = useCallback(
    (pageIndex: number) => setHitFrom(pageIndex * hitsSize),
    [hitsSize]
  );

  return isShowing
    ? createPortal(
        <Modal show={isShowing} onHide={hide} size="xl">
          <Modal.Header>
            <Modal.Title>
              {storeId != null && (
                <>
                  {elasticResponse &&
                    (elasticResponse.error ||
                    !elasticResponse.searchResult ||
                    elasticResponse.searchResult.hits?.total?.value === 0 ? (
                      <>Aucun article trouvé</>
                    ) : elasticResponse.searchResult.hits.total.value > 1 ? (
                      <>
                        {elasticResponse?.searchResult?.hits?.total?.value}{" "}
                        articles trouvés
                      </>
                    ) : (
                      <>
                        {elasticResponse?.searchResult?.hits?.total?.value}{" "}
                        article trouvé
                      </>
                    ))}

                  {(!elasticResponse || isLoading) && (
                    <Spinner animation="border" size="sm" />
                  )}
                </>
              )}

              {storeId == null && <>Sélectionner un magasin</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <StoresSelect
                placeholder="Sélectionner un magasin"
                idPropsValue={storeId}
                onIdPropsValueChanged={onStoreIdChanged}
                autoSelectFirst
              />

              {elasticResponse?.error && (
                <>Impossible de charger vos articles</>
              )}
              {isLoading && <>Chargement de vos articles</>}

              {elasticResponse &&
                !isLoading &&
                elasticResponse?.searchResult?.hits?.hits?.length !==
                  undefined &&
                elasticResponse.searchResult.hits.hits.length === 0 && (
                  <>Aucun article ne correspond à ces critères</>
                )}

              <PaginationTable
                responsive
                size="sm"
                hover
                columns={columns}
                data={elasticResponse?.searchResult?.hits.hits ?? []}
                pageCount={pagesTotal}
                totalItemsCount={
                  elasticResponse?.searchResult?.hits.total.value ?? 0
                }
                pageIndexChanged={onPageIndexChanged}
                pageSizeChanged={setHitsSize}
                initialState={initialState}
                hideTableHead={true}
              />
            </>
          </Modal.Body>
        </Modal>,
        document.body
      )
    : null;
};
