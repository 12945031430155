import { useCallback } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { Country } from "../../models/Country";
import { ListCondition } from "../../models/ListCondition";
import { CountriesFilters } from "../../models/filters/CountriesFilters";
import {
  ControlledResourcesSelect,
  ControlledResourcesSelectProps,
  ReactSelectProps,
  ResourcesSelect,
} from "../../resources/components/selects/ResourcesSelect";
import { ResourceName } from "../../resources/types/resourceName";
import ReactSelectOption from "../ReactSelectOption";

const useCountriesSelectController = <
  TCountry extends Country = Country,
  TNumber extends number = number,
  TFilters extends CountriesFilters = CountriesFilters
>() => {
  const convertToOption = useCallback(
    (value: TCountry | undefined): ReactSelectOption<TNumber> | undefined =>
      value != null
        ? {
            label: `${value.name}`,
            value: value.id as TNumber,
          }
        : undefined,
    []
  );
  const transformToFilters = useCallback(
    (search: string) =>
      ({
        name: search,
        listCondition: ListCondition.Or,
      } as TFilters),
    []
  );
  const resourceName: ResourceName = "countries";
  return { convertToOption, resourceName, transformToFilters };
};

type CountriesSelectProps<IsMulti extends boolean> = ReactSelectProps<
  number,
  IsMulti
>;

export const CountriesSelect = <IsMulti extends boolean>(
  props: CountriesSelectProps<IsMulti>
) => {
  const c = useCountriesSelectController();
  return (
    <ResourcesSelect<Country, number, IsMulti, ResourceName, CountriesFilters>
      {...c}
      {...props}
    />
  );
};

type ControlledCountriesSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledResourcesSelectProps<
  CountriesSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledCountriesSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledCountriesSelectProps<TFieldValues, TName, IsMulti>
) => {
  const c = useCountriesSelectController();

  return (
    <ControlledResourcesSelect<
      Country,
      number,
      IsMulti,
      ResourceName,
      CountriesFilters,
      TFieldValues,
      TName
    >
      {...c}
      {...props}
      name={props.name}
    />
  );
};
