import React from "react";
import {
  UseIsAdminRoleProps,
  useIsUserInServerAdminRole,
} from "../../hooks/useIsUserInServerAdminRole";

type ServerAdminTemplateProps = React.PropsWithChildren & UseIsAdminRoleProps;
const ServerAdminTemplate = (props: ServerAdminTemplateProps) => {
  const { isAllowed } = useIsUserInServerAdminRole(props);
  return <>{isAllowed && props.children}</>;
};
export default ServerAdminTemplate;
