import { useMemo } from "react";
import { redirect } from "react-router";
import {
  clearStoredPath,
  getStoredPath,
} from "./../tools/LocalRedirectUrlStorage";
function isValidUrl(url: string) {
  const whitelist = [/^\/.*$/];

  return whitelist.some((r) => r.test(url));
}

const SigninRedirect = function () {
  let storedPath = getStoredPath();
  const url: string = useMemo(() => {
    if (!storedPath || !isValidUrl(storedPath)) {
      console.warn("Invalid local redirect URL, redirecting to root instead");
      return "/";
    }

    return storedPath;
  }, [storedPath]);
  clearStoredPath();

  redirect(url);
  return <></>;
};

export default SigninRedirect;
