import { Form, FormControlProps } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FormControl } from "../../../components/Input";
import { Resource } from "../../../resources/types";
import { ResourceInputProps } from "./ResourceInput";

export const ResourceTextAreaInput = <TResource extends Resource>(
  props: ResourceInputProps<TResource> &
    FormControlProps &
    React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >
) => {
  const { name, options, ...otherProps } = props;

  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext();

  return (
    <FormControl
      errorMessage={errors[name]?.message as string}
      isInvalid={errors[name] != null}
      disabled={isSubmitting}
      formControl={
        <Form.Control
          as="textarea"
          isInvalid={errors[name] != null}
          {...register(name, options)}
          {...otherProps}
        />
      }
    />
  );
};
