import { useEditOrCreateSubmitForm } from "../../../hooks/useSubmitForm";
import AnimationMedia from "../../../models/AnimationMedia";
import AnimationMediasCampaign from "../../../models/AnimationMediasCampaign";
import { useResourceApiPathResolverContext } from "../../../resources/hooks/useResourceApiPathResolverContext";
import { useResourceName } from "../../../resources/hooks/useResourceName";
import formatBeforeSubmitCampaign from "./formatBeforeSubmitCampaign";

const useSubmitAnimationMediasCampaign = function <
  T extends AnimationMediasCampaign<U>,
  U extends AnimationMedia
>(campaignId?: number, OnAnimationMediasCampaignSaved?: (campaign: T) => void) {
  const { resourceName } = useResourceName();
  const { resourceApiPathResolver: { createUrl } = {} } =
    useResourceApiPathResolverContext();

  const { onSubmit, errors, isBusy } = useEditOrCreateSubmitForm<T>(
    createUrl != null ? createUrl(resourceName) : "",
    campaignId?.toString(),
    (c) => {
      if (OnAnimationMediasCampaignSaved) {
        OnAnimationMediasCampaignSaved(c);
      }
    }
  );
  const beforeSubmit = (campaign: T) => {
    campaign = formatBeforeSubmitCampaign<T>(campaign);

    const campaignAnimationMedias = campaign.campaignAnimationMedias;
    campaign.isDraft = !campaignAnimationMedias?.length;

    if (campaignAnimationMedias) {
      campaignAnimationMedias.forEach((m) => {
        if (m.animationMediaId !== undefined && !isNaN(m.animationMediaId)) {
          m.animationMediaId = Number(m.animationMediaId);
        } else {
          m.animationMediaId = 0;
        }
        if (!m.animationMedia) {
          m.animationMedia = {} as U;
        }
        if (!isNaN(m.animationMedia.id)) {
          m.animationMedia.id = Number(m.animationMedia.id);
        } else {
          m.animationMedia.id = 0;
        }
        m.animationMedia.targetType = Number(m.animationMedia.targetType);
      });
    }

    onSubmit(campaign);
  };

  return { onSubmit: beforeSubmit, errors, isBusy };
};
export default useSubmitAnimationMediasCampaign;
