import ApplicationUser from "../../models/ApplicationUser";
import { CreatePage } from "../../resources/components/CreatePage";
import EditApplicationUserForm from "./components/EditApplicationUserForm";
import { useResolver } from "./hooks/useResolver";

const CreateApplicationUserPage = () => {
  const res = useResolver();
  return (
    <CreatePage<ApplicationUser> {...res}>
      <EditApplicationUserForm mode="create" />
    </CreatePage>
  );
};

export default CreateApplicationUserPage;
