import { useMemo } from "react";
import { Price } from "../../../models/Price";
import { PricesFilters } from "../../../models/filters/PricesFilters";
import { ListView } from "../../../resources/components/lists/ListView";
import {
  ResourceColumn,
  ResourceTable,
} from "../../../resources/components/lists/ResourceTable";
import { ResourceName } from "../../../resources/types/resourceName";

type ArticlePricesProps = {
  articleId?: number;
};
export const ArticlePrices = ({ articleId }: ArticlePricesProps) => {
  const columns: ResourceColumn<Price>[] = useMemo(
    () => [
      {
        accessor: (p) => p.store?.code,
        header: "Magasin (Code)",
      },
      {
        accessor: (p) => p.store?.name,
        header: "Magasin (Nom)",
      },
      {
        accessor: "unitPriceIncludingTaxes",
        header: "Prix TTC",
      },
      {
        accessor: "promotionalPriceExcludingTaxes",
        header: "Prix Promo TTC",
      },
    ],
    []
  );
  return (
    <ListView<ResourceName, Price, PricesFilters>
      defaultFilters={{ articleId }}
      resourceName="prices"
    >
      <ResourceTable<Price> columns={columns} />
    </ListView>
  );
};
