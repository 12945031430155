import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useCallback, useEffect, useState } from "react";
import { Editor, EditorProps } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const getHtml = (contentState: ContentState) => {
  //https://github.com/jpuri/draftjs-to-html/issues/28
  return draftToHtml(
    convertToRaw(contentState),
    undefined,
    undefined,
    ({ type, data }) => {
      //entity.data.alignment is for float using the LCR options on the image 'none' means the user clicked center
      if (type === "IMAGE") {
        const alignment = data.alignment || "none";
        const textAlign = alignment === "none" ? "center" : alignment;

        return `
            <p style="text-align:${textAlign};">
                <img src="${data.src}" alt="${data.alt}" style="height: ${data.height};width: ${data.width}"/>
            </p>
        `;
      }
    }
  );
};
const emptyHtml = getHtml(EditorState.createEmpty().getCurrentContent());

interface HtmlEditorProps extends EditorProps {
  onHtmlChanged?: (html?: string) => void;
  html?: string;
}

const HtmlEditor = (props: HtmlEditorProps) => {
  const { html, onHtmlChanged, ...otherProps } = props;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      const defaultValue = html ? html : "";
      const blocksFromHtml = htmlToDraft(defaultValue);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [html, updated]);

  const onEditorStateChange = useCallback(
    (editorState: EditorState) => {
      setUpdated(true);
      setEditorState(editorState);
      if (onHtmlChanged) {
        const updatedHtml = getHtml(editorState.getCurrentContent());
        onHtmlChanged(
          updatedHtml !== undefined && updatedHtml === emptyHtml
            ? undefined
            : updatedHtml
        );
      }
    },
    [onHtmlChanged]
  );

  //images

  const uploadCallback = useCallback((files: File) => {
    return new Promise<object>((resolve, reject) => {
      if (files) {
        let reader = new FileReader();
        reader.readAsDataURL(files);
        reader.onerror = () => {
          reject();
        };
        reader.onload = () => {
          if (reader.result != null && typeof reader.result === "string") {
            resolve({ data: { link: reader.result } });
          }
        };
      }
    });
  }, []);

  return (
    <Editor
      editorState={editorState}
      wrapperClassName="rich-editor"
      onEditorStateChange={onEditorStateChange}
      // handlePastedFiles={handlePastedFiles}
      uploadCallback={uploadCallback}
      {...otherProps}
    />
  );
};
export default HtmlEditor;
