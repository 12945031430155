import { PropsWithChildren, useCallback, useState } from "react";

import { Button, Spinner } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useCustomerLinkTo } from "../../../hooks/useCustomerLinkTo";
import { useDeleteAllResources } from "../../../resources/hooks/useDeleteAllResources";
import { useResourceName } from "../../../resources/hooks/useResourceName";
import { useResourceUrlPathResolver } from "../../../resources/hooks/useResourceUrlPathResolver";

type DeleteAllResourcesButtonProps = PropsWithChildren & {
  askForDeletion?: () => void;
  isBusyDeleting?: boolean;
  deletionErrors?: unknown;
  confirmPopupMessage?: string;
};
export const DeleteAllResourcesButton = (
  props: DeleteAllResourcesButtonProps
) => {
  const {
    askForDeletion: askForDeletionProps,
    isBusyDeleting: isBusyDeletingProps,
    deletionErrors: deletionErrorsProps,
    children,
    confirmPopupMessage = "Voulez vous vraiment tout supprimer ?",
  } = props;

  const { resourceName } = useResourceName();

  const navigate = useNavigate();

  const {
    resourceUrlPathResolver: { list },
  } = useResourceUrlPathResolver();
  const { customerLinkUrl } = useCustomerLinkTo();
  const { deleteAllAction, errors } = useDeleteAllResources();

  const [isBusy, setIsBusy] = useState(false);

  const onSuccessCallback = useCallback(() => {
    navigate(customerLinkUrl(list(resourceName)), {
      replace: true,
    });
  }, [customerLinkUrl, list, resourceName, navigate]);

  const askForDeletion = useCallback(async () => {
    if (window.confirm(confirmPopupMessage)) {
      setIsBusy(true);

      await deleteAllAction({ resourceName });

      setIsBusy(false);
      onSuccessCallback();
    }
  }, [confirmPopupMessage, deleteAllAction, resourceName, onSuccessCallback]);

  const finalAskForDeletion = askForDeletionProps ?? askForDeletion;
  const finalDeletionErrors = deletionErrorsProps ?? errors;
  const finalIsBusyDeleting = isBusyDeletingProps ?? isBusy;

  return (
    <div className="float-right text-end">
      <Button
        variant="outline-danger"
        onClick={finalAskForDeletion}
        disabled={finalIsBusyDeleting}
      >
        <>
          {finalIsBusyDeleting ? (
            <Spinner animation="border" size="sm" className="mx-1" />
          ) : (
            ""
          )}
        </>
        <MdDeleteForever className="me-1" />
        <>{children != null ? children : "Tout supprimer"}</>
      </Button>
      <>
        {finalDeletionErrors && (
          <div className="text-danger">
            Une erreur est survenue lors de la suppression.
          </div>
        )}
      </>
    </div>
  );
};
