import { EditPage } from "../../../resources/components/EditPage";
import EditInteractiveCatalogForm from "./components/EditInteractiveCatalogForm";

const EditInteractiveCatalogPage = () => {
  return (
    <EditPage>
      <EditInteractiveCatalogForm />
    </EditPage>
  );
};

export default EditInteractiveCatalogPage;
