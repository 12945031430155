import Campaign, { CampaignTargetType } from "../models/Campaign";
import { CampaignPeriodType } from "../models/CampaignPeriodType";
import { CampaignState } from "../models/CampaignState";

export const getCampaignTargetType = <TCampaign extends Campaign>(
  c: TCampaign
): CampaignTargetType =>
  c.targetStoreId != null
    ? "store"
    : c.targetAnimationGroupId != null
    ? "animation-group"
    : "all";

export const getCampaignPeriodType = <TCampaign extends Campaign>(
  c: TCampaign
): CampaignPeriodType =>
  !c.startDate && !c.endDate
    ? CampaignPeriodType.Permanent
    : CampaignPeriodType.Planified;

export const getCampaignState = <TCampaign extends Campaign>(
  c: TCampaign,
  now: Date
): CampaignState =>
  c.endDate == null || now <= new Date(c.endDate)
    ? CampaignState.Active
    : CampaignState.Passed;
