import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import Device from "../../models/Device";
import { CreateResourceButton } from "../../resources/components/buttons/CreateResourceButton";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { useAddEditButton } from "../../resources/hooks/useAddEditButton";

const fields: FilterFieldData<Filters>[] = [
  {
    fieldName: "id",
    type: "text",
    label: "Id",
    placeholder: "Id",
  },
  {
    label: "Nom",
    fieldName: "label",
    type: "text",
    placeholder: "Nom",
  },
  {
    label: "Magasin",
    defaultValue: undefined,
    fieldName: "storeId",
    type: "stores-select",
  },
  {
    label: "Groupe d'animation",
    defaultValue: undefined,
    fieldName: "animationGroupId",
    type: "animation-groups-select",
  },
  {
    label: "Type de périphérique",
    defaultValue: undefined,
    fieldName: "deviceTypeId",
    type: "device-types-select",
  },
];
type Filters = {
  id?: string;
  label?: string;
  deviceTypeId?: number;
  animationGroupId?: number;
  storeId?: number;
};

const DevicesPage = () => {
  const columns: ResourceColumn<Device>[] = useMemo(
    () => [
      {
        header: "Id",
        accessor: (values) => values.id,
      },
      {
        header: "Nom",
        accessor: (values) => values.label,
      },
      {
        header: "Nom du magasin",
        accessor: "storeId",
        expand: "stores",
        getCell: (d, v, expandedList) => {
          const s = expandedList?.data.items.find((a) => a.id === d.storeId);
          return s?.name;
        },
      },
      {
        header: "Groupe d'animation",
        accessor: "animationGroupId",
        expand: "animations/groups",
        getCell: (d, v, expandedList) => {
          const s = expandedList?.data.items.find(
            (a) => a.id === d.animationGroupId
          );
          return s?.label;
        },
      },
      {
        header: "Type",
        accessor: "deviceTypeId",
        expand: "device-types",
        getCell: (d, v, expandedList) => {
          const s = expandedList?.data.items.find(
            (a) => a.id === d.deviceTypeId
          );
          return s?.name;
        },
      },
    ],
    []
  );

  const { columnsWithButton } = useAddEditButton({
    columns,
  });

  return (
    <ListPage
      filterFieds={fields}
      actions={<CreateResourceButton />}
      showChildWithoutFilters
    >
      <ResourceTable columns={columnsWithButton} />
    </ListPage>
  );
};

export default DevicesPage;
