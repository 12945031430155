import { PropsWithChildren, useCallback, useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { GoBackButton } from "../../components/buttons/GoBackButton";
import { useCustomerLinkTo } from "../../hooks/useCustomerLinkTo";
import { useNotificationsContext } from "../../hooks/useNotificationsContext";
import { useCreateResource } from "../hooks/useCreateResource";
import { useResourceName } from "../hooks/useResourceName";
import { useResourceUrlPathResolver } from "../hooks/useResourceUrlPathResolver";
import { Resource } from "../types";
import { PageTitle } from "./PageTitle";
import { PublicResourceFormProps, ResourceForm } from "./ResourceForm";

type CreatePageProps<
  TResource extends FieldValues,
  TContext
> = PropsWithChildren &
  PublicResourceFormProps<TResource, TContext> & {
    onSuccess?: (item: TResource) => void;
    canRedirect?: boolean;
  };
export const CreatePage = <
  TResource extends Resource = Resource,
  TContext = any
>({
  children,
  onSuccess: onSuccessProps,
  canRedirect = true,
  transformAfterSubmit: transformAfterSubmitProps,
  ...otherProps
}: CreatePageProps<TResource, TContext>) => {
  const { resourceName: name } = useResourceName();
  const { customerLinkUrl } = useCustomerLinkTo();
  const navigate = useNavigate();
  const { resourceUrlPathResolver } = useResourceUrlPathResolver();
  const onSuccess = useCallback(
    (r?: TResource) => {
      if (canRedirect && r != null) {
        navigate(
          customerLinkUrl(resourceUrlPathResolver.edit(name ?? "", r.id)),
          {
            replace: true,
          }
        );
      }
    },
    [canRedirect, customerLinkUrl, name, navigate, resourceUrlPathResolver]
  );

  const { create, errors: submitError } = useCreateResource<
    string,
    TResource
  >();

  const { addNotification } = useNotificationsContext();
  useEffect(() => {
    if (submitError == null) return;
    addNotification({
      title: "Une erreur est survenue",
      variant: "danger",
    });
  }, [addNotification, submitError]);

  const { resourceName } = useResourceName();

  const onSubmit = useCallback(
    async (r?: TResource) => {
      if (r != null) {
        const c = await create({
          data: r,
          resourceName,
        });
        return c?.data;
      }
      return undefined;
    },
    [create, resourceName]
  );

  const transformAfterSubmit = async (
    updatedResource: TResource,
    form: UseFormReturn<TResource, TContext>
  ) => {
    // const f = await Promise.resolve(updatedResource);
    if (updatedResource != null) {
      let finalResource =
        transformAfterSubmitProps != null
          ? await transformAfterSubmitProps(updatedResource, form)
          : updatedResource;

      onSuccess(finalResource);
      addNotification({ title: "Sauvegarde réussie", variant: "success" });
      return finalResource;
    }
  };

  return (
    <>
      <GoBackButton />
      <PageTitle />
      <hr />
      <ResourceForm<TResource, TContext>
        submitError={submitError}
        onSubmit={onSubmit}
        transformAfterSubmit={transformAfterSubmit}
        {...otherProps}
      >
        {children}
      </ResourceForm>
    </>
  );
};
