import loadash from "lodash";
import { useEffect, useState } from "react";
import {
  ArticleQueryOperator,
  ArticlesSelection,
  ArticlesSelectionType,
  ManualArticlesSelection,
  SelectionFilter,
  SelectionFilterType,
} from "../models/ArticlesSelection";
import { PriceDisplay } from "../models/PriceDisplay";
import { ShowAvailableProducts } from "../models/ShowAvailableProducts";
import { Article } from "../tools/elasticsearch/models/Article";
import { BooleanAttribute } from "../tools/elasticsearch/models/BooleanAttribute";
import { Category } from "../tools/elasticsearch/models/Category";
import { ElasticGetArticlesResponse } from "../tools/elasticsearch/models/ElasticGetArticlesResponse";
import { ElasticQuery } from "../tools/elasticsearch/models/ElasticQuery";
import { QueryOperator } from "../tools/elasticsearch/models/QueryOperator";
import { SearchResult } from "../tools/elasticsearch/models/SearchResult";
import { StringAttribute } from "../tools/elasticsearch/models/StringAttribute";
import {
  convertBooleanAttributeValueFilterToElasticQuery,
  convertBrandFilterToElasticQuery,
  convertCateroryFilterToElasticQuery,
  convertCentralOrderableFilterToElasticQuery,
  convertInStoreOrderableFilterToElasticQuery,
  convertPricesFilterToElasticQuery,
  convertStringAttributeValueFilterToElasticQuery,
  getMustQuery,
  getShouldQuery,
  getShowAllOrAvailableQuery,
  getShowOnlyChildrenOrParentsQuery,
} from "../tools/elasticsearch/queryHelper";
import { search } from "../tools/elasticsearch/search";
import { nameof } from "../tools/nameof";
import useCustomerApiFetch from "./useCustomerApiFetch";

export function useElasticSearchArticles(
  hitFrom: number,
  hitsSize: number,
  showAvailableProducts?: ShowAvailableProducts,
  showOnlyParentProducts?: boolean,
  priceDisplay?: PriceDisplay,
  articlesSelection?: ArticlesSelection,
  storeId?: number
) {
  const [elasticResponse, setElasticResponse] =
    useState<ElasticGetArticlesResponse>();
  const apiFetcher = useCustomerApiFetch();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      priceDisplay &&
      storeId &&
      articlesSelection &&
      articlesSelection.query
    ) {
      if (articlesSelection.type === ArticlesSelectionType.Manual) {
        if (
          (articlesSelection as ManualArticlesSelection).articleReferences
            ?.length &&
          showOnlyParentProducts !== undefined
        ) {
          const res = getManualArticlesSelection(
            hitFrom,
            hitsSize,
            articlesSelection,
            apiFetcher,
            showOnlyParentProducts,
            storeId,
            showAvailableProducts
          );
        }
      } else if (articlesSelection.type === ArticlesSelectionType.Automatic) {
        if (
          storeId &&
          articlesSelection.query &&
          articlesSelection.query.filters?.length &&
          showOnlyParentProducts !== undefined &&
          showAvailableProducts !== undefined
        ) {
          let query = getAutomaticArticlesSelectionQuery(
            priceDisplay,
            articlesSelection.query.operator,
            articlesSelection.query.filters,
            storeId
          );
          if (query) {
            const newLocal = getShowAllOrAvailableQuery(
              showAvailableProducts,
              storeId
            );
            query = getMustQuery([
              query,
              getShowOnlyChildrenOrParentsQuery(showOnlyParentProducts),
              newLocal,
            ]);
            setIsLoading(true);
            search(hitFrom, hitsSize, { query: query }, apiFetcher)
              .then((r) => {
                if (r.ok) {
                  return r.json();
                } else {
                  throw new Error("Unable to get products");
                }
              })
              .then((r: SearchResult) => {
                setElasticResponse({ searchResult: r, error: undefined });
                setIsLoading(false);
              })
              .catch((error) => {
                setElasticResponse({ searchResult: undefined, error });
                setIsLoading(false);
              });
          } else {
            setElasticResponse({ searchResult: undefined, error: undefined });
            setIsLoading(false);
          }
        }
      }
    }
  }, [
    apiFetcher,
    articlesSelection,
    hitFrom,
    hitsSize,
    priceDisplay,
    showAvailableProducts,
    showOnlyParentProducts,
    storeId,
  ]);
  return { elasticResponse, isLoading };
}

function getAutomaticArticlesSelectionQuery(
  priceDisplay: PriceDisplay,
  queryOperator: ArticleQueryOperator,
  filters: SelectionFilter[],
  storeId: number
): ElasticQuery | undefined {
  const mainQueries = new Array<ElasticQuery>();
  const subQueries = filters
    .filter((q) => q.query && q.query.filters?.length)
    .map((q) => {
      return getAutomaticArticlesSelectionQuery(
        priceDisplay,
        q.query!.operator,
        q.query!.filters,
        storeId
      );
    })
    .filter((f) => {
      return f !== null && f !== undefined;
    });
  if (subQueries.length) {
    subQueries.forEach((query) => {
      if (query !== undefined) {
        mainQueries.push(query);
      }
    });
  }
  const queries = Object.entries(
    loadash.groupBy(
      filters.filter((q) => !q.query),
      nameof<SelectionFilter>((s) => s.type)
    )
  )
    .map(([key, selectionFilters]): any =>
      convertSelectionToElasticQuery(
        priceDisplay,
        queryOperator,
        key,
        selectionFilters as SelectionFilter[],
        storeId
      )
    )
    .filter((f) => {
      return f !== null && f !== undefined;
    });
  if (queries.length) {
    queries.forEach((query) => {
      mainQueries.push(query);
    });
  }
  if (mainQueries.length === 0) {
    return undefined;
  }
  if (queryOperator === ArticleQueryOperator.And) {
    return getMustQuery(mainQueries);
  }

  return getShouldQuery(mainQueries);
}

async function getManualArticlesSelection(
  hitFrom: number,
  hitsSize: number,
  articlesSelection: ManualArticlesSelection,
  apiFetcher: (input: string) => Promise<any>,
  showOnlyParentProducts: boolean,
  storeId?: number,
  showAvailableProducts?: ShowAvailableProducts
) {
  if (
    articlesSelection.articleReferences &&
    storeId !== undefined &&
    showAvailableProducts !== undefined
  ) {
    return await search(
      hitFrom,
      hitsSize,
      {
        from: 0,
        size: 10,
        query: {
          query: getMustQuery([
            {
              terms: {
                [nameof<Article>((a) => a.reference) + ".keyword"]:
                  articlesSelection.articleReferences,
              },
            },
            getShowOnlyChildrenOrParentsQuery(showOnlyParentProducts),
            getShowAllOrAvailableQuery(showAvailableProducts, storeId),
          ]),
          sort: {
            _script: {
              type: "number",
              script: {
                source: "params.get(doc['reference.keyword'].value);",
                lang: "painless",
                params: articlesSelection.articleReferences.map((v, index) => {
                  return { [v]: index };
                }),
              },
            },
          },
        },
      },
      apiFetcher
    );
  }
}

function convertSelectionToElasticQuery(
  priceDisplay: PriceDisplay,
  articleQueryOperator: ArticleQueryOperator,
  key: string,
  selectionFilters: SelectionFilter[],
  storeId: number
): ElasticQuery | undefined {
  const type = Number(key) as SelectionFilterType;

  let queryOperator: QueryOperator;
  switch (articleQueryOperator) {
    case ArticleQueryOperator.And:
      queryOperator = QueryOperator.And;
      break;
    case ArticleQueryOperator.Or:
      queryOperator = QueryOperator.Or;
      break;
  }
  switch (type) {
    case SelectionFilterType.StringAttributeValue:
      return convertStringAttributeValueFilterToElasticQuery(
        queryOperator,
        selectionFilters
          .filter(
            (f) => f.attributeId !== undefined && f.attributeValue !== undefined
          )
          .map(
            (f) =>
              ({
                attributeId: f.attributeId,
                value: f.attributeValue,
              } as StringAttribute)
          )
      );
    case SelectionFilterType.BooleanAttributeValue:
      return convertBooleanAttributeValueFilterToElasticQuery(
        queryOperator,
        selectionFilters
          .filter(
            (f) => f.attributeId !== undefined && f.attributeValue !== undefined
          )
          .map(
            (f) =>
              ({
                attributeId: f.attributeId,
                value: JSON.parse(f.attributeValue!),
              } as BooleanAttribute)
          )
      );
    case SelectionFilterType.Brand:
      return convertBrandFilterToElasticQuery(
        queryOperator,
        selectionFilters
          .filter((f) => f.brandId !== undefined)
          .map((f) => f.brandId!)
      );
    case SelectionFilterType.Category:
      return convertCateroryFilterToElasticQuery(
        queryOperator,
        selectionFilters
          .filter((f) => f.categoryId !== undefined)
          .map((f) => ({ categoryId: f.categoryId } as Category))
      );
    case SelectionFilterType.CentralOrderable:
      return convertCentralOrderableFilterToElasticQuery();
    case SelectionFilterType.InStoreOrderable:
      return convertInStoreOrderableFilterToElasticQuery(storeId);
    case SelectionFilterType.Price:
      const priceFilter = selectionFilters.find(
        (f) => f.type === SelectionFilterType.Price
      );
      return convertPricesFilterToElasticQuery(
        priceDisplay,
        priceFilter?.minimumValue,
        priceFilter?.maximumValue,
        storeId
      );
    default:
      return undefined;
  }
}
