import { CustomerLink } from "../../../components/navigation/CustomerLink";
import Category from "../../../models/Category";
import { useResourceUrlPathResolver } from "../../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../../resources/types/resourceName";
import { isNullOrUndefined } from "../../../tools/isNullOrUndefined";

type BreadCrumbProps = {
  category?: Category;
};
export const BreadCrumb = (props: BreadCrumbProps) => {
  const { category } = props;
  const {
    resourceUrlPathResolver: { display, list },
  } = useResourceUrlPathResolver<ResourceName>();
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {category && category.id ? (
          <li className="breadcrumb-item" aria-current="page">
            <CustomerLink to={list("categories")}>Accueil</CustomerLink>
          </li>
        ) : (
          <li className="breadcrumb-item active" aria-current="page">
            Accueil
          </li>
        )}

        {category && (
          <>
            {!isNullOrUndefined(category.parentId) && (
              <li className="breadcrumb-item" aria-current="page">
                <CustomerLink to={display("categories", category.parentId)}>
                  ..
                </CustomerLink>
              </li>
            )}
            <li className="breadcrumb-item active" aria-current="page">
              {category.label}
            </li>
          </>
        )}
      </ol>
    </nav>
  );
};
