import { useCallback, useMemo } from "react";
import { convertToURLSearchParams } from "../../tools/convertToURLSearchParams";
import { ResourceAction } from "../types/resourceAction";
import { ResourceApiPathResolver } from "../types/resourceApiPathResolver";
import { ResourceName } from "../types/resourceName";

export const useCreateResourceApiPathResolver = () => {
  const resourceToApiPath = useCallback(
    (resource: ResourceName, method: ResourceAction) => {
      switch (resource) {
        case "animations/articles-selections": {
          switch (method) {
            case "Read":
            case "List":
              return "/api/animations/articlesselections/bo";
          }
          break;
        }
        case "animations/ads": {
          switch (method) {
            case "Read":
            case "List":
              return "/api/animations/ads/bo";
          }
          break;
        }
        case "devices": {
          switch (method) {
            case "Read":
              return "/api/devices/bo";
          }
          break;
        }
        case "stores": {
          switch (method) {
            case "List":
              return "/api/stores/bo";
          }
          break;
        }
        case "orders": {
          switch (method) {
            case "List":
            case "Update":
              return "/api/orders/bo";
          }
          break;
        }
        case "stocks": {
          switch (method) {
            case "List":
              return "/api/stocks/bo";
          }
          break;
        }
        case "logistic-operations": {
          switch (method) {
            case "List":
              return "/api/logisticOperations/bo";
          }
          break;
        }
        case "animations/interactives-catalogs": {
          switch (method) {
            case "Read":
            case "List":
              return "/api/animations/interactivesCatalogs/bo";
          }
          break;
        }
        case "import-job-definition-entities": {
          switch (method) {
            case "List":
              return "/api/importjobdefinitiondataentities";
          }
          break;
        }
      }
      return "/api/" + resource.replaceAll("-", "");
    },
    []
  );

  const resourceApiPathResolver = useMemo(
    () =>
      ({
        listUrl: (resourceName, otherProps) => {
          return `${resourceToApiPath(
            resourceName,
            "List"
          )}?${convertToURLSearchParams(otherProps)}`;
        },
        readUrl: (resourceName, id) => {
          return `${resourceToApiPath(resourceName, "Read")}/${id}`;
        },
        createUrl: (resourceName) => {
          return resourceToApiPath(resourceName, "Create");
        },
        updateUrl: (resourceName, id) => {
          return `${resourceToApiPath(resourceName, "Update")}/${id}`;
        },
        deleteUrl: (resourceName, id) => {
          return `${resourceToApiPath(resourceName, "Delete")}/${id}`;
        },
        deleteAllUrl: (resourceName) => {
          return `${resourceToApiPath(resourceName, "Delete")}`;
        },
      } as ResourceApiPathResolver<ResourceName>),
    [resourceToApiPath]
  ) as ResourceApiPathResolver;
  return { resourceApiPathResolver };
};
