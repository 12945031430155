import { useEffect, useMemo, useRef } from "react";
import { Form, FormGroup } from "react-bootstrap";
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import ReactSelect from "react-select";
import ReactSelectOption, {
  ReactSelectOptionValueType,
} from "../../../components/ReactSelectOption";
import { useSWRListResource } from "../../hooks/useSWRListResource";
import { ResourceFilters } from "../../types/resourceFilters";
import {
  UseReactSelectProps,
  useReactSelectProps,
} from "./hooks/useReactSelectProps";

export type ReactSelectProps<
  TReactSelectOptionValueType extends ReactSelectOptionValueType,
  IsMulti extends boolean
> = UseReactSelectProps<TReactSelectOptionValueType, IsMulti> & {
  label?: string;
};

type ResourcesSelectProps<
  TResource,
  TReactSelectOptionValueType extends ReactSelectOptionValueType,
  IsMulti extends boolean,
  TResourceName extends string,
  TResourceFilters extends ResourceFilters
> = ReactSelectProps<TReactSelectOptionValueType, IsMulti> & {
  resourceName: TResourceName;
  convertToOption: (
    value: TResource | undefined
  ) => ReactSelectOption<TReactSelectOptionValueType> | undefined;
  transformToFilters: (search: string) => TResourceFilters;
  invalidFeedBack?: string;
};

const useResourceSelect = <
  TResource,
  TResourceName extends string,
  IsMulti extends boolean,
  TReactSelectOptionValueType extends ReactSelectOptionValueType,
  TResourceFilters extends ResourceFilters
>(
  props: ResourcesSelectProps<
    TResource,
    TReactSelectOptionValueType,
    IsMulti,
    TResourceName,
    TResourceFilters
  >
) => {
  const { resourceName, transformToFilters, ...otherProps } = props;
  const { idPropsValue, convertToOption, autoSelectFirst } = otherProps;

  const idsToFetch = useMemo(() => {
    return idPropsValue != null
      ? Array.isArray(idPropsValue)
        ? (idPropsValue as TReactSelectOptionValueType[])
        : [idPropsValue as TReactSelectOptionValueType]
      : undefined;
  }, [idPropsValue]);

  const { data: defaultData } = useSWRListResource<TResourceName, TResource>({
    resourceName,
    filters: {
      ids: idsToFetch,
    },
    canFetch: idsToFetch && idsToFetch.length > 0,
  });

  const defaultPropsValue = useMemo(() => {
    return defaultData != null
      ? props.isMulti
        ? defaultData.data.items
        : defaultData.data.items.length > 0
        ? defaultData.data.items[0]
        : undefined
      : undefined;
  }, [defaultData, props.isMulti]);

  const reactSelectProps = useReactSelectProps<
    TReactSelectOptionValueType,
    TResource,
    IsMulti
  >({
    ...otherProps,
    defaultPropsValue,
  });

  const {
    search,
    needRefresh,
    setValue,
    isWaitingForDefaultValue,
    input,
    ...restReactSelectProps
  } = reactSelectProps;
  const { isMenuOpen } = restReactSelectProps;

  // gestion de l'annulation
  const aborter = useRef<AbortController>();
  const abort = () => aborter.current?.abort();

  // quand l'input change, on annule la requête précédente
  useEffect(() => {
    //log annulation
    abort();
    aborter.current = new AbortController();
    return abort;
  }, [input]);

  const { data, isLoading: areOptionsLoading } = useSWRListResource<
    TResourceName,
    TResource
  >({
    filters: transformToFilters(search), // { name: search },
    resourceName,
    canFetch: true,
    signal: aborter.current?.signal,
  });

  const options = useMemo(
    () =>
      data?.data.items
        .filter((item) => item != null)
        .map<ReactSelectOption<TReactSelectOptionValueType>>(
          (s) => s && convertToOption(s)!
        ),
    [convertToOption, data?.data.items]
  );

  //Sélection de la première valeur si aucune valeur par défaut fourni
  useEffect(() => {
    if (
      isWaitingForDefaultValue &&
      autoSelectFirst &&
      !idPropsValue &&
      options != null &&
      options.length > 0
    ) {
      setValue(options[0]);
    }
  }, [
    autoSelectFirst,
    idPropsValue,
    isWaitingForDefaultValue,
    options,
    setValue,
  ]);

  const isLoading = useMemo(() => {
    return (
      needRefresh || areOptionsLoading || isWaitingForDefaultValue
      // ((idPropsValue != null || autoSelectFirst) && value == null) ||
      // (isMenuOpen && (needRefresh || options == null))
    );
  }, [areOptionsLoading, isWaitingForDefaultValue, needRefresh]);

  return {
    ...restReactSelectProps,
    ...otherProps,
    isMenuOpen,
    needRefresh,
    options,
    isLoading,
  };
};

export const ResourcesSelect = <
  TResource,
  TReactSelectOptionValueType extends ReactSelectOptionValueType,
  IsMulti extends boolean = false,
  TResourceName extends string = string,
  TResourceFilters extends ResourceFilters = ResourceFilters
>(
  props: ResourcesSelectProps<
    TResource,
    TReactSelectOptionValueType,
    IsMulti,
    TResourceName,
    TResourceFilters
  >
) => {
  const { label, invalidFeedBack, ...oProps } = props;

  const rsProps = useResourceSelect<
    TResource,
    TResourceName,
    IsMulti,
    TReactSelectOptionValueType,
    TResourceFilters
  >(oProps);

  return (
    <FormGroup>
      {label && <Form.Label>{label}</Form.Label>}

      <ReactSelect<ReactSelectOption<TReactSelectOptionValueType>, IsMulti>
        {...rsProps}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (base) => ({
            ...base,
            width: "max-content",
            minWidth: "100%",
          }),
        }}
      />
      {invalidFeedBack && (
        <Form.Control.Feedback type="invalid">
          {invalidFeedBack}
        </Form.Control.Feedback>
      )}
    </FormGroup>
  );
};

export type ControlledResourcesSelectProps<
  T,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Omit<T, "name" | "idPropsValue" | "onIdPropsValueChanged"> &
  UseControllerProps<TFieldValues, TName>;

export const ControlledResourcesSelect = <
  TResource,
  TReactSelectOptionValueType extends ReactSelectOptionValueType,
  IsMulti extends boolean,
  TResourceName extends string,
  TResourceFilters extends ResourceFilters,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: ControlledResourcesSelectProps<
    ResourcesSelectProps<
      TResource,
      TReactSelectOptionValueType,
      IsMulti,
      TResourceName,
      TResourceFilters
    >,
    TFieldValues,
    TName
  >
) => {
  const { name, control: controlProps, ...oProps } = props;
  const { control /*, getValues*/ } = useFormContext<TFieldValues>();

  return (
    <Controller<TFieldValues, TName>
      render={({
        field: { onChange, value, ...otherControllerProps },
        fieldState: { error, invalid },
        formState: { isSubmitting },
      }) => (
        <ResourcesSelect<
          TResource,
          TReactSelectOptionValueType,
          IsMulti,
          TResourceName,
          TResourceFilters
        >
          {...oProps}
          {...otherControllerProps}
          idPropsValue={value}
          className={`${invalid ? "is-invalid" : ""}`}
          isDisabled={isSubmitting}
          invalidFeedBack={invalid === true ? error?.message : undefined}
          onIdPropsValueChanged={onChange}
        />
      )}
      {...oProps}
      name={name}
      control={controlProps ?? control}
    />
  );
};
