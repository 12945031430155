import moment from "moment";
import { useEffect, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useNotificationsContext } from "../hooks/useNotificationsContext";
import { BoNotification } from "../types/BoNotification";

type NotificationsContainerProps = {};
export const NotificationsContainer = (props: NotificationsContainerProps) => {
  const { notifications } = useNotificationsContext();
  return (
    <>
      <ToastContainer>
        {notifications &&
          notifications.map((n, i) => (
            <HidableToast key={i} notification={n} />
          ))}
      </ToastContainer>
    </>
  );
};

type HidableToastProps = {
  notification: BoNotification;
};
const HidableToast = (props: HidableToastProps) => {
  const { notification } = props;
  const [, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 2000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const [show, setShow] = useState(true);
  return (
    <Toast
      show={show}
      onClose={() => setShow(false)}
      delay={10000}
      bg={notification.variant}
      autohide
    >
      <Toast.Header>
        <strong className="me-auto">{notification.title}</strong>
        {notification.date && (
          <small>{moment(notification.date).fromNow()}</small>
        )}
      </Toast.Header>
      {notification.message && <Toast.Body>{notification.message}</Toast.Body>}
    </Toast>
  );
};
