export type ArticlesSelection = {
  id: number;
  code: string;
  label: string;
  type: ArticlesSelectionType;
  imageUrl: string | FileList;
  thumbnailUrl: string;
  query?: ArticlesQuery;
};

export type ManualArticlesSelection = ArticlesSelection & {
  articleReferences?: string[];
};

export type AutomaticArticlesSelection = ArticlesSelection & {
  query?: ArticlesQuery;
};

export enum ArticlesSelectionType {
  Manual,
  Automatic,
}

export interface ArticlesQuery {
  operator: ArticleQueryOperator;
  filters: Array<SelectionFilter>;
}

export enum ArticleQueryOperator {
  And = 0,
  Or = 1,
}
//workaround  It's a little bit tricky but it worked, the idea is to bypass circular check on array type definition https://github.com/react-hook-form/react-hook-form/issues/4055
export interface SelectionFilter {
  type?: SelectionFilterType;
  categoryId?: string;
  brandId?: string;
  attributeId?: string;
  attributeValue?: string;
  minimumValue?: number;
  maximumValue?: number;
  query?: ArticlesQuery;
}

export enum SelectionFilterType {
  Category = 0,
  Brand = 1,
  InStoreOrderable = 2,
  CentralOrderable = 3,
  StringAttributeValue = 4,
  BooleanAttributeValue = 5,
  NumericAttributeValue = 6,
  VariationStringAttributeValue = 7,
  VariationBooleanAttributeValue = 8,
  VariationNumericAttributeValue = 9,
  Price = 10,
}
