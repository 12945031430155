import { useCallback, useState } from "react";
import { ApiError } from "../../models/ApiError";
import { DataProvider } from "../types/dataProvider";
import { useResourceRestDataProvider } from "./useResourceRestDataProvider";

type UseResourceActionSafeCallerProps<
  TActionProps,
  TActionReturn,
  TResourceName extends string,
  TResourceType
> = {
  canThrow?: boolean;
  unsafeAction: (
    dataProvider: DataProvider<TResourceName, TResourceType>,
    props: TActionProps
  ) => Promise<TActionReturn>;
};
export const useResourceSafeActionCaller = <
  TActionProps,
  TActionReturn,
  TResourceName extends string,
  TResourceType
>({
  unsafeAction,
  canThrow = false,
}: UseResourceActionSafeCallerProps<
  TActionProps,
  TActionReturn,
  TResourceName,
  TResourceType
>) => {
  const [errors, setErrors] = useState<ApiError>();

  const resourceRestDataProvider = useResourceRestDataProvider<
    TResourceName,
    TResourceType
  >();

  const safeAction = useCallback(
    async (props: TActionProps) => {
      setErrors(undefined);
      try {
        return await unsafeAction(resourceRestDataProvider, props);
      } catch (e) {
        setErrors(e as ApiError);
        if (canThrow) {
          throw e;
        }
      }
    },
    [unsafeAction, resourceRestDataProvider, canThrow]
  );
  return { errors, safeAction };
};
