import { ReactNode } from "react";
import { useResourceContext } from "../hooks/useResourceContext";
import { Resource } from "../types";

export type ReadResourceProps<TResource extends Resource> = {
  resource: (resource: TResource) => ReactNode;
};
export const ReadResource = <TResource extends Resource>({
  resource: children,
}: ReadResourceProps<TResource>) => {
  const { resource } = useResourceContext<TResource>();
  return <>{resource && children(resource)}</>;
};
