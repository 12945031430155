import { ElasticQuery } from "./models/ElasticQuery";
import loadash from "lodash";
import { Category } from "./models/Category";
import { nameof } from "../nameof";
import { Article } from "./models/Article";
import { Attribute } from "./models/Attribute";
import { StringAttribute } from "./models/StringAttribute";
import { BooleanAttribute } from "./models/BooleanAttribute";
import { QueryOperator } from "./models/QueryOperator";
import { StockInfos } from "./models/StockInfos";
import { PriceDisplay } from "../../models/PriceDisplay";
import { Price } from "./models/Price";
import { ShowAvailableProducts } from "../../models/ShowAvailableProducts";

export function convertPricesFilterToElasticQuery(
  priceDisplay: PriceDisplay,
  lowerValue: number | undefined,
  higherValue: number | undefined,
  storeId: number
): ElasticQuery | undefined {
  if (lowerValue === null && higherValue === null) {
    return undefined;
  }
  let hasStorePrice = {
    term: {
      [nameof<Article>((a) => a.prices) +
      "." +
      nameof<Price>((p) => p.storeId)]: {
        value: storeId,
      },
    },
  };
  let hasNestedStorePrice = pricesNested(hasStorePrice);

  let isStorePricesPromotionDateRange = isPromotionDateRange(
    nameof<Article>((a) => a.prices) +
      "." +
      nameof<Price>((p) => p.promotionStartDate),
    nameof<Article>((a) => a.prices) +
      "." +
      nameof<Price>((p) => p.promotionEndDate)
  );

  let storePriceFilter = pricesNested(
    getMustQuery([
      hasStorePrice,
      getShouldQuery([
        getMustQuery([
          isStorePricesPromotionDateRange,
          priceRangeFilter(
            priceDisplay,
            nameof<Article>((a) => a.prices) +
              "." +
              nameof<Price>((p) => p.promotionalPriceIncludingTaxes),
            nameof<Article>((a) => a.prices) +
              "." +
              nameof<Price>((p) => p.promotionalPriceExcludingTaxes),
            lowerValue,
            higherValue
          ),
        ]),
        getMustQuery([
          getMustNotQuery(isStorePricesPromotionDateRange),
          priceRangeFilter(
            priceDisplay,
            nameof<Article>((a) => a.prices) +
              "." +
              nameof<Price>((p) => p.unitPriceIncludingTaxes),
            nameof<Article>((a) => a.prices) +
              "." +
              nameof<Price>((p) => p.unitPriceExcludingTaxes),
            lowerValue,
            higherValue
          ),
        ]),
      ]),
    ])
  );

  let isWebPricePromotionDateRange = isPromotionDateRange(
    nameof<Article>((a) => a.promotionStartDate),
    nameof<Article>((a) => a.promotionEndDate)
  );

  let webPriceFilter = getShouldQuery([
    getMustQuery([
      isWebPricePromotionDateRange,
      priceRangeFilter(
        priceDisplay,
        nameof<Article>((a) => a.promotionalPriceIncludingTaxes),
        nameof<Article>((a) => a.promotionalPriceExcludingTaxes),
        lowerValue,
        higherValue
      ),
    ]),
    getMustQuery([
      getMustNotQuery(isWebPricePromotionDateRange),
      priceRangeFilter(
        priceDisplay,
        nameof<Article>((a) => a.unitPriceIncludingTaxes),
        nameof<Article>((a) => a.unitPriceExcludingTaxes),
        lowerValue,
        higherValue
      ),
    ]),
  ]);

  return getShouldQuery([
    storePriceFilter,
    getMustQuery([getMustNotQuery(hasNestedStorePrice), webPriceFilter]),
  ]);
}

function priceRangeFilter(
  priceDisplay: PriceDisplay,
  articlePriceIncludingTaxField: string,
  articlePriceIExcludingTaxField: string,
  lowerValue: number | undefined,
  higherValue: number | undefined
): ElasticQuery {
  let range: {
    gte?: number;
    lte?: number;
  } = {};
  if (lowerValue !== undefined) {
    range.gte = lowerValue;
  }
  if (higherValue !== undefined) {
    range.lte = higherValue;
  }
  return {
    range: {
      [priceDisplay === "ExcludingTaxes"
        ? articlePriceIExcludingTaxField
        : articlePriceIncludingTaxField]: range,
    },
  };
}

function isPromotionDateRange(
  promotionStartDatePath: string,
  promotionEndDatePath: string
): ElasticQuery {
  const timezoneOffsetMinutes = -new Date().getTimezoneOffset();
  let dif = timezoneOffsetMinutes >= 0 ? "+" : "-";
  let pad = function (num: number) {
    return (num < 10 ? "0" : "") + num;
  };
  const positiveTzoMIniutes = Math.abs(timezoneOffsetMinutes);
  let timeZone =
    dif +
    pad(Math.floor(positiveTzoMIniutes / 60)) +
    ":" +
    pad(Math.abs(timezoneOffsetMinutes) % 60);

  // let timeZone = timezoneOffsetMinutes// "+01:00";

  return getMustQuery([
    getMustQuery([
      {
        exists: {
          field: promotionStartDatePath,
        },
      },
      {
        range: {
          [promotionStartDatePath]: {
            lte: "now",
            time_zone: timeZone,
          },
        },
      },
    ]),
    getShouldQuery([
      getMustNotQuery([
        {
          exists: {
            field: promotionEndDatePath,
          },
        },
      ]),
      {
        range: {
          [promotionEndDatePath]: {
            gte: "now",
            time_zone: timeZone,
          },
        },
      },
    ]),
  ]);
}

function pricesNested(query: ElasticQuery): ElasticQuery {
  return {
    nested: {
      path: nameof<Article>((a) => a.prices),
      query: query,
    },
  };
}

export function convertBooleanAttributeValueFilterToElasticQuery(
  queryOperator: QueryOperator,
  selectionFilters: BooleanAttribute[]
): ElasticQuery | undefined {
  return convertAttributeValueFilterToElasticQuery<BooleanAttribute>(
    queryOperator,
    selectionFilters,
    nameof<Article>((a) => a.booleanAttributes),
    nameof<Article>((a) => a.booleanAttributes) +
      "." +
      nameof<BooleanAttribute>((b) => b.attributeId),
    nameof<Article>((a) => a.booleanAttributes) +
      "." +
      nameof<BooleanAttribute>((b) => b.value),
    (f) => f.value
  );
}

export function convertStringAttributeValueFilterToElasticQuery(
  queryOperator: QueryOperator,
  selectionFilters: StringAttribute[]
): ElasticQuery | undefined {
  return convertAttributeValueFilterToElasticQuery<StringAttribute>(
    queryOperator,
    selectionFilters,
    nameof<Article>((a) => a.stringAttributes),
    nameof<Article>((a) => a.stringAttributes) +
      "." +
      nameof<StringAttribute>((s) => s.attributeId),
    nameof<Article>((a) => a.stringAttributes) +
      "." +
      nameof<StringAttribute>((s) => s.value) +
      ".keyword",
    (f) => f.value
  );
}
function convertAttributeValueFilterToElasticQuery<T extends Attribute>(
  queryOperator: QueryOperator,
  attributes: T[],
  attributesField: string,
  attributesIdField: string,
  attributesValueField: string,
  getAttributeValue: (groupAttributes: T) => any
): ElasticQuery | undefined {
  if (queryOperator === QueryOperator.Or) {
    return getShouldQuery(
      Object.entries(
        loadash.groupBy(
          attributes,
          nameof<Attribute>((a) => a.attributeId)
        )
      ).map(([attributeId, groupSelectionFilters]) => {
        const attributeValues = (groupSelectionFilters as T[]).map(
          getAttributeValue
        );
        return getAttributesQuery(
          attributesField,
          attributesIdField,
          attributesValueField,
          Number(attributeId),
          attributeValues
        );
      })
    );
  }
  return getMustQuery(
    attributes.map((f) => {
      return getAttributesQuery(
        attributesField,
        attributesIdField,
        attributesValueField,
        f.attributeId!,
        getAttributeValue(f)
      );
    })
  );
}
export function convertInStoreOrderableFilterToElasticQuery(
  storeId: number
): ElasticQuery | undefined {
  return {
    nested: {
      path: nameof<Article>((a) => a.stockInfos),
      query: getMustQuery([
        getHasStoreStockInfos(storeId),
        getHasAvailableQuantity(),
      ]),
    },
  };
}
export function convertCentralOrderableFilterToElasticQuery():
  | ElasticQuery
  | undefined {
  return {
    nested: {
      path: nameof<Article>((a) => a.stockInfos),
      query: getMustQuery([
        getHasCentralStockInfos(),
        getHasAvailableQuantity(),
      ]),
    },
  };
}
export function convertBrandFilterToElasticQuery(
  queryOperator: QueryOperator,
  brands: string[]
): ElasticQuery | undefined {
  const brandsProperty = nameof<Article>((a) => a.brand) + ".keyword";
  if (queryOperator === QueryOperator.Or) {
    return {
      terms: {
        [brandsProperty]: brands,
      },
    };
  }
  return brands.map((b) => {
    return {
      term: {
        [brandsProperty]: { value: b },
      },
    };
  });
}

export function convertCateroryFilterToElasticQuery(
  queryOperator: QueryOperator,
  categories: Category[]
): ElasticQuery | undefined {
  const categoriesProperty = nameof<Article>((a) => a.categories);
  const categoriesCategoryIdProperty =
    nameof<Article>((a) => a.categories) +
    "." +
    nameof<Category>((c) => c.categoryId);
  if (queryOperator === QueryOperator.Or) {
    return {
      nested: {
        path: categoriesProperty,
        query: {
          terms: {
            [categoriesCategoryIdProperty]: categories.map((f) => f.categoryId),
          },
        },
      },
    };
  }
  return categories.map((f) => {
    return {
      path: categoriesProperty,
      nested: {
        query: {
          term: { [categoriesCategoryIdProperty]: { value: f.categoryId } },
        },
      },
    };
  });
}

export function getShouldQuery(
  queries: ElasticQuery | ElasticQuery[]
): ElasticQuery {
  if (!Array.isArray(queries)) return queries;
  else if (queries.length === 1) {
    return queries[0];
  } else
    return {
      bool: { should: queries },
    };
}

export function getMustQuery(
  queries: ElasticQuery | (ElasticQuery | undefined)[]
): ElasticQuery {
  if (!Array.isArray(queries)) return queries;
  else if (queries.length === 1) {
    return queries[0];
  } else
    return {
      bool: { must: queries.filter((q) => q !== undefined) },
    };
}

export function getMustNotQuery(
  queries: ElasticQuery | ElasticQuery[]
): ElasticQuery {
  return {
    bool: { must_not: queries },
  };
}

export function getShowOnlyChildrenOrParentsQuery(
  showOnlyParentProducts: boolean
): ElasticQuery {
  return getMustNotQuery({
    exists: {
      field: showOnlyParentProducts
        ? nameof<Article>((a) => a.parentArticleId)
        : nameof<Article>((a) => a.articleChildrenIds),
    },
  });
}

export function getShowAllOrAvailableQuery(
  showAvailableProducts: ShowAvailableProducts,
  storeId: number
): ElasticQuery | undefined {
  let showAllOrAvailableQuery: ElasticQuery | undefined = undefined;

  switch (showAvailableProducts) {
    case "InStock":
      showAllOrAvailableQuery = {
        nested: {
          path: nameof<Article>((a) => a.stockInfos),
          query: getMustQuery([
            getShouldQuery([
              getHasCentralStockInfos(),
              getHasStoreStockInfos(storeId),
            ]),
            getHasAvailableQuantity(),
          ]),
        },
      };
      break;
  }

  return showAllOrAvailableQuery;
}
function getHasCentralStockInfos(): ElasticQuery {
  return {
    term: {
      [nameof<Article>((a) => a.stockInfos) +
      "." +
      nameof<StockInfos>((s) => s.availabilityType) +
      ".keyword"]: { value: "DEPOT" },
    },
  };
}
function getHasStoreStockInfos(storeId: number): ElasticQuery {
  return {
    term: {
      [nameof<Article>((a) => a.stockInfos) +
      "." +
      nameof<StockInfos>((s) => s.storeId)]: { value: storeId },
    },
  };
}
function getHasAvailableQuantity(): ElasticQuery {
  return {
    range: {
      [nameof<Article>((a) => a.stockInfos) +
      "." +
      nameof<StockInfos>((s) => s.availableQuantity)]: { gt: 0 },
    },
  };
}

function getAttributesQuery(
  attributesField: string,
  attributesIdField: string,
  attributesValueField: string,
  attributeId: number,
  attributeValues: any | any[]
): ElasticQuery {
  const isArray = Array.isArray(attributeValues);
  return {
    nested: {
      path: attributesField,
      query: getMustQuery([
        {
          term: {
            [attributesIdField]: { value: attributeId },
          },
        },
        {
          [isArray ? "terms" : "term"]: {
            [attributesValueField]: isArray
              ? attributeValues
              : { value: attributeValues },
          },
        },
      ]),
    },
  };
}
