import { useFormContext } from "react-hook-form";
import Nullable from "../../Nullable";
import { DropShippingProvider } from "../../models/DropShippingProvider";
import { ResourceHiddenNumberInput } from "../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceInput } from "../../resources/components/inputs/ResourceInput";

const EditDropShippingProviderForm = () => {
  const { getValues } = useFormContext<DropShippingProvider>();
  return (
    <>
      <ResourceHiddenNumberInput<DropShippingProvider> name="id" />
      <ResourceInput<DropShippingProvider>
        name="name"
        label="Nom*"
        options={{
          required: "Le nom est obligatoire",
          maxLength: {
            message: "Le nom ne peut pas dépasser 50 caractères",
            value: 50,
          },
        }}
      />
      <ResourceInput<DropShippingProvider>
        name="code"
        label="Code*"
        options={{
          required: "Le code est obligatoire",
          maxLength: {
            message: "Le code ne peut pas dépasser 50 caractères",
            value: 50,
          },
        }}
      />
      <div className="row">
        <div className="col">
          <ResourceInput<DropShippingProvider>
            name="minDelay"
            label="Délai minimum de livraison (en jour)"
            options={{
              min: {
                value: 0,
                message:
                  "Le délai minimum de livraison doit être supérieur ou égal à 0",
              },
              valueAsNumber: true,
            }}
            type="number"
          />
        </div>
        <div className="col">
          <ResourceInput<DropShippingProvider>
            name="maxDelay"
            label="Délai maximum de livraison (en jour)"
            options={{
              validate: (value) => {
                const minDelay = getValues("minDelay");
                if (isValidNumber(value) && isValidNumber(minDelay)) {
                  return value! > minDelay!;
                }
              },
              min: {
                value: 0,
                message:
                  "Le délai maximum de livraison doit être supérieur ou égal à 0",
              },
              valueAsNumber: true,
            }}
            type="number"
          />
        </div>
      </div>
    </>
  );
};

export default EditDropShippingProviderForm;
function isValidNumber(value: Nullable<number>) {
  return typeof value === "number" && !Number.isNaN(value);
}
