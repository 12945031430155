import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useForm, useFormContext } from "react-hook-form";
import Input from "../../../components/Input";
import TargetInput from "../../../components/TargetInput";
import AnimationMedia, {
  AnimationMediaTargetType,
} from "../../../models/AnimationMedia";
import AnimationMediasCampaign from "../../../models/AnimationMediasCampaign";
import Campaign from "../../../models/Campaign";
import CampaignAnimationMedia from "../../../models/CampaignAnimationMedia";
import { useResourceEditContext } from "../../../resources/hooks/useResourceEditContext";
import { isNullOrUndefined } from "../../../tools/isNullOrUndefined";
import MediaPreviewer, {
  useFileUrlPreview,
} from "../../Animations/AdsMedia/components/MediaPreviewer";
import useSubmitAnimationMediasCampaign from "../utils/useSubmitAnimationMediasCampaign";
import useUploadMedia from "../utils/useUploadMedia";
import { EditOrCreateAnimationMediasCampaignContext } from "./EditOrCreateAnimationMediasCampaign";

export interface EditOrCreateAnimationMediaModalProps {
  createTitle: string;
  editTitle: string;
  item: AnimationMedia;
  index: number;
  showModal: boolean;
  onCloseModal: () => void;
  editOrCreate: "edit" | "create";
}

const EditOrCreateAnimationMediaModal = function <T extends AnimationMedia>(
  props: EditOrCreateAnimationMediaModalProps
) {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AnimationMedia>();

  const { getValues: animationMediasCampaignGetValues, reset: resetCampaign } =
    useFormContext<AnimationMediasCampaign<T>>();

  const campaign =
    animationMediasCampaignGetValues() as AnimationMediasCampaign<T>;
  const currentAnimationMediaId = !isNaN(props.item?.id)
    ? Number(props.item?.id)
    : undefined;
  const index = props.index;

  const {
    uploadMedia,
    error: uploadError,
    isBusy: uploadMediaIsBusy,
  } = useUploadMedia();
  const [mediaFile, setMediaFile] = useState<FileList | undefined | null>(
    undefined
  );
  const onMediaChanged = useCallback(
    (e: any) => setMediaFile(e.target.files),
    []
  );

  const previewMediaFileUrl = useFileUrlPreview(mediaFile); // Get a local url for the file input
  const onCloseModal = props.onCloseModal;
  const editOrCreateAnimationMediasCampaignContext = React.useContext(
    EditOrCreateAnimationMediasCampaignContext
  );
  const { notifyAnimationMediaCampaignSaved } =
    editOrCreateAnimationMediasCampaignContext;

  const { onSuccess } = useResourceEditContext<AnimationMediasCampaign<T>>();

  const OnAnimationMediasCampaignSaved = useCallback(
    async (c: AnimationMediasCampaign<T>) => {
      if (mediaFile) {
        onSuccess && onSuccess(c);
        resetCampaign(c);
        if (notifyAnimationMediaCampaignSaved) {
          notifyAnimationMediaCampaignSaved(c as Campaign);
        }
        const animationMediaArray = c.campaignAnimationMedias;
        // uploader
        if (animationMediaArray[index].animationMediaId !== undefined) {
          const animationMedia = await uploadMedia(
            currentAnimationMediaId ??
              animationMediaArray[index].animationMediaId!,
            mediaFile[0]
          );
          animationMediaArray[index].animationMedia = animationMedia as T;
        }
      }
      onSuccess && onSuccess(c);
      resetCampaign(c);

      if (notifyAnimationMediaCampaignSaved) {
        notifyAnimationMediaCampaignSaved(c as Campaign);
      }
      onCloseModal();
    },
    [
      mediaFile,
      onSuccess,
      resetCampaign,
      notifyAnimationMediaCampaignSaved,
      onCloseModal,
      index,
      uploadMedia,
      currentAnimationMediaId,
    ]
  );
  const {
    isBusy: isSubmitFormBusy,
    onSubmit: submitForm,
    errors: submitError,
  } = useSubmitAnimationMediasCampaign(
    campaign.id,
    OnAnimationMediasCampaignSaved
  );
  // When the modal is closed.
  const onHide = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);
  const onSubmit = (animationMedia: AnimationMedia) => {
    if (index >= 0) {
      if (!isNaN(animationMedia.id)) {
        animationMedia.id = Number(animationMedia.id);
      }
      if (animationMedia.mediaUrl && animationMedia.mediaUrl === "") {
        animationMedia.mediaUrl = undefined;
      }
      if (!campaign.campaignAnimationMedias) {
        campaign.campaignAnimationMedias = [];
      }
      campaign.campaignAnimationMedias[index] = {
        animationMedia: animationMedia,
      } as CampaignAnimationMedia<T>;

      submitForm(campaign);
    }
  };
  const initialAnimationMedia = props.item;
  useEffect(() => {
    if (initialAnimationMedia) {
      reset(initialAnimationMedia);
    }
  }, [initialAnimationMedia, reset]);
  return (
    <Modal show={props.showModal} onHide={onHide} size="lg">
      <Modal.Header>
        <Modal.Title>
          {props.editOrCreate === "edit" && props.editTitle}
          {props.editOrCreate === "create" && props.createTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-2">
          <input {...register("id", { valueAsNumber: true })} type="hidden" />

          <input {...register("thumbnailUrl")} type="hidden" />
          <input {...register("mediaUrl")} type="hidden" />
          <Input
            id="AnimationMediaName"
            label="Nom"
            {...control.register("label", { required: true })}
            errorMessage="Le nom est obligatoire"
            isInvalid={errors.label !== undefined}
          />
          <TargetInput
            control={control}
            errors={errors}
            targetOptions={[
              AnimationMediaTargetType.Category,
              AnimationMediaTargetType.Brand,
              AnimationMediaTargetType.Search,
              AnimationMediaTargetType.InteractiveCatalog,
              AnimationMediaTargetType.ArticlesSelection,
              AnimationMediaTargetType.WebUrl,
            ]}
          />
          <Input
            accept=".jpg,.jpeg,.jfif,.png"
            type="file"
            label="Image"
            required={
              props.editOrCreate === "create" ||
              isNullOrUndefined(initialAnimationMedia?.mediaUrl)
            }
            onChange={onMediaChanged}
            errorMessage="L'image est obligatoire."
            isInvalid={errors.mediaUrl !== undefined}
          />
          <div className="form-group">
            <MediaPreviewer
              mediaUrl={previewMediaFileUrl ?? initialAnimationMedia?.mediaUrl}
              mediaType={"image"}
            />
          </div>
          <button
            type="button"
            disabled={!mediaFile && !initialAnimationMedia?.mediaUrl}
            onClick={handleSubmit(onSubmit)}
            className="btn btn-primary"
          >
            {uploadMediaIsBusy || isSubmitFormBusy ? (
              <Spinner className="mx-1" animation="border" size="sm" />
            ) : (
              ""
            )}
            Sauvegarder
          </button>

          <>
            {submitError && (
              <small className="text-danger">
                Une erreur est survenue. Veuillez réessayer.
              </small>
            )}
          </>
          <>
            {uploadError && (
              <small className="text-danger">
                Impossible de charger le média. Veuillez réessayer.
              </small>
            )}
          </>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default EditOrCreateAnimationMediaModal;
