import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import Modal from "react-bootstrap/esm/Modal";
import { useFieldArray, useForm } from "react-hook-form";
import { MdAdd, MdDeleteForever } from "react-icons/md";
import Input from "../../../../components/Input";
import TargetInput from "../../../../components/TargetInput";
import usePrevious from "../../../../hooks/usePrevious";
import { useEditOrCreateSubmitForm } from "../../../../hooks/useSubmitForm";
import { AnimationMediaTargetType } from "../../../../models/AnimationMedia";
import {
  InteractiveArea,
  InteractivePage,
} from "../../../../models/InteractiveCatalog";
import { SaveResourceButton } from "../../../../resources/components/buttons/SaveResourceButton";
import deepCopy from "../../../../tools/deepCopy";
import { isNullOrUndefined } from "../../../../tools/isNullOrUndefined";
import { useFileUrlPreview } from "../../AdsMedia/components/MediaPreviewer";
import InteractivePageCanvas from "./InteractivePageCanvas";
import useUpload from "./useUpload";
import {
  useAreasIndexesWithValidationError,
  useCurrentPageState,
} from "./utils";
import InteractiveAreaForm from "./InteractiveAreaForm";

interface InteractivePageModalFormProps {
  showModal: boolean;
  editOrCreate: "edit" | "create";
  currentPage: InteractivePage;
  catalogId: number;
  onSaveCallback?: (
    page: InteractivePage,
    mediaSuccessfullyUploaded: boolean
  ) => void;
  onCancel: () => void;
}

const InteractivePageModalForm = ({
  showModal,
  onCancel,
  editOrCreate,
  currentPage: currentPageProps,
  catalogId,
  onSaveCallback,
}: InteractivePageModalFormProps) => {
  const { currentPage, onImageChange } = useCurrentPageState(currentPageProps);

  /* FORM */
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,

    formState: { errors },
  } = useForm<InteractivePage>({
    mode: "onBlur",
    defaultValues: currentPage,
  });

  const {
    fields: areaFields,
    append: appendArea,
    remove: removeInteractiveArea,
  } = useFieldArray({
    control,
    name: "interactiveAreas",
  });
  const addInteractiveArea = useCallback(() => {
    appendArea(
      new InteractiveArea(new Date().getTime().toString(), 10, 10, 200, 100)
    );
  }, [appendArea]);

  /* CANVAS */
  const invalidsAreasIndexes = useAreasIndexesWithValidationError(errors);
  const [currentEditingAreaId, setCurrentEditingAreaId] = useState<
    number | string
  >(); //Canvas currently selected area.

  const [imageFile, setMediaFile] = useState<FileList | undefined | null>(
    undefined
  );
  const onMediaChanged = useCallback(
    (e: any) => setMediaFile(e.target.files),
    []
  );

  const imageUrl = useFileUrlPreview(imageFile) ?? currentPage?.mediaUrl; //image used on canvas

  //Reset the form for each time the currentPage change.
  const previousPage = usePrevious(currentPage);
  useEffect(() => {
    if (previousPage?.id !== currentPage?.id) {
      reset(currentPage);
      setMediaFile(undefined);
      setCurrentEditingAreaId(undefined);
    }
  }, [reset, currentPage, previousPage]);

  /* FORM (SUBMIT & UPLOAD) */
  const { uploadNewMedia, isBusy: isBusyUploading } =
    useUpload<InteractivePage>();
  /** Upload media when the data is succefully saved */
  const onDataSavedUploadMedia = useCallback(
    async (data: InteractivePage) => {
      let page = data;
      let mediaSuccessfullyUploaded = false;
      if (imageFile && imageFile.length > 0) {
        try {
          page = await uploadNewMedia(
            `/api/animations/interactivescatalogs/${catalogId}/pages/${page.id}/UploadImage`,
            imageFile[0]
          );
          reset(page);
          mediaSuccessfullyUploaded = true;
        } catch (e) {
          console.error("Unable to upload file", e);
        }
      }
      onSaveCallback && onSaveCallback(page, mediaSuccessfullyUploaded);
    },
    [onSaveCallback, imageFile, reset, uploadNewMedia, catalogId]
  );

  const {
    isBusy: isBusySubmit,
    onSubmit: internalOnSubmit,
    errors: submitError,
  } = useEditOrCreateSubmitForm(
    `/api/animations/interactivescatalogs/${catalogId}/pages`,
    editOrCreate === "create" ? undefined : currentPage?.id?.toString(),
    onDataSavedUploadMedia
  );

  const isBusy = isBusySubmit || isBusyUploading;

  // Submit the data without the media.
  const onSubmit = useCallback(
    async (formPage: InteractivePage) => {
      //Merge formPage with currentPage data.
      const page = new InteractivePage();

      page.id = editOrCreate === "create" ? undefined : currentPage.id;
      page.imageHeight = currentPage.imageHeight;
      page.imageWidth = currentPage.imageWidth;
      page.interactiveAreas = formPage.interactiveAreas?.map((v, i) => {
        const postArea = deepCopy(v);
        postArea.id = undefined!; //remove id
        return postArea;
      });
      await internalOnSubmit(page);
    },
    [
      currentPage.id,
      currentPage.imageHeight,
      currentPage.imageWidth,
      editOrCreate,
      internalOnSubmit,
    ]
  );

  const onHide = useCallback(() => {
    if (isBusy) {
      return;
    }

    onCancel();
  }, [isBusy, onCancel]);

  return (
    <Modal
      size="lg"
      dialogClassName="ultra-lg-modal"
      show={showModal}
      onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title>
          {editOrCreate === "edit" ? <>Editer une page</> : <>Créer une page</>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="row" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-lg-8 col-md-6 col-sm-5">
            <InteractivePageCanvas
              imageUrl={imageUrl}
              defaultValues={areaFields as InteractiveArea[]}
              setValue={setValue}
              register={register}
              invalidsAreasIndexes={invalidsAreasIndexes}
              onNewImage={onImageChange}
              selectedAreaId={currentEditingAreaId}
              onSelectArea={(i) => {
                setCurrentEditingAreaId(i);
              }}
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-7 d-flex flex-column gap-2">
            <Input
              accept=".jpg,.jpeg,.jfif,.png"
              type="file"
              label="Image"
              required={
                editOrCreate === "create" ||
                isNullOrUndefined(currentPage?.mediaUrl)
              }
              errorMessage="L'image est obligatoire."
              onChange={onMediaChanged}
              isInvalid={errors.mediaUrl !== undefined}
              disabled={isBusy}
            />

            {imageUrl && (
              <Button className="align-self-start" onClick={addInteractiveArea}>
                <MdAdd />
                Ajouter une zone
              </Button>
            )}

            {areaFields?.map((v, i) => {
              const a = v as InteractiveArea;
              return (
                <InteractiveAreaForm
                  key={a.id}
                  interactiveArea={a}
                  interactivePageFieldArrayWithId={v}
                  index={i}
                  currentEditingAreaId={currentEditingAreaId}
                  control={control}
                  errors={errors}
                  isBusy={isBusy}
                  removeInteractiveArea={removeInteractiveArea}
                />
              );
            })}
            <hr />
            <SaveResourceButton
              mode={"edit"}
              disabled={isBusy || (!imageFile && !currentPage?.mediaUrl)}
              submitError={submitError}
            />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default InteractivePageModalForm;
