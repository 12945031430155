import { useMemo } from "react";
import Nullable from "../../Nullable";
import Customer from "../../models/Customer";
import { ImportJobStatusInfo } from "../../models/Synchronization";
import { createImportJobStatusInfoFromFetchResult } from "../../models/createSynchronizationFromFetchedData";
import useFetchForCustomer from "../swr/useSWRFetchForCustomer";

const useImportJobStatusInfos = function (
  customer: Nullable<Customer>,
  synchronizationId?: string
) {
  const { data, ...others } = useFetchForCustomer(
    "/api/Synchronizations/" + synchronizationId + "/ImportJobStatusInfos",
    customer
  );

  const importJobStatusInfos = useMemo(
    () =>
      data != null
        ? data.map(createImportJobStatusInfoFromFetchResult)
        : undefined,
    [data]
  );

  return {
    importJobStatusInfos: importJobStatusInfos as ImportJobStatusInfo[],
    data,
    ...others,
  };
};
export default useImportJobStatusInfos;
