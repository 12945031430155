import { useCallback, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { MdAdd, MdDeleteForever, MdEdit } from "react-icons/md";
import AnimationMedia, {
  AnimationMediaTargetType,
} from "../../../models/AnimationMedia";
import AnimationMediasCampaign from "../../../models/AnimationMediasCampaign";
import { ResourceHiddenNumberInput } from "../../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceHiddenTextInput } from "../../../resources/components/inputs/ResourceHiddenTextInput";
import EditOrCreateAnimationMediaModal from "./EditOrCreateAnimationMediaModal";
import Workflow from "./Workflow";
import { WorkflowItem } from "./WorkflowListItem";

export interface AnimationMediasWorkflowProps {
  createModalTitle?: string;
  editModalTitle?: string;
  addItemButtonMessage?: string;
}

const fieldArrayName = "campaignAnimationMedias";
const AnimationMediasWorklow = <TAnimationMedia extends AnimationMedia>({
  createModalTitle,
  editModalTitle,
  addItemButtonMessage,
}: AnimationMediasWorkflowProps) => {
  const { register, control } =
    useFormContext<AnimationMediasCampaign<TAnimationMedia>>();
  // Componant state
  const {
    fields: campaignAnimationMedias,
    remove,
    move,
  } = useFieldArray({
    name: fieldArrayName,
    control,
  });
  const [currentItemToEdit, setCurrentItemToEdit] = useState<
    { animationMedia: Partial<AnimationMedia>; index: number } | undefined
  >();

  // Open modal callbacks
  const openCreateModal = useCallback(() => {
    const AnimationMedia = {} as AnimationMedia;
    setCurrentItemToEdit({
      animationMedia: AnimationMedia,
      index: campaignAnimationMedias?.length ?? 0,
    });
  }, [campaignAnimationMedias]);

  // Open edit modal
  const openEditModalForPanel = useCallback(
    (panelId: number, index: number) => {
      const animationMedia = campaignAnimationMedias.find(
        (value: any) => value.animationMedia?.id === panelId
      )?.animationMedia;
      if (animationMedia) {
        setCurrentItemToEdit({ animationMedia: animationMedia, index });
      }
    },
    [campaignAnimationMedias]
  );
  const closeModal = useCallback(() => setCurrentItemToEdit(undefined), []);

  const removePanel = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  // Sort list on sort.
  const onSortEnd = useCallback(
    (oldIndex: number, newIndex: number) => {
      move(oldIndex, newIndex);
    },
    [move]
  );

  const AnimationMediaActionsFactory = (item: WorkflowItem, index: number) => (
    <>
      <button
        type="button"
        className="btn btn-outline-secondary"
        onClick={() => removePanel(index)}
      >
        <MdDeleteForever />
      </button>
      <button
        type="button"
        className="btn btn-outline-secondary"
        onClick={() => openEditModalForPanel(item.id, index)}
      >
        <MdEdit />
      </button>
    </>
  );

  let workflowItems = useMemo(() => {
    return campaignAnimationMedias.map((m) => {
      return {
        id: m.animationMedia?.id,
        label: m.animationMedia?.label,
        thumbnailUrl: m.animationMedia?.thumbnailUrl,
        isLoading:
          m.animationMedia?.thumbnailUrl == null ||
          m.animationMedia?.thumbnailUrl === "",
      };
    }) as WorkflowItem[];
  }, [campaignAnimationMedias]);

  return (
    <>
      <div>
        <button
          type="button"
          onClick={openCreateModal}
          className="btn btn-outline-primary"
        >
          <MdAdd />
          {addItemButtonMessage}
        </button>
      </div>
      <br />
      <Workflow
        itemsCount={workflowItems.length}
        Items={workflowItems}
        onSortEnd={onSortEnd}
        IsSortable={true}
        actionFactory={AnimationMediaActionsFactory}
      />
      {campaignAnimationMedias.map((field, index) => (
        <div key={field.id}>
          <ResourceHiddenNumberInput
            name={`${fieldArrayName}.${index}.animationMedia.id`}
            value={field.animationMedia?.id}
          />
          <ResourceHiddenTextInput
            name={`${fieldArrayName}.${index}.animationMedia.label`}
            value={field.animationMedia?.label}
          />
          <ResourceHiddenNumberInput
            name={`${fieldArrayName}.${index}.animationMedia.targetType`}
            value={field.animationMedia?.targetType}
          />

          {field.animationMedia?.targetType && (
            <>
              {Number(field.animationMedia.targetType) ===
                AnimationMediaTargetType.InteractiveCatalog && (
                <input
                  type="hidden"
                  {...register(
                    `${fieldArrayName}.${index}.animationMedia.interactiveCatalogId` as any,
                    {
                      valueAsNumber: true,
                    }
                  )}
                  value={field.animationMedia?.interactiveCatalogId?.toString()}
                />
              )}
              {field.animationMedia.targetType ===
                Number(AnimationMediaTargetType.ArticlesSelection) && (
                <input
                  type="hidden"
                  {...register(
                    `${fieldArrayName}.${index}.animationMedia.articlesSelectionId` as any,
                    {
                      valueAsNumber: true,
                    }
                  )}
                  value={field.animationMedia?.articlesSelectionId?.toString()}
                />
              )}
              {field.animationMedia.targetType ===
                Number(AnimationMediaTargetType.Search) && (
                <input
                  type="hidden"
                  {...register(
                    `${fieldArrayName}.${index}.animationMedia.searchTerms` as any
                  )}
                  value={field.animationMedia?.searchTerms}
                />
              )}
              {field.animationMedia.targetType ===
                Number(AnimationMediaTargetType.Brand) && (
                <input
                  type="hidden"
                  {...register(
                    `${fieldArrayName}.${index}.animationMedia.brandId` as any
                  )}
                  value={field.animationMedia?.brandId}
                />
              )}
              {field.animationMedia.targetType ===
                Number(AnimationMediaTargetType.Category) && (
                <input
                  type="hidden"
                  {...register(
                    `${fieldArrayName}.${index}.animationMedia.categoryId` as any,
                    { valueAsNumber: true }
                  )}
                  value={field.animationMedia?.categoryId?.toString()}
                />
              )}
              {field.animationMedia.targetType ===
                Number(AnimationMediaTargetType.WebUrl) && (
                <input
                  type="hidden"
                  {...register(
                    `${fieldArrayName}.${index}.animationMedia.webUrl` as any
                  )}
                  value={field.animationMedia?.webUrl}
                />
              )}
            </>
          )}
          <input
            type="hidden"
            {...register(
              `${fieldArrayName}.${index}.animationMedia.thumbnailUrl` as any
            )}
            value={field.animationMedia?.thumbnailUrl}
          />
          <input
            type="hidden"
            {...register(
              `${fieldArrayName}.${index}.animationMedia.mediaUrl` as any
            )}
            value={field.animationMedia?.mediaUrl}
          />
        </div>
      ))}
      {currentItemToEdit && createModalTitle && editModalTitle && (
        <EditOrCreateAnimationMediaModal
          editOrCreate={
            currentItemToEdit?.animationMedia?.id ? "edit" : "create"
          }
          item={currentItemToEdit?.animationMedia as AnimationMedia}
          index={
            currentItemToEdit && currentItemToEdit.index >= 0
              ? currentItemToEdit.index
              : -1
          }
          showModal={true}
          onCloseModal={closeModal}
          createTitle={createModalTitle}
          editTitle={editModalTitle}
        />
      )}
    </>
  );
};
export default AnimationMediasWorklow;
