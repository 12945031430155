import * as React from "react";
import { useRef, useLayoutEffect } from "react";
import { InteractiveArea } from "../../../../models/InteractiveCatalog";
import { Rect, Transformer as KonvasTransformer } from "react-konva";
import Konva from "konva";

export interface Area {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface ResizableInteractiveAreaProps {
    interactiveArea: InteractiveArea;
    bound: Area;
    scale: number;
    isSelected: boolean;
    isInvalid: boolean;
    onSelect: () => void;
    onChange: (a: Area) => void;
}

const ResizableInteractiveArea = (props: ResizableInteractiveAreaProps) => {
  const { interactiveArea, isSelected, isInvalid } = props;

  // Konvas ref.
  const shapeRef = useRef<any>() as React.RefObject<Konva.Rect>;
  const transformerRef = useRef() as React.RefObject<Konva.Transformer>;

  // TODO: This should be a useEffect...  
  // But .. https://github.com/konvajs/react-konva/issues/365
  // and ... https://github.com/facebook/react/issues/15647 ... (waiting for the next react version)
  useLayoutEffect(() => {
    if (isSelected) {
      // transformerRef.current?.setNode(shapeRef.current);
      if(shapeRef.current) {
        transformerRef.current?.nodes([shapeRef.current])
      }
      transformerRef.current?.getLayer()?.batchDraw();
    }
  }, [isSelected, shapeRef, transformerRef]);


  const scale = props.scale;
  const shapeArea = { x: interactiveArea.originX, y: interactiveArea.originY, width: interactiveArea.width, height: interactiveArea.height }

  // If the boxToCheck touch the global bound area, return the new box and inform if the box touch. 
  const computeNewBox = (boxToCheck: Area) => {
    // Contain the drag inside the bound area...
    let { x, y, width, height } = boxToCheck;

    let hasTouchTheBound = false;
    const boudnArea = props.bound;

    if (x < boudnArea.x) { x = 0; hasTouchTheBound = true; }
    if (y < boudnArea.y) { y = 0; hasTouchTheBound = true; }
    if (x > boudnArea.width - width) { x = boudnArea.width - width; hasTouchTheBound = true; }
    if (y > boudnArea.height - height) { y = boudnArea.height - height; hasTouchTheBound = true; }
    return { newBox: { x, y, width, height }, hasTouchTheBound };
  }

  return (
    <>
      <Rect
        ref={shapeRef}
        x={shapeArea.x}
        y={shapeArea.y}
        width={shapeArea.width}
        height={shapeArea.height}
        fill={isInvalid ? "red" : "#eeeeee"}
        opacity={0.7}
        onClick={props.onSelect}
        onTap={props.onSelect}
        stroke={"black"}
        strokeEnabled={!isSelected}
        strokeWidth={2}
        draggable={isSelected}
        dragBoundFunc={(pos) => {
          const { x, y } = computeNewBox({ ...shapeArea, x: pos.x / scale, y: pos.y / scale }).newBox;
          return { x: x * scale, y: y * scale };
        }}
        onDragEnd={e => props.onChange({ ...shapeArea, x: e.target.x(), y: e.target.y() })}
        onTransformEnd={e => {
          const node = shapeRef.current;
          if(!node) {
            return;
          }
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          props.onChange({
            x: node.x(),
            y: node.y(),
            width: node.width() * scaleX,
            height: node.height() * scaleY
          });
        }}
      ></Rect>

      {
        isSelected &&
                <KonvasTransformer
                  ref={transformerRef}
                  rotateEnabled={false}
                  anchorFill="red"
                  anchorStroke="red"
                  borderStroke="red"
                  boundBoxFunc={(oldBox, newBox) => {
                    // if the user try to put negative value we block.
                    // this can be change, but nescessite to change to method "computeNewBox" (or here) to prevent negative value 

                    if (newBox.width < 0) { newBox.width = 10; }
                    if (newBox.height < 0) { newBox.height = 10; }
                    const boxInfo = computeNewBox(newBox);
                    return boxInfo.hasTouchTheBound ? oldBox : newBox;
                  }}
                  borderStrokeWidth={2}
                  keepRatio={false} />
      }

    </>);
}

export default ResizableInteractiveArea;