import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import StockLocation from "../../models/StockLocation";
import Store from "../../models/Store";
import { StockLocationsFilters } from "../../models/filters/StockLocationsFilters";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { useAddEditButton } from "../../resources/hooks/useAddEditButton";
import { useResourceUrlPathResolver } from "../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../resources/types/resourceName";

const StockLocationsPage = () => {
  const filters: FilterFieldData<StockLocationsFilters>[] = useMemo(
    () => [
      {
        fieldName: "code",
        type: "text",
        label: "Code",
        placeholder: "Code",
      },
      {
        fieldName: "name",
        type: "text",
        label: "Nom",
        placeholder: "Nom",
      },
      {
        fieldName: "stockLocationId",
        type: "stock-locations-select",
        label: "Dépôt",
        placeholder: "Dépôt",
      },
      {
        fieldName: "storeId",
        type: "stores-select",
        label: "Magasin",
        placeholder: "Magasin",
      },
    ],
    []
  );
  const {
    resourceUrlPathResolver: { edit },
  } = useResourceUrlPathResolver<ResourceName>();

  const columns: ResourceColumn<StockLocation>[] = useMemo(
    () => [
      {
        header: "Code",
        accessor: "code",
      },
      {
        header: "Nom",
        accessor: "name",
      },
      {
        header: "Magasin",
        accessor: "storeId",
        expand: "stores",
        getCell: (d, v, expandedList) => {
          const s = expandedList?.data.items.find((a) => a.id === d.storeId);
          return s && `${s.name} (${s.code})`;
        },
        navigationPath: (o) => o.storeId && edit("stores", o.storeId),
      } as ResourceColumn<StockLocation, Store, ResourceName>,
    ],
    [edit]
  );

  const { columnsWithButton } = useAddEditButton({ columns });
  return (
    <ListPage filterFieds={filters} showChildWithoutFilters>
      <ResourceTable columns={columnsWithButton} />
    </ListPage>
  );
};

export default StockLocationsPage;
