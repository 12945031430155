import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";

type CollapseDisplayGroupProps = PropsWithChildren & {
  className?: string;
  label?: string;
  canHide?: boolean;
  addBodyMargin?: boolean;
};
export const CollapseDisplayGroup = ({
  children,
  className,
  label,
  canHide = true,
  addBodyMargin = true,
}: CollapseDisplayGroupProps) => {
  const [open, setOpen] = useState(false);
  const { key } = useLocation();
  useEffect(() => {
    if (key) {
      setOpen(false);
    }
  }, [key]);
  const onClick = useCallback((e: any) => {
    e.preventDefault();
    setOpen((o) => !o);
  }, []);
  const showSimpleLabel = !canHide && open;
  return (
    <Card className={className}>
      {label && (
        <Card.Header>
          {showSimpleLabel && label}
          {!showSimpleLabel && (
            <a href="/#" className="text-reset" onClick={onClick}>
              {label}
            </a>
          )}
        </Card.Header>
      )}
      {open && (
        <>
          {!addBodyMargin && children}
          {addBodyMargin && <Card.Body>{children}</Card.Body>}
        </>
      )}
    </Card>
  );
};
