import { useCallback } from "react";
import Button from "react-bootstrap/esm/Button";
import { MdArrowBack } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

export const GoBackButton = () => {
  let navigate = useNavigate();
  const onClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const location = useLocation();
  return (
    <>
      {location.key && (
        <Button
          variant="outline-dark"
          size="sm"
          className="mb-2"
          onClick={onClick}
        >
          <MdArrowBack className="me-1" />
          Retour
        </Button>
      )}
    </>
  );
};
