import { useContext, useMemo } from "react";
import { LoggedInUserContext, useRolesContext } from "../LoggedInBackOffice";

export type UseIsAdminRoleProps = {
  ignoreIsShowingTenantAdminMode?: boolean;
};
export const useIsUserInTenantAdminRole = (props?: UseIsAdminRoleProps) => {
  const { ignoreIsShowingTenantAdminMode = false } = props ?? {};
  const { isShowingTenantAdminMode } = useContext(LoggedInUserContext);

  const { roles } = useRolesContext();
  const isAllowed = useMemo(
    () =>
      (ignoreIsShowingTenantAdminMode || isShowingTenantAdminMode) &&
      roles &&
      roles.length > 0 &&
      roles.includes("Bo.TenantAdmin"),
    [ignoreIsShowingTenantAdminMode, isShowingTenantAdminMode, roles]
  );
  return { isAllowed };
};
