import { EditPage } from "../../resources/components/EditPage";
import EditCountryForm from "./EditCountryForm";

const EditCountryPage = () => {
  return (
    <EditPage>
      <EditCountryForm />
    </EditPage>
  );
};

export default EditCountryPage;
