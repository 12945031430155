import { useFormContext } from "react-hook-form";
import {
  ArticlesSelection,
  ArticlesSelectionType,
} from "../../../../models/ArticlesSelection";
import { ArticlesSelectionForm } from "./ArticlesSelectionForm";
import AutomaticArticlesSelection from "./AutomaticArticlesSelectionForm";
import { ManualArticlesSelectionFrom } from "./ManualArticlesSelectionFrom";

export const EditOrCreateArticlesSelectionForm = () => {
  const { getValues } = useFormContext<ArticlesSelection>();

  const type = getValues("type");
  return (
    <>
      <ArticlesSelectionForm />

      {Number(type) === ArticlesSelectionType.Automatic && (
        <AutomaticArticlesSelection />
      )}
      {Number(type) === ArticlesSelectionType.Manual && (
        <ManualArticlesSelectionFrom />
      )}
    </>
  );
};
