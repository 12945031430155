import AdMedia from "../../../models/AdMedia";
import {
  AnimationMediaTargetType,
  MediaFileType,
} from "../../../models/AnimationMedia";
import { CreatePage } from "../../../resources/components/CreatePage";
import { useUploadMediaController } from "../../../resources/hooks/useUploadMediaController";
import EditOrCreateAdMediaForm from "./components/EditOrCreateAdMediaForm";

const CreateAdMediaPage = () => {
  // const transformBeforeSubmit = useCallback((adMedia: AdMedia) => {
  //   adMedia.targetType = Number(adMedia.targetType);
  //   return adMedia;
  // }, []);

  const { transformAfterSubmit, transformBeforeSubmit } =
    useUploadMediaController<AdMedia>({
      imageField: "mediaUrl",
    });

  return (
    <CreatePage
      transformBeforeSubmit={transformBeforeSubmit}
      transformAfterSubmit={transformAfterSubmit}
      defaultResource={{
        mediaType: MediaFileType.Image,
        targetType: AnimationMediaTargetType.Home,
      }}
    >
      <EditOrCreateAdMediaForm />
    </CreatePage>
  );
};

export default CreateAdMediaPage;
