import moment from "moment";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import FormGroup from "react-bootstrap/esm/FormGroup";
import { Controller, useFormContext } from "react-hook-form";
import { MdSend } from "react-icons/md";
import HtmlEditor from "../../components/HtmlEditor";
import Input from "../../components/Input";
import { ControlledBroadcastListsSelect } from "../../components/selects/BroadcastListsSelect";
import Notification from "../../models/Notification";
import { ResourceHiddenDateInput } from "../../resources/components/inputs/ResourceHiddenDateInput";
import { ResourceHiddenNumberInput } from "../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceInput } from "../../resources/components/inputs/ResourceInput";
import {
  getISO8601Date,
  getShortTextMonthnameDayofmonthYearDate,
} from "../../tools/dateUtils";

const EditNotificationForm = () => {
  const {
    register,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitting, defaultValues },
  } = useFormContext<Notification>();

  const htmlContent = watch("htmlContent", defaultValues?.htmlContent);

  const [isHtmlContentProvided, setIsHtmlContentProvided] = useState(true);

  useEffect(() => {
    setIsHtmlContentProvided(htmlContent != null);
  }, [htmlContent]);

  const actualSendingDate = watch(
    "actualSendingDate",
    defaultValues?.actualSendingDate
  );

  return (
    <>
      <ResourceHiddenNumberInput<Notification> name="id" />
      <ResourceHiddenDateInput<Notification> name="actualSendingDate" />
      <ResourceInput<Notification>
        label="Nom*"
        name="name"
        options={{
          required: "Le nom de la notification est obligatoire",
          maxLength: {
            value: 50,
            message:
              "Le nom de la notification ne doit pas dépasser 50 caractères",
          },
        }}
      />

      <ControlledBroadcastListsSelect name="broadcastListId" />

      <FormGroup>
        <Form.Label>Type de contenu*</Form.Label>
        <div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="isHtmlContentProvided1"
              checked={!!isHtmlContentProvided}
              onChange={() => {
                setIsHtmlContentProvided(true);
                setValue("htmlContent", "");
              }}
            />
            <label
              className="form-check-label"
              htmlFor="isHtmlContentProvided1"
            >
              Texte
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="isHtmlContentProvided2"
              checked={!isHtmlContentProvided}
              onChange={() => {
                setIsHtmlContentProvided(false);
                setValue("url", "");
              }}
            />
            <label
              className="form-check-label"
              htmlFor="isHtmlContentProvided2"
            >
              Url
            </label>
          </div>
        </div>
      </FormGroup>
      {!!isHtmlContentProvided && (
        <FormGroup>
          <Form.Label>Contenu de la notification</Form.Label>
          <Controller
            control={control}
            shouldUnregister={true}
            name="htmlContent"
            rules={{ required: "Le contenu de la notification est requis" }}
            render={({ field: { onChange, onBlur, value } }) => (
              <HtmlEditor
                wrapperClassName=""
                editorClassName={
                  errors.htmlContent
                    ? "form-control is-invalid"
                    : "form-control"
                }
                html={value}
                onBlur={onBlur}
                onHtmlChanged={(html) => onChange(html)}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                    "colorPicker",
                    // 'link',
                    // 'embedded',
                    // 'emoji',
                    // 'image',
                    "remove",
                    "history",
                  ],
                }}
              />
            )}
          />
          {errors.htmlContent?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.htmlContent.message}
            </Form.Control.Feedback>
          )}
        </FormGroup>
      )}
      {!isHtmlContentProvided && (
        <Input
          label="Url"
          {...register("url", {
            required: "L'url de la notification est obligatoire",
            maxLength: {
              value: 255,
              message:
                "L'url de la notification ne doit pas dépasser 255 caractères",
            },
            shouldUnregister: true,
          })}
          errorMessage={errors.url?.message}
          isInvalid={errors.url !== undefined}
          disabled={isSubmitting}
        />
      )}
      {actualSendingDate !== null &&
      actualSendingDate !== undefined &&
      actualSendingDate instanceof Date &&
      !isNaN(actualSendingDate.getTime()) ? (
        <FormGroup>
          <Form.Label>
            Envoyée le{" "}
            {getShortTextMonthnameDayofmonthYearDate(actualSendingDate)}
          </Form.Label>
          <div>
            <Button
              variant="outline-primary"
              onClick={() => setValue("actualSendingDate", undefined)}
            >
              <MdSend />
              Renvoyer
            </Button>
          </div>
        </FormGroup>
      ) : (
        <Controller
          name="desiredSendingDate"
          defaultValue=""
          control={control}
          shouldUnregister={true}
          rules={{
            required: true,
            validate: (value) =>
              moment(value).isSameOrAfter(moment().format("YYYY-MM-DD")),
          }}
          render={({ field }) => (
            <Input
              {...field}
              label="Date d'envoi*"
              type="date"
              value={field.value ? getISO8601Date(field.value) : ""}
              errorMessage={
                errors.desiredSendingDate &&
                errors.desiredSendingDate.type === "required"
                  ? "La date d'envoi est obligatoire"
                  : "La date d'envoi ne doit pas être passée"
              }
              isInvalid={errors.desiredSendingDate !== undefined}
              disabled={isSubmitting}
              onClear={() => field.onChange("")}
            />
          )}
        />
      )}
    </>
  );
};

export default EditNotificationForm;
