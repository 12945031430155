import { useMemo } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { LogisticOperationStatus } from "../../models/LogisticOperation";
import {
  ControlledSimpleSelect,
  ControlledSimpleSelectProps,
  SimpleSelect,
  SimpleSelectProps,
} from "../SimpleSelect";
import { displayLogisticOperationStatus } from "../assets/display/displayLogisticOperationStatus";

const useLogisticOperationStatuses = () => {
  const options = useMemo(
    () => [
      {
        label: displayLogisticOperationStatus(
          LogisticOperationStatus.Validated
        ),
        value: LogisticOperationStatus.Validated,
      },
      {
        label: displayLogisticOperationStatus(LogisticOperationStatus.Archived),
        value: LogisticOperationStatus.Archived,
      },
      {
        label: displayLogisticOperationStatus(LogisticOperationStatus.Deleted),
        value: LogisticOperationStatus.Deleted,
      },
    ],
    []
  );
  return { options };
};

type LogisticOperationStatusesSelectProps<IsMulti extends boolean> = Omit<
  SimpleSelectProps<number, IsMulti>,
  "options"
>;

export const LogisticOperationStatusesSelect = <IsMulti extends boolean>(
  props: LogisticOperationStatusesSelectProps<IsMulti>
) => {
  const res = useLogisticOperationStatuses();
  return <SimpleSelect<number, IsMulti> {...props} {...res} />;
};

type ControlledLogisticOperationStatusesSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledSimpleSelectProps<
  LogisticOperationStatusesSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledLogisticOperationStatusesSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledLogisticOperationStatusesSelectProps<
    TFieldValues,
    TName,
    IsMulti
  >
) => {
  const res = useLogisticOperationStatuses();

  return (
    <ControlledSimpleSelect<TFieldValues, number, IsMulti, TName>
      {...props}
      {...res}
      name={props.name}
    />
  );
};
