import { PropsWithChildren } from "react";

type DisplayGroupProps = PropsWithChildren & {
  className?: string;
  label?: string;
};
export const DisplayGroup = ({
  children,
  className,
  label,
}: DisplayGroupProps) => {
  return (
    <div className={className ? `form-group ${className}` : "form-group"}>
      {label && <label className="form-label">{label}</label>}
      {children}
    </div>
  );
};
