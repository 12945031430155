import { ProviderProps, createContext, useContext } from "react";
import { KeyedMutator } from "swr";
import { ReadReturn } from "../types/dataProvider";

type ResourceContextValue<TResource = any> = {
  resource?: TResource;
  error?: any;
  mutate?: KeyedMutator<ReadReturn<TResource> | undefined>;
  isLoading?: boolean;
};

const ResourceContext = createContext<ResourceContextValue>({});

export const ResourceContextProvider = ({
  children,
  value,
}: ProviderProps<ResourceContextValue>) => (
  <>
    {value ? (
      <ResourceContext.Provider value={value}>
        {children}
      </ResourceContext.Provider>
    ) : (
      children
    )}
  </>
);
type UseResourceProps<T> = {
  resource?: T;
};

export const useResourceContext = <TResource,>(
  props?: UseResourceProps<TResource>
) => {
  const context = useContext(ResourceContext);
  return (
    props?.resource != null ? { resource: props.resource } : context
  ) as ResourceContextValue<TResource>;
};
