import { ReactElement, useEffect } from "react";
import Table, { TableProps } from "react-bootstrap/Table";
import { Column, TableState, usePagination, useTable } from "react-table";
import { Paginator } from "./Paginator";

type PaginationTableProps<T extends object> = TableProps & {
  columns: Column<T>[];
  data: T[];
  pageCount: number;
  desiredPageIndex?: number;
  totalItemsCount: number;
  pageIndexChanged?: (pageIndex: number) => void;
  pageSizeChanged: (pageSize: number) => void;
  initialState: Partial<TableState<T>>;
  hideTableHead?: boolean;
  //à utiliser pour gérer l'état de l'index en dehors de useTable, associé à desiredPageIndex, ne plus utiliser pageIndexChanged dans ce cas
  requestedPageIndex?: (index: number) => void;
};
const hooks = [usePagination];

const PaginationTable = function <T extends object>(
  props: PaginationTableProps<T>
): ReactElement {
  const {
    desiredPageIndex,
    columns,
    data,
    pageCount: controlledPageCount,
    totalItemsCount,
    pageIndexChanged,
    pageSizeChanged,
    initialState,
    hideTableHead,
    requestedPageIndex,
    ...tableProps
  } = props;

  const instance = useTable<T>(
    {
      columns,
      data,
      initialState: initialState, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    ...hooks
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = instance;

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    if (pageSize !== initialState.pageSize) {
      pageSizeChanged(pageSize);
    }
  }, [initialState.pageSize, pageSize, pageSizeChanged]);

  useEffect(() => {
    if (requestedPageIndex === undefined && pageIndexChanged !== undefined) {
      pageIndexChanged(pageIndex);
    }
  }, [pageIndex, pageIndexChanged, requestedPageIndex]);

  useEffect(() => {
    if (desiredPageIndex !== undefined && desiredPageIndex !== pageIndex) {
      gotoPage(desiredPageIndex);
    }
  }, [pageIndex, gotoPage, desiredPageIndex]);

  return (
    <>
      <Table {...tableProps} {...getTableProps()}>
        {!hideTableHead && (
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className="align-middle" {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td className="align-middle" {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={1000}>
              Affichage de {page.length} résultats sur {totalItemsCount}
            </td>
          </tr>
        </tfoot>
      </Table>

      <div>
        <Paginator
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          pageCount={pageCount}
          requestedPageIndex={requestedPageIndex ?? gotoPage}
          pageIndex={pageIndex}
        />
      </div>
    </>
  );
};

export default PaginationTable;
