import { FilterFieldData } from "../../components/DynamicFieldData";
import { ArticlesFilters } from "../../models/filters/ArticlesFilters";
import { ListPage } from "../../resources/components/lists/ListPage";
import { ResourceTable } from "../../resources/components/lists/ResourceTable";
import { useArticlesColumns } from "./hooks/useArticlesColumns";

const fields: FilterFieldData<ArticlesFilters>[] = [
  {
    fieldName: "id",
    type: "number",
    label: "Id",
    placeholder: "Id",
  },
  {
    fieldName: "reference",
    type: "text",
    label: "Référence",
    placeholder: "Référence",
  },
  {
    fieldName: "name",
    type: "text",
    label: "Nom",
    placeholder: "Nom",
  },
  {
    fieldName: "parentId",
    type: "number",
    label: "Id Parent",
    placeholder: "Id Parent",
  },
  {
    fieldName: "isPublished",
    type: "boolean",
    label: "Publié",
    placeholder: "Publié",
  },
  {
    fieldName: "isDeleted",
    type: "boolean",
    label: "Supprimé",
    placeholder: "Supprimé",
  },
];

const ArticlesPage = () => {
  const { columns } = useArticlesColumns();
  return (
    <ListPage filterFieds={fields}>
      <ResourceTable columns={columns} />
    </ListPage>
  );
};
export default ArticlesPage;
