import { CreatePage } from "../../resources/components/CreatePage";
import EditDeliveryZoneForm from "./EditDeliveryZoneForm";

const CreateDeliveryZonePage = () => {
  return (
    <CreatePage>
      <EditDeliveryZoneForm />
    </CreatePage>
  );
};

export default CreateDeliveryZonePage;
