import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { GiReceiveMoney } from "react-icons/gi";
import {
  MdArrowBack,
  MdArrowForward,
  MdCancel,
  MdReceipt,
} from "react-icons/md";
import {
  CustomerAddressElement,
  DeliveryAddressElement,
} from "../../components/Address";
import PaymentInfosSlider from "../../components/PaymentInfosSlider";
import TenantAdminTemplate from "../../components/layout/TenantAdminTemplate";
import useChangeOrderStatus from "../../hooks/orders/useChangeOrderStatus";
import useGetTicketOrder from "../../hooks/orders/useGetTicketOrder";
import { AddressType } from "../../models/AddressType";
import Order from "../../models/Order";
import { OrderStatus, orderStatusToText } from "../../models/OrderStatus";
import { ReadPage } from "../../resources/components/ReadPage";
import { useResourceContext } from "../../resources/hooks/useResourceContext";
import {
  getDate,
  getShortMonthnameDayofmonthYearTimeDate,
} from "../../tools/dateUtils";
import styles from "./OrderPage.module.css";

const DisplayOrderPage = () => {
  const { resource: order, mutate: mutateContext } =
    useResourceContext<Order>();
  const mutate = useCallback(
    (o?: Order) => {
      if (mutateContext != null) {
        mutateContext(o != null ? { data: o } : undefined);
      }
    },
    [mutateContext]
  );
  // Compute orderLine group object.
  const orderLines = useMemo(() => {
    if (!order || !order.orderLines) {
      return;
    }

    const dropShippingGroup = _.groupBy(
      order.orderLines.filter((o) => o.dropShippingProviderName),
      "dropShippingProviderName"
    );
    const stockLocationGroup = _.groupBy(
      order.orderLines.filter((o) => !o.dropShippingProviderName),
      "stockLocationId"
    );
    return { ...dropShippingGroup, ...stockLocationGroup };
  }, [order]);

  const [paymentIsVisible, setPaymentIsVisible] = useState(false);
  const togglePaymentVisibility = () => setPaymentIsVisible(!paymentIsVisible);

  const { changeStatus: payOrder, isBusy: isBusyPaying } = useChangeOrderStatus(
    "PAID",
    "Voulez-vous vraiment changer le statut de cette commande ?",
    mutate
  );
  const { changeStatus: cancelOrder, isBusy: isBusyCancelling } =
    useChangeOrderStatus(
      "CANCELED",
      "Voulez-vous vraiment changer le statut de cette commande ?",
      mutate
    );

  const [ticket, setTicket] = useState<Blob | undefined>();

  const { getTicket, isBusy } = useGetTicketOrder({
    onSuccessCallback(blob) {
      setTicket(blob);
      setTimeout(() => {
        document.querySelector("#ticket")?.scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        });
      }, 500);

      // // file object
      // const file = blob;
      // // anchor link
      // const element = document.createElement("a");
      // element.href = URL.createObjectURL(file);
      // element.download = "ticket-" + Date.now() + ".jpg";
      // // simulate link click
      // document.body.appendChild(element);
      // // Required for this to work in FireFox
      // element.click();
    },
  });

  return (
    <>
      {order && (
        <>
          <h5>
            Commande n°{order.reference} effectuée le{" "}
            {getShortMonthnameDayofmonthYearTimeDate(order.orderDate)}
          </h5>
          {ticket == null && (
            <TenantAdminTemplate>
              <Button
                variant="outline-secondary"
                className="me-2 mb-2"
                disabled={isBusy}
                onClick={() => getTicket(order?.id)}
              >
                <MdReceipt />
                <span className="ms-1">Ticket</span>
                {isBusy ? (
                  <Spinner className="mx-1" animation="border" size="sm" />
                ) : (
                  <></>
                )}
              </Button>
            </TenantAdminTemplate>
          )}

          {order.orderStatus === OrderStatus.Registred && (
            <>
              <Button
                variant="outline-secondary"
                className="me-2 mb-2"
                onClick={() => payOrder(order?.id)}
              >
                <GiReceiveMoney />
                <span className="ms-1">Payer</span>
                {isBusyPaying ? (
                  <Spinner className="mx-1" animation="border" size="sm" />
                ) : (
                  ""
                )}
              </Button>
              <Button
                variant="outline-secondary"
                className="me-2 mb-2"
                onClick={() => cancelOrder(order?.id)}
              >
                <MdCancel />
                <span className="ms-1">Annuler</span>
                {isBusyCancelling ? (
                  <Spinner className="mx-1" animation="border" size="sm" />
                ) : (
                  ""
                )}
              </Button>
            </>
          )}
          <table
            className={`${styles["key-value-table"]} table table-sm m-0 mb-2`}
          >
            <tbody>
              <tr>
                <th>Etat</th>
                <td>{orderStatusToText(order.orderStatus)}</td>
              </tr>
              {order.paymentInformations.length > 0 && (
                <tr>
                  <th>
                    <Button
                      variant="outline-dark"
                      size="sm"
                      className="mb-2"
                      onClick={() => togglePaymentVisibility()}
                    >
                      {paymentIsVisible && (
                        <span>
                          Masquer Informations de paiement
                          <MdArrowBack />
                        </span>
                      )}
                      {!paymentIsVisible && (
                        <span>
                          Afficher Informations de paiement
                          <MdArrowForward />
                        </span>
                      )}
                    </Button>
                  </th>
                  <td>
                    {paymentIsVisible && (
                      <PaymentInfosSlider
                        paymentInfos={order.paymentInformations}
                      ></PaymentInfosSlider>
                    )}
                  </td>
                </tr>
              )}
              <tr>
                <th>
                  Montant TTC <br /> <small>(frais de port inclus)</small>
                </th>
                <td>{order.totalIncludingTaxes}€</td>
              </tr>
              <tr>
                <th>Magasin</th>
                <td>{order?.store?.name}</td>
              </tr>
            </tbody>
          </table>
          <div className="card mb-2">
            <div className="card-header">Informations client</div>
            <div className="card-body">
              {!order.guestCustomer && <>Nom: {order.customerName}</>}
              <CustomerAddressElement customer={order.guestCustomer} />
            </div>
          </div>

          {orderLines &&
            _.map(orderLines, (v, k) => {
              const firstLine = v.length > 0 ? v[0] : undefined;
              const stockLocation = firstLine?.stockLocation;
              const isDropShipping =
                firstLine?.dropShippingProviderName !== undefined &&
                firstLine?.dropShippingProviderName !== null;
              const isInStorePickUp =
                firstLine?.stockLocation?.storeId !== null;
              const isInStoreDelivery = !order.isCustomerHomeDelivery;
              const isHomeDelivery =
                order.isCustomerHomeDelivery && order.deliveryModeId !== null;
              const isWebOrder = stockLocation
                ? stockLocation.code === "WEB"
                : false;

              return (
                <div className="card mb-2" key={k}>
                  <div className="card-header">
                    {isDropShipping && (
                      <>
                        <>
                          Produits livrés à domicile par le fournisseur{" "}
                          {firstLine?.dropShippingProviderName}
                        </>
                        {firstLine?.dropShippingProviderMinDate &&
                        firstLine?.dropShippingProviderMaxDate ? (
                          <>
                            {" "}
                            entre le{" "}
                            {getDate(firstLine?.dropShippingProviderMinDate)} et
                            le {getDate(firstLine?.dropShippingProviderMaxDate)}
                          </>
                        ) : firstLine?.dropShippingProviderMaxDate ? (
                          <>
                            {" "}
                            le {getDate(firstLine?.dropShippingProviderMaxDate)}
                          </>
                        ) : null}
                      </>
                    )}
                    {isInStorePickUp && (
                      <>
                        Produits retirés dans le magasin {stockLocation?.name}
                      </>
                    )}
                    {isWebOrder && (
                      <>
                        {" "}
                        {!isDropShipping && (
                          <>{order.deliveryModeLabel ?? "Contenu"}</>
                        )}
                        {order.estimatedDeliveryDate && (
                          <> prévue le {getDate(order.estimatedDeliveryDate)}</>
                        )}
                        {order.deliveryPrice !== null && (
                          <> - Frais de port : {order.deliveryPrice} € TTC</>
                        )}
                      </>
                    )}
                  </div>
                  <div className="card-body">
                    {isWebOrder && (
                      <>
                        {isInStoreDelivery && (
                          <DeliveryAddressElement
                            address={order.deliveryAddresses.find(
                              (a) => a.type === AddressType.Store
                            )}
                          />
                        )}
                        {isHomeDelivery && (
                          <DeliveryAddressElement
                            address={order.deliveryAddresses.find(
                              (a) => a.type === AddressType.Customer
                            )}
                          />
                        )}
                        {isDropShipping && (
                          <DeliveryAddressElement
                            address={
                              order.deliveryAddresses.find(
                                (a) => a.type === AddressType.Customer
                              ) ?? order.deliveryAddress
                            }
                          />
                        )}
                      </>
                    )}
                    <strong>Produits</strong>
                    <table className="table table-sm table-borderless  table-sm table-hover">
                      <tbody>
                        {v.map((line) => (
                          <tr
                            key={line.orderLineId}
                            className={styles["order-line"]}
                          >
                            <td>
                              <span className={styles["line-label"]}>
                                {line.label}
                              </span>
                              <small>
                                Ref: {line.reference}{" "}
                                {line.barCode && <>- EAN: {line.barCode}</>}
                              </small>
                            </td>
                            <td className="text-end align-middle">
                              <pre>
                                {line.priceIncludingTaxes
                                  .toString()
                                  .padStart(6)}{" "}
                                € x {line.quantity.toString().padEnd(3)} ={" "}
                                {(line.priceIncludingTaxes * line.quantity)
                                  .toString()
                                  .padStart(6)}{" "}
                                € TTC
                              </pre>
                            </td>
                            <td></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
          <div id="ticket">
            {ticket != null && (
              <img
                style={{ width: "51mm" }}
                src={URL.createObjectURL(ticket)}
                className="img-fluid img-thumbnail mx-auto d-block"
                alt="ticket"
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
const OrderPage = () => (
  <ReadPage>
    <DisplayOrderPage />
  </ReadPage>
);
export default OrderPage;
