import EditCampaignPage from "../components/EditCampaignPage";
import EditOrCreateHomeCatalogsCampaign from "../components/EditOrCreateHomeCatalogsCampaign";

const EditHomeCatalogsCampaignPage = () => {
  return (
    <EditCampaignPage>
      <EditOrCreateHomeCatalogsCampaign />
    </EditCampaignPage>
  );
};

export default EditHomeCatalogsCampaignPage;
