import { useCallback, useState } from "react";
import Alert from "react-bootstrap/esm/Alert";
import { CollapseDisplayGroup } from "../../components/CollapseDisplayGroup";
import { Attribute } from "../../models/Attribute";
import { EditPage } from "../../resources/components/EditPage";
import { ReadResource } from "../../resources/components/ReadResource";
import { ArticleAttributes } from "./components/ArticleAttributes";
import { AttributeValues } from "./components/AttributeValues";
import { EditAttributeForm } from "./components/EditAttributeForm";

const EditAttributePage = () => {
  const [showWarning, SetShowWarning] = useState(false);
  const onSuccess = useCallback(() => {
    SetShowWarning(true);
  }, []);

  const transformBeforeSubmit = useCallback((d: Attribute) => {
    d.visibility = Number(d.visibility);
    return d;
  }, []);
  return (
    <EditPage<Attribute>
      canDelete={false}
      onSuccess={onSuccess}
      transformBeforeSubmit={transformBeforeSubmit}
    >
      <Alert variant="warning" dismissible show={showWarning}>
        Les modifications seront visibles après la prochaine synchronisation
      </Alert>
      <EditAttributeForm />
      <CollapseDisplayGroup
        label="Valeurs"
        canHide={false}
        addBodyMargin={false}
      >
        <ReadResource<Attribute>
          resource={(resource: Attribute) => (
            <AttributeValues attributeId={resource.id} />
          )}
        />
      </CollapseDisplayGroup>
      <CollapseDisplayGroup
        label="Articles"
        canHide={false}
        addBodyMargin={false}
      >
        <ReadResource<Attribute>
          resource={(resource: Attribute) => (
            <ArticleAttributes attributeId={resource.id} />
          )}
        />
      </CollapseDisplayGroup>
    </EditPage>
  );
};

export default EditAttributePage;
