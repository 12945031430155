import { useCallback } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { ListCondition } from "../../models/ListCondition";
import { ArticlesFilters } from "../../models/filters/ArticlesFilters";
import { BrandsFilters } from "../../models/filters/BrandsFilters";
import {
  ControlledResourcesSelect,
  ControlledResourcesSelectProps,
  ReactSelectProps,
  ResourcesSelect,
} from "../../resources/components/selects/ResourcesSelect";
import { ResourceIdentifier } from "../../resources/types/resourceIdentifier";
import { ResourceName } from "../../resources/types/resourceName";
import ReactSelectOption from "../ReactSelectOption";

type UseBrandsSelectControllerProps<
  TResourceIdentifier extends ResourceIdentifier
> = {
  excludedIds?: TResourceIdentifier[];
};

const useBrandsSelectController = <
  TFilters extends BrandsFilters = BrandsFilters
>(
  props: UseBrandsSelectControllerProps<string>
) => {
  const { excludedIds } = props;
  const convertToOption = useCallback(
    (value: string | undefined): ReactSelectOption<string> | undefined =>
      value != null
        ? {
            label: `${value}`,
            value: value as string,
          }
        : undefined,
    []
  );
  const transformToFilters = useCallback(
    (search: string) =>
      ({
        excludedIds,
        brand: search,
        listCondition: ListCondition.Or,
      } as TFilters),
    [excludedIds]
  );
  const resourceName: ResourceName = "brands";
  return { convertToOption, resourceName, transformToFilters };
};

type BrandsSelectProps<IsMulti extends boolean> =
  UseBrandsSelectControllerProps<string> & ReactSelectProps<string, IsMulti>;

export const BrandsSelect = <IsMulti extends boolean>(
  props: BrandsSelectProps<IsMulti>
) => {
  const c = useBrandsSelectController(props);
  return (
    <ResourcesSelect<string, string, IsMulti, ResourceName, BrandsFilters>
      {...c}
      {...props}
    />
  );
};

type ControlledBrandsSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledResourcesSelectProps<
  BrandsSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledBrandsSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledBrandsSelectProps<TFieldValues, TName, IsMulti>
) => {
  const c = useBrandsSelectController(props);

  return (
    <ControlledResourcesSelect<
      string,
      string,
      IsMulti,
      ResourceName,
      ArticlesFilters,
      TFieldValues,
      TName
    >
      {...c}
      {...props}
      name={props.name}
    />
  );
};
