import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import Notification from "../../models/Notification";
import { NotificationsFilters } from "../../models/filters/NotificationsFilters";
import { CreateResourceButton } from "../../resources/components/buttons/CreateResourceButton";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { useAddEditButton } from "../../resources/hooks/useAddEditButton";
import { getShortTextMonthnameDayofmonthYearDate } from "../../tools/dateUtils";

const NotificationsPage = () => {
  const filters: FilterFieldData<NotificationsFilters>[] = useMemo(
    () => [
      {
        fieldName: "name",
        type: "text",
        label: "Nom",
        placeholder: "Nom",
      },
    ],
    []
  );

  const columns: ResourceColumn<Notification>[] = useMemo(
    () => [
      {
        header: "Nom",
        accessor: "name",
      },
      {
        header: "Date d'envoi souhaitée",
        accessor: "desiredSendingDate",
        getCell: (n) =>
          getShortTextMonthnameDayofmonthYearDate(n.desiredSendingDate),
      },
      {
        header: "Status",
        accessor: "actualSendingDate",
        getCell: (n) =>
          n.actualSendingDate
            ? "envoyée le " +
              getShortTextMonthnameDayofmonthYearDate(n.actualSendingDate)
            : "non envoyée",
      },
      {
        header: "Cible",
        accessor: (n) => n.broadcastList?.name ?? "tous les périphériques",
      },
    ],
    []
  );
  const { columnsWithButton } = useAddEditButton({ columns });

  return (
    <ListPage
      filterFieds={filters}
      actions={<CreateResourceButton />}
      showChildWithoutFilters
    >
      <ResourceTable columns={columnsWithButton} />
    </ListPage>
  );
};

export default NotificationsPage;
