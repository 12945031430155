import EditCampaignPage from "../components/EditCampaignPage";
import EditOrCreateAnimationMediasCampaign from "../components/EditOrCreateAnimationMediasCampaign";

const EditHomePanelsCampaignPage = () => {
  return (
    <EditCampaignPage>
      <EditOrCreateAnimationMediasCampaign
        createModalTitle="Créer un HomePanel"
        editModalTitle="Editer un HomePanel"
        addItemButtonMessage="Ajouter un Home Panel"
      />
    </EditCampaignPage>
  );
};
export default EditHomePanelsCampaignPage;
