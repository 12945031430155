import { useCallback, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import FormGroup from "react-bootstrap/esm/FormGroup";
import Table from "react-bootstrap/esm/Table";
import { useFieldArray, useFormContext } from "react-hook-form";
import { PropsValue } from "react-select";
import { CustomerLink } from "../../../components/navigation/CustomerLink";
import { DevicesSelect } from "../../../components/selects/DevicesSelect";
import { StoresSelect } from "../../../components/selects/StoresSelect";
import AnimationGroup from "../../../models/AnimationGroup";
import Device from "../../../models/Device";
import { ResourceHiddenInput } from "../../../resources/components/inputs/ResourceHiddenInput";
import { ResourceInput } from "../../../resources/components/inputs/ResourceInput";
import { useReadResourceFromSelect } from "../../../resources/components/selects/hooks/useReadResourceFromSelect";
import { useResourceUrlPathResolver } from "../../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../../resources/types/resourceName";

const EditAnimationGroupForm = () => {
  const {
    resourceUrlPathResolver: { edit },
  } = useResourceUrlPathResolver<ResourceName>();
  const { getValues } = useFormContext<AnimationGroup>();
  const animationGroupId = getValues("id");
  const { fields, remove, append } = useFieldArray<
    AnimationGroup,
    "devices",
    "_id"
  >({
    name: "devices",
    keyName: "_id",
  });

  const [storeId, setStoreId] = useState<number | undefined>();
  const onStoreIdChanged = useCallback((s: PropsValue<number> | undefined) => {
    if (Array.isArray(s)) {
      const [first] = s;
      setStoreId(first);
    } else {
      setStoreId(s as number);
    }
  }, []);

  const {
    resourceId,
    resource: currentDevice,
    onResourceIdChanged: onDeviceIdChanged,
    setResourceId: setDeviceId,
  } = useReadResourceFromSelect<Device, ResourceName, string>({
    resourceName: "devices",
  });

  return (
    <>
      <ResourceInput<AnimationGroup>
        label="Nom du groupe*"
        name="label"
        options={{
          required: {
            message: "Le nom du groupe est obligatoire",
            value: true,
          },
          maxLength: {
            message: "Le nom du groupe ne peut pas dépasser 50 caractères",
            value: 50,
          },
        }}
      />
      <FormGroup>
        <Form.Label>Périphériques</Form.Label>
        <div className="row">
          <div className="col">
            <StoresSelect
              idPropsValue={storeId}
              placeholder="Sélectionner un magasin"
              onIdPropsValueChanged={onStoreIdChanged}
            />
          </div>
          <div className="col">
            {storeId && (
              <DevicesSelect
                storeId={storeId}
                excludedIds={fields
                  ?.filter((f) => f.id != null)
                  .map((f) => f.id)}
                idPropsValue={resourceId}
                onIdPropsValueChanged={onDeviceIdChanged}
              />
            )}
          </div>
          <div className="col-auto">
            {storeId && (
              <Button
                type="button"
                disabled={!currentDevice}
                variant="outline-primary"
                onClick={() => {
                  if (currentDevice) {
                    append(currentDevice);
                    setDeviceId(undefined);
                  }
                }}
              >
                Ajouter ce périphérique
              </Button>
            )}
          </div>
        </div>
      </FormGroup>
      {fields.length > 0 && (
        <Table size="sm" hover>
          <thead>
            <tr>
              <th>Nom du périphérique</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {fields.map((f, index) => {
              return (
                <tr key={f._id}>
                  <td className="align-middle">
                    {f.label}
                    <ResourceHiddenInput<AnimationGroup>
                      name={`devices.${index}.id`}
                    />
                  </td>
                  <td className="align-middle">
                    {f.animationGroupId &&
                      f.animationGroupId !== animationGroupId && (
                        <CustomerLink
                          to={edit("animations/groups", f.animationGroupId)}
                          className="btn btn-sm btn-warning"
                          target="_blank"
                        >
                          Attention, ce périphérique est déjà associé à un autre
                          groupe d'animation
                        </CustomerLink>
                      )}
                  </td>
                  <td>
                    <Button
                      type="button"
                      size="sm"
                      variant="outline-danger"
                      onClick={() => remove(index)}
                    >
                      Enlever
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default EditAnimationGroupForm;
