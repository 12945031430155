import Customer from "../../models/Customer";
import useFetchForCustomer from "../swr/useSWRFetchForCustomer";

const useAreAnalysticsEnabled = function (customer?: Customer) {
  const res = useFetchForCustomer(
    "/api/analytics/analyticsareenabled",
    customer
  );
  return { ...res, areAnalysticsEnabled: res.data as boolean };
};
export default useAreAnalysticsEnabled;
