import { useCurrentCustomer } from "../../LoggedInBackOffice";
import { AsyncHandler } from "../../components/AsyncHandler";
import Input from "../../components/Input";
import { Loading } from "../../components/types/loading";
import { useFtpParameters } from "../../hooks/useFtpParameters/useFtpParameters";
import { PageTitle } from "../../resources/components/PageTitle";

export const FtpParametersPage = () => {
  const currentCustomer = useCurrentCustomer();

  const {
    ftpParameters,
    error,
    mutate: refresh,
    isLoading,
  } = useFtpParameters({ customer: currentCustomer });
  return (
    <>
      <PageTitle label="Paramètres FTP" />
      <hr />
      <AsyncHandler
        error={error}
        errorMessage="Une erreur est survenue lors de la récupération des paramètres."
        refresh={refresh}
        isLoading={isLoading}
        loading={Loading.Circle}
      >
        {ftpParameters && (
          <div className="d-flex flex-row gap-2">
            <Input
              className="col-md-6"
              label="Login FTP"
              value={ftpParameters.loginFtp}
              disabled
            />
            <Input
              className="col-md-6"
              label="Mot de passe FTP"
              value={ftpParameters.passwordFtp}
              disabled
            />
          </div>
        )}
      </AsyncHandler>
    </>
  );
};
