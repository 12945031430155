import { useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import PaymentInfo from "../models/PaymentInfo";

const PaymentInfosSlider = ({
  paymentInfos,
}: {
  paymentInfos: PaymentInfo[];
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const onPrevious = useCallback(() => {
    setCurrentIndex((i) => i - 1);
  }, []);
  const onNext = useCallback(() => {
    setCurrentIndex((i) => i + 1);
  }, []);

  if (!paymentInfos || paymentInfos.length === 0) {
    return <div>Pas de paiement</div>;
  }

  return (
    <div>
      <pre>{paymentInfos[currentIndex].transactionInfo}</pre>
      <Button
        variant="outline-dark"
        size="sm"
        className="mb-2"
        disabled={currentIndex <= 0}
        onClick={onPrevious}
      >
        <MdArrowBack />
      </Button>
      <Button
        variant="outline-dark"
        size="sm"
        className="mb-2"
        disabled={currentIndex >= paymentInfos.length - 1}
        onClick={onNext}
      >
        <MdArrowForward />
      </Button>
    </div>
  );
};

export default PaymentInfosSlider;
