import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import FormGroup from "react-bootstrap/esm/FormGroup";
import Spinner from "react-bootstrap/esm/Spinner";
import { Controller, useForm, useWatch } from "react-hook-form";
import ReactSelect from "react-select";
import Input from "../../components/Input";
import ReactSelectOption from "../../components/ReactSelectOption";
import useCustomerApiFetch from "../../hooks/useCustomerApiFetch";
import { AnalyticsTable } from "../../models/AnalyticsTable";
import TemporaryUrl from "../../models/TemporaryUrl";
import { PageTitle } from "../../resources/components/PageTitle";
import { useAnalyticsTableOptions } from "./shared/useAnalyticsTableOptions";

type ExportStatisticsParameters = {
  table?: AnalyticsTable;
  from?: Date | string;
  to?: Date | string;
};

export enum Period {
  year = "year",
  week = "week",
  month = "month",
  dates = "dates",
}

type DetailedExportStatisticsParameters = {
  table?: AnalyticsTable;
  year: number;
  month: string;
  week: string;
  period: Period;
  fromDate: string;
  toDate: string;
};
const ExportStatsPage = () => {
  const currentDate = useMemo(() => new Date(), []);
  const todayDate = useMemo(
    () => currentDate.toISOString().split("T")[0],
    [currentDate]
  );
  const currentYear = useMemo(() => currentDate.getFullYear(), [currentDate]);
  const currentMonth = useMemo(
    () => currentYear + "-" + ("0" + (currentDate.getMonth() + 1)).slice(-2),
    [currentYear, currentDate]
  );
  const currentWeek = useMemo(() => {
    const { week, year } = getWeek(currentDate);
    return year + "-W" + ("0" + week).slice(-2);
  }, [currentDate]);
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { dirtyFields, errors, isSubmitting },
  } = useForm<DetailedExportStatisticsParameters>({
    mode: "onChange",
    defaultValues: {
      period: Period.week,
    },
  });
  const period = useWatch({
    control,
    name: "period",
  });

  const [temporaryUrl, setTemporaryUrl] = useState<TemporaryUrl>();
  const getParameters = useCallback(
    (data: DetailedExportStatisticsParameters) => {
      let paramerters = {} as ExportStatisticsParameters;
      if (data.table !== undefined) {
        paramerters.table = data.table;
      }
      switch (period) {
        case Period.dates:
          if (data.fromDate !== undefined && data.toDate !== undefined) {
            paramerters.from = new Date(data.fromDate);
            paramerters.to = new Date(data.toDate);
          }
          break;
        case Period.year:
          if (data.year) {
            paramerters.from = new Date(data.year, 0, 1);
            paramerters.to = new Date(data.year, 11, 31);
          }
          break;
        case Period.month:
          if (data.month !== undefined) {
            if (typeof data.month === "string") {
              paramerters.from = new Date(data.month + "-01");
              let to = new Date(paramerters.from);
              to.setMonth(to.getMonth() + 1);
              to.setDate(to.getDate() - 1);
              paramerters.to = to;
            }
          }
          break;
        case Period.week:
          if (data.week !== undefined) {
            if (typeof data.week === "string") {
              const { monday: from, sunday: to } = getWeekDates(data.week);

              paramerters.from = from;
              paramerters.to = to;
            }
          }
          break;
      }
      return paramerters;
    },
    [period]
  );
  const apiFetcher = useCustomerApiFetch();

  const fetchTemporaryUrl = useCallback(
    (data: DetailedExportStatisticsParameters) => {
      return apiFetcher(`/api/analytics/export`, {
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(getParameters(data)),
        method: "POST",
      })
        .then((d) => {
          if (!d.ok) {
            throw new Error(d.statusText);
          }
          return d.json();
        })
        .then((d) => {
          const t = d as TemporaryUrl;
          return t;
        });
    },
    [apiFetcher, getParameters]
  );

  const analyticsTableOptions = useAnalyticsTableOptions();

  const periodOptions = useMemo(() => {
    return [
      // { value: Period.year, label: "Année" },
      { value: Period.month, label: "Mois" },
      { value: Period.week, label: "Semaine" },
      { value: Period.dates, label: "Dates" },
    ] as ReactSelectOption<string>[];
  }, []);
  const isDirty = Object.keys(dirtyFields).length > 0;

  useEffect(() => {
    if (isDirty) {
      setSubmitError("");
      setTemporaryUrl(undefined);
    }
  }, [isDirty]);
  const [submitError, setSubmitError] = useState("");
  const onSubmit = useCallback(
    async (data: DetailedExportStatisticsParameters) => {
      setSubmitError("");

      if (temporaryUrl === undefined) {
        try {
          let t = await fetchTemporaryUrl(data);
          setTemporaryUrl(t);
          reset(data, { keepDirty: false });
          window.open(t.url);
        } catch (e) {
          setSubmitError("Impossible de télécharger ce fichier");
        }
      } else {
        window.open(temporaryUrl.url);
      }
    },
    [fetchTemporaryUrl, reset, temporaryUrl]
  );

  return (
    <>
      <PageTitle label="Export de statistiques" />
      <hr />
      <Form
        className="d-flex align-items-start flex-column gap-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormGroup>
          <Form.Label>Fichier*</Form.Label>
          <Controller
            name="table"
            control={control}
            rules={{ required: "Veuillez choisir un fichier" }}
            render={({ field: { onChange, value, ref } }) => (
              <ReactSelect
                className={`${errors && errors?.table ? "is-invalid" : ""}`}
                options={analyticsTableOptions}
                ref={ref}
                value={analyticsTableOptions.filter((c) => value === c.value)}
                onChange={(val) =>
                  onChange((val as ReactSelectOption<number>).value)
                }
                placeholder="Sélectionner un fichier"
                isLoading={
                  analyticsTableOptions === undefined ||
                  analyticsTableOptions.length === 0
                }
              />
            )}
          />
          {errors && errors?.table?.message && (
            <Form.Control.Feedback type="invalid">
              {errors?.table?.message}
            </Form.Control.Feedback>
          )}
        </FormGroup>
        <FormGroup>
          <Form.Label className="form-label">Période*</Form.Label>
          <div>
            {periodOptions.map((p) => (
              <div key={p.value} className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  {...register("period", {
                    required: true,
                  })}
                  type="radio"
                  id={p.value}
                  value={p.value}
                  defaultChecked={period === p.value}
                />
                <label className="form-check-label" htmlFor={p.value}>
                  {p.label}
                </label>
              </div>
            ))}
          </div>
        </FormGroup>
        {period && (
          <>
            {period === Period.year && (
              <Input
                label="Année*"
                {...register("year", {
                  valueAsNumber: true,
                  required: true,
                })}
                errorMessage={errors.year?.message}
                isInvalid={errors.year !== undefined}
                defaultValue={currentYear}
                type="number"
                min="2000"
                max={currentYear}
                step="1"
                placeholder="année"
              />
            )}
            {period === Period.month && (
              <Input
                label="Mois*"
                {...register("month", {
                  required: true,
                })}
                errorMessage={errors.month?.message}
                isInvalid={errors.month !== undefined}
                defaultValue={currentMonth}
                type="month"
                placeholder="mois"
              />
            )}
            {period === Period.week && (
              <Input
                label="Semaine*"
                {...register("week", {
                  required: true,
                })}
                errorMessage={errors.week?.message}
                isInvalid={errors.week !== undefined}
                defaultValue={currentWeek}
                type="week"
                max={currentWeek}
                placeholder="semaine"
              />
            )}
            {period === Period.dates && (
              <>
                <Input
                  label="Du*"
                  {...register("fromDate", {
                    required: true,
                    validate: (value) =>
                      new Date(value) <= new Date(getValues("toDate")),
                  })}
                  errorMessage={errors.fromDate?.message}
                  isInvalid={errors.fromDate !== undefined}
                  defaultValue={todayDate}
                  max={todayDate}
                  type="date"
                  placeholder="du"
                />
                <Input
                  label="Au*"
                  {...register("toDate", {
                    required: true,
                    validate: (value) =>
                      new Date(getValues("fromDate")) <= new Date(value),
                  })}
                  errorMessage={errors.toDate?.message}
                  isInvalid={errors.toDate !== undefined}
                  defaultValue={todayDate}
                  type="date"
                  max={todayDate}
                  placeholder="au"
                />
              </>
            )}
          </>
        )}
        {isSubmitting && (
          <Spinner className="mx-1" animation="border" size="sm" />
        )}
        <Button type="submit">Télécharger</Button>

        {submitError && submitError !== "" && (
          <small className="text-danger ms-1">{submitError}</small>
        )}
      </Form>
    </>
  );
};

const getWeek = (d: Date) => {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(
    ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  );
  // Return year and week number
  return { year: d.getUTCFullYear(), week: weekNo };
};

/**
 *
 * @param yearWeek : string (2023-W31)
 * @returns monday : Date, sunday : Date
 */
function getWeekDates(yearWeek: string) {
  const [year, weekNumber] = yearWeek.split("-W").map(Number);

  const firstDayOfYear = new Date(year, 0, 1);
  const daysOffset = firstDayOfYear.getDay() - 1; // Offset for making Monday the first day of the week

  const startDate = new Date(year, 0, 1 + (weekNumber - 1) * 7 - daysOffset);
  const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);

  return {
    monday: startDate,
    sunday: endDate,
  };
}

export default ExportStatsPage;
