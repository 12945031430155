import { useEffect, useState } from "react";
import { AnalyticsExportPeriodicity } from "../../models/AnalyticsExportPeriodicity";
import { AnalyticsExportScheduledJob } from "../../models/AnalyticsExportScheduledJob";
import { AnalyticsTable } from "../../models/AnalyticsTable";
import Customer from "../../models/Customer";
import Nullable from "../../Nullable";
import useFetchForCustomer from "../swr/useSWRFetchForCustomer";

const useAnalyticsExportScheduledJobs = function (
  customer: Nullable<Customer>,
  analyticsTable: AnalyticsTable | undefined,
  email: string | undefined,
  periodicity: AnalyticsExportPeriodicity | undefined
) {
  const res = useFetchForCustomer(
    "/api/analyticsexportscheduledjobs",
    customer
  );
  const [jobs, setJobs] = useState<AnalyticsExportScheduledJob[]>();
  useEffect(() => {
    let result = res.data as AnalyticsExportScheduledJob[];
    if (result) {
      if (analyticsTable !== undefined) {
        result = result.filter((job) =>
          job.analyticsTables.some((a) => a === analyticsTable)
        );
      }
      if (periodicity !== undefined) {
        result = result.filter((job) => job.periodicity === periodicity);
      }
      if (email !== undefined) {
        result = result.filter((job) =>
          job.emails.some((e) => e.toLowerCase().indexOf(email) > -1)
        );
      }
      setJobs(result);
    }
  }, [analyticsTable, email, periodicity, res.data]);

  return { ...res, analyticsExportScheduledJobs: jobs };
};
export default useAnalyticsExportScheduledJobs;
