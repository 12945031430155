import moment from "moment";
import { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import FormGroup from "react-bootstrap/esm/FormGroup";
import { useFormContext } from "react-hook-form";
import {
  MdSentimentSatisfied,
  MdSentimentVeryDissatisfied,
} from "react-icons/md";
import { useCurrentCustomer } from "../../../LoggedInBackOffice";
import { CollapseDisplayGroup } from "../../../components/CollapseDisplayGroup";
import { ControlledSimpleSelect } from "../../../components/SimpleSelect";
import { Stack } from "../../../components/Stack";
import TenantAdminTemplate from "../../../components/layout/TenantAdminTemplate";
import { ControlledAnimationGroupsSelect } from "../../../components/selects/AnimationGroupsSelect";
import { ControlledDeviceTypesSelect } from "../../../components/selects/DeviceTypesSelect";
import { ControlledStoresSelect } from "../../../components/selects/StoresSelect";
import useDeviceConnectionLog from "../../../hooks/devices/useDeviceConnectionLog";
import Device from "../../../models/Device";
import { PaymentProvider } from "../../../models/DeviceParameters";
import { ResourceCheckInput } from "../../../resources/components/inputs/ResourceCheckInput";
import { ResourceInput } from "../../../resources/components/inputs/ResourceInput";
import { useResourceParams } from "../../../resources/hooks/useResourceParams";

export interface EditDeviceFormProps {
  mode: "edit" | "create";
}
const EditDeviceForm = ({ mode }: EditDeviceFormProps) => {
  const currentCustomer = useCurrentCustomer();
  const { id } = useResourceParams();
  const { deviceConnectionLog } = useDeviceConnectionLog(currentCustomer, id);
  const paymentProviderOptions = useMemo(
    () => [
      { value: PaymentProvider.None, label: "Aucun" },
      { value: PaymentProvider.PayPlug, label: "PayPlug" },
      { value: PaymentProvider.Nepting, label: "Nepting" },
      { value: PaymentProvider.Test, label: "Test" },
    ],
    []
  );
  const { watch } = useFormContext<Device>();
  const paymentProvider = Number(watch("parameters.paymentProvider"));
  const paymentIsEnabled = watch("parameters.paymentIsEnabled");

  return (
    <>
      {mode === "edit" && deviceConnectionLog && (
        <FormGroup>
          {deviceConnectionLog.isActive ? (
            <>
              <MdSentimentSatisfied style={{ color: "green" }} /> Connecté
            </>
          ) : (
            <>
              <MdSentimentVeryDissatisfied style={{ color: "red" }} /> Non
              connecté
            </>
          )}
          {deviceConnectionLog.lastConnection && (
            <span>
              , dernière connexion le{" "}
              {moment(deviceConnectionLog.lastConnection).format(
                "DD/MM/YYYY [à] HH[h]mm"
              )}{" "}
            </span>
          )}
        </FormGroup>
      )}
      <ResourceInput<Device>
        name="id"
        label="Identifiant du périphérique*"
        options={{
          required: "L'identifiant du périphérique est obligatoire",
          validate: (id: string) =>
            /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
              id
            ) || "L'identifiant du périphérique doit être un uuid / guid",
        }}
        readOnly={mode === "edit"}
      />

      <ResourceInput<Device>
        label="Nom du périphérique*"
        name="label"
        options={{
          required: "Le nom du périphérique du périphérique est obligatoire",
        }}
      />

      <Row>
        <Col xl={4}>
          <ControlledStoresSelect<Device>
            label="Magasin*"
            name="storeId"
            rules={{ required: "Le magasin est obligatoire" }}
          />
        </Col>
        <Col xl={4}>
          <ControlledDeviceTypesSelect<Device>
            label="Type du périphérique*"
            rules={{ required: "Le type du périphérique est obligatoire" }}
            name="deviceTypeId"
            placeholder="Sélectionner le type du périphérique"
          />
        </Col>
        <Col xl={4}>
          <ControlledAnimationGroupsSelect<Device>
            label="Groupe d'animation"
            placeholder="Sélectionner un groupe de périphériques"
            name="animationGroupId"
          />
        </Col>
      </Row>
      <TenantAdminTemplate>
        <Row>
          <Col>
            <CollapseDisplayGroup label="Paramètres">
              <Stack>
                <ResourceCheckInput<Device>
                  label="Activation du paiement"
                  name="parameters.paymentIsEnabled"
                />
                {paymentIsEnabled && (
                  <>
                    <ControlledSimpleSelect<Device, number>
                      name="parameters.paymentProvider"
                      options={paymentProviderOptions}
                    />
                    {paymentProvider === PaymentProvider.PayPlug && (
                      <>
                        <ResourceInput<Device>
                          label="Poi Id"
                          name="parameters.payPlugParameters.poiId"
                        />
                        <ResourceInput<Device>
                          label="Port"
                          name="parameters.payPlugParameters.port"
                          type="number"
                        />
                        <ResourceInput<Device>
                          label="Tpe Ip Address"
                          name="parameters.payPlugParameters.tpeIpAddress"
                        />
                      </>
                    )}
                    {paymentProvider === PaymentProvider.Nepting && (
                      <>
                        <ResourceInput<Device>
                          label="Merchant Code"
                          name="parameters.neptingParameters.merchantCode"
                        />
                        <ResourceInput<Device>
                          label="Url"
                          name="parameters.neptingParameters.url"
                        />
                      </>
                    )}
                  </>
                )}
              </Stack>
            </CollapseDisplayGroup>
          </Col>
        </Row>
      </TenantAdminTemplate>
    </>
  );
};

export default EditDeviceForm;
