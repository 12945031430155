import isEqual from "lodash/isEqual";
import { PropsWithChildren, ReactNode, useCallback, useEffect } from "react";
import { FilterFieldData } from "../../../components/DynamicFieldData";
import { Filter } from "../../../components/Filter";
import { UseControlledPaginationSWRListResourceReturn } from "../../hooks/useControlledPaginationSWRListResource";
import { ResourceListContextProvider } from "../../hooks/useResourceListContext";
import { ResourceFilters } from "../../types/resourceFilters";

export type ListLayoutDefaultFitlersProps<
  TResourceFilters extends ResourceFilters = ResourceFilters
> = {
  defaultFilters?: TResourceFilters;
};
export type ListLayoutProps<TResourceFilters extends ResourceFilters> =
  PropsWithChildren &
    ListLayoutDefaultFitlersProps<TResourceFilters> & {
      actions?: ReactNode;
      filterFieds?: FilterFieldData<TResourceFilters>[];
      showChildWithoutFilters?: boolean;
      transformResourceFilters?: (
        filters: TResourceFilters
      ) => TResourceFilters;
    };

type ListLayoutExtendedProps<
  TResource = any,
  TResourceFilters extends ResourceFilters = ResourceFilters
> = PropsWithChildren &
  UseControlledPaginationSWRListResourceReturn<TResource> &
  ListLayoutProps<TResourceFilters> & {
    setPageIndex: (pageIndex: number) => void;
    filters: TResourceFilters;
    setFilters: (
      filters:
        | TResourceFilters
        | ((prevState: TResourceFilters) => TResourceFilters)
    ) => void;
  };

export const ListLayout = <
  TResource,
  TResourceFilters extends ResourceFilters = ResourceFilters
>(
  props: ListLayoutExtendedProps<TResource, TResourceFilters>
) => {
  const defaultPageIndex = 0;
  const {
    actions,
    filterFieds,
    filters,
    setFilters,
    showChildWithoutFilters,
    defaultFilters,
    children,
    setPageIndex,
    transformResourceFilters,
  } = props;

  useEffect(() => {
    setFilters((prevFilters) => {
      const nextFilters = { ...prevFilters, ...defaultFilters };
      const transformedNewFilters =
        transformResourceFilters != null
          ? transformResourceFilters(nextFilters)
          : nextFilters;
      if (!isEqual(prevFilters, transformedNewFilters)) {
        return transformedNewFilters;
      }
      return prevFilters;
    });
  }, [defaultFilters, transformResourceFilters, setFilters]);

  const onSubmit = useCallback(
    (d: TResourceFilters) => {
      let f = {
        ...defaultFilters,
        ...d,
      };
      if (transformResourceFilters) {
        f = transformResourceFilters(f);
      }
      setFilters(f);
      setPageIndex(defaultPageIndex);
    },
    [defaultFilters, transformResourceFilters, setFilters, setPageIndex]
  );
  return (
    <ResourceListContextProvider<TResource> value={props}>
      {actions != null && (
        <>
          <div className="d-flex gap-2">{actions}</div>
          <hr />
        </>
      )}
      {filterFieds && (
        <>
          <Filter fields={filterFieds} onSubmit={onSubmit} />
          <hr />
        </>
      )}
      {(showChildWithoutFilters ||
        filters == null ||
        Object.entries(filters).length > 0) &&
        children}
    </ResourceListContextProvider>
  );
};
