import React, { useContext, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { LoggedInUserContext } from "../../LoggedInBackOffice";
import "./Layout.css";
export interface LayoutProps {
  children: React.ReactNode;
  canChangeCustomer: boolean;
  isCurrentCustomerMandatory: boolean;
}

const Layout = ({
  children,
  canChangeCustomer,
  isCurrentCustomerMandatory,
}: LayoutProps) => {
  const userData = useContext(LoggedInUserContext);
  useEffect(() => {
    userData.setCanChangeCustomer(canChangeCustomer);
  }, [canChangeCustomer, userData]);
  return (
    <>
      {isCurrentCustomerMandatory && userData.currentCustomer == null ? (
        <Alert variant="info">
          Veuillez sélectionner un client pour continuer
        </Alert>
      ) : (
        children
      )}
    </>
  );
};

export default Layout;
