import { Spinner } from "react-bootstrap";
import Category from "../../../../models/Category";
import { ResourceHiddenTextInput } from "../../../../resources/components/inputs/ResourceHiddenTextInput";
import { useSWRReadResource } from "../../../../resources/hooks/useSWRReadResource";
import { ResourceName } from "../../../../resources/types/resourceName";
import { ExtendedAutomaticArticlesSelection } from "../types/ExtendedAutomaticArticlesSelection";
import { FilterContent } from "../types/FilterContent";

type ShowCategoryProps = {
  categoryIndex: number;
  c: FilterContent;
};
export const ShowCategory = (props: ShowCategoryProps) => {
  const { categoryIndex, c } = props;

  const { data: { data: category } = {} } = useSWRReadResource<
    ResourceName,
    Category
  >({
    resourceName: "categories",
    id: c.value,
  });
  return (
    <td>
      <ResourceHiddenTextInput<ExtendedAutomaticArticlesSelection>
        name={`categories.${categoryIndex}.value` as const}
      />
      {!category && <Spinner className="mx-1" animation="border" size="sm" />}
      {category && <>{category.code + " - " + category.label}</>}
    </td>
  );
};
