import { PropsWithChildren } from "react";
import { AsyncHandler } from "../../components/AsyncHandler";
import { Loading } from "../../components/types/loading";
import { ResourceContextProvider } from "../../resources/hooks/useResourceContext";
import { useSWRReadResource } from "../../resources/hooks/useSWRReadResource";
import { Resource } from "../../resources/types";
import { ResourceIdentifier } from "../../resources/types/resourceIdentifier";

export type ReadViewProps<TResourceName extends string> = PropsWithChildren & {
  resourceName: TResourceName;
  id: ResourceIdentifier;
};
export const ReadView = <
  TResourceName extends string = string,
  TResource extends Resource = Resource
>(
  props: ReadViewProps<TResourceName> & {
    loading: Loading;
  }
) => {
  const { id, resourceName, children, loading } = props;
  const { data, error, mutate, isLoading } = useSWRReadResource<
    TResourceName,
    TResource
  >({
    id,
    resourceName,
  });
  const { data: resource } = data ?? {};

  return (
    <ResourceContextProvider value={{ resource, mutate, error, isLoading }}>
      <AsyncHandler
        error={error}
        refresh={mutate}
        isLoading={isLoading}
        loading={loading}
      >
        {resource != null && children}
      </AsyncHandler>
    </ResourceContextProvider>
  );
};
