import { ElasticRequest } from "./models/ElasticRequest";

export async function search(
  hitFrom: number,
  hitsSize: number,
  request: ElasticRequest,
  apiFetcher: (input: string, r: RequestInit) => Promise<any>
) {
  return apiFetcher("/api/elasticsearch/search", {
    method: "POST",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({ ...request, size: hitsSize, from: hitFrom }),
  });
}
