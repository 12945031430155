import { FilterFieldValues } from "../../../components/DynamicFieldData";
import { useControlledQueryPaginationSWRListResource } from "../../../resources/hooks/useControlledQueryPaginationSWRListResource";
import {
  UseListControllerProps,
  useListController,
} from "../../../resources/hooks/useListController";
import { Resource } from "../../../resources/types";
import { PageTitle } from "../PageTitle";
import { ListLayout, ListLayoutProps } from "./ListLayout";

type ListPageProps<
  TResourceName extends string,
  TFilterFieldValues extends FilterFieldValues
> = ListLayoutProps<TFilterFieldValues> &
  UseListControllerProps<TResourceName, TFilterFieldValues>;

export const ListPage = <
  TResourceName extends string = string,
  TResource extends Resource = Resource,
  TFilterFieldValues extends FilterFieldValues = FilterFieldValues
>(
  props: ListPageProps<TResourceName, TFilterFieldValues>
) => {
  const {
    resourceName,
    defaultPageIndex,
    defaultPageSize = 40,
    ...otherListController
  } = useListController(props);
  const { filters } = otherListController;

  const listResources = useControlledQueryPaginationSWRListResource<
    string,
    TResource
  >({
    resourceName,
    filters,
    defaultPageSize,
    defaultPageIndex,
  });

  return (
    <>
      <PageTitle />
      <hr />
      <ListLayout {...props} {...listResources} {...otherListController} />
    </>
  );
};
