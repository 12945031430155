import { CSSProperties, useEffect, useMemo, useState } from "react";
import { isNullOrUndefined } from "../tools/isNullOrUndefined";
import photo_icon from "./assets/photo.png";
import video_icon from "./assets/video.png";

// Fallback style when image does not exist.
export const Thumbnail = ({
  url,
  className,
}: {
  url: string | undefined;
  className?: string;
}) => {
  const [isInError, setIsInError] = useState(false);

  const isVideo = useMemo(() => {
    return url?.endsWith(".mp4") ?? false;
  }, [url]);

  useEffect(() => {
    setIsInError(isNullOrUndefined(url));
  }, [url]);

  const imageStyle: CSSProperties = {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  };
  return (
    <div
      className="d-flex justify-content-center align-items-center h-100"
      style={{
        background: "#ecf0f1",
      }}
    >
      {isInError || isNullOrUndefined(url) ? (
        <img
          src={isVideo ? video_icon : photo_icon}
          alt="thumbnails"
          className={className}
          style={{ ...imageStyle, maxHeight: "7rem", maxWidth: "7rem" }}
        />
      ) : (
        <img
          src={url}
          alt="thumbnails"
          style={imageStyle}
          className={className}
          onError={() => setIsInError(true)}
        />
      )}
    </div>
  );
};
