import { useCallback, useState } from "react";
import useCustomerApiFetch from "../useCustomerApiFetch";

type UseGetTicketOrderProps = {
  onSuccessCallback: (blob: Blob) => void;
};
const useGetTicketOrder = function useChangeOrderStatus({
  onSuccessCallback,
}: UseGetTicketOrderProps) {
  const [isBusy, setIsBusy] = useState(false);
  const [errors, setErrors] = useState<unknown>();
  const apiFetcher = useCustomerApiFetch();

  const getTicket = useCallback(
    async (orderId?: number) => {
      setIsBusy(true);
      setErrors(undefined);

      try {
        const response = await apiFetcher(`/api/Orders/${orderId}/ticket`, {
          method: "GET",
          // headers: new Headers({
          //   Accept: "application/json",
          //   "Content-Type": "application/json",
          // }),
        });

        if (!response.ok) {
          throw new Error("Unable to download ticket");
        }

        setIsBusy(false);
        onSuccessCallback(await response.blob());
      } catch (ex) {
        setErrors(ex);
        setIsBusy(false);
      }
    },
    [apiFetcher, onSuccessCallback]
  );

  return { getTicket, errors, isBusy };
};

export default useGetTicketOrder;
