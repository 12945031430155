import { useCurrentCustomer } from "../../../LoggedInBackOffice";
import { AsyncHandler } from "../../../components/AsyncHandler";
import { Loading } from "../../../components/types/loading";
import useAnalyticsExportScheduledJobs from "../../../hooks/analyticsExportScheduledJob/useAnalyticsExportScheduledJobs";
import {
  SupportedParamsTypes,
  useQueryAsState,
} from "../../../hooks/useQueryAsState";
import { PageTitle } from "../../../resources/components/PageTitle";
import { CreateResourceButton } from "../../../resources/components/buttons/CreateResourceButton";
import AnalyticsExportScheduledJobsList from "../shared/AnalyticsExportScheduledJobsList";
import { Filters, FiltersForm } from "../shared/FiltersForm";

const FilterTypes: Record<string, SupportedParamsTypes> = {
  periodicity: "number",
  email: "string",
  analyticsTable: "number",
};

const AnalyticsExportScheduledJobsPage = () => {
  const [params, updateQueryParams] = useQueryAsState<Filters>(
    FilterTypes,
    {
      periodicity: undefined,
      email: undefined,
      analyticsTable: undefined,
    },
    true
  );

  const currentCustomer = useCurrentCustomer();

  const {
    analyticsExportScheduledJobs,
    error,
    mutate: refresh,
    isLoading,
  } = useAnalyticsExportScheduledJobs(
    currentCustomer,
    params.analyticsTable,
    params.email,
    params.periodicity
  );

  return (
    <>
      <PageTitle label="Planifications d'export de statistiques" />
      <hr />

      <AsyncHandler
        error={error}
        errorMessage="Une erreur est survenue lors de la récupération de la liste des planifications. "
        refresh={refresh}
        isLoading={isLoading}
        loading={Loading.Circle}
      >
        <CreateResourceButton to="/analyticsExportScheduledJobs/create" />
        <hr />
        <FiltersForm
          initialFilters={params}
          canReset={params !== undefined}
          onChange={(d) => {
            updateQueryParams(d);
          }}
        />
        <br />
        <AnalyticsExportScheduledJobsList
          analyticsExportScheduledJobs={analyticsExportScheduledJobs!}
        />
      </AsyncHandler>
    </>
  );
};

export default AnalyticsExportScheduledJobsPage;
