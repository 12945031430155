import React from "react";
import { Form, FormGroup, FormSelectProps } from "react-bootstrap";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/esm/helpers";
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import Select from "react-select";
import { InputProps } from "./Input";
import ReactSelectOption from "./ReactSelectOption";

export interface InputSelectProps extends FormSelectProps {
  label?: string;
  name: string;
  isInvalid?: boolean;
  errorMessage?: string;
  infoMessage?: string;
  options: { v: number; l: string }[];
}

/**
 * Input with Label & error message. This use the native html select so all value are returned as string.
 */
export const InputSelect = React.forwardRef<
  BsPrefixRefForwardingComponent<"select", FormSelectProps>,
  InputSelectProps
>(
  (
    {
      options,
      className,
      label,
      errorMessage,
      infoMessage,
      isInvalid,
      name,
      ...inputProps
    },
    ref
  ) => {
    const inputId = `form-input-${name}`;

    return (
      <FormGroup className={className != null ? className : "mb-3"}>
        {label && <Form.Label htmlFor={inputId}>{label}</Form.Label>}
        <Form.Select
          id={inputId}
          name={name}
          className={`form-control ${isInvalid ? "is-invalid" : ""}`}
          ref={ref as any}
          {...inputProps}
        >
          <option value="" disabled hidden>
            --
          </option>
          {options.map((v, i) => (
            <option key={v.v} value={v.v} label={v.l} />
          ))}
        </Form.Select>
        {isInvalid && errorMessage && (
          <Form.Control.Feedback type="invalid">
            {errorMessage}
          </Form.Control.Feedback>
        )}
        {infoMessage && (
          <small className="form-text text-muted">{infoMessage}</small>
        )}
      </FormGroup>
    );
  }
);

export type InputReactSelectProps<
  T extends FieldValues,
  TName extends FieldPath<T>
> = InputProps &
  UseControllerProps<T, TName> & {
    options: ReactSelectOption<number>[];
  };

/**
 * Input react select use the "react-select" lib. All values are returned as number, for the "react-hook-form" lib.
 */
export const InputNumberSelect = function <
  T extends FieldValues,
  TName extends FieldPath<T>
>({
  control,
  rules,
  name,
  className,
  label,
  infoMessage,
  options,
  disabled,
  defaultValue,
  placeholder,
}: InputReactSelectProps<T, TName>) {
  const inputId = `form-input-${name}`;

  return (
    <FormGroup className={className}>
      {label && <Form.Label htmlFor={inputId}>{label}</Form.Label>}
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error, invalid } }) => (
          <>
            <Select<ReactSelectOption<number>>
              {...field}
              options={options}
              className={`${invalid ? "is-invalid" : ""}`}
              isDisabled={disabled}
              onChange={(o) => {
                const v = o as ReactSelectOption<number>;
                field.onChange(v.value);
              }}
              value={options.find((o) =>
                field.value !== undefined
                  ? o.value === field.value
                  : o.value === defaultValue
              )}
              isSearchable={false}
              placeholder={placeholder}
              getOptionValue={(o) => o.value?.toString() ?? ""}
            />
            {invalid && error?.message && (
              <Form.Control.Feedback type="invalid">
                {error.message}
              </Form.Control.Feedback>
            )}
          </>
        )}
      />

      {infoMessage && (
        <small className="form-text text-muted">{infoMessage}</small>
      )}
    </FormGroup>
  );
};
