import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ResourceTextAreaInput } from "../../../../resources/components/inputs/ResourceTextAreaInput";
import { ExtendedManualArticlesSelection } from "../types/ExtendedManualArticlesSelection";

export const ManualArticlesSelectionFrom = () => {
  const { getValues, setValue, watch } =
    useFormContext<ExtendedManualArticlesSelection>();
  const articleReferences = getValues("articleReferences");
  useEffect(() => {
    if (articleReferences != null) {
      setValue("referencesText", articleReferences.join("\n"));
    }
  }, [articleReferences, setValue]);
  const referencesText = watch("referencesText");
  return (
    <>
      <ResourceTextAreaInput<ExtendedManualArticlesSelection>
        name="referencesText"
        label="Saisissez une référence article par ligne*"
        type="textarea"
        options={{
          required: "Veuillez saisir au moins une référence",
          min: "sdfgsdfg",
        }}
        onBlur={() =>
          setValue("articleReferences", referencesText?.split("\n"))
        }
        rows={20}
      />
    </>
  );
};
