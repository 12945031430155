import React from "react";
import { Alert, Button } from "react-bootstrap";
import { CircleLoading } from "./CircleLoading";
import { LinearLoading } from "./LinearLoading";
import ServerAdminTemplate from "./layout/ServerAdminTemplate";
import { Loading } from "./types/loading";

type AsyncHandlerProps = React.PropsWithChildren & {
  error?: any;
  errorMessage?: string;
  loadingMessage?: string;
  refresh?: () => void;
  isLoading?: boolean;
  loading: Loading;
};

/** Show an error with retry button if an error occured & Show a loader while waiting for result */
export const AsyncHandler = ({
  error,
  errorMessage,
  refresh,
  children,
  isLoading = false,
  loadingMessage,
  loading,
}: AsyncHandlerProps) => {
  if (error) {
    const msg = errorMessage ?? "Une erreur est survenue";
    return (
      <Alert variant="danger">
        <ServerAdminTemplate>
          <pre>
            <code>{JSON.stringify(error, null, 2)}</code>
          </pre>
        </ServerAdminTemplate>
        {msg}
        <Button variant="light" className="ms-3" onClick={refresh}>
          Réessayer
        </Button>
      </Alert>
    );
  }
  if (isLoading) {
    switch (loading) {
      case Loading.Circle:
        return <CircleLoading loadingMessage={loadingMessage} />;
      case Loading.Linear:
        return <LinearLoading loadingMessage={loadingMessage} />;
    }
  }
  return <>{children}</>;
};
