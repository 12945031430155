import React, {
  Component,
  ErrorInfo,
  PropsWithChildren,
  ReactNode,
} from "react";

type ErrorBoundaryProps = PropsWithChildren & {
  fallback?: ReactNode;
  onError?: (error: Error, errorInfo: ErrorInfo) => void;
};

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Gérez l'erreur ici (par exemple, en la journalisant)
    console.error("Erreur capturée :", error, errorInfo);
    this.setState({ hasError: true });
    if (this.props.onError) {
      this.props.onError(error, errorInfo); // Appeler la fonction de gestion d'erreurs du parent
    }
  }

  render() {
    if (this.state.hasError) {
      // Vous pouvez spécifier ici le composant de secours à afficher
      return this.props.fallback || <div>Une erreur est survenue.</div>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
