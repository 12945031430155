const getFriendlyJobName = function getFriendlyJobName(fullyQualifiedJobClassName?: string): string {
  if (fullyQualifiedJobClassName) {
    const split = fullyQualifiedJobClassName.split(",");
    if (split.length > 0) {
      let subSplit = split[0]?.split(".");
      if (subSplit.length > 0) {
        return (subSplit[subSplit.length - 1] as unknown) as string;
      }
    }
  }
  return fullyQualifiedJobClassName ?? "N/A";
};
export default getFriendlyJobName;
