import { PropsWithChildren, useCallback, useState } from "react";
import Form from "react-bootstrap/esm/Form";
import {
  DefaultValues,
  FormProvider,
  UseFormProps,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { useNotificationsContext } from "../../hooks/useNotificationsContext";
import { ApiError } from "../../models/ApiError";
import { useResourceContext } from "../hooks/useResourceContext";
import { ResourceFormContextProvider } from "../hooks/useResourceFormContext";
import { DeleteResourceButton } from "./buttons/DeleteResourceButton";
import { SaveResourceButton } from "./buttons/SaveResourceButton";

type AsyncDefaultValues<TFieldValues> = (
  payload?: unknown
) => Promise<TFieldValues>;

export type PublicResourceFormProps<
  TFieldValues extends FieldValues,
  TContext
> = Omit<UseFormProps<TFieldValues, TContext>, "values" | "defaultValues"> & {
  transformBeforeSubmit?: (
    formResource: TFieldValues,
    form: UseFormReturn<TFieldValues, TContext>
  ) => TFieldValues | Promise<TFieldValues | undefined>;

  transformAfterSubmit?: (
    updatedResource: TFieldValues,
    form: UseFormReturn<TFieldValues, TContext>
  ) => TFieldValues | Promise<TFieldValues | undefined>;

  defaultResource?:
    | DefaultValues<TFieldValues>
    | AsyncDefaultValues<TFieldValues>;
};
type ResourceFormProps<
  TFieldValues extends FieldValues,
  TContext
> = PropsWithChildren &
  PublicResourceFormProps<TFieldValues, TContext> & {
    id?: string;
    className?: string;

    submitError?: ApiError;
    resource?: TFieldValues;
    onSubmit: (
      formResource: TFieldValues | undefined
    ) => TFieldValues | Promise<TFieldValues | undefined>;
    canDelete?: boolean;
    canSave?: boolean;
  };
export const ResourceForm = <TFieldValues extends FieldValues, TContext = any>(
  props: ResourceFormProps<TFieldValues, TContext>
) => {
  const {
    children,
    submitError,
    onSubmit,
    resource: resourceProps,
    className = "d-flex flex-column gap-2",
    id,
    canDelete = true,
    canSave = true,
    defaultResource,
    transformAfterSubmit,
    transformBeforeSubmit,
    ...otherProps
  } = props;
  const { resource } = useResourceContext<TFieldValues>({
    resource: resourceProps,
  });

  const form = useForm<TFieldValues, TContext>({
    ...otherProps,
    values: resource,
    defaultValues: defaultResource,
  });
  const {
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = form;

  const [externalBusy, setExternalBusy] = useState<boolean>(false);

  const finalSubmit = useCallback(
    async (t: TFieldValues) => {
      const formResource = getValues();

      const readyResource =
        transformBeforeSubmit != null
          ? await Promise.resolve(transformBeforeSubmit(formResource, form))
          : formResource;

      const updatedResource = await Promise.resolve(onSubmit(readyResource));

      if (transformAfterSubmit != null && updatedResource != null) {
        await Promise.resolve(transformAfterSubmit(updatedResource, form));
      }
    },
    [form, getValues, onSubmit, transformAfterSubmit, transformBeforeSubmit]
  );
  const { addNotification } = useNotificationsContext();
  const finalSubmitWithNotificationOnError = useCallback(
    async (t: TFieldValues) => {
      try {
        await finalSubmit(t);
      } catch (error) {
        addNotification({
          title: "Un erreur est survenue",
          message: "Veuillez réessayer",
        });
      }
    },
    [addNotification, finalSubmit]
  );
  return (
    <ResourceFormContextProvider value={{ setExternalBusy }}>
      <FormProvider {...form}>
        <Form
          onSubmit={handleSubmit(finalSubmitWithNotificationOnError)}
          id={id}
          className={className}
        >
          {children}
          {canSave && (
            <SaveResourceButton
              mode={resource != null ? "edit" : "create"}
              isBusy={isSubmitting || externalBusy}
              // submitError={submitError}
            />
          )}

          {resource != null && canDelete && (
            <>
              <hr />
              <DeleteResourceButton />
            </>
          )}
        </Form>
      </FormProvider>
    </ResourceFormContextProvider>
  );
};
