import DeliveryModeDeliveryZone from "./DeliveryModeDeliveryZone";
import Transporter from "./Transporter";

export default interface DeliveryMode {
  id: number;
  label: string;
  code: string;
  price: number;
  dayBeforeDelivery?: number;
  cartMinPrice?: number;
  cartMaxPrice?: number;
  cartMinWeight?: number;
  cartMaxWeight?: number;
  deliveryModeType: DeliveryModeType;
  deliverableDays: DeliverableDays;
  orderMaxHour?: number;
  transporterId?: number;
  transporter?: Transporter;
  deliveryModesZones?: DeliveryModeDeliveryZone[];
}

export enum DeliveryModeType {
  Store = 0,
  HomeDelivery = 1,
}
export enum DeliverableDays {
  EveryDay = 0,
  Weekdays = 1, // jours ouvrés (du lundi au vendredi, excepté les jours fériés)
  BusinessDays = 2, // jours ouvrables (du lundi au samedi, excepté les jours fériés)
}
