import { useCallback, useMemo } from "react";
import { Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { MdAdd } from "react-icons/md";
import { CategoriesSelect } from "../../../../components/selects/CategoriesSelect";
import Category from "../../../../models/Category";
import { useReadResourceFromSelect } from "../../../../resources/components/selects/hooks/useReadResourceFromSelect";
import { ResourceName } from "../../../../resources/types/resourceName";
import { ExtendedAutomaticArticlesSelection } from "../types/ExtendedAutomaticArticlesSelection";
import { FilterContent } from "../types/FilterContent";
import { AddRowProps } from "./AddRowProps";

export function AddCategoryRow({ append }: AddRowProps<FilterContent>) {
  const { watch } = useFormContext<ExtendedAutomaticArticlesSelection>();
  const {
    resource: category,
    onResourceIdChanged: onCategoryIdChanged,
    setResourceId: setCategoryId,
    resourceId: categoryId,
  } = useReadResourceFromSelect<Category, ResourceName, number>({
    resourceName: "categories",
  });

  const appendCategory = useCallback(() => {
    if (category) {
      append({ value: category.id?.toString() /*, label: category.label*/ });
      setCategoryId(undefined);
    }
  }, [append, category, setCategoryId]);

  const categories = watch("categories");
  const excludedIds = useMemo(
    () => categories?.map((c) => c.value),
    [categories]
  );
  return (
    <tr>
      <td>
        <CategoriesSelect
          idPropsValue={categoryId}
          onIdPropsValueChanged={onCategoryIdChanged}
          excludedIds={excludedIds}
        />
      </td>
      <td className="min">
        {categoryId && (
          <Button
            type="button"
            variant="outline-primary"
            onClick={appendCategory}
          >
            <MdAdd />
          </Button>
        )}
      </td>
    </tr>
  );
}
