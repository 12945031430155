import { EditPage } from "../../resources/components/EditPage";
import EditBroadcastListForm from "./EditBroadcastListForm";

const EditBroadcastListPage = () => {
  return (
    <EditPage>
      <EditBroadcastListForm />
    </EditPage>
  );
};

export default EditBroadcastListPage;
