import React, { useEffect } from "react";
import Alert from "react-bootstrap/esm/Alert";
import Form from "react-bootstrap/esm/Form";
import FormGroup from "react-bootstrap/esm/FormGroup";
import { useFormContext } from "react-hook-form";
import DigitalSignageCampaign from "../../../models/DigitalSignageCampaign";
import EditOrCreateCampaign from "./EditOrCreateCampaign";
import MediaWorkflow from "./MediaWorkflow";

const EditOrCreateDigitalSignageCampaign = () => {
  const formMethods = useFormContext<DigitalSignageCampaign>();
  const {
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = formMethods;
  const { campaignAnimationMedias } = watch();

  let isFirstRender = React.useRef(true);
  const fieldsLength = campaignAnimationMedias?.length;

  useEffect(() => {
    if (!isFirstRender.current && !fieldsLength) {
      setError("campaignAnimationMedias", {
        message: "Veuillez sélectionner au moins une publicité",
        type: "min",
      });
    }
    if (fieldsLength > 0) {
      clearErrors("campaignAnimationMedias"); // always clear errors when there is add action.
    }
  }, [clearErrors, fieldsLength, setError]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, []);

  return (
    <>
      <EditOrCreateCampaign />

      <FormGroup>
        <Form.Label>Contenu de la campagne*</Form.Label>
        <MediaWorkflow />
        {(errors.campaignAnimationMedias as any)?.message && (
          <Alert variant="danger">
            {(errors.campaignAnimationMedias as any).message}
          </Alert>
        )}
      </FormGroup>
    </>
  );
};
export default EditOrCreateDigitalSignageCampaign;
