export enum AnalyticsTable {
  AddedArticles = 1,
  OrderedArticles = 2,
  BrowsedArticles = 3,
  Orders = 4,
  AbondonedOrders = 5,
  SharedCarts = 6,
  Searches = 7,
  BrowsedCategories = 8,
  SharedProducts = 9,
  SalesAssistantCalls = 10,
  NotifiedArticles = 11,
  NotifiedSearches = 12,
  NotifiedCategories = 13,
  NotifiedSelections = 14,
  StartedUserSessions = 15,
}
