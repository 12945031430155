import { ProviderProps, createContext } from "react";

export const ResourceOptionsContext =
  createContext<ResourceOptionsContextValue>({
    resourceName: "",
  });

export type ResourceOptionsContextValue<TResourceName extends string = string> =
  {
    resourceName: TResourceName;
    label?: React.ReactNode;
  };

export const ResourceOptionsContextProvider = ({
  children,
  value,
}: ProviderProps<ResourceOptionsContextValue>) => (
  <>
    {value ? (
      <ResourceOptionsContext.Provider value={value}>
        {children}
      </ResourceOptionsContext.Provider>
    ) : (
      children
    )}
  </>
);
