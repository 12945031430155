import Customer from "../../models/Customer";
import { FtpParameters } from "../../models/FtpParameters";
import useFetchForCustomer from "../swr/useSWRFetchForCustomer";

type UseFtpParametersProps = {
  customer?: Customer;
};
export const useFtpParameters = ({ customer }: UseFtpParametersProps) => {
  const res = useFetchForCustomer("/api/ftpParameters", customer);
  let ftpParameters = res.data as FtpParameters;
  return { ...res, ftpParameters };
};
