import { SynchronizationStatus } from "../models/Synchronization";

const getSynchronizationStatusLabel = (
  s: SynchronizationStatus | undefined
) => {
  switch (s) {
    case SynchronizationStatus.InProgress:
      return <span className="text-secondary">En cours...</span>;
    case SynchronizationStatus.Failure:
      return <span className="text-danger">Erreur</span>;
    case SynchronizationStatus.Success:
      return <span className="text-success">Terminée</span>;
    case SynchronizationStatus.Cancel:
      return <span className="text-warning">Annulée</span>;
    default:
      return <>N/A</>;
  }
};

export default getSynchronizationStatusLabel;
