import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import AnimationGroup from "../../models/AnimationGroup";
import { AnimationGroupsFilters } from "../../models/filters/AnimationGroupsFilters";
import { CreateResourceButton } from "../../resources/components/buttons/CreateResourceButton";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { useAddEditButton } from "../../resources/hooks/useAddEditButton";
import { ResourceName } from "../../resources/types/resourceName";

const AnimationGroupsList = () => {
  const columns: ResourceColumn<AnimationGroup>[] = useMemo(
    () => [
      {
        header: "Nom",
        accessor: "label",
      },
    ],
    []
  );

  const { columnsWithButton } = useAddEditButton({
    columns,
  });

  const filters: FilterFieldData<AnimationGroupsFilters>[] = useMemo(
    () => [
      {
        label: "Nom",
        fieldName: "label",
        type: "text",
        placeholder: "Nom",
      },
    ],
    []
  );

  return (
    <ListPage<ResourceName, AnimationGroup, AnimationGroupsFilters>
      filterFieds={filters}
      actions={<CreateResourceButton />}
      showChildWithoutFilters
    >
      <ResourceTable columns={columnsWithButton} />
    </ListPage>
  );
};

export default AnimationGroupsList;
