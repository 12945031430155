import { useMemo } from "react";
import { createSynchronizationFromFetchedData } from "../../models/createSynchronizationFromFetchedData";
import Customer from "../../models/Customer";
import Nullable from "../../Nullable";
import useFetchForCustomer from "../swr/useSWRFetchForCustomer";

const useSynchronization = function (
  customer: Nullable<Customer>,
  synchronizationJobId?: string
) {
  const { data, ...others } = useFetchForCustomer(
    "/api/Synchronizations/" + synchronizationJobId,
    customer
  );

  const synchronization = useMemo(
    () => (data ? createSynchronizationFromFetchedData(data) : undefined),
    [data]
  );

  return { synchronization, data, ...others };
};
export default useSynchronization;
