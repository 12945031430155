import { useMemo } from "react";
import { FieldArrayWithId } from "react-hook-form";
import AdMedia from "../../../models/AdMedia";
import AnimationMedia from "../../../models/AnimationMedia";
import AnimationMediasCampaign from "../../../models/AnimationMediasCampaign";
import Workflow, { WorkflowProps } from "./Workflow";
import { WorkflowItem } from "./WorkflowListItem";

type SortWorkflowProps<T extends AnimationMedia> = Omit<
  WorkflowProps,
  "Items"
> & {
  items: AdMedia[];
  fields: FieldArrayWithId<
    AnimationMediasCampaign<T>,
    "campaignAnimationMedias"
  >[];
};
const SortWorkflow = <T extends AnimationMedia>(
  props: SortWorkflowProps<T>
) => {
  const { fields, items, ...restProps } = props;
  const sortedItems = useMemo(() => {
    return (
      fields != null &&
      items != null &&
      fields
        .map((f) => ({
          index: f.index,
          animationMediaId: f.animationMediaId,
        }))
        .toSorted((a, b) => a.index! - b.index!)
        .map((f) => {
          const a = items.find((i) => i.id === f.animationMediaId);
          if (a) {
            return { ...a, index: f.index! } as WorkflowItem;
          }
          return null;
        })
        .filter((i): i is WorkflowItem => i != null)
    );
  }, [fields, items]);
  if (!sortedItems) return null;
  return <Workflow Items={sortedItems} {...restProps} />;
};

export default SortWorkflow;
