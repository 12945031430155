import { useMemo } from "react";
import { StockInfo } from "../../../models/StockInfo";
import { StockInfosFilters } from "../../../models/filters/StockInfosFilters";
import { ListView } from "../../../resources/components/lists/ListView";
import {
  ResourceColumn,
  ResourceTable,
} from "../../../resources/components/lists/ResourceTable";
import { useResourceUrlPathResolver } from "../../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../../resources/types/resourceName";

type ArticleStocksProps = {
  articleId?: number;
};
export const ArticleStockInfos = ({ articleId }: ArticleStocksProps) => {
  const {
    resourceUrlPathResolver: { display },
  } = useResourceUrlPathResolver<ResourceName>();

  const columns: ResourceColumn<StockInfo>[] = useMemo(
    () => [
      {
        accessor: "availabilityType",
        header: "Type disponibilité",
      },
      {
        accessor: "availableQuantity",
        header: "Quantité disponible",
      },
      {
        accessor: "reservedQuantity",
        header: "Quantité réservée",
      },
      {
        header: "Dépôt stock (Code)",
        accessor: (a) => a.stockLocationId,
        expand: "stock-locations",
        getCell: (d, v, expandedList) => {
          const s = expandedList?.data.items.find(
            (a) => a.id === d.stockLocationId
          );
          return s?.code;
        },
      },
      {
        header: "Dépôt stock (Nom)",
        accessor: (a) => a.stockLocationId,
        expand: "stock-locations",
        getCell: (d, v, expandedList) => {
          const s = expandedList?.data.items.find(
            (a) => a.id === d.stockLocationId
          );
          return s && `${s.name} (${s.code})`;
        },
        navigationPath: (o) => display("stock-locations", o.stockLocationId),
      },
    ],
    [display]
  );
  return (
    <ListView<ResourceName, StockInfo, StockInfosFilters>
      defaultFilters={{ articleId }}
      resourceName="stock-infos"
    >
      <ResourceTable<StockInfo> columns={columns} />
    </ListView>
  );
};
