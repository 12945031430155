import React, { useCallback, useEffect, useMemo, useState } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { Image as KonvaImage, Layer, Stage } from "react-konva";
import useElementWidthRef from "../../../../hooks/useElementWidthRef";
import usePrevious from "../../../../hooks/usePrevious";
import { InteractiveArea, InteractivePage } from "../../../../models/InteractiveCatalog";
import deepCopy from "../../../../tools/deepCopy";
import ResizableInteractiveArea, { Area } from "./ResizableInteractiveArea";

interface InteractivePageCanvasProps {
  imageUrl?: string;
  defaultValues: InteractiveArea[];
  setValue: UseFormSetValue<InteractivePage>;
  register: UseFormRegister<InteractivePage>;
  onNewImage: (width: number, height: number) => void;
  onSelectArea: (areaId: number | string) => void;
  selectedAreaId?: number | string;
  invalidsAreasIndexes: number[];
}

const InteractivePageCanvas = ({
  imageUrl,
  selectedAreaId,
  defaultValues,
  invalidsAreasIndexes,
  onNewImage,
  onSelectArea,
  setValue,
  register,
}: InteractivePageCanvasProps) => {
  // canvas state
  const [canvasImage, setCanvasImage] = useState<HTMLImageElement>();
  const [canvasScale, setCanvasScale] = useState<number>();
  const [canvasMaxWidth, setCanvasMaxWidth] = useState<number>();

  const [editedAreas, setEditedArea] = useState<InteractiveArea[]>([]);
  const interactiveArea = useMemo(
    () =>
      defaultValues.map((v, i) => {
        return editedAreas.find((a) => a.id === v.id) ?? v;
      }),
    [defaultValues, editedAreas]
  );

  //Register form input and set value.
  useEffect(() => {
    interactiveArea.forEach((v, i) => {
      // Auto register canvas field. (normalement le fait de faire un register à chaque rendu ne pose pas de problème...)
      register(`interactiveAreas.${i}.originX` as "interactiveAreas.0.originX", { valueAsNumber: true });
      register(`interactiveAreas.${i}.originY` as "interactiveAreas.0.originY", { valueAsNumber: true });
      register(`interactiveAreas.${i}.width` as "interactiveAreas.0.width", { valueAsNumber: true });
      register(`interactiveAreas.${i}.height` as "interactiveAreas.0.height", { valueAsNumber: true });
      setValue(`interactiveAreas.${i}.originX` as "interactiveAreas.0.originX", v.originX);
      setValue(`interactiveAreas.${i}.originY` as "interactiveAreas.0.originY", v.originY);
      setValue(`interactiveAreas.${i}.width` as "interactiveAreas.0.width", v.width);
      setValue(`interactiveAreas.${i}.height` as "interactiveAreas.0.height", v.height);
    });
  }, [interactiveArea, register, setValue]);

  const onResizableAreaChange = useCallback(
    (id: string | number, area: Area) => {
      const defaultArea = defaultValues.find((dv) => dv.id === id);
      if (!defaultArea) {
        return;
      }

      const editedArea = deepCopy(defaultArea);
      editedArea.originX = Math.floor(area.x);
      editedArea.originY = Math.floor(area.y);
      editedArea.width = Math.floor(area.width);
      editedArea.height = Math.floor(area.height);

      const i = editedAreas.findIndex((a) => a.id === id);
      if (i < 0) {
        setEditedArea([...editedAreas, editedArea]);
      } else {
        editedAreas[i] = editedArea;
        setEditedArea([...editedAreas]);
      }
    },
    [defaultValues, editedAreas]
  );

  // Fire 'onNewImage' as sone as canvasImage is set.
  const previousCanvasImage = usePrevious(canvasImage);
  useEffect(() => {
    if (canvasImage && canvasImage !== previousCanvasImage) {
      onNewImage(canvasImage?.width, canvasImage?.height);
    }
  }, [canvasImage, previousCanvasImage, onNewImage]);

  // Load canvasImage
  useEffect(() => {
    let isImageLoadingCanceled = false;
    if (imageUrl) {
      const image = document.createElement("img");
      image.onload = () => {
        if (isImageLoadingCanceled) {
          return;
        }
        setCanvasImage(image);
      };
      image.src = imageUrl;
    }

    return () => {
      isImageLoadingCanceled = true;
    };
  }, [imageUrl]);

  // Mesure the canvas placeholder...
  const mesuredCanvasPlaceholderRef = useElementWidthRef((width: number) => {
    setCanvasMaxWidth(width - 50);
  });

  // update scale when the image or the size of placeholder change...
  useEffect(() => {
    if (canvasMaxWidth && canvasImage && canvasImage.width) {
      const scale = canvasMaxWidth / canvasImage.width;
      setCanvasScale(scale < 1 ? scale : 1);
    }
  }, [canvasMaxWidth, canvasImage]);

  return (
    <>
      <div ref={mesuredCanvasPlaceholderRef}>
        {canvasImage && canvasScale && (
          <Stage
            width={canvasImage.width * canvasScale}
            height={canvasImage.height * canvasScale}
            scaleX={canvasScale}
            scaleY={canvasScale}
          >
            <Layer>
              <KonvaImage image={canvasImage} />
            </Layer>
            <Layer>
              {interactiveArea &&
                interactiveArea.map((v, i) => {
                  return (
                    <ResizableInteractiveArea
                      key={i}
                      interactiveArea={v}
                      bound={{ x: 0, y: 0, height: canvasImage.height, width: canvasImage.width }}
                      scale={canvasScale}
                      isSelected={v.id === selectedAreaId}
                      isInvalid={invalidsAreasIndexes.find((val) => val === i) !== undefined ? true : false}
                      onSelect={() => onSelectArea(v.id)}
                      onChange={(area) => onResizableAreaChange(v.id, area)}
                    />
                  );
                })}
            </Layer>
          </Stage>
        )}
      </div>
    </>
  );
};

export default InteractivePageCanvas;
