import React from "react";
import { Control, Controller } from "react-hook-form";
import Input from "./Input";

interface SearchInputProps {
  control: Control;
  isInvalid: boolean;
  disabled?: boolean;
  name: string;
}

const SearchInput = ({
  control,
  isInvalid,
  disabled,
  name,
}: SearchInputProps) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      rules={{
        required: { message: "Veuillez saisir les mots-clés", value: true },
        maxLength: {
          message: "Les mots-clés ne peuvent pas dépasser 50 caractères",
          value: 50,
        },
      }}
      render={({ field, fieldState }) => (
        <Input
          errorMessage={fieldState.error?.message}
          isInvalid={isInvalid}
          disabled={disabled}
          label="Mots-clés"
          {...field}
        />
      )}
    />
  );
};
export default SearchInput;
