import Form from "react-bootstrap/esm/Form";
import FormGroup from "react-bootstrap/esm/FormGroup";
import { Controller, useForm } from "react-hook-form";
import { useCurrentCustomer } from "../../LoggedInBackOffice";
import { AsyncHandler } from "../../components/AsyncHandler";
import HtmlEditor from "../../components/HtmlEditor";
import { Loading } from "../../components/types/loading";
import useOrderReceiptConfiguration from "../../hooks/orderReceiptConfiguration/useOrderReceiptConfiguration";
import { useEditOrCreateSubmitForm } from "../../hooks/useSubmitForm";
import OrderReceiptConfiguration from "../../models/OrderReceiptConfiguration";
import { PageTitle } from "../../resources/components/PageTitle";
import { SaveResourceButton } from "../../resources/components/buttons/SaveResourceButton";

const OrderReceiptConfigurationPage = () => {
  const customer = useCurrentCustomer();

  const {
    orderReceiptConfiguration,
    error,
    isLoading,
    mutate: refresh,
    mutate,
  } = useOrderReceiptConfiguration(customer);

  const { handleSubmit, control } = useForm<OrderReceiptConfiguration>({
    mode: "onBlur",
    values: orderReceiptConfiguration,
  });

  const {
    isBusy,
    onSubmit,
    errors: submitError,
  } = useEditOrCreateSubmitForm(
    "/api/orderreceiptconfiguration",
    undefined,
    mutate
  );

  return (
    <>
      <PageTitle label="Ticket de commande" />
      <hr />
      <AsyncHandler
        error={error}
        errorMessage="Une erreur est survenue lors de la récupération des paramètres."
        refresh={refresh}
        isLoading={isLoading}
        loading={Loading.Circle}
      >
        <Form
          className="d-flex flex-column gap-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="row">
            <div className="col-xl">
              <FormGroup>
                <Form.Label>En-tête</Form.Label>
                <Controller
                  control={control}
                  name="headerHtml"
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <HtmlEditor
                        wrapperClassName=""
                        editorClassName="form-control"
                        html={value}
                        onBlur={onBlur}
                        onHtmlChanged={(html) => onChange(html)}
                        toolbar={{
                          image: {
                            className: "centerImage",
                            component: undefined,
                            popupClassName: undefined,
                            urlEnabled: false,
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            uploadCallback: undefined,
                            previewImage: true,
                            inputAccept: "image/jpeg,image/jpg,image/png",
                            alt: { present: false, mandatory: false },
                            defaultSize: {
                              height: "auto",
                              width: "35mm",
                            },
                          },
                          options: [
                            "inline",
                            "blockType",
                            //   "fontSize",
                            //   "fontFamily",
                            "list",
                            "textAlign",
                            "colorPicker",
                            //   "link",
                            // 'embedded',
                            // 'emoji',
                            "image",
                            "remove",
                            "history",
                          ],
                        }}
                      />
                    );
                  }}
                />
              </FormGroup>
            </div>
            <div className="col-xl">
              <FormGroup>
                <Form.Label>Pied de page</Form.Label>
                <Controller
                  control={control}
                  name="footerHtml"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <HtmlEditor
                      wrapperClassName=""
                      editorClassName="form-control"
                      html={value}
                      onBlur={onBlur}
                      onHtmlChanged={(html) => onChange(html)}
                      toolbar={{
                        image: {
                          className: "centerImage",
                          component: undefined,
                          popupClassName: undefined,
                          urlEnabled: false,
                          uploadEnabled: true,
                          alignmentEnabled: true,
                          uploadCallback: undefined,
                          previewImage: true,
                          inputAccept: "image/jpeg,image/jpg,image/png",
                          alt: { present: false, mandatory: false },
                          defaultSize: {
                            height: "auto",
                            width: "35mm",
                          },
                        },
                        options: [
                          "inline",
                          "blockType",
                          //   "fontSize",
                          //   "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          //   "link",
                          // 'embedded',
                          // 'emoji',
                          "image",
                          "remove",
                          "history",
                        ],
                      }}
                    />
                  )}
                />
              </FormGroup>
            </div>
          </div>
          <SaveResourceButton
            isBusy={isBusy}
            submitError={submitError}
            mode={"edit"}
          />
        </Form>
      </AsyncHandler>
    </>
  );
};

export default OrderReceiptConfigurationPage;
