import { useMemo } from "react";
import { DisplayNull } from "../../../components/DisplayNull";
import { Article } from "../../../models/Article";
import { ResourceColumn } from "../../../resources/components/lists/ResourceTable";
import { getISO8601Date } from "../../../tools/dateUtils";

export const useArticlesColumns = () => {
  const columns: ResourceColumn<Article>[] = useMemo(
    () => [
      {
        header: "Id",
        accessor: "id",
      },
      {
        header: "Référence",
        accessor: "reference",
        navigationPath: (a) => `/articles/${a?.id}`,
      },
      {
        header: "Nom",
        accessor: "name",
      },
      {
        header: "Date dernier changement",
        accessor: "lastChange",
        // getCell: (a) => getISO8601Date(a?.lastChange),
      },
      {
        header: "Publié",
        accessor: "isPublished",
      },
      {
        header: "Supprimé",
        accessor: "isDeleted",
      },
      {
        header: "Marque",
        accessor: "brand",
      },
      {
        header: "Description",
        accessor: "description",
        getCell: (a) =>
          `${a?.description?.substring(0, 50)}${
            a?.description?.length > 50 ? "..." : ""
          }`,
      },
      {
        header: "Parent",
        accessor: "parentId",
        navigationPath: (a) =>
          a?.parentId ? `/articles/${a?.parentId}` : undefined,
      },
      {
        header: "Prix TTC",
        id: "unitPriceIncludingTaxes",
        accessor: "unitPriceIncludingTaxes",
        getCell: (a) => `${a?.unitPriceIncludingTaxes}`,
      },
      {
        header: "Prix HT",
        accessor: "unitPriceExcludingTaxes",
      },
      {
        header: "Prix Promo TTC",
        accessor: "promotionalUnitPriceIncludingTaxes",
      },
      {
        header: "Prix Promo HT",
        accessor: "promotionalUnitPriceExcludingTaxes",
      },
      {
        header: "Date début promotion",
        accessor: "promotionStartDate",
        getCell: (a) =>
          a?.promotionStartDate != null ? (
            `${getISO8601Date(a?.promotionStartDate)}`
          ) : (
            <DisplayNull />
          ),
      },
      {
        header: "Date fin promotion",
        accessor: "promotionEndDate",
        getCell: (a) =>
          a?.promotionEndDate != null ? (
            `${getISO8601Date(a?.promotionEndDate)}`
          ) : (
            <DisplayNull />
          ),
      },
    ],
    []
  );
  return { columns };
};
