import { isNullOrUndefined } from "../tools/isNullOrUndefined";
import Synchronization, {
  ImportJobStatusInfo,
  ImportJobOperation,
  ImportFileReception,
  ImportFileInfo,
} from "./Synchronization";

// Transform json Synchronization any object in sync obj (mostly for date handling)
export const createSynchronizationFromFetchedData = (
  data: any
): Synchronization => {
  const res = data as Synchronization;
  res.startDate = new Date(res.startDate);
  res.endDate = !isNullOrUndefined(res.endDate)
    ? new Date(res.endDate!)
    : undefined;
  res.jobOperation = createImportJobOperationFromFetchedData(res.jobOperation);
  return res;
};

const createImportJobOperationFromFetchedData = (
  data: any
): ImportJobOperation => {
  const res = data as ImportJobOperation;
  res.receivedFiles = res.receivedFiles
    ? res.receivedFiles.map(createImportFileReceptionFromFetchResult)
    : [];
  return res;
};

export const createImportJobStatusInfoFromFetchResult = (
  data: any
): ImportJobStatusInfo => {
  const res = data as ImportJobStatusInfo;
  res.statusDate = new Date(res.statusDate);
  return res;
};

const createImportFileReceptionFromFetchResult = (
  data: any
): ImportFileReception => {
  const res = data as ImportFileReception;
  res.fileInfo = createImportFileInfoFromFetchedResult(res.fileInfo);
  return res;
};

const createImportFileInfoFromFetchedResult = (data: any): ImportFileInfo => {
  const res = data as ImportFileInfo;
  res.date = new Date(res.date);
  return res;
};
