export default interface AnimationMedia {
  id: number;
  label: string;
  type: AnimationMediaType;
  thumbnailUrl?: string;
  mediaUrl?: string;
  mediaType: MediaFileType;
  targetType: AnimationMediaTargetType;
  categoryId?: number;
  interactiveCatalogId?: number;
  productId?: number;
  brandId?: string;
  searchTerms?: string;
  articlesSelectionId?: number;
  webUrl?: string;
}

export enum AnimationMediaType {
  DigitalSignage,
  HomePanel,
  HomeAd,
}

export enum MediaFileType {
  Image,
  Video,
}

export enum AnimationMediaTargetType {
  Home,
  Search,
  Category,
  Product,
  Brand,
  InteractiveCatalog,
  ArticlesSelection,
  WebUrl,
}
