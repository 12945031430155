import { EditPage } from "../../resources/components/EditPage";
import EditLogisticOperationForm from "./EditLogisticOperationForm";

const EditLogisticOperationPage = () => {
  return (
    <EditPage canDelete={false}>
      <EditLogisticOperationForm />
    </EditPage>
  );
};

export default EditLogisticOperationPage;
