import { useMemo } from "react";
import ReactSelectOption from "../../../components/ReactSelectOption";
import { AnalyticsTable } from "../../../models/AnalyticsTable";
import { useTranslatedAnalyticsTable } from "./useTranslatedAnalyticsTable";

export const useAnalyticsTableOptions = () => {
  const analyticsTableOptionsLabelValue = useTranslatedAnalyticsTable();

  const analyticsTableOptions = useMemo(() => {
    return [
      AnalyticsTable.AddedArticles,
      AnalyticsTable.OrderedArticles,
      AnalyticsTable.BrowsedArticles,
      AnalyticsTable.Orders,
      AnalyticsTable.AbondonedOrders,
      AnalyticsTable.SharedCarts,
      AnalyticsTable.Searches,
      AnalyticsTable.BrowsedCategories,
      AnalyticsTable.SharedProducts,
      AnalyticsTable.SalesAssistantCalls,
      AnalyticsTable.NotifiedArticles,
      AnalyticsTable.NotifiedSearches,
      AnalyticsTable.NotifiedCategories,
      AnalyticsTable.NotifiedSelections,
      AnalyticsTable.StartedUserSessions,
    ]?.map((c) => {
      return {
        value: c,
        label: analyticsTableOptionsLabelValue[c],
      } as ReactSelectOption<number>;
    });
  }, [analyticsTableOptionsLabelValue]);
  return analyticsTableOptions;
};
