import { useCallback } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import Device from "../../models/Device";
import { ListCondition } from "../../models/ListCondition";
import { DevicesFilters } from "../../models/filters/DevicesFilters";
import {
  ControlledResourcesSelect,
  ControlledResourcesSelectProps,
  ReactSelectProps,
  ResourcesSelect,
} from "../../resources/components/selects/ResourcesSelect";
import { ResourceName } from "../../resources/types/resourceName";
import ReactSelectOption from "../ReactSelectOption";

type UseDevicesSelectControllerProps = {
  storeId?: number;
  excludedIds?: string[];
};
const useDevicesSelectController = <
  TDevice extends Device = Device,
  TString extends string = string,
  TFilters extends DevicesFilters = DevicesFilters
>(
  props: UseDevicesSelectControllerProps
) => {
  const { storeId, excludedIds } = props;
  const convertToOption = useCallback(
    (value: TDevice | undefined): ReactSelectOption<TString> | undefined =>
      value != null
        ? {
            label: `${value.label}`,
            value: value.id as TString,
          }
        : undefined,
    []
  );
  const transformToFilters = useCallback(
    (search: string) =>
      ({
        excludedIds,
        label: search,
        storeId,
        listCondition: ListCondition.Or,
      } as TFilters),
    [excludedIds, storeId]
  );
  const resourceName: ResourceName = "devices";
  return { convertToOption, resourceName, transformToFilters };
};

type DevicesSelectProps<IsMulti extends boolean> =
  UseDevicesSelectControllerProps & ReactSelectProps<string, IsMulti>;

export const DevicesSelect = <IsMulti extends boolean>(
  props: DevicesSelectProps<IsMulti>
) => {
  const c = useDevicesSelectController(props);
  return (
    <ResourcesSelect<Device, string, IsMulti, ResourceName, DevicesFilters>
      {...c}
      {...props}
    />
  );
};

type ControlledDevicesSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledResourcesSelectProps<
  DevicesSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledDevicesSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledDevicesSelectProps<TFieldValues, TName, IsMulti>
) => {
  const c = useDevicesSelectController(props);

  return (
    <ControlledResourcesSelect<
      Device,
      string,
      IsMulti,
      ResourceName,
      DevicesFilters,
      TFieldValues,
      TName
    >
      {...c}
      {...props}
      name={props.name}
    />
  );
};
