import { useCallback, useState } from "react";
import useCustomerApiFetch from "../../../../hooks/useCustomerApiFetch";
import uploadNewMediaFunc from "../../utils/uploadNewMedia";

const useUpload = <T>() => {
  const apiFetcher = useCustomerApiFetch();

  const [error, setError] = useState<unknown>();
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const uploadNewMedia = useCallback(
    async (url: string, file: File) => {
      try {
        setError(undefined);
        setIsBusy(true);
        return await uploadNewMediaFunc<T>(url, apiFetcher, file);
      } catch (e) {
        setError(e);
        throw e;
      } finally {
        setIsBusy(false);
      }
    },
    [apiFetcher]
  );

  return { uploadNewMedia, error, isBusy };
};

export default useUpload;
