import { useMemo } from "react";
import { Resource } from "../../resources/types";
import { ResourceIdentifier } from "../../resources/types/resourceIdentifier";
import { useResourceName } from "./useResourceName";

export type UseAddButtonControllerProps<TResourceName extends string> = Omit<
  PrivateUseAddButtonControllerProps<TResourceName>,
  "label" | "resourceUrlPath"
>;
type PrivateUseAddButtonControllerProps<TResourceName extends string> = {
  columns: any[];
  label: string;
  resourceName?: TResourceName;
  id?: keyof ResourceIdentifier;
  resourceUrlPath: (
    resourceName: TResourceName,
    id: ResourceIdentifier
  ) => string;
};
export const useAddButtonController = <
  TResourceName extends string = string,
  TResource extends Resource = Resource
>(
  props: PrivateUseAddButtonControllerProps<TResourceName>
) => {
  const { columns, id = "id", label, resourceUrlPath } = props;
  const { resourceName } = useResourceName<TResourceName>(props);

  const columnsWithButton = useMemo(() => {
    return [
      ...columns,
      {
        header: " ",
        getCell: () => label,
        accessor: id,
        navigationPath: (_o: TResource, value: ResourceIdentifier) =>
          value != null ? resourceUrlPath(resourceName, value) : undefined,
      },
    ];
  }, [columns, id, label, resourceUrlPath, resourceName]);

  return { columnsWithButton };
};
