import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import { displayLogisticOperationStatus } from "../../components/assets/display/displayLogisticOperationStatus";
import { displayLogisticOperationType } from "../../components/assets/display/displayLogisticOperationType";
import Device from "../../models/Device";
import { LogisticOperation } from "../../models/LogisticOperation";
import Store from "../../models/Store";
import { LogisticOperationsFilters } from "../../models/filters/LogisticOperationsFilters";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { useAddEditButton } from "../../resources/hooks/useAddEditButton";
import { useResourceUrlPathResolver } from "../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../resources/types/resourceName";
import { getDateTime } from "../../tools/dateUtils";

const LogisticOperationsPage = () => {
  const filters: FilterFieldData<LogisticOperationsFilters>[] = useMemo(
    () => [
      {
        fieldName: "type",
        type: "logistic-operation-types-select",
        label: "Type",
        placeholder: "Type",
      },
      //date
      {
        fieldName: "from",
        type: "date",
        label: "Date de début",
        placeholder: "Date de début",
      },
      {
        fieldName: "to",
        type: "date",
        label: "Date de fin",
        placeholder: "Date de fin",
      },
      {
        fieldName: "status",
        type: "logistic-operation-statuses-select",
        label: "Statut",
        placeholder: "Statut",
      },
      {
        fieldName: "storeId",
        type: "stores-select",
        label: "Magasin",
        placeholder: "Magasin",
      },
      {
        fieldName: "deviceId",
        type: "devices-select",
        label: "Périphérique",
        placeholder: "Périphérique",
      },
      //area
      {
        fieldName: "area",
        type: "text",
        label: "Zone",
        placeholder: "Zone",
      },
    ],
    []
  );
  const {
    resourceUrlPathResolver: { edit },
  } = useResourceUrlPathResolver<ResourceName>();

  const columns: ResourceColumn<LogisticOperation>[] = useMemo(
    () => [
      // {
      //   header: "Id",
      //   accessor: "id",
      // },
      {
        header: "Type",
        accessor: "type",
        getCell: (a: LogisticOperation) => displayLogisticOperationType(a.type),
      },
      // date
      {
        header: "Date",
        accessor: "date",
        getCell: (o: LogisticOperation) => getDateTime(o.date),
      },
      {
        header: "Status",
        accessor: "status",
        getCell: (a: LogisticOperation) =>
          displayLogisticOperationStatus(a.status),
      },
      {
        header: "Magasin",
        accessor: "storeId",
        expand: "stores",
        getCell: (d, v, expandedList) => {
          const s = expandedList?.data.items.find((a) => a.id === d.storeId);
          return s && `${s.name} (${s.code})`;
        },
        navigationPath: (o) => o.storeId && edit("stores", o.storeId),
      } as ResourceColumn<LogisticOperation, Store, ResourceName>,
      {
        header: "Périphérique",
        accessor: "deviceId",
        expand: "devices",
        getCell: (d, v, expandedList) => {
          const s = expandedList?.data.items.find((a) => a.id === d.deviceId);
          return s && `${s.label} (${s.id})`;
        },
        navigationPath: (o) => o.storeId && edit("devices", o.storeId),
      } as ResourceColumn<LogisticOperation, Device, ResourceName>,
      {
        header: "Zone",
        accessor: "area",
      },
    ],
    [edit]
  );

  const { columnsWithButton } = useAddEditButton({ columns });
  return (
    <ListPage filterFieds={filters} showChildWithoutFilters>
      <ResourceTable columns={columnsWithButton} />
    </ListPage>
  );
};

export default LogisticOperationsPage;
