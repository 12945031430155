import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import DeliveryZone from "../../models/DeliveryZone";
import { DeliveryZonesFilters } from "../../models/filters/DeliveryZonesFilters";
import { CreateResourceButton } from "../../resources/components/buttons/CreateResourceButton";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { useAddEditButton } from "../../resources/hooks/useAddEditButton";

const DeliveryZonesPage = () => {
  const filters: FilterFieldData<DeliveryZonesFilters>[] = useMemo(
    () => [
      {
        fieldName: "name",
        type: "text",
        label: "Nom",
        placeholder: "Nom",
      },
    ],
    []
  );

  const columns: ResourceColumn<DeliveryZone>[] = useMemo(
    () => [
      {
        header: "Nom",
        accessor: "name",
      },
    ],
    []
  );
  const { columnsWithButton } = useAddEditButton({ columns });

  return (
    <ListPage
      filterFieds={filters}
      actions={<CreateResourceButton />}
      showChildWithoutFilters
    >
      <ResourceTable columns={columnsWithButton} />
    </ListPage>
  );
};

export default DeliveryZonesPage;
