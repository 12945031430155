import { useMemo } from "react";
import { CartLine } from "../../../models/CartLine";
import { CartLinesFilters } from "../../../models/filters/CartLinesFilters";
import { ListView } from "../../../resources/components/lists/ListView";
import {
  ResourceColumn,
  ResourceTable,
} from "../../../resources/components/lists/ResourceTable";
import { useResourceUrlPathResolver } from "../../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../../resources/types/resourceName";

type ArticleCartLinesProps = {
  articleId?: number;
};
export const ArticleCartLines = ({ articleId }: ArticleCartLinesProps) => {
  const {
    resourceUrlPathResolver: { display },
  } = useResourceUrlPathResolver<ResourceName>();

  const columns: ResourceColumn<CartLine>[] = useMemo(
    () => [
      {
        accessor: (p) => p.cartId,
        header: "Paniers (id)",
      },
      {
        accessor: (p) => p.stockLocationId,
        header: "Dépôt stock",
        navigationPath: (o) => display("stock-locations", o.stockLocationId),
      },
      {
        accessor: "quantity",
        header: "Quantité",
      },
    ],
    [display]
  );
  return (
    <ListView<ResourceName, CartLine, CartLinesFilters>
      defaultFilters={{ articleId }}
      resourceName="cart-lines"
    >
      <ResourceTable<CartLine> columns={columns} />
    </ListView>
  );
};
