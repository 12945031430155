import React, { useMemo } from "react";
import {
  Button,
  Form,
  FormControlProps,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FeedbackProps } from "react-bootstrap/esm/Feedback";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/esm/helpers";
import { MdClear } from "react-icons/md";

export type InputProps = FormControlProps &
  React.InputHTMLAttributes<FormControlElement> & {
    label?: string;
    name?: string;
    // isInvalid?: boolean;
    onClear?: () => void;
    errorMessage?: string;
    infoMessage?: string;
    // accept?: string;
  };
export type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

const Input = React.forwardRef<
  BsPrefixRefForwardingComponent<"input", InputProps> & {
    Feedback: BsPrefixRefForwardingComponent<"div", FeedbackProps>;
  },
  InputProps
>((props, ref) => {
  const {
    name,
    label,
    isInvalid,
    errorMessage,
    infoMessage,
    className,
    onClear,
    ...inputProps
  } = props;

  const inputId = `form-input-${name}`;

  const formControl = (
    <Form.Control
      ref={ref}
      type="text"
      id={inputId}
      name={name}
      {...inputProps}
      isInvalid={isInvalid}
    />
  );
  const hasValidation = useMemo(
    () => isInvalid && errorMessage != null,
    [errorMessage, isInvalid]
  );
  return (
    <Form.Group className={className}>
      {label && <Form.Label>{label}</Form.Label>}
      {infoMessage && (
        <>
          &nbsp;
          <OverlayTrigger
            placement="top"
            trigger="click"
            overlay={
              <Tooltip id={`tooltip-info-${inputId}`}>{infoMessage}</Tooltip>
            }
          >
            <span className="text-muted" style={{ cursor: "help" }}>
              (?)
            </span>
          </OverlayTrigger>
        </>
      )}
      {onClear && (
        <InputGroup hasValidation={hasValidation}>
          {formControl}
          <Button variant="outline-secondary" type="button" onClick={onClear}>
            <MdClear />
          </Button>
          {hasValidation && (
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          )}
        </InputGroup>
      )}

      {!onClear && (
        <>
          {formControl}
          {hasValidation && (
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          )}
        </>
      )}
    </Form.Group>
  );
});

export default Input;

export const FormControl = React.forwardRef<
  BsPrefixRefForwardingComponent<"input", InputProps> & {
    Feedback: BsPrefixRefForwardingComponent<"div", FeedbackProps>;
  },
  InputProps & {
    formControl: JSX.Element;
  }
>((props, ref) => {
  const {
    formControl,
    name,
    label,
    isInvalid,
    errorMessage,
    infoMessage,
    className,
    onClear,
    ...inputProps
  } = props;

  const inputId = `form-input-${name}`;

  const finalFormControl = (
    <>
      {React.isValidElement(formControl) && (
        <>
          {React.cloneElement(formControl, {
            ...inputProps,
            ref: ref,
            id: inputId,
            name,
            isInvalid,
          } as any)}
        </>
      )}
    </>
  );
  const hasValidation = useMemo(
    () => isInvalid && errorMessage != null,
    [errorMessage, isInvalid]
  );
  return (
    <Form.Group className={className}>
      {label && <Form.Label>{label}</Form.Label>}
      {infoMessage && (
        <>
          &nbsp;
          <OverlayTrigger
            placement="top"
            trigger="click"
            overlay={
              <Tooltip id={`tooltip-info-${inputId}`}>{infoMessage}</Tooltip>
            }
          >
            <span className="text-muted" style={{ cursor: "help" }}>
              (?)
            </span>
          </OverlayTrigger>
        </>
      )}
      {onClear && (
        <InputGroup hasValidation={hasValidation}>
          {finalFormControl}
          <Button variant="outline-secondary" type="button" onClick={onClear}>
            <MdClear />
          </Button>
          {hasValidation && (
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          )}
        </InputGroup>
      )}

      {!onClear && (
        <>
          {formControl}
          {hasValidation && (
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          )}
        </>
      )}
    </Form.Group>
  );
});
