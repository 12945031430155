import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import Synchronization from "../../models/Synchronization";
import { SynchronizationsFilters } from "../../models/filters/SynchronizationsFilters";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { useResourceUrlPathResolver } from "../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../resources/types/resourceName";
import { getShortMonthnameDayofmonthYearTimeDate } from "../../tools/dateUtils";
import getFriendlyJobName from "../../tools/getFriendlyJobName";
import getSynchronizationStatusLabel from "../../tools/getSynchronizationStatusLabel";

const SynchronizationsPage = () => {
  const filters: FilterFieldData<SynchronizationsFilters>[] = useMemo(
    () => [
      {
        fieldName: "startDate",
        type: "date",
        label: "Entre le : ",
        placeholder: "",
      },
      {
        fieldName: "endDate",
        type: "date",
        label: "et : ",
        placeholder: "",
      },
      {
        fieldName: "importJobDefinitionId",
        type: "import-job-definitions-select",
        label: "Type d'import",
        placeholder: "Type d'import",
      },
    ],
    []
  );
  const {
    resourceUrlPathResolver: { display },
  } = useResourceUrlPathResolver<ResourceName>();
  const columns: ResourceColumn<Synchronization>[] = useMemo(
    () => [
      {
        header: "Date",
        accessor: (s) => getShortMonthnameDayofmonthYearTimeDate(s.startDate),
      },
      {
        header: "Job",
        accessor: (s) =>
          getFriendlyJobName(
            s.jobOperation?.definition?.fullyQualifiedJobClassName
          ) ?? s.jobOperation?.definition?.fullyQualifiedJobClassName,
      },
      {
        header: "Status",
        accessor: "status",
        getCell: (s) => getSynchronizationStatusLabel(s.status),
      },
      {
        header: " ",
        accessor: "jobId",
        getCell: () => "Détails",
        navigationPath: (_, jobId) => display("synchronizations", jobId),
      },
    ],
    [display]
  );

  return (
    <ListPage filterFieds={filters} showChildWithoutFilters>
      <ResourceTable columns={columns} />
    </ListPage>
  );
};

// const SynchronizationsPage = () => {
//   const currentCustomer = useCurrentCustomer();

//   const actionsFactory = useCallback((sync: Synchronization) => {
//     return (
//       <CustomerLink to={"/synchronizations/" + sync.jobId}>
//         Détails
//       </CustomerLink>
//     );
//   }, []);

//   return (
//     <SynchronizationsFilterableList
//       customer={currentCustomer}
//       actions={actionsFactory}
//     />
//   );
// };

export default SynchronizationsPage;
