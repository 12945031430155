import Store from "../../models/Store";
import { CreatePage } from "../../resources/components/CreatePage";
import EditStoreForm from "./EditStoreForm";

const CreateStorePage = () => {
  return (
    <CreatePage<Store> defaultResource={{ countryId: 75 }}>
      <EditStoreForm />
    </CreatePage>
  );
};

export default CreateStorePage;
