import { useCallback } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import Category from "../../models/Category";
import { ListCondition } from "../../models/ListCondition";
import { CategoriesFilters } from "../../models/filters/CategoriesFilters";
import {
  ControlledResourcesSelect,
  ControlledResourcesSelectProps,
  ReactSelectProps,
  ResourcesSelect,
} from "../../resources/components/selects/ResourcesSelect";
import { ResourceIdentifier } from "../../resources/types/resourceIdentifier";
import { ResourceName } from "../../resources/types/resourceName";
import ReactSelectOption from "../ReactSelectOption";

type UseCategoriesSelectControllerProps = {
  excludedIds?: ResourceIdentifier[];
};

const useCategoriesSelectController = <
  TCategory extends Category = Category,
  TNumber extends number = number,
  TFilters extends CategoriesFilters = CategoriesFilters
>(
  props: UseCategoriesSelectControllerProps
) => {
  const { excludedIds } = props;
  const convertToOption = useCallback(
    (value: TCategory | undefined): ReactSelectOption<TNumber> | undefined =>
      value != null
        ? {
            label: `${value.code} - ${value.label}`,
            value: value.id as TNumber,
          }
        : undefined,
    []
  );
  const transformToFilters = useCallback(
    (search: string) =>
      ({
        excludedIds,
        label: search,
        code: search,
        listCondition: ListCondition.Or,
      } as TFilters),
    [excludedIds]
  );
  const resourceName: ResourceName = "categories";
  return { convertToOption, resourceName, transformToFilters };
};

type CategoriesSelectProps<IsMulti extends boolean> =
  UseCategoriesSelectControllerProps & ReactSelectProps<number, IsMulti>;

export const CategoriesSelect = <IsMulti extends boolean>(
  props: CategoriesSelectProps<IsMulti>
) => {
  const c = useCategoriesSelectController(props);
  return (
    <ResourcesSelect<Category, number, IsMulti, ResourceName, CategoriesFilters>
      {...c}
      {...props}
    />
  );
};

type ControlledCategoriesSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledResourcesSelectProps<
  CategoriesSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledCategoriesSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledCategoriesSelectProps<TFieldValues, TName, IsMulti>
) => {
  const c = useCategoriesSelectController(props);

  return (
    <ControlledResourcesSelect<
      Category,
      number,
      IsMulti,
      ResourceName,
      CategoriesFilters,
      TFieldValues,
      TName
    >
      {...c}
      {...props}
      name={props.name}
    />
  );
};
