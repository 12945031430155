import { useCallback } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import AnimationGroup from "../../models/AnimationGroup";
import { ListCondition } from "../../models/ListCondition";
import { AnimationGroupsFilters } from "../../models/filters/AnimationGroupsFilters";
import {
  ControlledResourcesSelect,
  ControlledResourcesSelectProps,
  ReactSelectProps,
  ResourcesSelect,
} from "../../resources/components/selects/ResourcesSelect";
import { ResourceName } from "../../resources/types/resourceName";
import ReactSelectOption from "../ReactSelectOption";

const useAnimationGroupsSelectController = <
  TAnimationGroup extends AnimationGroup = AnimationGroup,
  TNumber extends number = number,
  TAnimationGroupsFilters extends AnimationGroupsFilters = AnimationGroupsFilters
>() => {
  const convertToOption = useCallback(
    (
      value: TAnimationGroup | undefined
    ): ReactSelectOption<TNumber> | undefined =>
      value != null
        ? {
            label: `${value.label}`,
            value: value.id as TNumber,
          }
        : undefined,
    []
  );
  const transformToFilters = useCallback(
    (search: string) =>
      ({
        label: search,
        listCondition: ListCondition.Or,
      } as TAnimationGroupsFilters),
    []
  );
  const resourceName: ResourceName = "animations/groups";
  return { convertToOption, resourceName, transformToFilters };
};

type AnimationGroupsSelectProps<IsMulti extends boolean> = ReactSelectProps<
  number,
  IsMulti
>;

export const AnimationGroupsSelect = <IsMulti extends boolean>(
  props: AnimationGroupsSelectProps<IsMulti>
) => {
  const c = useAnimationGroupsSelectController();
  return (
    <ResourcesSelect<
      AnimationGroup,
      number,
      IsMulti,
      ResourceName,
      AnimationGroupsFilters
    >
      {...c}
      {...props}
    />
  );
};

type ControlledAnimationGroupsSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledResourcesSelectProps<
  AnimationGroupsSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledAnimationGroupsSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledAnimationGroupsSelectProps<TFieldValues, TName, IsMulti>
) => {
  const c = useAnimationGroupsSelectController();

  return (
    <ControlledResourcesSelect<
      AnimationGroup,
      number,
      IsMulti,
      ResourceName,
      AnimationGroupsFilters,
      TFieldValues,
      TName
    >
      {...c}
      {...props}
      name={props.name}
    />
  );
};
