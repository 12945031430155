import { useEffect } from "react";
import { Form, FormGroup } from "react-bootstrap";
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import {
  GroupBase,
  OptionsOrGroups,
  default as ReactSelect,
} from "react-select";
import ReactSelectOption, {
  ReactSelectOptionValueType,
} from "./ReactSelectOption";
import {
  UseSimpleSelectController,
  useSimpleSelectController,
} from "./hooks/useSimpleSelectController";

export type SimpleSelectProps<
  TReactSelectOptionValueType extends ReactSelectOptionValueType,
  IsMulti extends boolean
> = Omit<
  UseSimpleSelectController<TReactSelectOptionValueType, IsMulti>,
  "options"
> & {
  label?: string;
  invalidFeedBack?: string;
  options: OptionsOrGroups<
    ReactSelectOption<TReactSelectOptionValueType>,
    GroupBase<ReactSelectOption<TReactSelectOptionValueType>>
  >;
};

export const SimpleSelect = <
  TReactSelectOptionValueType extends ReactSelectOptionValueType,
  IsMulti extends boolean = false
>(
  props: SimpleSelectProps<TReactSelectOptionValueType, IsMulti>
) => {
  const { label, invalidFeedBack, idPropsValue, ...otherProps } = props;
  const { options, autoSelectFirst } = otherProps;

  const { setValue, isWaitingForDefaultValue, ...otherSimpleSelectProps } =
    useSimpleSelectController(props);

  // valeur par défaut ou premiére des options
  useEffect(() => {
    if (isWaitingForDefaultValue && options != null && options.length > 0) {
      if (idPropsValue != null) {
        setValue(
          (options as ReactSelectOption<TReactSelectOptionValueType>[]).find(
            (o) => o.value === idPropsValue
          )
        );
      } else {
        if (autoSelectFirst) {
          setValue(
            (options as ReactSelectOption<TReactSelectOptionValueType>[])[0]
          );
        }
      }
    }
  }, [
    autoSelectFirst,
    idPropsValue,
    isWaitingForDefaultValue,
    options,
    setValue,
  ]);

  return (
    <FormGroup>
      {label && <Form.Label>{label}</Form.Label>}

      <ReactSelect<ReactSelectOption<TReactSelectOptionValueType>, IsMulti>
        {...otherSimpleSelectProps}
        {...otherProps}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (base) => ({
            ...base,
            width: "max-content",
            minWidth: "100%",
          }),
        }}
      />
      {invalidFeedBack && (
        <Form.Control.Feedback type="invalid">
          {invalidFeedBack}
        </Form.Control.Feedback>
      )}
    </FormGroup>
  );
};

export type ControlledSimpleSelectProps<
  T,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Omit<T, "name" | "idPropsValue" | "onIdPropsValueChanged"> &
  UseControllerProps<TFieldValues, TName>;

export const ControlledSimpleSelect = <
  TFieldValues extends FieldValues,
  TReactSelectOptionValueType extends ReactSelectOptionValueType,
  IsMulti extends boolean = false,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: ControlledSimpleSelectProps<
    SimpleSelectProps<TReactSelectOptionValueType, IsMulti>,
    TFieldValues,
    TName
  >
) => {
  const { name, ...oProps } = props;
  const { control /*, getValues */ } = useFormContext<TFieldValues>();

  return (
    <Controller<TFieldValues, TName>
      render={({
        field: { onChange, value, ...otherControllerProps },
        fieldState: { error, invalid },
        formState: { isSubmitting },
      }) => {
        return (
          <SimpleSelect<TReactSelectOptionValueType, IsMulti>
            {...oProps}
            {...otherControllerProps}
            className={`${invalid ? "is-invalid" : ""}`}
            isDisabled={isSubmitting}
            invalidFeedBack={invalid === true ? error?.message : undefined}
            idPropsValue={value}
            onIdPropsValueChanged={onChange}
          />
        );
      }}
      {...oProps}
      name={name}
      control={control}
    />
  );
};
