import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import { Country } from "../../models/Country";
import { CountriesFilters } from "../../models/filters/CountriesFilters";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { useAddEditButton } from "../../resources/hooks/useAddEditButton";

const CountriesPage = () => {
  const filters: FilterFieldData<CountriesFilters>[] = useMemo(
    () => [
      {
        fieldName: "name",
        type: "text",
        label: "Nom",
        placeholder: "Nom",
      },
      {
        fieldName: "activated",
        type: "boolean",
        label: "Actif",
        placeholder: "Actif",
      },
    ],
    []
  );

  const columns: ResourceColumn<Country>[] = useMemo(
    () => [
      {
        header: "Nom",
        accessor: "name",
      },
      {
        header: "État",
        accessor: "activated",
        getCell: (v) =>
          v.activated === undefined ? "N/A" : v.activated ? "actif" : "inactif",
      },
    ],
    []
  );
  const { columnsWithButton } = useAddEditButton({ columns });

  return (
    <ListPage filterFieds={filters} showChildWithoutFilters>
      <ResourceTable columns={columnsWithButton} />
    </ListPage>
  );
};

export default CountriesPage;
