import { useCallback, useRef } from "react";
import { Button } from "react-bootstrap";
import { useCurrentCustomer } from "../../../LoggedInBackOffice";
import { AsyncHandler } from "../../../components/AsyncHandler";
import { GoBackButton } from "../../../components/buttons/GoBackButton";
import { Loading } from "../../../components/types/loading";
import useSynchronization from "../../../hooks/synchronizations/useSynchronization";
import { SynchronizationStatus } from "../../../models/Synchronization";
import { PageTitle } from "../../../resources/components/PageTitle";
import { useResourceParams } from "../../../resources/hooks/useResourceParams";
import { getShortMonthnameDayofmonthYearTimeDate } from "../../../tools/dateUtils";
import getFriendlyJobName from "../../../tools/getFriendlyJobName";
import getSynchronizationStatusLabel from "../../../tools/getSynchronizationStatusLabel";
import ImportFileReceptionList from "./ImportFileReceptionList";
import ImportJobStatusInfoList, {
  ImportJobStatusInfoListHandle,
} from "./ImportJobStatusInfoList";

const SynchronizationsDetailsPage = () => {
  const currentCustomer = useCurrentCustomer();

  const { id } = useResourceParams();

  const {
    mutate: refresh,
    error,
    synchronization,
    isLoading,
  } = useSynchronization(currentCustomer, id);

  // hack pour déclancher un refresh de l'enfant

  const ref = useRef<ImportJobStatusInfoListHandle>(null);
  const triggerRefresh = useCallback(() => {
    refresh();
    if (ref.current != null) {
      ref.current.refresh();
    }
  }, [refresh]);

  return (
    <>
      <GoBackButton />
      <PageTitle
        label={
          <>
            {getFriendlyJobName(
              synchronization?.jobOperation?.definition
                ?.fullyQualifiedJobClassName
            )}{" "}
            sur l'environnement {currentCustomer?.name}
            &nbsp; ({getSynchronizationStatusLabel(synchronization?.status)})
          </>
        }
      />
      <AsyncHandler
        errorMessage="Une erreur est survenue lors de la récupération de cet import."
        refresh={refresh}
        error={error}
        isLoading={isLoading}
        loading={Loading.Circle}
      >
        {currentCustomer && synchronization && (
          <>
            Début :{" "}
            {getShortMonthnameDayofmonthYearTimeDate(
              synchronization!.startDate
            )}
            <br />
            Fin :{" "}
            {synchronization!.endDate
              ? getShortMonthnameDayofmonthYearTimeDate(
                  synchronization!.endDate
                )
              : "❔"}{" "}
            <br />
            Dernière activité :{" "}
            {getShortMonthnameDayofmonthYearTimeDate(
              synchronization!.lastSyncedChange
            )}
            <br />
            <br />
            {
              // In progress ?
              synchronization!.status === SynchronizationStatus.InProgress && (
                <>
                  <Button variant="outline-primary" onClick={triggerRefresh}>
                    <span>Actualiser</span>
                  </Button>
                  <br />
                  <br />
                </>
              )
            }
            <ImportJobStatusInfoList synchronizationId={id} ref={ref} />
            <br />
            <ImportFileReceptionList
              receivedFiles={synchronization!.jobOperation.receivedFiles}
              jobId={synchronization!.jobId}
            />
          </>
        )}
      </AsyncHandler>
    </>
  );
};
export default SynchronizationsDetailsPage;
