import { useCallback } from "react";
import { UseControlledPaginationProps } from "./useControlledPagination";
import { useQueryAsState } from "./useQueryAsState";

export const useControlledQueryPagination = ({
  defaultPageIndex = 0,
  defaultPageSize = 10,
}: UseControlledPaginationProps) => {
  const [params, updateQueryParams] = useQueryAsState(
    {
      pageIndex: "number",
      pageSize: "number",
    },
    { pageIndex: defaultPageIndex, pageSize: defaultPageSize },
    true
  );

  const { pageIndex, pageSize } = params;

  const setPageIndex = useCallback(
    (pageIndex: number) => {
      updateQueryParams({ pageIndex });
    },
    [updateQueryParams]
  );

  const setPageSize = useCallback(
    (pageSize: number) => {
      updateQueryParams({ pageSize });
    },
    [updateQueryParams]
  );
  return { pageIndex, setPageIndex, pageSize, setPageSize };
};
