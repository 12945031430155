import { Form, FormCheckProps } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FormControl } from "../../../components/Input";
import { Resource } from "../../types";
import { ResourceInputProps } from "./ResourceInput";

export const ResourceCheckInput = <TResource extends Resource>(
  props: ResourceInputProps<TResource> & FormCheckProps
) => {
  const { name, options, ...otherProps } = props;
  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext();

  return (
    <FormControl
      formControl={
        <Form.Check
          {...register(name, options)}
          errorMessage={errors[name]?.message as string}
          isInvalid={errors[name] != null}
          disabled={isSubmitting}
          id={`inline-${name}-1`}
          {...otherProps}
        ></Form.Check>
      }
    />
  );
};
