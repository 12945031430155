import FormGroup from "react-bootstrap/esm/FormGroup";
import { Country } from "../../models/Country";
import { ResourceCheckInput } from "../../resources/components/inputs/ResourceCheckInput";
import { ResourceHiddenNumberInput } from "../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceInput } from "../../resources/components/inputs/ResourceInput";

const EditCountryForm = () => {
  return (
    <>
      <ResourceHiddenNumberInput<Country> name="id" />
      <ResourceInput<Country>
        label="Nom"
        name="name"
        readOnly={true}
        disabled={true}
      />

      <div className="row">
        <div className="col">
          <ResourceInput<Country>
            label="Code ISO 2"
            name="isoCode2"
            readOnly={true}
            disabled={true}
          />
        </div>
        <div className="col">
          <ResourceInput<Country>
            label="Code ISO 3"
            name="isoCode3"
            readOnly={true}
            disabled={true}
          />
        </div>
      </div>
      <ResourceInput<Country> label="RegEx Codes Postaux" name="zipCodeRegEx" />
      <ResourceInput<Country> label="RegEx Téléphones" name="phoneRegEx" />

      <FormGroup>
        <ResourceCheckInput<Country> // prettier-ignore
          type="checkbox"
          name="activated"
          label="Actif"
        />
        {/* <div className="form-check">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              {...register("activated")}
              defaultChecked={country?.activated}
            />
            Actif
          </label>
        </div> */}
      </FormGroup>
    </>
  );
};

export default EditCountryForm;
