import Customer from "../../models/Customer";
import OrderReceiptConfiguration from "../../models/OrderReceiptConfiguration";
import useFetchForCustomer from "../swr/useSWRFetchForCustomer";

const useOrderReceiptConfiguration = function (customer?: Customer) {
  const res = useFetchForCustomer("/api/orderreceiptconfiguration", customer);
  let orderReceiptConfiguration = res.data as OrderReceiptConfiguration;
  return { ...res, orderReceiptConfiguration };
};
export default useOrderReceiptConfiguration;
