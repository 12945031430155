import { KeyedMutator } from "swr";
import {
  UseControlledPaginationProps,
  useControlledPagination,
} from "../../hooks/useControlledPagination";
import { ListReturn } from "../types/dataProvider";
import { ResourceFilters } from "../types/resourceFilters";
import { useSWRListResource } from "./useSWRListResource";

export type UseControlledPaginationSWRListResourceProps<TResourceName> =
  UseControlledPaginationProps & {
    filters?: ResourceFilters;
    resourceName: TResourceName;
  };

export type UseControlledPaginationSWRListResourceReturn<TResource> = {
  pageIndex: number;
  pageSize: number;
  setPageIndex: (pageIndex: number) => void;
  setPageSize: (pageSize: number) => void;
  data?: ListReturn<TResource>;
  error: any;
  mutate: KeyedMutator<ListReturn<TResource> | undefined>;
};
export const useControlledPaginationSWRListResource = <
  TResourceName extends string = string,
  TResource = any
>(
  props: UseControlledPaginationSWRListResourceProps<TResourceName>
): UseControlledPaginationSWRListResourceReturn<TResource> => {
  const { filters, resourceName, defaultPageIndex, defaultPageSize } = props;
  const { pageIndex, pageSize, setPageIndex, setPageSize } =
    useControlledPagination({
      defaultPageIndex,
      defaultPageSize,
    });
  const { data, error, mutate } = useSWRListResource<TResourceName, TResource>({
    resourceName,
    pageIndex,
    pageSize,
    filters,
  });
  return {
    pageIndex,
    pageSize,
    setPageIndex,
    setPageSize,
    data,
    error,
    mutate,
  };
};
