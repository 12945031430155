import { Col, Row } from "react-bootstrap";
import { CollapseDisplayGroup } from "../../components/CollapseDisplayGroup";
import { DisplayGroup } from "../../components/DisplayGroup";
import { DisplayNull } from "../../components/DisplayNull";
import HtmlEditor from "../../components/HtmlEditor";
import Input from "../../components/Input";
import { Article } from "../../models/Article";
import { ReadPage } from "../../resources/components/ReadPage";
import { ReadResource } from "../../resources/components/ReadResource";
import { getISO8601Date } from "../../tools/dateUtils";
import { ArticleAssociations } from "./components/ArticleAssociations";
import { ArticleAttributes } from "./components/ArticleAttributes";
import { ArticleCartLines } from "./components/ArticleCartLines";
import { ArticleCategories } from "./components/ArticleCategories";
import { ArticleMedias } from "./components/ArticleMedias";
import { ArticleOrderLines } from "./components/ArticleOrderLines";
import { ArticlePrices } from "./components/ArticlePrices";
import { ArticleReferences } from "./components/ArticleReferences";
import { ArticleStockInfos } from "./components/ArticleStockInfos";
import { ArticleStocks } from "./components/ArticleStocks";
import { Articles } from "./components/Articles";

const ArticlePage = () => {
  return (
    <ReadPage>
      <ReadResource<Article>
        resource={(article) => {
          return (
            <>
              <div className="d-flex flex-column gap-2">
                <Row>
                  <Input
                    className="col-md-3"
                    label="Id"
                    value={article.id}
                    disabled
                  />
                  <Input
                    className="col-md-3"
                    label="Référence"
                    value={article.reference}
                    disabled
                  />
                  <Input
                    className="col-md-3"
                    label="Nom"
                    value={article.name}
                    disabled
                  />
                  <Input
                    className="col-md-3"
                    label="Id parent"
                    value={String(article.parentId)}
                    disabled
                  />
                  <Input
                    className="col-md-3"
                    label="Marque"
                    value={article.brand?.toString()}
                    disabled
                  />
                </Row>
                <Row>
                  <Input
                    className="col-md-3"
                    label="Publié"
                    value={String(article.isPublished)}
                    disabled
                  />
                  <Input
                    className="col-md-3"
                    label="Supprimé"
                    value={String(article.isDeleted)}
                    disabled
                  />
                  <Input
                    className="col-md-3"
                    label="Date dernier changement"
                    value={String(article.lastChange)}
                    disabled
                  />
                </Row>
                <Row>
                  <Input
                    className="col-md-3"
                    label="Prix TTC"
                    value={String(article.unitPriceIncludingTaxes)}
                    disabled
                  />
                  <Input
                    className="col-md-3"
                    label="Prix HT"
                    value={String(article.unitPriceExcludingTaxes)}
                    disabled
                  />
                </Row>
                <Row>
                  <Input
                    className="col-md-3"
                    label="Prix promotion TTC"
                    value={String(article.promotionalUnitPriceIncludingTaxes)}
                    disabled
                  />
                  <Input
                    className="col-md-3"
                    label="Prix promotion HT"
                    value={String(article.promotionalUnitPriceExcludingTaxes)}
                    disabled
                  />
                  <Input
                    className="col-md-3"
                    label="Date début promo"
                    value={getISO8601Date(article.promotionStartDate)}
                    disabled
                  />
                  <Input
                    className="col-md-3"
                    label="Date fin promo"
                    value={getISO8601Date(article.promotionEndDate)}
                    disabled
                  />
                </Row>
                <Row>
                  <Col>
                    <CollapseDisplayGroup label="Description">
                      {article.description != null && (
                        <HtmlEditor
                          html={article.description}
                          readOnly
                          toolbarHidden
                        />
                      )}
                      {article.description == null && <DisplayNull />}
                    </CollapseDisplayGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DisplayGroup label="ImageUrl">
                      <a href={article.imageUrl}>
                        <img
                          style={{ maxHeight: "10rem" }}
                          className="img-thumbnail mx-auto d-block"
                          src={article.imageUrl}
                          alt={article.imageUrl}
                        />
                      </a>
                    </DisplayGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex flex-column gap-2">
                    <CollapseDisplayGroup
                      label="Articles enfants"
                      canHide={false}
                      addBodyMargin={false}
                    >
                      <Articles parentId={article.id} />
                    </CollapseDisplayGroup>
                    <CollapseDisplayGroup
                      label="Interrogation des stocks via API pour Cataleeze"
                      canHide={false}
                      addBodyMargin={false}
                    >
                      <ArticleStocks articleId={article.id} />
                    </CollapseDisplayGroup>
                    <CollapseDisplayGroup
                      label="Stocks"
                      canHide={false}
                      addBodyMargin={false}
                    >
                      <ArticleStockInfos articleId={article.id} />
                    </CollapseDisplayGroup>
                    <CollapseDisplayGroup
                      label="Prix"
                      canHide={false}
                      addBodyMargin={false}
                    >
                      <ArticlePrices articleId={article.id} />
                    </CollapseDisplayGroup>
                    <CollapseDisplayGroup
                      label="Commandes en cours"
                      canHide={false}
                      addBodyMargin={false}
                    >
                      <ArticleOrderLines articleId={article.id} />
                    </CollapseDisplayGroup>
                    <CollapseDisplayGroup
                      label="Paniers en cours"
                      canHide={false}
                      addBodyMargin={false}
                    >
                      <ArticleCartLines articleId={article.id} />
                    </CollapseDisplayGroup>
                    <CollapseDisplayGroup
                      label="Attributs"
                      canHide={false}
                      addBodyMargin={false}
                    >
                      <ArticleAttributes articleId={article.id} />
                    </CollapseDisplayGroup>
                    <CollapseDisplayGroup
                      label="Références"
                      canHide={false}
                      addBodyMargin={false}
                    >
                      <ArticleReferences articleId={article.id} />
                    </CollapseDisplayGroup>
                    <CollapseDisplayGroup
                      label="Medias"
                      canHide={false}
                      addBodyMargin={false}
                    >
                      <ArticleMedias articleId={article.id} />
                    </CollapseDisplayGroup>
                    <CollapseDisplayGroup
                      label="Catégories"
                      canHide={false}
                      addBodyMargin={false}
                    >
                      <ArticleCategories articleId={article.id} />
                    </CollapseDisplayGroup>
                    <CollapseDisplayGroup
                      label="Articles associés"
                      canHide={false}
                      addBodyMargin={false}
                    >
                      <ArticleAssociations articleId={article.id} />
                    </CollapseDisplayGroup>
                  </Col>
                </Row>
              </div>
            </>
          );
        }}
      />
    </ReadPage>
  );
};

export default ArticlePage;
