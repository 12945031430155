import { useContext } from "react";
import {
  MdAnnouncement,
  MdCategory,
  MdCelebration,
  MdChecklist,
  MdCheckroom,
  MdDataset,
  MdDevices,
  MdEuro,
  MdEventNote,
  MdFolderOpen,
  MdGroups,
  MdHome,
  MdImage,
  MdInterests,
  MdLocalShipping,
  MdLockOutline,
  MdMap,
  MdOutlineApps,
  MdOutlineCloudSync,
  MdOutlineDepartureBoard,
  MdOutlineGroupWork,
  MdOutlineReceipt,
  MdPublic,
  MdQueryStats,
  MdSchedule,
  MdScheduleSend,
  MdSearch,
  MdSettings,
  MdStackedBarChart,
  MdStorage,
  MdStore,
  MdStorefront,
  MdSupervisorAccount,
  MdVerticalShadesClosed,
  MdVideoLibrary,
  MdViewCarousel,
  MdViewComfy,
  MdWarehouse,
  MdWebStories,
} from "react-icons/md";
import { LoggedInUserContext } from "../../LoggedInBackOffice";
import { useResourceUrlPathResolver } from "../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../resources/types/resourceName";
import { SidebarElement, SidebarSubElement } from "./Sidebar";
import TenantAdminTemplate from "./TenantAdminTemplate";
export const MainMenu = () => {
  const userData = useContext(LoggedInUserContext);

  const {
    resourceUrlPathResolver: { list },
  } = useResourceUrlPathResolver<ResourceName>();

  return (
    <>
      <SidebarElement title="Synchronisations" icon={<MdOutlineCloudSync />}>
        <SidebarSubElement
          icon={<MdOutlineCloudSync />}
          title="Synchronisations"
          to={list("synchronizations")}
        />
        <SidebarSubElement
          icon={<MdChecklist />}
          isTenantAdmin
          title="Définitions des tâches d'import"
          to={list("import-job-definition-entities")}
        />
        <SidebarSubElement
          icon={<MdFolderOpen />}
          isTenantAdmin
          title="Réception de fichiers"
          to={list("reception-files")}
        />
      </SidebarElement>

      <SidebarElement title="Catalogue" icon={<MdCheckroom />}>
        <SidebarSubElement
          title="Catégories"
          to={list("categories")}
          icon={<MdCategory />}
        />
        <SidebarSubElement
          title="Articles"
          to={list("articles")}
          isTenantAdmin
          icon={<MdCheckroom />}
        />
        <SidebarSubElement
          icon={<MdSearch />}
          title="Articles (ElasticSearch)"
          to="/elastic-articles"
          isTenantAdmin
        />
        <SidebarSubElement
          title="Attributs"
          to={list("attributes")}
          icon={<MdDataset />}
        />
        <SidebarSubElement
          title="Medias synchronisés"
          to={list("sync-medias")}
          isTenantAdmin
          icon={<MdImage />}
        />
        <SidebarSubElement
          title="Dépôts stocks"
          to={list("stock-locations")}
          isTenantAdmin
          icon={<MdVerticalShadesClosed />}
        />
        <SidebarSubElement
          title="Stocks"
          to={list("stock-infos")}
          isTenantAdmin
          icon={<MdStorage />}
        />
      </SidebarElement>
      <TenantAdminTemplate>
        <SidebarElement title="Logistique" icon={<MdEventNote />}>
          <SidebarSubElement
            title="Opérations"
            to={list("logistic-operations")}
            isTenantAdmin
            icon={<MdEventNote />}
          />
        </SidebarElement>
      </TenantAdminTemplate>
      <SidebarElement title="Magasins et périphériques" icon={<MdStore />}>
        <SidebarSubElement title="Magasins" to="/stores" icon={<MdStore />} />
        <SidebarSubElement
          title="Périphériques"
          to={list("devices")}
          isTenantAdmin
          icon={<MdDevices />}
        />
      </SidebarElement>
      <SidebarElement title="Animations" icon={<MdCelebration />}>
        <SidebarSubElement
          icon={<MdOutlineGroupWork />}
          title="Groupes d'animations"
          to={list("animations/groups")}
        />
        <SidebarSubElement
          icon={<MdInterests />}
          title="Sélections d'articles"
          to={list("animations/articles-selections")}
        />
        <SidebarSubElement
          title="Publicités de veille"
          to={list("animations/ads")}
          icon={<MdWebStories />}
        />
        <SidebarSubElement
          icon={<MdVideoLibrary />}
          title="Campagnes de veille"
          to={list("animations/digital-signage-campaigns")}
        />
        <SidebarSubElement
          icon={<MdHome />}
          title="Campagnes de Home Panels"
          to={list("animations/home-panels-campaigns")}
        />
        <SidebarSubElement
          icon={<MdViewCarousel />}
          title="Campagnes de Home Ads"
          to={list("animations/home-ads-campaigns")}
        />
        <SidebarSubElement
          icon={<MdViewComfy />}
          title="Catalogues interactifs"
          to={list("animations/interactives-catalogs")}
        />
        <SidebarSubElement
          icon={<MdStorefront />}
          title="Campagnes de Shop in Shop"
          to={list("animations/home-catalogs-campaigns")}
        />
      </SidebarElement>
      <SidebarElement title="Notifications" icon={<MdAnnouncement />}>
        <SidebarSubElement
          title="Notifications"
          to={list("notifications")}
          icon={<MdAnnouncement />}
        />
        <SidebarSubElement
          title="Listes de diffusion"
          to={list("broadcast-lists")}
          icon={<MdGroups />}
        />
      </SidebarElement>
      <SidebarElement title="Commandes" icon={<MdEuro />}>
        <SidebarSubElement title="Commandes" to="/orders" icon={<MdEuro />} />
        <SidebarSubElement
          icon={<MdOutlineReceipt />}
          title="Ticket de commande"
          to="/order-receipt-configuration"
          isTenantAdmin
        />
      </SidebarElement>
      <SidebarElement title="Statistiques" icon={<MdQueryStats />}>
        {userData.areAnalysticsEnabled && (
          <SidebarSubElement
            title="Dashboard"
            to="/stats"
            icon={<MdQueryStats />}
          />
        )}
        <SidebarSubElement
          title="Export de statistiques"
          to="/stats/export"
          icon={<MdStackedBarChart />}
        />
        <SidebarSubElement
          icon={<MdScheduleSend />}
          title="Planifications d'export de statistiques"
          to={list("analyticsExportScheduledJobs")}
        />
      </SidebarElement>
      <SidebarElement title="Paramètres" icon={<MdSettings />}>
        <SidebarSubElement
          icon={<MdSettings />}
          title="Catalogue & Applications"
          to="/tenant-configuration"
          isTenantAdmin
        />
        <SidebarSubElement
          title="Paramètres FTP"
          to="/ftp-parameters"
          isTenantAdmin
          icon={<MdLockOutline />}
        />
        <SidebarSubElement
          title="Modes de livraison"
          to={list("deliverymodes")}
          icon={<MdOutlineDepartureBoard />}
        />
        <SidebarSubElement
          title="Transporteurs"
          to={list("transporters")}
          icon={<MdLocalShipping />}
        />
        <SidebarSubElement
          title="Zones de livraison"
          to={list("deliveryzones")}
          icon={<MdMap />}
        />
        <SidebarSubElement title="Pays" to="/countries" icon={<MdPublic />} />
        <SidebarSubElement
          icon={<MdWarehouse />}
          title="Fournisseurs dropshipping"
          to={list("dropshippingproviders")}
        />
        <SidebarSubElement
          icon={<MdSchedule />}
          title="Tâches planifiées"
          to={list("scheduled-jobs")}
          isTenantAdmin
        />
        <SidebarSubElement
          icon={<MdOutlineApps />}
          title="Définitions des services d'extension"
          to={list("service-extension-definitions")}
          isTenantAdmin
        />
        <SidebarSubElement
          title="Utilisateurs"
          to={list("users")}
          isTenantAdmin
          icon={<MdSupervisorAccount />}
        />
      </SidebarElement>
    </>
  );
};
