import { useMemo } from "react";
import { AttributeValuesFilters } from "../../../models/filters/AttributeValuesFilters";
import { ListView } from "../../../resources/components/lists/ListView";
import {
  ResourceColumn,
  ResourceTable,
} from "../../../resources/components/lists/ResourceTable";
import { ResourceName } from "../../../resources/types/resourceName";

type AttributeValuesProps = {
  attributeId?: number;
};
export const AttributeValues = ({ attributeId }: AttributeValuesProps) => {
  const columns: ResourceColumn<any>[] = useMemo(
    () => [
      {
        accessor: (o) => o,
        header: "Valeur",
      },
    ],
    []
  );
  return (
    <>
      {attributeId && (
        <ListView<ResourceName, string, AttributeValuesFilters>
          defaultFilters={{ attributeId }}
          resourceName="attribute-values"
        >
          <ResourceTable<any> columns={columns} />
        </ListView>
      )}
    </>
  );
};
