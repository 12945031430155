import { useCallback, useMemo } from "react";
import { Button } from "react-bootstrap";
import { FilterFieldData } from "../../components/DynamicFieldData";
import useCustomerApiFetch from "../../hooks/useCustomerApiFetch";
import { useIsUserInServerAdminRole } from "../../hooks/useIsUserInServerAdminRole";
import ScheduledJob from "../../models/ScheduledJob";
import { ScheduledJobsFilters } from "../../models/filters/ScheduledJobsFilters";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { getShortMonthnameDayofmonthYearTimeDate } from "../../tools/dateUtils";

const ScheduledJobsPage = () => {
  const filters: FilterFieldData<ScheduledJobsFilters>[] = useMemo(
    () => [
      {
        fieldName: "fullyQualifiedJobClassName",
        type: "text",
        label: "FullyQualifiedJobClassName",
        placeholder: "FullyQualifiedJobClassName",
      },
    ],
    []
  );
  const apiFetcher = useCustomerApiFetch();

  const forceExecute = useCallback(
    async (id: string) => {
      try {
        const response = await apiFetcher(
          `/api/scheduledjobs/${id}/ForceExecute`,
          {
            method: "GET",
            headers: new Headers({
              Accept: "application/json",
              "Content-Type": "application/json",
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Unable to change the state");
        }
      } catch (ex) {}
    },
    [apiFetcher]
  );
  const { isAllowed } = useIsUserInServerAdminRole();
  const columns: (ResourceColumn<ScheduledJob> | undefined)[] = useMemo(
    () => [
      {
        header: "FullyQualifiedJobClassName",
        accessor: "fullyQualifiedJobClassName",
      },
      {
        header: "CronExpression",
        accessor: "cronExpression",
      },
      {
        header: "LastExecuted",
        accessor: "lastExecuted",
        getCell: (_o: ScheduledJob, value: any) =>
          getShortMonthnameDayofmonthYearTimeDate(value),
      },
      isAllowed
        ? {
            header: "Forcer l'exécution",
            accessor: (o: ScheduledJob) => o,
            getCell: (o: ScheduledJob) => (
              <Button onClick={() => forceExecute(o.id)}>
                Forcer l'exécution
              </Button>
            ),
          }
        : undefined,
    ],
    [forceExecute, isAllowed]
  );
  return (
    <ListPage filterFieds={filters} showChildWithoutFilters>
      <ResourceTable columns={columns} />
    </ListPage>
  );
};

export default ScheduledJobsPage;
