import { IPublicClientApplication } from "@azure/msal-browser/dist/app/IPublicClientApplication";
import { AccountInfo } from "@azure/msal-common/dist/account/AccountInfo";
import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect, useMemo, useRef } from "react";
import useSWR from "swr";
import Nullable from "../../Nullable";
import { useLogin } from "../../hooks/useLogin";
import Customer from "../../models/Customer";
import authFetch from "../../tools/authFetch";
import useSWRKeyInterfaceForCustomer from "./useSWRKeyInterfaceForCustomer";

const maxHttp401Count = 3;
const useSWRFetchForCustomer = function useSWRFetchForCustomer(
  api: Nullable<string>,
  customer: Nullable<Customer>
) {
  const swrKey = useSWRKeyInterfaceForCustomer(api, customer);
  const http401Count = useRef(0); //keep track of http401Count
  const isWaitingForLoginResult = useRef(false);
  const login = useLogin();
  const isAuthenticated = useIsAuthenticated();
  const swr = useSWR(swrKey, ([apiUrl, instance, account]) =>
    authFetch(
      apiUrl
        ? new Request(apiUrl as string, {
            headers: new Headers({
              Accept: "application/json",
              "Content-Type": "application/json",
            }),
          })
        : undefined,
      instance as IPublicClientApplication,
      account as AccountInfo
    ).then((r) => {
      if (!r.ok) {
        throw r;
      }
      return r.json();
    })
  );
  const { error: swrError, mutate } = swr;
  // Call 'login' on http 401 & hide error from the view.
  const error = useMemo(() => {
    const resp = swrError as Response;
    if (resp?.status === 401) {
      http401Count.current++;
      if (http401Count.current < maxHttp401Count) {
        console.info("try to login after 401....");
        isWaitingForLoginResult.current = true;
        login();
        return undefined;
      } else {
        return swrError;
      }
    } else {
      http401Count.current = 0; // Reset conter if no error or if no 401 error.
      return swrError;
    }
  }, [swrError, login]);

  //Refresh is new user and previous error was 401.
  useEffect(() => {
    if (isAuthenticated && isWaitingForLoginResult.current) {
      isWaitingForLoginResult.current = false;
      console.info("Refresh after 401....");
      mutate();
    }
  }, [isAuthenticated, mutate]);

  return { ...swr, error };
};
export default useSWRFetchForCustomer;
