import { useCallback, useState } from "react";
import useCustomerApiFetch from "../../../hooks/useCustomerApiFetch";
import AnimationMedia from "../../../models/AnimationMedia";
import AnimationMediasCampaign from "../../../models/AnimationMediasCampaign";
import { useResourceApiPathResolverContext } from "../../../resources/hooks/useResourceApiPathResolverContext";
import { useResourceContext } from "../../../resources/hooks/useResourceContext";
import { useResourceName } from "../../../resources/hooks/useResourceName";
import uploadMedia from "./uploadMedia";

const useUploadMedia = () => {
  const apiFetcher = useCustomerApiFetch();

  const [error, setError] = useState<unknown>();
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const { resourceName } = useResourceName();
  const { resourceApiPathResolver: { createUrl } = {} } =
    useResourceApiPathResolverContext();
  const { resource } =
    useResourceContext<AnimationMediasCampaign<AnimationMedia>>();

  const func = useCallback(
    async (homePanelId: number, file: File) => {
      try {
        setError(undefined);
        setIsBusy(true);
        return await uploadMedia(
          createUrl != null ? createUrl(resourceName) : undefined,
          resource?.id,
          homePanelId,
          apiFetcher,
          file
        );
      } catch (e) {
        setError(e);
        throw e;
      } finally {
        setIsBusy(false);
      }
    },
    [createUrl, resourceName, resource?.id, apiFetcher]
  );

  return { uploadMedia: func, error, isBusy };
};
export default useUploadMedia;
