import { PublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import React, { useContext, useEffect, useMemo } from "react";
import { IconContext } from "react-icons";
import { Route, Routes } from "react-router-dom";
import LoggedInBackOffice from "./LoggedInBackOffice";
import { msalConfig } from "./authConfig";
import SigninRedirect from "./components/SigninRedirect";
import useConfig, { BackOfficeConfiguration } from "./hooks/useConfig";
import Authentication from "./pages/Authentication";
import { Middleware, SWRConfig } from "swr";

export const ConfigurationContext = React.createContext<
  BackOfficeConfiguration | undefined
>(undefined);

export const useConfigurationContext = () => {
  const config = useContext(ConfigurationContext);

  if (!config) {
    throw new Error(
      "Unable to use undefined config. The config has yet to be initialized."
    );
  }

  return config;
};

const BackOffice = () => {
  const { config, error } = useConfig();

  if (error) {
    return <p>Impossible de récupérer la configuration du back-office.</p>;
  }

  if (!config) {
    return null;
  }
  const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <IconContext.Provider
      value={{ style: { verticalAlign: "text-bottom" }, size: "1.3em" }}
    >
      <ConfigurationContext.Provider value={config}>
        <MsalProvider instance={msalInstance}>
          <AuthenticatedTemplate>
            <Routes>
              <Route path="/signin-redirect/" element={<SigninRedirect />} />
              <Route path="/*" element={<LoggedInBackOffice />} />
            </Routes>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Authentication />
          </UnauthenticatedTemplate>
        </MsalProvider>
      </ConfigurationContext.Provider>
    </IconContext.Provider>
  );
};

export default BackOffice;
