import { useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import { MdContentCopy } from "react-icons/md";
import { FilterFieldData } from "../../components/DynamicFieldData";
import { Filter } from "../../components/Filter";
import { useElasticSearchArticlesByReference } from "../../hooks/useElasticSearchArticlesByReference";
import { PageTitle } from "../../resources/components/PageTitle";
import styles from "./ElasticArticlesPage.module.css";

const fields: FilterFieldData<Filters>[] = [
  {
    fieldName: "id",
    type: "number",
    label: "Id",
    placeholder: "Id",
  },
  {
    fieldName: "reference",
    type: "text",
    label: "Référence",
    placeholder: "Référence",
  },
  {
    fieldName: "barcode",
    type: "text",
    label: "Code barre",
    placeholder: "Code barre",
  },
];
type Filters = {
  id: string;
  reference: string;
  barcode: string;
  name: string;
};
const ElasticArticlesPage = () => {
  const [filters, setFilters] = useState<Filters>();
  const { elasticResponse } = useElasticSearchArticlesByReference({
    id: filters?.id,
    reference: filters?.reference,
    barcode: filters?.barcode,
    name: filters?.name,
  });

  const onSubmit = useCallback((d: Filters) => {
    setFilters(d);
  }, []);
  const copyOnClick = useCallback(() => {
    if (elasticResponse != null) {
      navigator.clipboard.writeText(
        JSON.stringify(elasticResponse, undefined, 2)
      );
    } else {
    }
  }, [elasticResponse]);

  const isArray = Array.isArray(elasticResponse?.searchResult);
  const isObject =
    !isArray && typeof elasticResponse?.searchResult === "object";
  return (
    <>
      <PageTitle label="Articles (ElasticSearch)" />
      <hr />
      <Filter fields={fields} onSubmit={onSubmit} />
      <hr />

      {elasticResponse && (
        <>
          <div className="text-end mb-2">
            <Button variant="outline-success" onClick={copyOnClick}>
              <MdContentCopy />
              Copier
            </Button>
          </div>
          {elasticResponse.searchResult != null && (
            <pre className={styles.json}>
              <>
                {isArray && <>{"["}</>}
                {isObject && <>{"{"}</>}
                <div className={styles["line"]}>
                  <HighlightedJSON jsonObj={elasticResponse.searchResult} />
                </div>
                {isArray && <>{"],"}</>}
                {isObject && <>{"},"}</>}
              </>
            </pre>
          )}
        </>
      )}
    </>
  );
};
export default ElasticArticlesPage;

type HighlightedJSONProps = {
  jsonObj: any;
};
const HighlightedJSON = ({ jsonObj }: HighlightedJSONProps) => {
  return (
    <>
      {Object.keys(jsonObj).map((key) => (
        <HighlightedChildJSON
          key={key}
          propertyName={key}
          value={jsonObj[key]}
          isArrayElement={Array.isArray(jsonObj)}
        />
      ))}
    </>
  );
};

type HighlightedJSONChildProps = {
  propertyName: string;
  value: any;
  isArrayElement: boolean;
};
const HighlightedChildJSON = ({
  value,
  propertyName,
  isArrayElement,
}: HighlightedJSONChildProps) => {
  const [isVisible, setIsVisible] = useState(true);

  let valueType = typeof value;
  const isSimpleValue =
    ["string", "number", "boolean"].includes(valueType) || !value;
  if (isSimpleValue && valueType === "object") {
    valueType = "null" as any;
  }
  const onDoubleClick = useCallback(() => setIsVisible((i) => !i), []);
  const isArray = Array.isArray(value);
  const isObject = !isArray && valueType === "object";
  return (
    <div className={styles["line"]}>
      <span onDoubleClick={onDoubleClick} className={styles["key"]}>
        {!isArrayElement && <>"{propertyName}":</>}
      </span>

      {isSimpleValue ? (
        <span className={styles[valueType]}>
          {JSON.stringify(value)}
          {","}
        </span>
      ) : (
        <>
          {(isArray || isObject) && (
            <>
              {isArray && <>{"["}</>}
              {isObject && <>{"{"}</>}
              {isVisible && (
                <div className={styles["line"]}>
                  <HighlightedJSON jsonObj={value} />
                </div>
              )}
              {!isVisible && <div onDoubleClick={onDoubleClick}>...</div>}
              {isArray && <>{"],"}</>}
              {isObject && <>{"},"}</>}
            </>
          )}
          {!(isArray || isObject) && <HighlightedJSON jsonObj={value} />}
        </>
      )}
    </div>
  );
};
