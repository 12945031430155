import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { BoNotification } from "../types/BoNotification";

type NotificationsContextValue = {
  notifications: BoNotification[];
  addNotification: (notification: BoNotification) => void;
};

const NotificationsContext = createContext<NotificationsContextValue>({
  addNotification: function (notification: BoNotification): void {
    throw new Error("Function not implemented.");
  },
  notifications: [],
});

export const NotificationsContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [notifications, setBoNotifications] = useState<BoNotification[]>([]);
  const addNotification = useCallback((notification: BoNotification) => {
    setBoNotifications((notifications) => {
      notification.date = new Date();
      return [...notifications, notification];
    });
  }, []);
  return (
    <NotificationsContext.Provider value={{ notifications, addNotification }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);
  return context;
};
