import { useMemo } from "react";
import { FilterFieldData } from "../../../components/DynamicFieldData";
import { Thumbnail } from "../../../components/Thumbnail";
import { CustomerLink } from "../../../components/navigation/CustomerLink";
import {
  ArticlesSelection,
  ArticlesSelectionType,
} from "../../../models/ArticlesSelection";
import { ArticlesSelectionsFilters } from "../../../models/filters/ArticlesSelectionsFilters";
import { CreateResourceButton } from "../../../resources/components/buttons/CreateResourceButton";
import { ListPage } from "../../../resources/components/lists/ListPage";
import { PaginationListResource } from "../../../resources/components/lists/PaginationListResource";
import { useResourceUrlPathResolver } from "../../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../../resources/types/resourceName";

const ArticlesSelectionsList = () => {
  const filters: FilterFieldData<ArticlesSelectionsFilters>[] = useMemo(
    () => [
      {
        fieldName: "label",
        type: "text",
        label: "Nom",
        placeholder: "Nom",
      },
    ],
    []
  );
  return (
    <ListPage
      filterFieds={filters}
      actions={
        <>
          <CreateResourceButton params={{ type: ArticlesSelectionType.Manual }}>
            Créer une sélection d'articles manuelle
          </CreateResourceButton>
          <CreateResourceButton
            params={{ type: ArticlesSelectionType.Automatic }}
          >
            Créer une sélection d'articles automatique
          </CreateResourceButton>
        </>
      }
      showChildWithoutFilters
    >
      <PaginationListResource<ArticlesSelection>
        resources={(data) => (
          <div className="row row-cols-3">
            {data?.data?.items &&
              data.data.items.map((a) => (
                <ArticlesSelectionItem key={a.id} a={a} />
              ))}
          </div>
        )}
      />
    </ListPage>
  );
};

const ArticlesSelectionItem = (props: { a: ArticlesSelection }) => {
  const { a } = props;

  const {
    resourceUrlPathResolver: { edit },
  } = useResourceUrlPathResolver<ResourceName>();

  return (
    <div className="col mt-4">
      <div className="card h-100">
        <div className="row no-gutters h-100">
          <div className="col-md-auto">
            <Thumbnail
              url={a.thumbnailUrl}
              className="card-img-left card-thumbnails-left"
            />
          </div>
          <div className="col-md">
            <div className="card-body py-2 px-3 h-100">
              <h6 className="card-title">{a.label}</h6>
              <p className="card-text">
                <small className="text-muted">
                  {a.type === ArticlesSelectionType.Manual
                    ? "Manuelle"
                    : "Automatique"}
                </small>
              </p>
              <CustomerLink
                className="card-link btn btn-sm btn-outline-primary"
                to={edit("animations/articles-selections", a.id)}
              >
                Éditer
              </CustomerLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlesSelectionsList;
