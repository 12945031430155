import Input from "../../components/Input";
import { CustomerLink } from "../../components/navigation/CustomerLink";
import StockLocation from "../../models/StockLocation";
import Store from "../../models/Store";
import { ReadPage } from "../../resources/components/ReadPage";
import { ReadResource } from "../../resources/components/ReadResource";
import { ReadSimpleResource } from "../../resources/components/ReadSimpleResource";
import { useResourceUrlPathResolver } from "../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../resources/types/resourceName";

const StockLocationPage = () => {
  const {
    resourceUrlPathResolver: { edit },
  } = useResourceUrlPathResolver<ResourceName>();
  return (
    <ReadPage>
      <ReadResource<StockLocation>
        resource={(s) => {
          return (
            <>
              <div className="d-flex flex-row gap-2">
                <Input className="col-md-3" label="Id" value={s.id} disabled />
                <Input
                  className="col-md-3"
                  label="Code"
                  value={s.code}
                  disabled
                />
                <Input
                  className="col-md-3"
                  label="Nom"
                  value={s.name}
                  disabled
                />
                {s.storeId && (
                  <div>
                    <label>Magasin</label>
                    <div>
                      <CustomerLink to={edit("stores", s.storeId)}>
                        <ReadSimpleResource<ResourceName, Store>
                          resource={(s) => `${s.name} (${s.code})`}
                          id={s.storeId}
                          resourceName="stores"
                        />
                      </CustomerLink>
                    </div>
                  </div>
                )}
              </div>
            </>
          );
        }}
      />
    </ReadPage>
  );
};

export default StockLocationPage;
