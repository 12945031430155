import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import DeliveryMode from "../../models/DeliveryMode";
import { DeliveryModesFilters } from "../../models/filters/DeliveryModesFilters";
import { CreateResourceButton } from "../../resources/components/buttons/CreateResourceButton";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { useAddEditButton } from "../../resources/hooks/useAddEditButton";

const DeliveryModesPage = () => {
  const filters: FilterFieldData<DeliveryModesFilters>[] = useMemo(
    () => [
      {
        fieldName: "label",
        type: "text",
        label: "Nom",
        placeholder: "Nom",
      },
      {
        fieldName: "code",
        type: "text",
        label: "Code",
        placeholder: "Code",
      },
    ],
    []
  );

  const columns: ResourceColumn<DeliveryMode>[] = useMemo(
    () => [
      {
        header: "Code",
        accessor: "code",
      },
      {
        header: "Nom",
        accessor: "label",
      },
      {
        header: "Frais de port",
        accessor: "price",
        getCell: (_o, value) => value + "€",
      },
      {
        header: "Frais de port",
        accessor: (o) => o,
        getCell: (o) => getConditions(o),
      },
    ],
    []
  );
  const { columnsWithButton } = useAddEditButton({ columns });

  return (
    <ListPage
      filterFieds={filters}
      actions={<CreateResourceButton />}
      showChildWithoutFilters
    >
      <ResourceTable columns={columnsWithButton} />
    </ListPage>
  );
};

export default DeliveryModesPage;

const getConditions = (deliveryMode: DeliveryMode) => {
  var conditions = "";
  if (deliveryMode.cartMinPrice !== null)
    conditions += "Min: " + deliveryMode.cartMinPrice + "€";
  if (deliveryMode.cartMaxPrice !== null)
    conditions +=
      (conditions !== "" ? " - " : "") +
      "Max: " +
      deliveryMode.cartMaxPrice +
      "€ ";
  if (deliveryMode.cartMinWeight !== null)
    conditions +=
      (conditions !== "" ? " - " : "") +
      "Poids min: " +
      deliveryMode.cartMinWeight +
      "gr ";
  if (deliveryMode.cartMaxWeight !== null)
    conditions +=
      (conditions !== "" ? " - " : "") +
      "Poids max: " +
      deliveryMode.cartMaxWeight +
      "gr ";
  return conditions;
};
