import { EditPage } from "../../resources/components/EditPage";
import EditDeliveryModeForm from "./EditDeliveryModeForm";

const EditDeliveryModePage = () => {
  return (
    <EditPage>
      <EditDeliveryModeForm />
    </EditPage>
  );
};

export default EditDeliveryModePage;
