import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { useCurrentCustomer } from "../../LoggedInBackOffice";
import usePowerBIParameters from "../../hooks/statistics/usePowerBIParameters";
import { PageTitle } from "../../resources/components/PageTitle";

// const StatsPage = () => {
//   return <iframe style={{ border: 'none' }} width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=6fc08450-776e-44d6-915d-db1e72395ce7&autoAuth=true&ctid=b1d29e2e-a808-409e-ab2b-27a613d8844b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWZyYW5jZS1jZW50cmFsLWEtcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D"></iframe>
// };

const StatsPage = () => {
  const currentCustomer = useCurrentCustomer();
  const { powerBIParameters } = usePowerBIParameters(currentCustomer);

  return (
    <>
      <PageTitle label="Statistiques" />
      <hr />
      <section id="report-container">
        {powerBIParameters && (
          <PowerBIEmbed
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual and qna
              id: powerBIParameters.reportId,
              embedUrl: powerBIParameters.embedUrl,
              accessToken: powerBIParameters.accessToken,
              tokenType: models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Default,
              },
            }}
            eventHandlers={
              new Map([
                ["loaded", function () {}],
                ["rendered", function () {}],
                ["error", function (event) {}],
              ])
            }
            //  cssClassName = { "report-style-class" }

            // getEmbeddedComponent = { (embeddedReport) => {
            //   if(this!=null){
            //     this.report = embeddedReport as Report;
            //   }

            // }}
          />
        )}
      </section>
    </>
  );
};

export default StatsPage;
