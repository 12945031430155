import { useEffect, useState } from "react";

export type UseControlledPaginationProps = {
  defaultPageIndex?: number;
  defaultPageSize?: number;
};
export const useControlledPagination = ({
  defaultPageIndex = 0,
  defaultPageSize = 10,
}: UseControlledPaginationProps) => {
  const [pageIndex, setPageIndex] = useState(defaultPageIndex);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  useEffect(() => {
    setPageIndex(defaultPageIndex);
  }, [defaultPageIndex]);
  useEffect(() => {
    setPageSize(defaultPageSize);
  }, [defaultPageSize]);

  return { pageIndex, setPageIndex, pageSize, setPageSize };
};
