import { useState } from "react";
import { sanitizeListRestProps } from "../../tools/sanitizeListRestProps";
import { ListLayoutDefaultFitlersProps } from "../components/lists/ListLayout";
import { ResourceFilters } from "../types/resourceFilters";
import { useResourceName } from "./useResourceName";

export type UseListControllerProps<
  TResourceName,
  TResourceFilters extends ResourceFilters
> = ListLayoutDefaultFitlersProps<TResourceFilters> & {
  defaultPageSize?: number;
  resourceName?: TResourceName;
};

export const useListController = <
  TResourceName extends string,
  TResourceFilters extends ResourceFilters
>(
  props: UseListControllerProps<TResourceName, TResourceFilters>
) => {
  const {
    defaultFilters = {} as TResourceFilters,
    defaultPageSize,
    ...otherProps
  } = props;
  const defaultPageIndex = 0;
  const [filters, setFilters] = useState<TResourceFilters>(defaultFilters);

  const sanProps = sanitizeListRestProps(otherProps, ["resourceName"]);

  const { resourceName } = useResourceName(sanProps);
  return {
    defaultPageIndex,
    defaultPageSize,
    filters,
    setFilters,
    resourceName,
  };
};
