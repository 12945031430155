import { useCallback, useEffect, useMemo, useState } from "react";
import { ActionMeta, GroupBase, OnChangeValue, PropsValue } from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import ReactSelectOption, {
  ReactSelectOptionValueType,
} from "../ReactSelectOption";

export type UseSimpleSelectController<
  TReactSelectOptionValueType extends ReactSelectOptionValueType,
  IsMulti extends boolean
> = StateManagerProps<
  ReactSelectOption<TReactSelectOptionValueType>,
  IsMulti,
  GroupBase<ReactSelectOption<TReactSelectOptionValueType>>
> & {
  idPropsValue:
    | TReactSelectOptionValueType
    | TReactSelectOptionValueType[]
    | null
    | undefined;
  onIdPropsValueChanged: (
    s:
      | TReactSelectOptionValueType
      | TReactSelectOptionValueType[]
      | undefined
      | null
  ) => void;
  // idPropsValue: PropsValue<TReactSelectOptionValueType>;
  // onIdPropsValueChanged: (
  //   s: PropsValue<TReactSelectOptionValueType> | undefined
  // ) => void;
  autoSelectFirst?: boolean;
};

export const useSimpleSelectController = <
  TReactSelectOptionValueType extends ReactSelectOptionValueType,
  IsMulti extends boolean
>(
  props: UseSimpleSelectController<TReactSelectOptionValueType, IsMulti>
) => {
  const { onIdPropsValueChanged, idPropsValue, isMulti, autoSelectFirst } =
    props;

  const [value, setValue] = useState<
    PropsValue<ReactSelectOption<TReactSelectOptionValueType>> | undefined
  >();

  const placeholder = useMemo(() => "Sélectionner", []);
  const isClearable = useMemo(() => true, []);
  const noOptionsMessage = useCallback(() => "Aucun élément", []);
  const loadingMessage = useCallback(() => "Chargement...", []);

  const changeValue = useCallback(
    (
      newValue: React.SetStateAction<
        PropsValue<ReactSelectOption<TReactSelectOptionValueType>> | undefined
      >
    ) => {
      setValue(newValue);

      if (onIdPropsValueChanged != null) {
        if (newValue != null) {
          if (Array.isArray(newValue)) {
            const newMultipleValue =
              newValue as ReactSelectOption<TReactSelectOptionValueType>[];
            if (isMulti) {
              onIdPropsValueChanged(newMultipleValue!.map((nv) => nv.value!));
            } else {
              onIdPropsValueChanged(
                (newMultipleValue.length > 0 ? newMultipleValue[0] : undefined)
                  ?.value
              );
            }
          } else {
            const newSingleValue =
              newValue as ReactSelectOption<TReactSelectOptionValueType>;
            if (isMulti) {
              onIdPropsValueChanged([newSingleValue.value!]);
            } else {
              onIdPropsValueChanged(newSingleValue.value);
            }
          }
        } else {
          onIdPropsValueChanged(newValue);
        }
      }
    },
    [isMulti, onIdPropsValueChanged]
  );

  const onChange = useCallback(
    (
      newValue: OnChangeValue<
        ReactSelectOption<TReactSelectOptionValueType>,
        IsMulti
      >,
      actionMeta: ActionMeta<ReactSelectOption<TReactSelectOptionValueType>>
    ) => {
      changeValue(newValue);
    },
    [changeValue]
  );

  useEffect(() => {
    if (idPropsValue == null) {
      if (value != null) {
        changeValue(null);
      }
    }
  }, [idPropsValue, changeValue, value]);

  const [firstTtime, setFirstTime] = useState(true);

  useEffect(() => {
    if (value != null) {
      setFirstTime(false);
    }
  }, [value]);

  const isWaitingForDefaultValue = useMemo(() => {
    return (
      firstTtime && value == null && (idPropsValue != null || autoSelectFirst)
    );
  }, [autoSelectFirst, firstTtime, idPropsValue, value]);

  return {
    onChange,
    value,
    noOptionsMessage,
    loadingMessage,
    placeholder,
    isClearable,
    setValue: changeValue,
    idPropsValue,
    isWaitingForDefaultValue,
  };
};
