import Device from "../../models/Device";
import { CreatePage } from "../../resources/components/CreatePage";
import EditDeviceForm from "./components/EditDeviceForm";

const CreateDevicePage = () => {
  return (
    <CreatePage<Device>>
      <EditDeviceForm mode="create" />
    </CreatePage>
  );
};

export default CreateDevicePage;
