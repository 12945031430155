import { Col, Row, Table } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import Input from "../../components/Input";
import { ControlledDevicesSelect } from "../../components/selects/DevicesSelect";
import { ControlledLogisticOperationStatusesSelect } from "../../components/selects/LogisticOperationStatusesSelect";
import { ControlledLogisticOperationTypesSelect } from "../../components/selects/LogisticOperationTypesSelect";
import { ControlledStoresSelect } from "../../components/selects/StoresSelect";
import {
  LogisticOperation,
  LogisticOperationType,
} from "../../models/LogisticOperation";
import { ReadResource } from "../../resources/components/ReadResource";
import { ResourceHiddenNumberInput } from "../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceInput } from "../../resources/components/inputs/ResourceInput";
import { getDateTime } from "../../tools/dateUtils";

const EditLogisticOperationForm = () => {
  const { watch } = useFormContext<LogisticOperation>();
  const type = Number(watch("type"));
  return (
    <ReadResource<LogisticOperation>
      resource={(s: LogisticOperation) => {
        return (
          <>
            <Row>
              <ResourceHiddenNumberInput<LogisticOperation> name="id" />
              <Col md={6}>
                <ControlledStoresSelect<LogisticOperation>
                  label="Magasin"
                  name="storeId"
                />
              </Col>
              <Col md={6}>
                <ControlledDevicesSelect<LogisticOperation>
                  label="Périphérique"
                  name="deviceId"
                />
              </Col>
              <Col md={3}>
                <ControlledLogisticOperationTypesSelect<LogisticOperation>
                  label="Type"
                  name="type"
                  rules={{ required: "Le type est obligatoire" }}
                />
              </Col>
              <Input
                className="col-md-3"
                label="Date"
                value={getDateTime(s.date)}
                disabled
              />
              {type && type === LogisticOperationType.Inventory && (
                <Col md={3}>
                  <ResourceInput<LogisticOperation> name="area" label="Zone" />
                </Col>
              )}
              <Col md={3}>
                <ControlledLogisticOperationStatusesSelect<LogisticOperation>
                  label="Statut"
                  name="status"
                  rules={{ required: "Le statut est obligatoire" }}
                />
              </Col>
            </Row>
            <>
              {s.references && (
                <Table responsive size="sm" hover>
                  <thead>
                    <tr>
                      <td>Ligne</td>
                      <td>Code barre</td>
                      <td>Référence</td>
                      <td>Quantité</td>
                    </tr>
                  </thead>
                  <tbody>
                    {s.references.map((r) => (
                      <tr key={r.line}>
                        <td>{r.line}</td>
                        <td>{r.barCode}</td>
                        <td>{r.reference}</td>
                        <td>{r.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </>
          </>
        );
      }}
    />
  );
};

export default EditLogisticOperationForm;
