import { ProviderProps, createContext, useContext } from "react";
import { KeyedMutator } from "swr";
import { PaginationProps } from "../../resources/components/lists/PaginationProps";
import { Resource } from "../../resources/types";
import { ListReturn } from "../types/dataProvider";

type ResourceListContextValue<TResource = any> = PaginationProps & {
  data?: ListReturn<TResource>;
  error?: any;
  mutate?: KeyedMutator<ListReturn<TResource> | undefined>;
  isLoading?: boolean;
};

const ResourceListContext = createContext<ResourceListContextValue>({});

export const ResourceListContextProvider = <TResource,>({
  children,
  value,
}: ProviderProps<ResourceListContextValue<TResource>>) => (
  <>
    {value ? (
      <ResourceListContext.Provider
        value={value as ResourceListContextValue<Resource>}
      >
        {children}
      </ResourceListContext.Provider>
    ) : (
      children
    )}
  </>
);

export type UseResourceListProps<T> = ResourceListContextValue<T>;

export const useResourceListContext = <TResource,>(
  props?: UseResourceListProps<TResource>
) => {
  const context = useContext(ResourceListContext);
  return (
    props != null && Object.entries(props).length ? props : context
  ) as ResourceListContextValue<TResource>;
};
