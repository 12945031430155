export const convertToURLSearchParams = (params?: Record<string, any>) => {
  if (params != null) {
    let searchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(params).filter(
      ([, value]) => value != null
    )) {
      if (Array.isArray(value)) {
        for (const v of value) {
          searchParams.append(key, v);
        }
      } else {
        searchParams.append(key, value);
      }
    }

    return searchParams;
  }
};
