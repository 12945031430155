import { ApiError } from "../models/ApiError";
import PagedItems from "../models/PagedItems";
import { DataProvider } from "../resources/types/dataProvider";
import { ResourceApiPathResolver } from "../resources/types/resourceApiPathResolver";

type ApiFetcher = (
  input: RequestInfo | URL,
  init?: RequestInit
) => Promise<Response>;

type HttpMethod =
  | "GET"
  | "HEAD"
  | "POST"
  | "PUT"
  | "DELETE"
  | "CONNECT"
  | "OPTIONS"
  | "TRACE";

export function getResourceRestDataProvider<
  TResourceName extends string,
  TResource
>(
  apiFetcher: ApiFetcher,
  resourceApiPathResolver: ResourceApiPathResolver
): DataProvider<TResourceName, TResource> {
  return {
    list: async ({ resourceName, signal, ...otherProps }) => {
      const url = resourceApiPathResolver.listUrl(resourceName, otherProps);
      const response = await httpClient<PagedItems<TResource>>(
        apiFetcher,
        url,
        undefined,
        "GET",
        signal
      );
      if (response == null) {
        throw Error("Unexpected null response");
      }
      return response;
    },
    create: async ({ resourceName, data }) => {
      const url = resourceApiPathResolver.createUrl(resourceName);
      const body = JSON.stringify(data);
      const response = await httpClient<TResource>(
        apiFetcher,
        url,
        body,
        "POST"
      );
      if (response == null) {
        throw Error("Unexpected null response");
      }
      return response;
    },
    update: async ({ resourceName, id, data }) => {
      const url = resourceApiPathResolver.updateUrl(resourceName, id);
      const body = JSON.stringify(data);
      const response = await httpClient<TResource>(
        apiFetcher,
        url,
        body,
        "PUT"
      );
      if (response == null) {
        throw Error("Unexpected null response");
      }
      return response;
    },
    read: async ({ resourceName, id }) => {
      const url = resourceApiPathResolver.readUrl(resourceName, id);
      const response = await httpClient<TResource>(
        apiFetcher,
        url,
        undefined,
        "GET"
      );
      if (response == null) {
        throw Error("Unexpected null response");
      }
      return response;
    },
    delete: async ({ resourceName, id }) => {
      const url = resourceApiPathResolver.deleteUrl(resourceName, id);
      const response = await httpClient<TResource>(
        apiFetcher,
        url,
        undefined,
        "DELETE"
      );
      if (response == null) {
        return {};
      }
      return response;
    },
    deleteAll: async ({ resourceName }: { resourceName: TResourceName }) => {
      const url = resourceApiPathResolver.deleteAllUrl(resourceName);
      const response = await httpClient<TResource>(
        apiFetcher,
        url,
        undefined,
        "DELETE"
      );
      if (response == null) {
        return {};
      }
      return response;
    },
  };
}

async function httpClient<TResourceType>(
  apiFetcher: ApiFetcher,
  url: string,
  body: string | undefined,
  httpMethod: HttpMethod,
  signal?: AbortSignal
): Promise<
  { data: TResourceType } | undefined
  // | PromiseLike<{ data: TResourceType } | undefined>
> {
  try {
    const response = await apiFetcher(url, {
      method: httpMethod,
      body,
      signal,
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    });

    if (!response.ok) {
      let apiException: ApiError;
      try {
        apiException = (await response.json()) as ApiError;
      } catch (error) {
        throw new Error("Unable to handle json error response");
      }
      throw apiException;
    } else {
      const contentType = response.headers.get("content-type");
      if (contentType == null || contentType.indexOf("application/json") < 0) {
        return;
      }
      const json = (await response.json()) as TResourceType;
      if (!json) {
        throw Error("Unable to handle json response");
      }

      return { data: json };
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}
