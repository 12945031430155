import { CreatePage } from "../../../resources/components/CreatePage";
import { EditOrCreateArticlesSelectionForm } from "./components/EditArticlesSelectionForm";
import { useArticlesSelectionController } from "./hooks/useArticlesSelectionController";

const CreateArticlesSelectionPage = () => {
  const res = useArticlesSelectionController();

  return (
    <CreatePage {...res}>
      <EditOrCreateArticlesSelectionForm />
    </CreatePage>
  );
};

export default CreateArticlesSelectionPage;
