// import { EditPage } from "../../../resources/components/EditPage";
import AdMedia from "../../../models/AdMedia";
import { EditPage } from "../../../resources/components/EditPage";
import { useUploadMediaController } from "../../../resources/hooks/useUploadMediaController";
import EditOrCreateAdMediaForm from "./components/EditOrCreateAdMediaForm";

const EditAdMediaPage = () => {
  const { transformAfterSubmit, transformBeforeSubmit } =
    useUploadMediaController<AdMedia>({
      imageField: "mediaUrl",
    });
  // const transformBeforeSubmit = useCallback((adMedia: AdMedia) => {
  //   adMedia.targetType = Number(adMedia.targetType);
  //   return adMedia;
  // }, []);
  return (
    <EditPage<AdMedia>
      transformBeforeSubmit={transformBeforeSubmit}
      transformAfterSubmit={transformAfterSubmit}
    >
      <EditOrCreateAdMediaForm />
    </EditPage>
  );
};

export default EditAdMediaPage;
