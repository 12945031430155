import React, { useCallback, useRef, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import FormGroup from "react-bootstrap/esm/FormGroup";
import Spinner from "react-bootstrap/esm/Spinner";

const UploadImageForm = (props: {
  onSubmit: (file: File) => Promise<boolean>;
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const [hasPendingFile, setHasPendingFile] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      const current = fileInput?.current;
      const files = current?.files;
      if (files != null && files.length > 0) {
        setIsBusy(true);
        const result = await props.onSubmit(files[0]);
        if (result) {
          current!.value = "";
        }
        setIsBusy(false);
      }
    },
    [props]
  );

  const updateHasPendingFile = useCallback(() => {
    setHasPendingFile(
      fileInput.current?.value !== undefined && fileInput.current?.value !== ""
    );
  }, []);

  return (
    <Form className="d-flex flex-row gap-2" onSubmit={handleSubmit}>
      <FormGroup>
        <Form.Control
          type="file"
          ref={fileInput}
          accept="image/*"
          onChange={() => updateHasPendingFile()}
        />
      </FormGroup>
      <Button
        disabled={!hasPendingFile}
        type="submit"
        variant="dark"
        className="ms-2"
      >
        {isBusy ? (
          <Spinner animation="border" size="sm" className="mx-1" />
        ) : (
          ""
        )}
        Valider
      </Button>
    </Form>
  );
};

export default UploadImageForm;
