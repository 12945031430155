export interface LogisticOperation {
  id: number;
  deviceId: string;
  storeId: number;
  date: string;
  type: LogisticOperationType;
  status: LogisticOperationStatus;
  area: string;
  references: OperationReference[];
}

export interface OperationReference {
  line: number;
  barCode: string;
  reference: string;
  quantity: number;
}
export enum LogisticOperationType {
  Unknown = 0,
  Labelling = 1,
  Receipt = 2,
  Inventory = 3,
}

export enum LogisticOperationStatus {
  Validated = 0,
  Archived = 1,
  Deleted = 2,
}
