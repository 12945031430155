import { useMemo } from "react";
import { ImportFileType } from "../../models/ImportFileType";
import ImportJobDefinitionEntity from "../../models/ImportJobDefinitionEntity";
import { CategoriesFilters } from "../../models/filters/CategoriesFilters";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { ResourceName } from "../../resources/types/resourceName";

const ImportJobDefinitionEntitiesPage = () => {
  const columns: ResourceColumn<ImportJobDefinitionEntity>[] = useMemo(
    () => [
      {
        header: "FullyQualifiedJobClassName",
        accessor: (a) => a.importJobDefinition?.fullyQualifiedJobClassName,
      },
      {
        header: "RequiredFiles",
        accessor: (a) => a.importJobDefinition?.requiredFiles,
        getCell: (v) => (
          <table className="table-sm">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Regex</th>
                <th scope="col">MultipleFilesAccepted</th>
              </tr>
            </thead>
            <tbody>
              {v.importJobDefinition?.requiredFiles?.map((file, index) => (
                <tr key={index}>
                  <td>{convertImportFileType(file.importFileType)}</td>
                  <td> {file.fileNameRule?.pattern} </td>
                  <td>{JSON.stringify(file.multipleFilesAccepted)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ),
      },
      {
        header: "Version",
        accessor: "version",

        // navigationPath: (_s, value) =>
        //   value != null ? edit("stores", value) : undefined,
      },
    ],
    []
  );
  return (
    <ListPage<ResourceName, ImportJobDefinitionEntity, CategoriesFilters>
      showChildWithoutFilters
    >
      <ResourceTable columns={columns} />
    </ListPage>
  );
};

export default ImportJobDefinitionEntitiesPage;

function convertImportFileType(
  importFileType: ImportFileType | undefined
): string | undefined {
  if (importFileType !== undefined) {
    return ImportFileType[importFileType].toString();
  }
}
