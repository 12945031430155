import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Button } from "react-bootstrap";
import {
  AutoSizerProps,
  CellMeasurerCache,
  CellMeasurerProps,
  ListProps,
  ListRowProps,
  AutoSizer as _AutoSizer,
  CellMeasurer as _CellMeasurer,
  List as _List,
} from "react-virtualized";
import { useCurrentCustomer } from "../../../LoggedInBackOffice";
import { AsyncHandler } from "../../../components/AsyncHandler";
import { Loading } from "../../../components/types/loading";
import useImportJobStatusInfos from "../../../hooks/synchronizations/useImportJobStatusInfos";
import {
  ImportJobStatusInfo,
  StatusType,
} from "../../../models/Synchronization";
import { getTimeWithoutSecondsDate } from "../../../tools/dateUtils";

const List = _List as unknown as FC<ListProps>;
const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;
const CellMeasurer = _CellMeasurer as unknown as FC<CellMeasurerProps>;

export interface ImportJobStatusInfoListProps {
  synchronizationId?: string;
}

const getImportJobStatusInfoClass = (h: ImportJobStatusInfo) => {
  if (h.statusType === StatusType.Error) {
    return "list-group-item-danger";
  } else if (h.statusType === StatusType.Warning) {
    return "list-group-item-warning";
  }
};

// Use to virtualize the large JobStatus list.
const VirtualizedImportJobStatusInfoList = React.memo(function (props: {
  list: ImportJobStatusInfo[];
}) {
  const { list } = props;

  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        defaultHeight: 41,
        fixedHeight: false,
        fixedWidth: true,
      }),
    []
  );

  const rowRenderer = useCallback(
    ({ index, key, parent, style }: ListRowProps) => {
      const h = list[index];
      return (
        <>
          <CellMeasurer
            cache={cache}
            columnIndex={0}
            parent={parent}
            key={key}
            rowIndex={index}
          >
            <li
              style={style}
              className={
                "list-group-item py-2 " + getImportJobStatusInfoClass(h)
              }
            >
              {getTimeWithoutSecondsDate(h.statusDate)} - {h.message}
            </li>
          </CellMeasurer>
        </>
      );
    },
    [list, cache]
  );

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          deferredMeasurementCache={cache}
          width={width}
          height={500}
          rowRenderer={rowRenderer}
          rowHeight={cache.rowHeight}
          rowCount={list.length}
        ></List>
      )}
    </AutoSizer>
  );
});
export type ImportJobStatusInfoListHandle = {
  refresh: () => void;
};
const ImportJobStatusInfoList = forwardRef<
  ImportJobStatusInfoListHandle,
  ImportJobStatusInfoListProps
>((props, ref) => {
  const { synchronizationId } = props;
  const currentCustomer = useCurrentCustomer();

  const {
    mutate: refreshImportJobStatusInfos,
    error,
    importJobStatusInfos: statusHistory,
    isLoading,
  } = useImportJobStatusInfos(currentCustomer, synchronizationId);

  useImperativeHandle(ref, () => ({
    refresh() {
      refreshImportJobStatusInfos();
    },
  }));

  const [showAllHistory, setShowAllHistory] = useState<boolean>(false);
  const [statusToShow, setStatusToShow] = useState<ImportJobStatusInfo[]>([]);

  const [{ errorCount, warningCount }, setErrorAndWarningCount] = useState<{
    errorCount: number;
    warningCount: number;
  }>({ errorCount: 0, warningCount: 0 });

  useEffect(() => {
    setErrorAndWarningCount({
      errorCount:
        statusHistory != null
          ? statusHistory.filter((s) => s.statusType === StatusType.Error)
              .length
          : 0,
      warningCount:
        statusHistory != null
          ? statusHistory.filter((s) => s.statusType === StatusType.Warning)
              .length
          : 0,
    });
  }, [statusHistory]);

  //useLayoutEffect pour eviter de rendre toute la liste au moment ou l'on clique sur "Voir moins"...
  useLayoutEffect(() => {
    if (statusHistory != null) {
      const stmp =
        showAllHistory || statusHistory.length <= 8
          ? statusHistory.slice(0)
          : statusHistory.slice(statusHistory.length - 8);
      stmp.reverse();
      setStatusToShow(stmp);
    }
  }, [statusHistory, showAllHistory]);

  let list;
  if (!showAllHistory) {
    list = (
      <>
        {statusToShow.map((h, i) => {
          return (
            <li
              key={i}
              className={
                "list-group-item py-2 " + getImportJobStatusInfoClass(h)
              }
            >
              {getTimeWithoutSecondsDate(h.statusDate)} - {h.message}
            </li>
          );
        })}
      </>
    );
  } else {
    list = <VirtualizedImportJobStatusInfoList list={statusToShow} />;
  }
  return (
    <div className="card">
      <div className="card-header d-flex">
        <span className="flex-fill">Historique</span>
        {statusHistory != null && (
          <span>
            {warningCount !== 0 && (
              <Badge bg="warning">{warningCount} warnings</Badge>
            )}
            &nbsp;
            {errorCount !== 0 && (
              <Badge bg="danger">{errorCount} erreurs</Badge>
            )}
          </span>
        )}
      </div>
      <AsyncHandler
        errorMessage="Une erreur est survenue lors de l'historique."
        refresh={refreshImportJobStatusInfos}
        error={error}
        isLoading={isLoading}
        loading={Loading.Circle}
      >
        <ul className="list-group list-group-flush">
          {list}

          <li className="list-group-item d-grid gap-2">
            <Button
              variant="link"
              onClick={() => setShowAllHistory(!showAllHistory)}
            >
              {showAllHistory
                ? "🔼 Voir moins"
                : "🔽 Voir tout l’historique..."}
            </Button>
          </li>
        </ul>
      </AsyncHandler>
    </div>
  );
});

export default ImportJobStatusInfoList;
