import { useCallback, useContext, useState } from "react";
import Alert from "react-bootstrap/esm/Alert";
import Button from "react-bootstrap/esm/Button";
import { MdDeleteForever } from "react-icons/md";
import { LoggedInUserContext } from "../../LoggedInBackOffice";
import UploadImageForm from "../../components/UploadImageForm";
import { CustomerLink } from "../../components/navigation/CustomerLink";
import useCustomerApiFetch from "../../hooks/useCustomerApiFetch";
import Category from "../../models/Category";
import { ReadPage } from "../../resources/components/ReadPage";
import { ReadResource } from "../../resources/components/ReadResource";
import { useResourceContext } from "../../resources/hooks/useResourceContext";
import { useNumberResourceParams } from "../../resources/hooks/useResourceParams";
import { isNullOrUndefined } from "../../tools/isNullOrUndefined";
import uploadCategoryImage from "../../tools/uploadCategoryImage";

const EditCategoryPage = () => {
  return (
    <ReadPage>
      <EditCategoryForm />
    </ReadPage>
  );
};

export default EditCategoryPage;

type EditCategoryFormProps = {};
export const EditCategoryForm = (props: EditCategoryFormProps) => {
  const { currentCustomer } = useContext(LoggedInUserContext);
  const { id } = useNumberResourceParams();
  const { mutate } = useResourceContext<Category>();
  const apiFetcher = useCustomerApiFetch();

  const [showWarning, SetShowWarning] = useState(false);
  const onNewImageSubmit = useCallback(
    async (file?: File) => {
      try {
        if (currentCustomer && id) {
          const category = await uploadCategoryImage(id, apiFetcher, file);
          mutate && mutate({ data: category });
          SetShowWarning(true);
          return true;
        }
      } catch {}

      return false;
    },
    [currentCustomer, id, apiFetcher, mutate]
  );

  const onDeleteCurrentImage = useCallback(async () => {
    if (window.confirm("Etes-vous sûr de vouloir supprimer cette image ?")) {
      await onNewImageSubmit();
    }
  }, [onNewImageSubmit]);

  return (
    <>
      <Alert variant="warning" dismissible show={showWarning}>
        Les modifications seront visibles après la prochaine synchronisation
      </Alert>
      <ReadResource<Category>
        resource={(category: Category) => (
          <>
            <h5>{category.label}</h5>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Code</th>
                  <td>{category.code}</td>
                </tr>
                <tr>
                  <th>Catégorie parent</th>
                  <td>
                    {isNullOrUndefined(category.parentId) ? (
                      <CustomerLink
                        className="btn btn-sm btn-outline-primary"
                        to="/categories"
                      >
                        Aucune (Racine)
                      </CustomerLink>
                    ) : (
                      <CustomerLink
                        className="btn btn-sm btn-outline-primary"
                        to={"/categories/edit/" + category.parentId}
                      >
                        Voir
                      </CustomerLink>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Ranking</th>
                  <td>{category.ranking}</td>
                </tr>
                <tr>
                  <th>Image</th>
                  <td>
                    {category.imageUrl && (
                      <div style={{ position: "relative" }}>
                        <img
                          src={category.imageUrl}
                          alt="..."
                          className="img-fluid img-thumbnail"
                        />
                        <div
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            left: "10px",
                          }}
                        >
                          <Button
                            disabled={isNullOrUndefined(category.imageUrl)}
                            onClick={() => onDeleteCurrentImage()}
                            className="btn btn-light btn-outline-dark btn-sm"
                          >
                            <MdDeleteForever /> Supprimer
                          </Button>
                        </div>
                      </div>
                    )}
                    <div className="mt-1">
                      <UploadImageForm onSubmit={onNewImageSubmit} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      />
    </>
  );
};
