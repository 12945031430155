import React, { useCallback } from "react";
import { Alert, Button } from "react-bootstrap";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { MdAdd } from "react-icons/md";
import Input from "../../components/Input";
import Store, { OpeningHours } from "../../models/Store";

const dayOfTheWeek = [
  { v: 1, l: "Lundi" },
  { v: 2, l: "Mardi" },
  { v: 3, l: "Mercredi" },
  { v: 4, l: "Jeudi" },
  { v: 5, l: "Vendredi" },
  { v: 6, l: "Samedi" },
  { v: 0, l: "Dimanche" },
];

const DayOfTheWeekSelect = React.forwardRef<
  HTMLSelectElement,
  React.SelectHTMLAttributes<any>
>((props, ref) => {
  return (
    <select className="form-control" ref={ref} {...props}>
      {dayOfTheWeek.map((v, i) => (
        <option key={v.v} value={v.v} label={v.l} />
      ))}
    </select>
  );
});

const OpeningHoursFields = () => {
  const {
    register,
    control,
    formState: { errors, isSubmitting },
  } = useFormContext<Store>();
  const { fields, remove, append } = useFieldArray({
    control: control,
    name: "openingHours",
  });

  const openingHours = useWatch({
    control,
    name: "openingHours",
    defaultValue: [],
  }) as OpeningHours[];

  const addDay = useCallback(() => {
    if (!openingHours || openingHours.length === 0) {
      append({ day: 1 }); // Lundi par default
    } else {
      const lastF = openingHours[openingHours.length - 1];
      let newDay =
        typeof lastF.day === "number"
          ? lastF.day
          : parseInt(lastF.day as string);
      newDay = newDay + 1;

      if (newDay >= 7) {
        newDay = 0;
      }
      append({
        day: newDay,
        openingHour1: lastF.openingHour1,
        closingHour1: lastF.closingHour1,
        openingHour2: lastF.openingHour2,
        closingHour2: lastF.closingHour2,
      });
    }
  }, [append, openingHours]);

  return (
    <div>
      <label>Horaires</label>
      <div>
        <Button variant="outline-primary" onClick={() => addDay()}>
          <MdAdd />
          Ajouter une plage d'horaires
        </Button>
      </div>
      <br />
      {fields && fields.length === 0 && (
        <Alert variant="info">Aucune plage d'horaires</Alert>
      )}
      {fields && fields.length !== 0 && (
        <table className="table table-sm mb-0   ">
          <thead>
            <tr>
              <th>Jour de la semaine</th>
              <th>Heure d'ouverture (1)</th>
              <th>Heure de fermeture (1)</th>
              <th>Heure d'ouverture (2)</th>
              <th>Heure de fermeture (2)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {fields.map((v, i) => {
              // const hours = v as OpeningHours;
              return (
                <tr key={v.id}>
                  <td>
                    <DayOfTheWeekSelect
                      {...register(`openingHours.${i}.day` as const, {
                        valueAsNumber: true,
                      })}
                      disabled={isSubmitting}
                      defaultValue={v.day}
                    />
                  </td>
                  <td>
                    <Input
                      type="time"
                      {...register(`openingHours.${i}.openingHour1` as const, {
                        required: true,
                      })}
                      errorMessage="L'heure d'ouverture est invalide."
                      isInvalid={
                        errors &&
                        errors.openingHours &&
                        errors.openingHours[i] &&
                        errors.openingHours[i]?.openingHour1 !== undefined
                      }
                      disabled={isSubmitting}
                      defaultValue={v.openingHour1}
                    />
                  </td>
                  <td>
                    <Input
                      type="time"
                      {...register(`openingHours.${i}.closingHour1` as const, {
                        required: true,
                      })}
                      errorMessage="L'heure de fermeture est invalide."
                      isInvalid={
                        errors &&
                        errors.openingHours &&
                        errors.openingHours[i] &&
                        errors.openingHours[i]?.closingHour1 !== undefined
                      }
                      disabled={isSubmitting}
                      defaultValue={v.closingHour1}
                    />
                  </td>
                  <td>
                    <Input
                      type="time"
                      {...register(`openingHours.${i}.openingHour2` as const)}
                      disabled={isSubmitting}
                      defaultValue={v.openingHour2}
                    />
                  </td>
                  <td>
                    <Input
                      type="time"
                      {...register(`openingHours.${i}.closingHour2` as const)}
                      disabled={isSubmitting}
                      defaultValue={v.closingHour2}
                    />
                  </td>
                  <td>
                    <Button variant="light" onClick={() => remove(i)}>
                      ✖
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default OpeningHoursFields;
