export default interface ServiceExtensionDefinition {
  id: string;
  fullyQualifiedClassName: string;
  extensionModes: ExtensionModes;
  extensionType: ExtensionType;
}

export enum ExtensionModes {
  None = 0,
  PostExtension = 1,
  PreExtension = 2,
  ReplaceExtension = 4,
}

export enum ExtensionType {
  ValidateOrder = 0,
  AddCartLine = 1,
  UpdateCartLine = 2,
  GetStockInfos = 3,
  CreateOrder = 4,
  ChangeOrderStatus = 5,
  CreateLogisticOperation = 6,
}
