import { useMemo } from "react";
import { useParams } from "react-router-dom";

type UseResourceParamsProps<T> = { id: T };

export const useResourceParams = () =>
  useParams<UseResourceParamsProps<string>>();

export const useNumberResourceParams = () => {
  const { id: idString } = useResourceParams();
  const id = useMemo(() => {
    return idString != null ? parseInt(idString) : undefined;
  }, [idString]);
  return { id } as UseResourceParamsProps<number>;
};
