import InteractiveCatalog from "../../../models/InteractiveCatalog";
import { CreatePage } from "../../../resources/components/CreatePage";
import { ResourceInput } from "../../../resources/components/inputs/ResourceInput";

const CreateInteractiveCatalogPage = () => {
  return (
    <CreatePage>
      <ResourceInput<InteractiveCatalog>
        name="label"
        label="Nom du catalogue"
        options={{ required: true }}
      />
    </CreatePage>
  );
};

export default CreateInteractiveCatalogPage;
