import { Col } from "react-bootstrap";
import FormGroup from "react-bootstrap/esm/FormGroup";
import { useFormContext } from "react-hook-form";
import Input from "../../../components/Input";
import { Attribute } from "../../../models/Attribute";
import { AttributeVisibility } from "../../../models/AttributeVisibility";
import { ResourceHiddenNumberInput } from "../../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceInput } from "../../../resources/components/inputs/ResourceInput";
import { displayAttributeType } from "../displayAttributeType";

export const EditAttributeForm = () => {
  const { register, watch, getValues } = useFormContext();

  const visibility = Number(watch("visibility"));
  const isFacet = watch("isFacet");
  const isDifferantiating = watch("isDifferantiating");

  return (
    <>
      <ResourceHiddenNumberInput<Attribute> name="id" />
      <ResourceInput<Attribute>
        label="Libellé"
        name="name"
        readOnly={true}
        disabled={true}
      />
      <div className="row">
        <Col md={6}>
          <ResourceInput<Attribute>
            label="Code"
            name="code"
            readOnly={true}
            disabled={true}
          />
        </Col>
        <Col md={3}>
          <ResourceInput<Attribute>
            label="Ordre"
            name="sortOrder"
            readOnly={true}
            disabled={true}
          />
        </Col>
        <Col md={3}>
          <Input
            label="Type"
            defaultValue={displayAttributeType(getValues("type"))}
            readOnly={true}
            disabled={true}
          />
        </Col>
      </div>

      <FormGroup>
        <label>Visibilité*</label>
        <div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              {...register("visibility", {
                required: true,
                valueAsNumber: true,
              })}
              type="radio"
              id="visibility0"
              value={AttributeVisibility.None}
              checked={visibility === AttributeVisibility.None}
              disabled={isFacet || isDifferantiating}
            />
            <label className="form-check-label" htmlFor="visibility0">
              Aucune
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              {...register("visibility", {
                required: true,
                valueAsNumber: true,
              })}
              type="radio"
              id="visibility1"
              defaultChecked={visibility === AttributeVisibility.Vendor}
              value={AttributeVisibility.Vendor}
            />
            <label className="form-check-label" htmlFor="visibility1">
              Vendeur
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              {...register("visibility", {
                required: true,
                valueAsNumber: true,
              })}
              type="radio"
              id="visibility2"
              defaultChecked={visibility === AttributeVisibility.Public}
              value={AttributeVisibility.Public}
            />
            <label className="form-check-label" htmlFor="visibility2">
              Publique
            </label>
          </div>
        </div>
      </FormGroup>
      <FormGroup>
        <div className="form-check">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              {...register("isFacet")}
              // defaultChecked={resource?.isFacet}
              disabled={Number(visibility) === 0}
            />
            Afficher dans les filtres de recherche
          </label>
        </div>
      </FormGroup>
      <FormGroup>
        <div className="form-check">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              {...register("isDifferantiating")}
              // defaultChecked={resource?.isDifferantiating}
              disabled={Number(visibility) === 0}
            />
            Attribut de déclinaison
          </label>
        </div>
      </FormGroup>
    </>
  );
};
