import { CreatePage } from "../../resources/components/CreatePage";
import EditBroadcastListForm from "./EditBroadcastListForm";

const CreateBroadcastListPage = () => {
  return (
    <CreatePage>
      <EditBroadcastListForm />
    </CreatePage>
  );
};

export default CreateBroadcastListPage;
