import { useControlledPaginationSWRListResource } from "../../../resources/hooks/useControlledPaginationSWRListResource";
import {
  UseListControllerProps,
  useListController,
} from "../../../resources/hooks/useListController";
import { ResourceFilters } from "../../../resources/types/resourceFilters";
import { ListLayout, ListLayoutProps } from "./ListLayout";

type ListViewProps<
  TResourceName extends string,
  TResourceFilters extends ResourceFilters
> = ListLayoutProps<TResourceFilters> &
  UseListControllerProps<TResourceName, TResourceFilters>;

export const ListView = <
  TResourceName extends string = string,
  TResource = any,
  TResourceFilters extends ResourceFilters = ResourceFilters
>(
  props: ListViewProps<TResourceName, TResourceFilters>
) => {
  const {
    resourceName,
    defaultPageIndex,
    defaultPageSize = 10,
    ...otherListController
  } = useListController(props);
  const { filters } = otherListController;

  const listResources = useControlledPaginationSWRListResource<
    string,
    TResource
  >({
    resourceName,
    filters,
    defaultPageSize,
    defaultPageIndex,
  });

  return (
    <ListLayout<TResource, TResourceFilters>
      {...props}
      {...listResources}
      {...otherListController}
    />
  );
};
