import { useMemo } from "react";
import { Stock } from "../../../models/Stock";
import { StocksFilters } from "../../../models/filters/StocksFilters";
import { ListView } from "../../../resources/components/lists/ListView";
import {
  ResourceColumn,
  ResourceTable,
} from "../../../resources/components/lists/ResourceTable";
import { useResourceUrlPathResolver } from "../../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../../resources/types/resourceName";

type ArticleStocksProps = {
  articleId?: number;
};
export const ArticleStocks = ({ articleId }: ArticleStocksProps) => {
  const {
    resourceUrlPathResolver: { edit, display },
  } = useResourceUrlPathResolver<ResourceName>();

  const columns: ResourceColumn<Stock>[] = useMemo(
    () => [
      {
        accessor: "articleId",
        header: "Article (Id)",
        navigationPath: (_s, value) =>
          value != null ? display("articles", value) : undefined,
      },
      {
        accessor: "code",
        header: "Code",
      },
      {
        accessor: "availabilityType",
        header: "Type disponibilité",
      },
      {
        accessor: "name",
        header: "Nom",
      },
      {
        accessor: "quantity",
        header: "Quantité",
      },
      {
        accessor: "storeId",
        header: "Magasin (Id)",
        navigationPath: (_s, value) =>
          value != null ? edit("stores", value) : undefined,
      },
    ],
    [display, edit]
  );
  return (
    <ListView<ResourceName, Stock, StocksFilters>
      defaultFilters={{ articleId, includeArticleChildrenStocks: true }}
      resourceName="stocks"
    >
      <ResourceTable<Stock> columns={columns} />
    </ListView>
  );
};
