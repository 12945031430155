import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { Attribute } from "../../../../models/Attribute";
import { ResourceHiddenTextInput } from "../../../../resources/components/inputs/ResourceHiddenTextInput";
import { useSWRReadResource } from "../../../../resources/hooks/useSWRReadResource";
import { ResourceName } from "../../../../resources/types/resourceName";
import { AttributeFilterContent } from "../types/AttributeFilterContent";
import { ExtendedAutomaticArticlesSelection } from "../types/ExtendedAutomaticArticlesSelection";
import { ShowAttributeValue } from "./ShowAttributeValue";

type ShowAttributeProps = {
  s: AttributeFilterContent;
  index: number;
};
export const ShowAttribute = (props: ShowAttributeProps) => {
  const { index, s } = props;
  const { setValue } = useFormContext<ExtendedAutomaticArticlesSelection>();
  const { data: { data: attribute } = {} } = useSWRReadResource<
    ResourceName,
    Attribute
  >({
    resourceName: "attributes",
    id: s.filterAttribute.value,
  });

  useEffect(() => {
    if (attribute?.type) {
      setValue(`ex_attributes.${index}.attributeType` as const, attribute.type);
    }
  }, [attribute?.type, index, setValue]);

  return (
    <>
      <td>
        <ResourceHiddenTextInput<ExtendedAutomaticArticlesSelection>
          name={`ex_attributes.${index}.attributeType` as const}
        />
        <ResourceHiddenTextInput<ExtendedAutomaticArticlesSelection>
          name={`ex_attributes.${index}.filterAttribute.value` as const}
        />
        {!attribute && (
          <Spinner className="mx-1" animation="border" size="sm" />
        )}
        {attribute && <>{attribute.code + " - " + attribute.name}</>}
      </td>
      <td>
        {s.filterAttributeValues && s.filterAttributeValues.length > 0 && (
          <ul>
            {s.filterAttributeValues.map((f, i) => (
              <ShowAttributeValue
                key={i}
                f={f}
                attribute={attribute}
                attributeIndex={index}
                attributeValueIndex={i}
              />
            ))}
          </ul>
        )}
      </td>
    </>
  );
};
