import moment from "moment";
import { useCallback, useMemo } from "react";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import FormGroup from "react-bootstrap/esm/FormGroup";
import InputGroup from "react-bootstrap/esm/InputGroup";
import {
  Controller,
  FieldError,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { MdAdd, MdDeleteForever, MdInfoOutline } from "react-icons/md";
import ReactSelect from "react-select";
import ReactSelectOption from "../../../components/ReactSelectOption";
import TenantAdminTemplate from "../../../components/layout/TenantAdminTemplate";
import { useEditOrCreateSubmitForm } from "../../../hooks/useSubmitForm";
import { AnalyticsExportPeriodicity } from "../../../models/AnalyticsExportPeriodicity";
import { AnalyticsExportScheduledJob } from "../../../models/AnalyticsExportScheduledJob";
import { SaveResourceButton } from "../../../resources/components/buttons/SaveResourceButton";
import { useAnalyticsTableOptions } from "./useAnalyticsTableOptions";
import { useTranslatedAnalyticsExportPeriodicity } from "./useTranslatedAnalyticsExportPeriodicity";

type EmailForm = {
  email: string;
};

type AnalyticsExportScheduledJobForm = AnalyticsExportScheduledJob & {
  emails?: Array<EmailForm>;
  periodicity?: number | string;
};

export interface EditAnalyticsExportScheduledJobFormProps {
  analyticsExportScheduledJob?: AnalyticsExportScheduledJob;
  mode: "edit" | "create";
  onSaveCallback?: (
    analyticsExportScheduledJob: AnalyticsExportScheduledJob
  ) => void;
}

const EditAnalyticsExportScheduledJobForm = (
  props: EditAnalyticsExportScheduledJobFormProps
) => {
  const { analyticsExportScheduledJob, mode, onSaveCallback } = props;
  const analyticsExportScheduledJobForm = useMemo(() => {
    const d = {
      ...analyticsExportScheduledJob,
      emails: analyticsExportScheduledJob?.emails.map((email) => ({
        email: email,
      })),
    } as AnalyticsExportScheduledJobForm;
    if (d.periodicity === undefined) {
      d.periodicity = AnalyticsExportPeriodicity.Week;
    }
    if (!d.emails) {
      d.emails = [];
    }
    if (d.emails.length === 0) {
      d.emails.push({ email: "" });
    }
    return d;
  }, [analyticsExportScheduledJob]);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AnalyticsExportScheduledJobForm>({
    mode: "onBlur",
    defaultValues: analyticsExportScheduledJobForm,
  });
  const {
    fields: emailsFields,
    append,
    remove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "emails", // unique name for your Field Array
  });
  const {
    isBusy,
    onSubmit,
    errors: submitError,
  } = useEditOrCreateSubmitForm(
    "/api/analyticsExportScheduledJobs",
    mode === "create" ? undefined : analyticsExportScheduledJob?.id.toString(),
    onSaveCallback
  );
  const onBeforeSubmit = useCallback(
    (data: AnalyticsExportScheduledJobForm) => {
      const newLocal = {
        ...data,
        periodicity:
          typeof data?.periodicity === "string"
            ? parseInt(data?.periodicity)
            : data?.periodicity,
        emails: data?.emails?.map((email: EmailForm) => email.email),
      } as AnalyticsExportScheduledJob;

      onSubmit(newLocal);
    },
    [onSubmit]
  );
  const translatedAnalyticsExportPeriodicity =
    useTranslatedAnalyticsExportPeriodicity();

  const periodicityOptions = useMemo(() => {
    return [
      AnalyticsExportPeriodicity.Week,
      AnalyticsExportPeriodicity.Month,
    ]?.map((c) => {
      return {
        value: c,
        label: translatedAnalyticsExportPeriodicity[c],
      } as ReactSelectOption<number>;
    });
  }, [translatedAnalyticsExportPeriodicity]);
  const analyticsTableOptions = useAnalyticsTableOptions();
  return (
    <Form
      className="d-flex flex-column gap-2"
      onSubmit={handleSubmit(onBeforeSubmit)}
    >
      <input
        readOnly
        type="hidden"
        {...register("id", { valueAsNumber: true })}
        defaultValue="0"
      />
      <FormGroup>
        {analyticsExportScheduledJob?.lastExecuted && (
          <TenantAdminTemplate>
            <p>
              <MdInfoOutline />
              <span className="ms-1">
                Dernier envoi le{" "}
                {moment(analyticsExportScheduledJob.lastExecuted).format(
                  "DD/MM/YYYY [à] HH[h]mm"
                )}
              </span>
            </p>
          </TenantAdminTemplate>
        )}

        <label>Fréquence*</label>
        <div className="form-group">
          {periodicityOptions.map((p) => (
            <div key={p.value} className="form-check form-check-inline">
              <input
                className="form-check-input"
                {...register("periodicity", {
                  required: "Veuillez choisir une fréquence",
                })}
                type="radio"
                id={p.value?.toString()}
                value={p.value}
                defaultChecked={
                  analyticsExportScheduledJobForm?.periodicity === p.value
                }
              />
              <label className="form-check-label" htmlFor={p.value?.toString()}>
                {p.label}
              </label>
            </div>
          ))}
        </div>
      </FormGroup>
      <FormGroup>
        <Form.Label>Emails*</Form.Label>

        {emailsFields.map((field, index) => {
          let isInvalid =
            errors &&
            errors?.emails &&
            errors.emails[index] &&
            errors.emails[index]?.email;
          return (
            <div
              className="mb-2"
              key={field.id} // important to include key with field's id
            >
              <InputGroup>
                <Form.Control
                  type="email"
                  className={`form-control ${isInvalid ? "is-invalid" : ""}`}
                  {...register(`emails.${index}.email` as const, {
                    required: "Veuillez saisir une adresse email",
                  })}
                />
                {emailsFields.length > 1 && (
                  <Button
                    size="sm"
                    variant="outline-danger"
                    type="button"
                    onClick={() => remove(index)}
                  >
                    <MdDeleteForever onClick={() => remove(index)} />
                  </Button>
                )}
              </InputGroup>
              {errors &&
                errors?.emails &&
                errors.emails[index] &&
                errors.emails[index]?.email?.message && (
                  <span className="text-danger small">
                    {errors.emails[index]?.email?.message}
                  </span>
                )}
            </div>
          );
        })}
        {errors && (errors?.emails as FieldError | undefined)?.message && (
          <div>{(errors?.emails as FieldError | undefined)?.message}</div>
        )}
        <Button
          size="sm"
          variant="outline-primary"
          type="button"
          onClick={() => append({ email: "" })}
        >
          <MdAdd /> Ajouter un email
        </Button>
      </FormGroup>
      <FormGroup>
        <Form.Label>Fichier*</Form.Label>
        <Controller
          name="analyticsTables"
          control={control}
          rules={{ required: "Veuillez choisir un fichier", min: 1 }}
          render={({ field: { onChange, value, ref } }) => (
            <ReactSelect
              className={`${
                errors && errors?.analyticsTables ? "is-invalid" : ""
              }`}
              isMulti
              options={analyticsTableOptions}
              ref={ref}
              value={analyticsTableOptions.filter((c) => {
                return (
                  c.value && value && value.some((table) => table === c.value)
                );
              })}
              onChange={(val) =>
                onChange(
                  (val as undefined | ReactSelectOption<number>[])?.map(
                    (r) => r.value
                  )
                )
              }
              placeholder="Sélectionner un fichier"
              isLoading={
                analyticsTableOptions === undefined ||
                analyticsTableOptions.length === 0
              }
            />
          )}
        />
        {errors &&
          (errors?.analyticsTables as FieldError | undefined)?.message && (
            <Form.Control.Feedback type="invalid">
              {(errors?.analyticsTables as FieldError | undefined)?.message}
            </Form.Control.Feedback>
          )}
      </FormGroup>
      <SaveResourceButton
        mode={mode}
        isBusy={isBusy}
        submitError={submitError}
      />
    </Form>
  );
};

export default EditAnalyticsExportScheduledJobForm;
