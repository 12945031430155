import { useCallback, useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import Category from "../../models/Category";
import { CategoriesFilters } from "../../models/filters/CategoriesFilters";
import { ListPage } from "../../resources/components/lists/ListPage";
import { ResourceName } from "../../resources/types/resourceName";
import CategoriesList from "./CategoriesList";

const CategoriesPage = () => {
  const filters: FilterFieldData<CategoriesFilters>[] = useMemo(
    () => [
      {
        fieldName: "label",
        type: "text",
        label: "Label",
        placeholder: "Label",
      },
      {
        fieldName: "code",
        type: "text",
        label: "Code",
        placeholder: "Code",
      },
    ],
    []
  );

  const onBeforeSubmitFilters = useCallback((f: CategoriesFilters) => {
    if (Object.keys(f).filter((key) => key !== "isParentNull").length > 0) {
      delete f.isParentNull;
    }
    return f;
  }, []);
  return (
    <ListPage<ResourceName, Category, CategoriesFilters>
      defaultPageSize={30}
      filterFieds={filters}
      showChildWithoutFilters
      transformResourceFilters={onBeforeSubmitFilters}
      defaultFilters={{ isParentNull: true }}
    >
      <CategoriesList />
    </ListPage>
  );
};

export default CategoriesPage;
