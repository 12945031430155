import * as React from "react";
import { useCallback } from "react";
import { Pagination } from "react-bootstrap";

export type PaginatorProps = {
  canPreviousPage: boolean;
  canNextPage: boolean;
  requestedPageIndex: (index: number) => void;
  pageIndex: number;
  pageCount: number;
};
export const Paginator = (props: PaginatorProps) => {
  const {
    canPreviousPage,
    canNextPage,
    requestedPageIndex,
    pageIndex,
    pageCount,
  } = props;

  const goToFirstPage = useCallback(
    () => requestedPageIndex(0),
    [requestedPageIndex]
  );
  const goToPreviousPage = useCallback(
    () => requestedPageIndex(pageIndex - 1),
    [requestedPageIndex, pageIndex]
  );
  const goToNextPage = useCallback(
    () => requestedPageIndex(pageIndex + 1),
    [requestedPageIndex, pageIndex]
  );
  const goToLastPage = useCallback(
    () => requestedPageIndex(pageCount - 1),
    [requestedPageIndex, pageCount]
  );

  return (
    <Pagination className="justify-content-center" size="sm">
      {canPreviousPage && (
        <>
          <Pagination.First
            onClick={goToFirstPage}
            disabled={!canPreviousPage}
          />
          <Pagination.Prev
            onClick={goToPreviousPage}
            disabled={!canPreviousPage}
          />
        </>
      )}
      <Pagination.Item active>{pageIndex + 1}</Pagination.Item>
      {canNextPage && (
        <>
          <Pagination.Next onClick={goToNextPage} disabled={!canNextPage} />
          <Pagination.Last onClick={goToLastPage} disabled={!canNextPage} />
        </>
      )}
    </Pagination>
  );
};
