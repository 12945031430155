import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import Store from "../../models/Store";
import { StoresFilters } from "../../models/filters/StoresFilters";
import { CreateResourceButton } from "../../resources/components/buttons/CreateResourceButton";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { useAddEditButton } from "../../resources/hooks/useAddEditButton";

const StoresPage = () => {
  const filters: FilterFieldData<StoresFilters>[] = useMemo(
    () => [
      {
        fieldName: "code",
        type: "text",
        label: "Code",
        placeholder: "Code",
      },
      {
        fieldName: "name",
        type: "text",
        label: "Nom",
        placeholder: "Nom",
      },
    ],
    []
  );

  const columns: ResourceColumn<Store>[] = useMemo(
    () => [
      {
        header: "Code",
        accessor: "code",
      },
      {
        header: "Nom",
        accessor: "name",
      },
      {
        header: "Ville",
        accessor: "city",
      },
    ],
    []
  );
  const { columnsWithButton } = useAddEditButton({
    columns,
  });
  //
  return (
    <ListPage
      filterFieds={filters}
      actions={<CreateResourceButton />}
      showChildWithoutFilters
    >
      <ResourceTable columns={columnsWithButton} />
    </ListPage>
  );
};

export default StoresPage;
