import { useCallback } from "react";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { useNotificationsContext } from "../../hooks/useNotificationsContext";
import useUpload from "../../pages/Animations/InteractiveCatalog/components/useUpload";
import { useResourceApiPathResolverContext } from "./useResourceApiPathResolverContext";
import { useResourceName } from "./useResourceName";

type UseUploadMediaControllerProps<TResource> = {
  imageField: Path<TResource>;
};

export const useUploadMediaController = <TResource extends FieldValues>(
  props: UseUploadMediaControllerProps<TResource>
) => {
  const { imageField } = props;

  const { resourceName } = useResourceName();
  const { resourceApiPathResolver: { updateUrl } = {} } =
    useResourceApiPathResolverContext();

  /* FORM (SUBMIT & UPLOAD) */
  const { uploadNewMedia } = useUpload<TResource>();
  const { addNotification } = useNotificationsContext();

  const transformAfterSubmit = useCallback(
    async (a: TResource, form: UseFormReturn<TResource>) => {
      const url =
        updateUrl != null
          ? `${updateUrl(resourceName, a.id)}/UploadMedia`
          : undefined;
      if (url == null) return;
      const { getValues } = form;
      const imageUrl = getValues(imageField);
      if (typeof imageUrl === "object") {
        const i = imageUrl as FileList;
        if (i && i.length > 0) {
          try {
            const articlesSelection = await uploadNewMedia(url, i[0]);
            addNotification({
              title: "Sauvegarde du média réussie",
              variant: "success",
            });
            return articlesSelection;
          } catch (e) {
            console.error("Unable to upload file", e);
            addNotification({
              title: "Impossible de charger le média",
              variant: "danger",
            });
          }
        }
      }

      return a;
    },
    [addNotification, imageField, resourceName, updateUrl, uploadNewMedia]
  );
  const transformBeforeSubmit = useCallback(
    (a: TResource) => {
      const articlesSelection = a as Partial<TResource>;
      if (typeof articlesSelection[imageField] !== "string") {
        delete articlesSelection[imageField];
        // delete articlesSelection.thumbnailUrl;
      }
      return a;
    },
    [imageField]
  );

  return { transformAfterSubmit, transformBeforeSubmit };
};
