import React from "react";
import {
  UseIsAdminRoleProps,
  useIsUserInTenantAdminRole,
} from "../../hooks/useIsUserInTenantAdminRole";

type TenantAdminTemplateProps = React.PropsWithChildren & UseIsAdminRoleProps;
const TenantAdminTemplate = (props: TenantAdminTemplateProps) => {
  const { isAllowed } = useIsUserInTenantAdminRole(props);

  return <>{isAllowed && props.children}</>;
};
export default TenantAdminTemplate;
