import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../components/layout/LayoutRoute";
import { Resource } from "../components/layout/Resource";
import TenantAdminTemplate from "../components/layout/TenantAdminTemplate";
import { useCreateResourceApiPathResolver } from "../resources/hooks/useCreateResourceApiPathResolver";
import { ResourceApiPathResolverContextProvider } from "../resources/hooks/useResourceApiPathResolverContext";
import { ResourceName } from "../resources/types/resourceName";
import AdMediaPage from "./Animations/AdsMedia/AdMediaPage";
import CreateAdMediaPage from "./Animations/AdsMedia/CreateAdMediaPage";
import EditAdMediaPage from "./Animations/AdsMedia/EditAdMediaPage";
import ArticlesSelectionsPage from "./Animations/ArticlesSelections/ArticlesSelectionsPage";
import CreateArticlesSelectionPage from "./Animations/ArticlesSelections/CreateArticlesSelectionPage";
import EditArticlesSelectionPage from "./Animations/ArticlesSelections/EditArticlesSelectionPage";
import CreateInteractiveCatalogPage from "./Animations/InteractiveCatalog/CreateInteractiveCatalogPage";
import EditInteractiveCatalogPage from "./Animations/InteractiveCatalog/EditInteractiveCatalogPage";
import InteractivesCatalogsPage from "./Animations/InteractiveCatalog/InteractivesCatalogsPage";
import AnimationsGroupsPage from "./AnimationsGroups/AnimationsGroupsPage";
import CreateAnimationGroupPage from "./AnimationsGroups/CreateAnimationGroupPage";
import EditAnimationGroupPage from "./AnimationsGroups/EditAnimationGroupPage";
import ApplicationUsersPage from "./ApplicationUsers/ApplicationUsersPage";
import CreateApplicationUserPage from "./ApplicationUsers/CreateApplicationUserPage";
import EditApplicationUserPage from "./ApplicationUsers/EditApplicationUserPage";
import ArticlePage from "./Articles/ArticlePage";
import ArticlesPage from "./Articles/ArticlesPage";
import AttributesPage from "./Attributes/AttributesPage";
import EditAttributePage from "./Attributes/EditAttributePage";
import BroadcastListsPage from "./BroadcastLists/BroadcastListsPage";
import CreateBroadcastListPage from "./BroadcastLists/CreateBroadcastListPage";
import EditBroadcastListPage from "./BroadcastLists/EditBroadcastListPage";
import CreateDigitalSignageCampaignPage from "./Campaigns/DigitalSignage/CreateDigitalSignageCampaignPage";
import EditDigitalSignageCampaignPage from "./Campaigns/DigitalSignage/EditDigitalSignageCampaignPage";
import CreateHomeAdCampaignPage from "./Campaigns/HomeAds/CreateHomeAdsCampaignPage";
import EditHomeAdCampaignPage from "./Campaigns/HomeAds/EditHomeAdsCampaignPage";
import CreateHomeCatalogsCampaignPage from "./Campaigns/HomeCatalogs/CreateHomeCatalogsCampaignPage";
import EditHomeCatalogsCampaignPage from "./Campaigns/HomeCatalogs/EditHomeCatalogsCampaignPage";
import CreateHomePanelCampaignPage from "./Campaigns/HomePanels/CreateHomePanelsCampaignPage";
import EditHomePanelCampaignPage from "./Campaigns/HomePanels/EditHomePanelsCampaignPage";
import { CampaignsPage } from "./Campaigns/components/CampaignsPage";
import ParametersPage from "./CatalogParameters/EditParametersPage";
import CategoriesPage from "./Categories/CategoriesPage";
import CategoryPage from "./Categories/CategoryPage";
import EditCategoryPage from "./Categories/EditCategoryPage";
import CountriesPage from "./Countries/CountriesPage";
import EditCountryPage from "./Countries/EditCountryPage";
import CreateDeliveryModePage from "./DeliveryModes/CreateDeliveryModePage";
import DeliveryModesPage from "./DeliveryModes/DeliveryModesPage";
import EditDeliveryModePage from "./DeliveryModes/EditDeliveryModePage";
import CreateDeliveryZonePage from "./DeliveryZones/CreateDeliveryZonePage";
import DeliveryZonesPage from "./DeliveryZones/DeliveryZonesPage";
import EditDeliveryZonePage from "./DeliveryZones/EditDeliveryZonePage";
import CreateDevicePage from "./Devices/CreateDevicePage";
import DevicesPage from "./Devices/DevicesPage";
import EditDevicePage from "./Devices/EditDevicePage";
import CreateDropShippingProviderPage from "./DropShippingProviders/CreateDropShippingProviderPage";
import DropShippingProvidersPage from "./DropShippingProviders/DropShippingProvidersPage";
import EditDropShippingProviderPage from "./DropShippingProviders/EditDropShippingProviderPage";
import ElasticArticlesPage from "./ElasticArticles/ElacticArticlesPage";
import { FtpParametersPage } from "./FtpParameters/FtpParametersPage";
import ImportJobDefinitionEntitiesPage from "./ImportJobDefinitionEntities/ImportJobDefinitionEntitiesPage";
import EditLogisticOperationPage from "./LogisticOperations/EditLogisticOperationPage";
import LogisticOperationsPage from "./LogisticOperations/LogisticOperationsPage";
import CreateNotificationPage from "./Notifications/CreateNotificationPage";
import EditNotificationPage from "./Notifications/EditNotificationPage";
import NotificationsPage from "./Notifications/NotificationsPage";
import OrderReceiptConfigurationPage from "./OrderReceiptConfiguration/OrderReceiptConfigurationPage";
import OrderPage from "./Orders/OrderPage";
import OrdersPage from "./Orders/OrdersPage";
import ReceptionFilesPage from "./ReceptionFiles/ReceptionFilesPage";
import ScheduledJobsPage from "./ScheduledJobs/ScheduledJobsPage";
import ServiceExtensionDefinitionsPage from "./ServiceExtensionDefinitions/ServiceExtensionDefinitionsPage";
import AnalyticsExportScheduledJobsPage from "./Statistics/AnalyticsExportScheduledJobs/AnalyticsExportScheduledJobsPage";
import CreateAnalyticsExportScheduledJobPage from "./Statistics/AnalyticsExportScheduledJobs/CreateAnalyticsExportScheduledJobPage";
import EditAnalyticsExportScheduledJobPage from "./Statistics/AnalyticsExportScheduledJobs/EditAnalyticsExportScheduledJobPage";
import ExportStatsPage from "./Statistics/ExportStatsPage";
import StatsPage from "./Statistics/StatsPage";
import StockInfosPage from "./StockInfos/StockInfosPage";
import EditStockLocationPage from "./StockLocations/EditStockLocationPage";
import StockLocationPage from "./StockLocations/StockLocationPage";
import StockLocationsPage from "./StockLocations/StockLocationsPage";
import CreateStorePage from "./Stores/CreateStorePage";
import EditStorePage from "./Stores/EditStorePage";
import StoresPage from "./Stores/StoresPage";
import SyncMediasPage from "./SyncMedias/SyncMediasPage";
import SynchronizationsDetailsPage from "./Synchronizations/Details/SynchronizationsDetailsPage";
import SynchronizationsPage from "./Synchronizations/SynchronizationsPage";
import CreateTransporterPage from "./Transporters/CreateTransporterPage";
import EditTransporterPage from "./Transporters/EditTransporterPage";
import TransportersPage from "./Transporters/TransportersPage";

const TypedResource = Resource<ResourceName>;

export const Resources = () => {
  const { resourceApiPathResolver } = useCreateResourceApiPathResolver();

  return (
    <ResourceApiPathResolverContextProvider value={{ resourceApiPathResolver }}>
      <Routes>
        <Route
          path="stats/export"
          element={
            <ProtectedRoute
              canChangeCustomer={true}
              isCurrentCustomerMandatory={true}
            >
              <ExportStatsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="stats"
          element={
            <ProtectedRoute
              canChangeCustomer={true}
              isCurrentCustomerMandatory={true}
            >
              <StatsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="tenant-configuration"
          element={
            <ProtectedRoute
              isTenantAdminRoleMandatory
              canChangeCustomer={true}
              isCurrentCustomerMandatory={true}
            >
              <ParametersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="ftp-parameters"
          element={
            <ProtectedRoute
              isTenantAdminRoleMandatory
              canChangeCustomer={true}
              isCurrentCustomerMandatory={true}
            >
              <FtpParametersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="order-receipt-configuration"
          element={
            <ProtectedRoute
              isTenantAdminRoleMandatory
              canChangeCustomer={true}
              isCurrentCustomerMandatory={true}
            >
              <OrderReceiptConfigurationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="elastic-articles"
          element={
            <ProtectedRoute
              isTenantAdminRoleMandatory
              canChangeCustomer={true}
              isCurrentCustomerMandatory={true}
            >
              <ElasticArticlesPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="*"
          element={
            <>
              <TypedResource
                name="logistic-operations"
                label="Opérations logistiques"
                list={<LogisticOperationsPage />}
                edit={<EditLogisticOperationPage />}
              />
              <TypedResource
                name="stock-locations"
                label="Dépôts stocks"
                list={<StockLocationsPage />}
                display={<StockLocationPage />}
                edit={<EditStockLocationPage />}
              />
              <TypedResource
                name="sync-medias"
                label="Medias synchronisés"
                list={<SyncMediasPage />}
              />
              <TypedResource
                name="stock-infos"
                label="Stocks"
                list={<StockInfosPage />}
              />
              <TypedResource
                name="import-job-definition-entities"
                label="Définitions des tâches d'import"
                list={<ImportJobDefinitionEntitiesPage />}
              />
              <TypedResource
                name="synchronizations"
                label="Synchronisations"
                list={<SynchronizationsPage />}
                display={<SynchronizationsDetailsPage />}
              />
              <TypedResource
                name="categories"
                label="Catégories"
                list={<CategoriesPage />}
                display={<CategoryPage />}
                edit={<EditCategoryPage />}
              />
              <TypedResource
                name="stores"
                label="Magasins"
                create={<CreateStorePage />}
                edit={<EditStorePage />}
                list={<StoresPage />}
              />
              <TypedResource
                name="animations/groups"
                label="Groupes d'animations"
                create={<CreateAnimationGroupPage />}
                edit={<EditAnimationGroupPage />}
                list={<AnimationsGroupsPage />}
              />
              <TypedResource
                name="animations/ads"
                label="Publicités de veille"
                create={<CreateAdMediaPage />}
                edit={<EditAdMediaPage />}
                list={<AdMediaPage />}
              />
              <TypedResource
                name="animations/interactives-catalogs"
                label="Catalogues interactifs"
                create={<CreateInteractiveCatalogPage />}
                edit={<EditInteractiveCatalogPage />}
                list={<InteractivesCatalogsPage />}
              />
              <TypedResource
                name="animations/digital-signage-campaigns"
                label="Campagnes de veille"
                create={<CreateDigitalSignageCampaignPage />}
                edit={<EditDigitalSignageCampaignPage />}
                list={<CampaignsPage />}
              />
              <TypedResource
                name="animations/home-panels-campaigns"
                label="Campagnes de Home Panels"
                create={<CreateHomePanelCampaignPage />}
                edit={<EditHomePanelCampaignPage />}
                list={<CampaignsPage />}
              />
              <TypedResource
                name="animations/home-ads-campaigns"
                label="Campagnes de Home Ads"
                create={<CreateHomeAdCampaignPage />}
                edit={<EditHomeAdCampaignPage />}
                list={<CampaignsPage />}
              />
              <TypedResource
                name="animations/home-catalogs-campaigns"
                label="Campagnes de Shop in Shop"
                create={<CreateHomeCatalogsCampaignPage />}
                edit={<EditHomeCatalogsCampaignPage />}
                list={<CampaignsPage />}
              />
              <TypedResource
                name="orders"
                label="Commandes"
                edit={<OrderPage />}
                list={<OrdersPage />}
              />
              <TypedResource
                name="analyticsExportScheduledJobs"
                label="Export des statistiques"
                create={<CreateAnalyticsExportScheduledJobPage />}
                edit={<EditAnalyticsExportScheduledJobPage />}
                list={<AnalyticsExportScheduledJobsPage />}
              />
              <TypedResource
                name="animations/articles-selections"
                label="Sélections d'articles"
                create={<CreateArticlesSelectionPage />}
                edit={<EditArticlesSelectionPage />}
                list={<ArticlesSelectionsPage />}
              />
              <TypedResource
                name="transporters"
                label="Transporteurs"
                create={<CreateTransporterPage />}
                edit={<EditTransporterPage />}
                list={<TransportersPage />}
              />
              <TypedResource
                name="deliveryzones"
                label="Zones de livraison"
                create={<CreateDeliveryZonePage />}
                edit={<EditDeliveryZonePage />}
                list={<DeliveryZonesPage />}
              />
              <TypedResource
                name="deliverymodes"
                label="Modes de livraison"
                create={<CreateDeliveryModePage />}
                edit={<EditDeliveryModePage />}
                list={<DeliveryModesPage />}
              />
              <TypedResource
                name="countries"
                label="Pays"
                edit={<EditCountryPage />}
                list={<CountriesPage />}
              />
              <TenantAdminTemplate>
                <TypedResource
                  name="devices"
                  label="Périphériques"
                  create={<CreateDevicePage />}
                  edit={<EditDevicePage />}
                  list={<DevicesPage />}
                />
              </TenantAdminTemplate>
              <TenantAdminTemplate>
                <TypedResource
                  name="users"
                  label="Utilisateurs"
                  create={<CreateApplicationUserPage />}
                  edit={<EditApplicationUserPage />}
                  list={<ApplicationUsersPage />}
                />
              </TenantAdminTemplate>
              <TypedResource
                name="notifications"
                label="Notifications"
                create={<CreateNotificationPage />}
                edit={<EditNotificationPage />}
                list={<NotificationsPage />}
              />
              <TypedResource
                name="broadcast-lists"
                label="Listes de diffusion"
                create={<CreateBroadcastListPage />}
                edit={<EditBroadcastListPage />}
                list={<BroadcastListsPage />}
              />
              <TypedResource
                name="attributes"
                label="Attributs"
                edit={<EditAttributePage />}
                list={<AttributesPage />}
              />
              <TypedResource
                name="dropshippingproviders"
                label="Fournisseurs dropshipping"
                create={<CreateDropShippingProviderPage />}
                edit={<EditDropShippingProviderPage />}
                list={<DropShippingProvidersPage />}
              />
              <TenantAdminTemplate>
                <TypedResource
                  name="reception-files"
                  label="Réception de fichiers"
                  list={<ReceptionFilesPage />}
                />
              </TenantAdminTemplate>

              <TenantAdminTemplate>
                <TypedResource
                  name="articles"
                  label="Articles"
                  display={<ArticlePage />}
                  list={<ArticlesPage />}
                />
              </TenantAdminTemplate>

              <TenantAdminTemplate>
                <TypedResource
                  label="Tâches planifiées"
                  name="scheduled-jobs"
                  list={<ScheduledJobsPage />}
                />
              </TenantAdminTemplate>
              <TenantAdminTemplate>
                <TypedResource
                  label="Définitions des services d'extension"
                  name="service-extension-definitions"
                  list={<ServiceExtensionDefinitionsPage />}
                />
              </TenantAdminTemplate>
            </>
          }
        />
      </Routes>
    </ResourceApiPathResolverContextProvider>
  );
};
