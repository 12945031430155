import { useCallback, useEffect, useState } from "react";
import useCustomerApiFetch from "../useCustomerApiFetch";

const useRoles = () => {
  const [roles, setRoles] = useState<Array<string>>([]);
  const [isInError, setIsInError] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const apiFetcher = useCustomerApiFetch();
  const retry = useCallback(() => {
    setIsBusy(true);
    apiFetcher("/api/security/roles", {
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then((r) => r?.json())
      .then((d) => {
        const roles = d as string[];
        setRoles(roles);
      })
      .catch((e) => setIsInError(true))
      .then(() => setIsBusy(false));
  }, [apiFetcher]);

  useEffect(() => {
    retry();
  }, [retry]);

  return { roles, isBusy, isInError, retry };
};

export default useRoles;
