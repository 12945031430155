export enum OrderStatus {
  Draft = 0,
  Registred = 1,
  Canceled = 2,
  Paid = 3,
}

export const orderStatusToText = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.Draft:
      return "Brouillon";
    case OrderStatus.Registred:
      return "Enregistrée";
    case OrderStatus.Canceled:
      return "Annulée";
    case OrderStatus.Paid:
      return "Payée";
    default:
      return `${status}?`;
  }
};
