import AnimationMedia from "../../../models/AnimationMedia";

const uploadMedia = async function (
  campaignApiUrl: string | undefined,
  campaignId: number | undefined,
  AnimationMediaId: number,
  apiFetcher: (requestInfo: string, requestInit?: RequestInit) => Promise<any>,
  file?: File
) {
  let formData = new FormData();
  if (file) {
    formData.append("File", file);
  }

  const response = await apiFetcher(
    `${campaignApiUrl}/${campaignId}/uploadmedia/${AnimationMediaId}`,
    {
      method: "POST",
      body: formData,
    }
  );

  if (!response.ok) {
    throw new Error("Unable to upload");
  }

  const animationMedia = await response.json();
  return animationMedia as AnimationMedia;
};
export default uploadMedia;
