import {
  FieldValues,
  Path,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import Input, { InputProps } from "../../../components/Input";
import { Resource } from "../../../resources/types";

export type ResourceInputProps<
  TResource extends Resource
  // TFieldName extends FieldName<TResource> = FieldName<TResource>
> = InputProps & {
  name: Path<TResource>;
  label?: string;
  options?: RegisterOptions<FieldValues, Path<TResource>> | undefined;
};
export const ResourceInput = <TResource extends Resource>(
  props: ResourceInputProps<TResource>
) => {
  const { name, options, ...otherProps } = props;

  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext();

  return (
    <Input
      {...register(name, options)}
      errorMessage={errors[name]?.message as string}
      isInvalid={errors[name] != null}
      disabled={isSubmitting}
      {...otherProps}
    />
  );
};
