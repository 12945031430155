import { useContext } from "react";
import {
  ResourceOptionsContext,
  ResourceOptionsContextValue,
} from "./ResourceOptionsContextProvider";

export const useResourceName = <TResourceName extends string = string>(
  props?: Partial<ResourceOptionsContextValue<TResourceName>>
): Pick<ResourceOptionsContextValue<TResourceName>, "resourceName"> => {
  const context = useContext(
    ResourceOptionsContext
  ) as ResourceOptionsContextValue<TResourceName>;
  return { resourceName: props?.resourceName ?? context?.resourceName };
};
