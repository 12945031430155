import { useCallback, useEffect, useState } from "react";
import Customer from "../models/Customer";
import useCustomerApiFetch from "./useCustomerApiFetch";

const useCustomers = function (): [Customer[], boolean, boolean, () => void] {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isInError, setIsInError] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const apiFetcher = useCustomerApiFetch();
  const retry = useCallback(() => {
    setIsBusy(true);
    apiFetcher("/api/Customers/GetManageableCustomers", {
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then((r) => r?.json())
      .then((d) => {
        const customer = d as Customer[];
        if (customer) {
          customer.sort((a, b) => (a.name < b.name ? -1 : 1));
        }
        setCustomers(customer);
      })
      .catch((e) => setIsInError(true))
      .then(() => setIsBusy(false));
  }, [apiFetcher]);

  useEffect(() => {
    retry();
  }, [retry]);

  return [customers, isBusy, isInError, retry];
};

export default useCustomers;
