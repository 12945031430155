import debounce from "lodash/debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import { PropsValue } from "react-select/dist/declarations/src/types";
import ReactSelectOption, {
  ReactSelectOptionValueType,
} from "../../../../components/ReactSelectOption";
import {
  UseSimpleSelectController,
  useSimpleSelectController,
} from "../../../../components/hooks/useSimpleSelectController";

export type UseReactSelectProps<
  TReactSelectOptionValueType extends ReactSelectOptionValueType,
  IsMulti extends boolean
> = UseSimpleSelectController<TReactSelectOptionValueType, IsMulti> & {};

type PrivateUseReactSelectPropsProps<
  TReactSelectOptionValueType extends ReactSelectOptionValueType,
  U,
  IsMulti extends boolean = false
> = UseReactSelectProps<TReactSelectOptionValueType, IsMulti> & {
  defaultPropsValue?: U | U[] | undefined;
  convertToOption: (
    value: U | undefined
  ) => ReactSelectOption<TReactSelectOptionValueType> | undefined;
};

export const useReactSelectProps = <
  TReactSelectOptionValueType extends ReactSelectOptionValueType,
  U,
  IsMulti extends boolean
>(
  props: PrivateUseReactSelectPropsProps<
    TReactSelectOptionValueType,
    U,
    IsMulti
  >
) => {
  const { convertToOption, defaultPropsValue } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onMenuOpen = useCallback(() => setIsMenuOpen(true), []);
  const onMenuClose = useCallback(() => setIsMenuOpen(false), []);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [input, setInput] = useState("");

  const otherProps = useSimpleSelectController<
    TReactSelectOptionValueType,
    IsMulti
  >(props);

  const { setValue, isWaitingForDefaultValue } = otherProps;

  const delaySetInput = useCallback((input: string) => {
    setSearch(input);
    setNeedRefresh(false);
  }, []);
  const onDebounceInputChange = useRef(debounce(delaySetInput, 500)).current;
  const onInputChange = useCallback(
    (input: string) => {
      onDebounceInputChange?.cancel();

      setInput(input);
      if (input !== search) {
        setNeedRefresh(true);
      }

      onDebounceInputChange(input);
    },
    [onDebounceInputChange, search]
  );

  //valeurs par défaut
  useEffect(() => {
    if (
      isWaitingForDefaultValue &&
      defaultPropsValue != null &&
      (Array.isArray(defaultPropsValue) ? defaultPropsValue.length > 0 : true)
    ) {
      const v = Array.isArray(defaultPropsValue)
        ? defaultPropsValue.map((d) => convertToOption(d))
        : convertToOption(defaultPropsValue as U);

      setValue(v as PropsValue<ReactSelectOption<TReactSelectOptionValueType>>);
    }
  }, [convertToOption, defaultPropsValue, isWaitingForDefaultValue, setValue]);

  return {
    ...otherProps,
    isMenuOpen,
    // inputValue,
    input,
    onInputChange,
    onMenuOpen,
    onMenuClose,
    search,
    needRefresh,
  };
};
