import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import { SyncMedia } from "../../models/SyncMedia";
import { SyncMediasFilters } from "../../models/filters/SyncMediasFilters";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { getDateTime } from "../../tools/dateUtils";

const SyncMediasPage = () => {
  const filters: FilterFieldData<SyncMediasFilters>[] = useMemo(
    () => [
      {
        fieldName: "originalUrl",
        type: "text",
        label: "Url Source",
        placeholder: "Url Source",
      },
      {
        fieldName: "isSync",
        type: "boolean",
        label: "IsSync",
        placeholder: "IsSync",
      },
      {
        fieldName: "isFail",
        type: "boolean",
        label: "IsFail",
        placeholder: "IsFail",
      },
      {
        fieldName: "syncUrl",
        type: "text",
        label: "Url Synchro",
        placeholder: "Url Synchro",
      },
      {
        fieldName: "syncThumbnailUrl",
        type: "text",
        label: "Url Miniature Synchro",
        placeholder: "Url Miniature Synchro",
      },
    ],
    []
  );

  const columns: ResourceColumn<SyncMedia>[] = useMemo(
    () => [
      {
        header: "OriginalUrl",
        accessor: "originalUrl",
      },
      {
        header: "IsSync",
        accessor: "isSync",
      },
      {
        header: "IsFail",
        accessor: "isFail",
      },
      {
        header: "FailCount",
        accessor: "failCount",
      },
      {
        header: "AddedDate",
        accessor: "addedDate",
        getCell: (o) => getDateTime(o.addedDate),
      },
      {
        header: "SyncDate",
        accessor: "syncDate",
        getCell: (o) => getDateTime(o.syncDate),
      },
      {
        header: "FailureDate",
        accessor: "failureDate",
        getCell: (o) => getDateTime(o.failureDate),
      },
      {
        header: "SyncUrl",
        accessor: "syncUrl",
      },
      {
        header: "SyncThumbnailUrl",
        accessor: "syncThumbnailUrl",
      },
    ],
    []
  );

  return (
    <ListPage filterFieds={filters} showChildWithoutFilters>
      <ResourceTable columns={columns} />
    </ListPage>
  );
};

export default SyncMediasPage;
