import { ProviderProps, createContext, useContext } from "react";
import { ResourceApiPathResolver } from "../types/resourceApiPathResolver";

type ResourceApiPathResolverContextValue = {
  resourceApiPathResolver: ResourceApiPathResolver;
};

const ResourceApiPathResolverContext =
  createContext<ResourceApiPathResolverContextValue>({
    resourceApiPathResolver: {
      listUrl: () => {
        throw new Error("Function not implemented.");
      },
      readUrl: () => {
        throw new Error("Function not implemented.");
      },
      createUrl: () => {
        throw new Error("Function not implemented.");
      },
      updateUrl: () => {
        throw new Error("Function not implemented.");
      },
      deleteUrl: () => {
        throw new Error("Function not implemented.");
      },
      deleteAllUrl: () => {
        throw new Error("Function not implemented.");
      },
    },
  });

export const ResourceApiPathResolverContextProvider = ({
  children,
  value,
}: ProviderProps<ResourceApiPathResolverContextValue>) => (
  <>
    {value ? (
      <ResourceApiPathResolverContext.Provider value={value}>
        {children}
      </ResourceApiPathResolverContext.Provider>
    ) : (
      children
    )}
  </>
);

export const useResourceApiPathResolverContext = () =>
  useContext(ResourceApiPathResolverContext);
