import { useCallback } from "react";
import { Resource } from "../../resources/types";
import { DataProvider, DeleteAllProps } from "../types/dataProvider";
import { useResourceSafeActionCaller } from "./useResourceSafeActionCaller";

export const useDeleteAllResources = <
  TResourceName extends string = string,
  TResourceType extends Resource = Resource
>() => {
  const unsafeAction = useCallback(
    async (
      dataProvider: DataProvider<TResourceName, TResourceType>,
      props: DeleteAllProps<TResourceName>
    ) => await dataProvider.deleteAll(props),
    []
  );
  const { safeAction: deleteAllAction, errors } = useResourceSafeActionCaller({
    unsafeAction,
  });

  return { deleteAllAction, errors };
};
