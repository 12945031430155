import { useMemo } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { LogisticOperationType } from "../../models/LogisticOperation";
import {
  ControlledSimpleSelect,
  ControlledSimpleSelectProps,
  SimpleSelect,
  SimpleSelectProps,
} from "../SimpleSelect";
import { displayLogisticOperationType } from "../assets/display/displayLogisticOperationType";

const useLogisticOperationTypes = () => {
  const options = useMemo(
    () => [
      {
        label: displayLogisticOperationType(LogisticOperationType.Inventory),
        value: LogisticOperationType.Inventory,
      },
      {
        label: displayLogisticOperationType(LogisticOperationType.Labelling),
        value: LogisticOperationType.Labelling,
      },
      {
        label: displayLogisticOperationType(LogisticOperationType.Receipt),
        value: LogisticOperationType.Receipt,
      },
    ],
    []
  );
  return { options };
};

type LogisticOperationTypesSelectProps<IsMulti extends boolean> = Omit<
  SimpleSelectProps<number, IsMulti>,
  "options"
>;

export const LogisticOperationTypesSelect = <IsMulti extends boolean>(
  props: LogisticOperationTypesSelectProps<IsMulti>
) => {
  const res = useLogisticOperationTypes();
  return <SimpleSelect<number, IsMulti> {...props} {...res} />;
};

type ControlledLogisticOperationTypesSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledSimpleSelectProps<
  LogisticOperationTypesSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledLogisticOperationTypesSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledLogisticOperationTypesSelectProps<
    TFieldValues,
    TName,
    IsMulti
  >
) => {
  const res = useLogisticOperationTypes();
  return (
    <ControlledSimpleSelect<TFieldValues, number, IsMulti, TName>
      {...props}
      {...res}
      name={props.name}
    />
  );
};
