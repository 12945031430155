import { PropsWithChildren } from "react";
import { EditPage } from "../../../resources/components/EditPage";
import { useCampaignController } from "../hooks/useCampaignController";

const EditCampaignPage = ({ children }: PropsWithChildren) => {
  const res = useCampaignController();
  return <EditPage {...res}>{children}</EditPage>;
};

export default EditCampaignPage;
