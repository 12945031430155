import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import BroadcastList from "../../models/BroadcastList";
import { BroadcastListsFilters } from "../../models/filters/BroadcastListsFilters";
import { CreateResourceButton } from "../../resources/components/buttons/CreateResourceButton";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { useAddEditButton } from "../../resources/hooks/useAddEditButton";
import { ResourceName } from "../../resources/types/resourceName";

const BroadcastListsPage = () => {
  const columns: ResourceColumn<BroadcastList>[] = useMemo(
    () => [
      {
        header: "Nom",
        accessor: "name",
      },
    ],
    []
  );

  const { columnsWithButton } = useAddEditButton({
    columns,
  });

  const filters: FilterFieldData<BroadcastListsFilters>[] = useMemo(
    () => [
      {
        label: "Nom du groupe",
        fieldName: "name",
        type: "text",
        placeholder: "Nom",
      },
    ],
    []
  );
  return (
    <ListPage<ResourceName, BroadcastList, BroadcastListsFilters>
      filterFieds={filters}
      actions={<CreateResourceButton />}
      showChildWithoutFilters
    >
      <ResourceTable columns={columnsWithButton} />
    </ListPage>
  );
};

export default BroadcastListsPage;
