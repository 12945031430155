import { useCallback } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { ArticlesSelection } from "../../models/ArticlesSelection";
import { ListCondition } from "../../models/ListCondition";
import { ArticlesSelectionsFilters } from "../../models/filters/ArticlesSelectionsFilters";
import {
  ControlledResourcesSelect,
  ControlledResourcesSelectProps,
  ReactSelectProps,
  ResourcesSelect,
} from "../../resources/components/selects/ResourcesSelect";
import { ResourceName } from "../../resources/types/resourceName";
import ReactSelectOption from "../ReactSelectOption";

const useArticlesSelectionsSelectController = <
  TArticlesSelection extends ArticlesSelection = ArticlesSelection,
  TNumber extends number = number,
  TArticlesSelectionsFilters extends ArticlesSelectionsFilters = ArticlesSelectionsFilters
>() => {
  const convertToOption = useCallback(
    (
      value: TArticlesSelection | undefined
    ): ReactSelectOption<TNumber> | undefined =>
      value != null
        ? {
            label: `${value.label}`,
            // label: `${value.code} - ${value.label}`,
            value: value.id as TNumber,
          }
        : undefined,
    []
  );
  const transformToFilters = useCallback(
    (search: string) =>
      ({
        label: search,
        // code: search,
        listCondition: ListCondition.Or,
      } as TArticlesSelectionsFilters),
    []
  );
  const resourceName: ResourceName = "animations/articles-selections";
  return { convertToOption, resourceName, transformToFilters };
};

type ArticlesSelectionsSelectProps<IsMulti extends boolean> = ReactSelectProps<
  number,
  IsMulti
>;

export const ArticlesSelectionsSelect = <IsMulti extends boolean>(
  props: ArticlesSelectionsSelectProps<IsMulti>
) => {
  const c = useArticlesSelectionsSelectController();
  return (
    <ResourcesSelect<
      ArticlesSelection,
      number,
      IsMulti,
      ResourceName,
      ArticlesSelectionsFilters
    >
      {...c}
      {...props}
    />
  );
};

type ControlledArticlesSelectionsSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledResourcesSelectProps<
  ArticlesSelectionsSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledArticlesSelectionsSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledArticlesSelectionsSelectProps<TFieldValues, TName, IsMulti>
) => {
  const c = useArticlesSelectionsSelectController();

  return (
    <ControlledResourcesSelect<
      ArticlesSelection,
      number,
      IsMulti,
      ResourceName,
      ArticlesSelectionsFilters,
      TFieldValues,
      TName
    >
      {...c}
      {...props}
      name={props.name}
    />
  );
};
