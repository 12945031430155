import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import * as yup from "yup";
import ApplicationUser from "../../../models/ApplicationUser";

export const useResolver = () => {
  const resolver = useMemo(() => {
    return yupResolver<ApplicationUser>(
      yup.object().shape({
        id: yup
          .string()
          .required("L'identifiant de l'utilisateur est obligatoire"),
        email: yup
          .string()
          .required("L'email de l'utiliateur est obligatoire")
          .email("L'email n'est pas valide"),
      })
    );
  }, []);
  return { resolver };
};
