import { useCallback } from "react";
import { Resource } from "../../resources/types";
import { DataProvider, ReadProps } from "../types/dataProvider";
import { useResourceSafeActionCaller } from "./useResourceSafeActionCaller";

export const useReadResource = <
  TResourceName extends string = string,
  TResource extends Resource = Resource
>({
  canThrow = false,
}: {
  canThrow?: boolean;
}) => {
  const unsafeAction = useCallback(
    (
      dataProvider: DataProvider<TResourceName, TResource>,
      props: ReadProps<TResourceName>
    ) => {
      return dataProvider.read(props);
    },
    []
  );
  const { safeAction: read, errors } = useResourceSafeActionCaller({
    unsafeAction,
    canThrow,
  });

  return { read, errors };
};
