import { useCallback, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import TemporaryUrl from "../../models/TemporaryUrl";

export interface DownloadFileButtonProps {
  fetch: () => Promise<TemporaryUrl[]>;
}

const DownloadFileButton = (props: DownloadFileButtonProps) => {
  const { fetch } = props;
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [urls, setUrls] = useState<TemporaryUrl[]>();
  const [isInError, setIsInError] = useState<boolean>(false);

  const onClick = useCallback(async () => {
    if (urls) {
      for (const url of urls) {
        window.open(url.url);
      }
    } else {
      setIsBusy(true);
      fetch()
        .then((dataUrls) => {
          setUrls(dataUrls);
          for (const url of dataUrls) {
            window.open(url.url);
          }
        })
        .catch((e) => setIsInError(true))
        .finally(() => {
          setIsBusy(false);
        });
    }
  }, [urls, fetch]);

  return (
    <Button
      size="sm"
      variant={isInError ? "outline-danger" : "outline-dark"}
      disabled={isBusy}
      onClick={onClick}
    >
      {isBusy && <Spinner className="mx-1" animation="border" size="sm" />}
      <span>Télécharger</span>
    </Button>
  );
};

export default DownloadFileButton;
