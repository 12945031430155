import Customer from "../../models/Customer";
import TenantConfiguration from "../../models/TenantConfiguration";
import useFetchForCustomer from "../swr/useSWRFetchForCustomer";

const useTenantConfiguration = function (customer?: Customer) {
  const res = useFetchForCustomer("/api/Customers/configuration", customer);
  let tenantConfiguration = res.data as TenantConfiguration;
  return { ...res, tenantConfiguration };
};
export default useTenantConfiguration;
