import { useContext } from "react";
import {
  ResourceOptionsContext,
  ResourceOptionsContextValue,
} from "./ResourceOptionsContextProvider";

export const useResourceLabel = <TResourceLabel extends string = string>(
  props?: Partial<ResourceOptionsContextValue<TResourceLabel>>
): Pick<ResourceOptionsContextValue<TResourceLabel>, "label"> => {
  const context = useContext(
    ResourceOptionsContext
  ) as ResourceOptionsContextValue<TResourceLabel>;
  return { label: props?.label ?? context?.label };
};
