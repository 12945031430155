import { ImportFileType } from "./ImportFileType";
import ImportJobDefinition from "./ImportJobDefinition";

export default interface Synchronization {
  id: number;
  lastSyncedChange?: Date;
  startDate: Date;
  endDate?: Date;
  jobOperation: ImportJobOperation;
  jobId: string;
  status: SynchronizationStatus;
}

export interface ImportJobOperation {
  id: string;
  definition: ImportJobDefinition;
  receivedFiles: ImportFileReception[];
}

export interface ImportJobStatusInfo {
  message: string;
  statusDate: Date;
  statusType: StatusType;
}

export interface ImportFileReception {
  fileInfo: ImportFileInfo;
  importFileType: ImportFileType;
}

export interface ImportFileInfo {
  fileName: string;
  date: Date;
}

export enum StatusType {
  Info,
  Warning,
  Error,
}

export enum SynchronizationStatus {
  None = 0,
  InProgress = 1,
  Success = 2,
  Failure = 3,
  Cancel = 4,
}
