import { PropsWithChildren } from "react";
import { GoBackButton } from "../../components/buttons/GoBackButton";
import { Loading } from "../../components/types/loading";
import { useResourceName } from "../../resources/hooks/useResourceName";
import { useResourceParams } from "../../resources/hooks/useResourceParams";
import { Resource } from "../../resources/types";
import { PageTitle } from "./PageTitle";
import { ReadView } from "./ReadView";

type ReadPageProps = PropsWithChildren & {};

export const ReadPage = <TResource extends Resource = Resource>({
  children,
}: ReadPageProps) => {
  const { id } = useResourceParams();
  const { resourceName } = useResourceName();

  return (
    <>
      <GoBackButton />
      <PageTitle />
      <hr />
      {id && (
        <ReadView<string, TResource>
          loading={Loading.Circle}
          resourceName={resourceName}
          id={id}
        >
          {children}
        </ReadView>
      )}
    </>
  );
};
