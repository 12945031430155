import { LogisticOperationType } from "../../../models/LogisticOperation";

export const displayLogisticOperationType = (
  type: LogisticOperationType
): string => {
  return type === LogisticOperationType.Inventory
    ? "Inventaire"
    : type === LogisticOperationType.Labelling
    ? "Étiquetage"
    : type === LogisticOperationType.Receipt
    ? "Réception"
    : "";
};
