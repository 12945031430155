import { useEffect, useRef, useCallback } from "react";

/**
 * Get the width of an HTMLElement element.
 *  */
const useElementWidthRef = (callback: (elemWidth: number) => void) => {
  const elemRef = useRef<HTMLElement>(); // Store the element ref.

  const getAndCallbackWidth = useCallback(() => {
    if (elemRef.current) {
      callback(elemRef.current.getBoundingClientRect().width);
    }
  }, [callback]);

  // callback ref...
  const mesuredRefCallback = useCallback((div: HTMLElement | null) => {
    if (div != null) {

      elemRef.current = div;
      getAndCallbackWidth();
      window.addEventListener("resize", getAndCallbackWidth);

    }
  }, [getAndCallbackWidth]);

  // remove event listener...
  useEffect(() => {
    return () => { window.removeEventListener("resize", getAndCallbackWidth) }
  }, [getAndCallbackWidth]);

  return mesuredRefCallback;

};

export default useElementWidthRef;