import { useCallback, useMemo } from "react";
import { Button, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { MdClear, MdSearch } from "react-icons/md";
import { DynamicControl } from "./DynamicControl";
import { FilterFieldData, FilterFieldValues } from "./DynamicFieldData";

export interface HorizontalDynamicFormProps<T extends FilterFieldValues> {
  fields: FilterFieldData<T>[];
  value?: FilterFieldValues;
  onSubmit: (d: any) => void;
}

export const HorizontalDynamicForm = <T extends FilterFieldValues>(
  props: HorizontalDynamicFormProps<T>
) => {
  const { fields } = props;
  const emptyFields = useMemo(() => {
    let result: FilterFieldValues = {};
    for (const field of fields) {
      result[field.fieldName] = field.defaultValue;
    }
    return result;
  }, [fields]);

  const formMethods = useForm({
    defaultValues: emptyFields,
    values: props.value,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = formMethods;
  const finalSubmit = props.onSubmit;

  const onSubmit = useCallback(
    (data: any) => {
      finalSubmit(data);
      reset(data);
    },
    [finalSubmit, reset]
  );
  const canReset = useMemo(() => {
    return true;
  }, []);
  const onClear = useCallback(() => {
    // reset();
    onSubmit(emptyFields);
  }, [emptyFields, onSubmit /*, reset*/]);

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex align-items-end flex-wrap gap-2"
    >
      <FormProvider {...formMethods}>
        {fields.map((d, i) => (
          <DynamicControl key={i} {...d} />
        ))}

        <div className="vr"></div>
        <Form.Group className="d-flex gap-2">
          <Button
            type="submit"
            className="px-4"
            disabled={!isDirty || isSubmitting}
          >
            <MdSearch />
          </Button>
          <Button
            type="button"
            className="px-3"
            variant="outline-secondary"
            onClick={onClear}
            disabled={!canReset}
          >
            <MdClear />
          </Button>
        </Form.Group>
      </FormProvider>
    </Form>
  );
};
