import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import { DropShippingProvider } from "../../models/DropShippingProvider";
import { DropShippingProvidersFilters } from "../../models/filters/DropShippingProvidersFilters";
import { CreateResourceButton } from "../../resources/components/buttons/CreateResourceButton";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { useAddEditButton } from "../../resources/hooks/useAddEditButton";

const DropShippingProvidersPage = () => {
  const filters: FilterFieldData<DropShippingProvidersFilters>[] = useMemo(
    () => [
      {
        fieldName: "name",
        type: "text",
        label: "Nom",
        placeholder: "Nom",
      },
      {
        fieldName: "code",
        type: "text",
        label: "Code",
        placeholder: "Code",
      },
    ],
    []
  );

  const columns: ResourceColumn<DropShippingProvider>[] = useMemo(
    () => [
      {
        header: "Nom",
        accessor: "name",
      },
      {
        header: "Code",
        accessor: "code",
      },
    ],
    []
  );
  const { columnsWithButton } = useAddEditButton({ columns });

  return (
    <ListPage
      filterFieds={filters}
      actions={<CreateResourceButton />}
      showChildWithoutFilters
    >
      <ResourceTable columns={columnsWithButton} />
    </ListPage>
  );
};

export default DropShippingProvidersPage;
