import { ExtendedCampaign } from "../models/ExtendedCampaign";

const formatBeforeSubmitCampaign = function <T extends ExtendedCampaign>(c: T) {
  if (!isNaN(c.id)) {
    c.id = Number(c.id);
  }
  if (!c.startDate || (c.startDate && c.startDate.toString() === "")) {
    c.startDate = undefined;
  }
  if (!c.endDate || (c.endDate && (c.endDate as Date).toString() === "")) {
    c.endDate = undefined;
  }
  delete c.periodType;
  delete c.state;
  delete c.targetType;

  return c;
};
export default formatBeforeSubmitCampaign;
