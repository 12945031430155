import Campaign from "../models/Campaign";
import { CampaignPeriodType } from "../models/CampaignPeriodType";
import { getShortTextMonthnameDayofmonthYearDate } from "./dateUtils";
import { getCampaignPeriodType } from "./getCampaign";

const getCampaignDate = function (c: Campaign) {
  let result = "";
  if (getCampaignPeriodType(c) === CampaignPeriodType.Permanent) {
    result = "campagne permanente";
  } else {
    if (c.startDate) {
      result += `${getShortTextMonthnameDayofmonthYearDate(c.startDate)} `;
    }
    result += "► ";
    if (c.endDate) {
      result += getShortTextMonthnameDayofmonthYearDate(c.endDate);
    }
  }
  return result;
};
export default getCampaignDate;
