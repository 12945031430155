import { useEffect, useRef, useState } from "react";
import { AsyncHandler } from "../../../components/AsyncHandler";
import { LinearLoading } from "../../../components/LinearLoading";
import { Loading } from "../../../components/types/loading";
import { Resource } from "../../../resources/types";
import { usePaginationListResource } from "./usePaginationListResource";

export type AutoListResourceProps<TResource extends Resource> = {
  resources: (resources: Array<TResource>) => JSX.Element;
};

export const AutoListResource = <TResource extends Resource>(
  props: AutoListResourceProps<TResource>
) => {
  const { resources } = props;

  const { data, gotoPage, canNextPage, pageIndex, isLoading, error, mutate } =
    usePaginationListResource<TResource>();

  const pagedItems = data?.data;

  // items en memoire
  const [items, setItems] = useState<TResource[]>([]);

  useEffect(() => {
    if (pagedItems) {
      setItems((prevItems) => [...prevItems, ...pagedItems.items]);
    }
  }, [pagedItems]);

  // ref du dernier item visible dans le viewport pour s'abonnner à l'event qui permet de savoir qu'il apparait dans le viewport
  const lastItemRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const current = lastItemRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (pageIndex != null && entries[0].isIntersecting && canNextPage) {
          gotoPage(pageIndex + 1);
        }
      },
      // il faut que dès qu'il reste 100px avant que l'item soit visible, on charge la page suivante
      {
        rootMargin: "100px",
      }
    );
    if (current) {
      observer.observe(current);
    }
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [lastItemRef, canNextPage, gotoPage, pageIndex]);

  return (
    <AsyncHandler
      error={error}
      refresh={mutate}
      loading={Loading.Circle}
      isLoading={isLoading}
    >
      {resources && items && resources(items)}

      <div ref={lastItemRef}>
        {canNextPage && (
          <div className="my-4">
            <LinearLoading />
          </div>
        )}
      </div>
    </AsyncHandler>
  );
};
