import { EditPage } from "../../resources/components/EditPage";
import EditStoreForm from "./EditStoreForm";

const EditStorePage = () => {
  return (
    <EditPage>
      <EditStoreForm />
    </EditPage>
  );
};

export default EditStorePage;
