import { ControlledInteractiveCatalogsSelect } from "../../../components/selects/InteractiveCatalogsSelect";
import HomeCatalogsCampaign from "../../../models/HomeCatalogsCampaign";
import EditOrCreateCampaign from "./EditOrCreateCampaign";

const EditOrCreateHomeCatalogsCampaign = () => {
  return (
    <>
      <EditOrCreateCampaign />
      <ControlledInteractiveCatalogsSelect<HomeCatalogsCampaign>
        label="Contenu de la campagne*"
        name="interactiveCatalogId"
        rules={{ required: "Veuillez sélectionner un catalogue interactif" }}
      />
    </>
  );
};
export default EditOrCreateHomeCatalogsCampaign;
