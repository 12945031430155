import { DisplayBooleanFalse } from "./DisplayBooleanFalse";
import { DisplayBooleanTrue } from "./DisplayBooleanTrue";

type DisplayBooleanProps = {
  isTrue?: boolean;
};

export const DisplayBoolean = ({ isTrue }: DisplayBooleanProps) => {
  return isTrue ? <DisplayBooleanTrue /> : <DisplayBooleanFalse />;
};
