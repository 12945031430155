import { CustomerLink } from "../../../components/navigation/CustomerLink";
import { AnalyticsExportScheduledJob } from "../../../models/AnalyticsExportScheduledJob";
import { useTranslatedAnalyticsExportPeriodicity } from "./useTranslatedAnalyticsExportPeriodicity";
import { useTranslatedAnalyticsTable } from "./useTranslatedAnalyticsTable";

export interface AnalyticsExportScheduledJobsListProps {
  analyticsExportScheduledJobs: AnalyticsExportScheduledJob[];
}

const AnalyticsExportScheduledJobsList = (
  props: AnalyticsExportScheduledJobsListProps
) => {
  const { analyticsExportScheduledJobs } = props;
  const translatedAnalyticsTable = useTranslatedAnalyticsTable();
  const translatedAnalyticsExportPeriodicity =
    useTranslatedAnalyticsExportPeriodicity();

  return (
    <>
      <table className="table  table-sm table-hover">
        <thead>
          <tr>
            <th scope="col">Fréquence</th>
            <th scope="col">Emails</th>
            <th scope="col">Fichiers</th>
            <th scope="col" className="min"></th>
          </tr>
        </thead>
        <tbody>
          {analyticsExportScheduledJobs &&
            analyticsExportScheduledJobs.map((v) => (
              <tr key={v.id}>
                <td>{translatedAnalyticsExportPeriodicity[v.periodicity]}</td>
                <td>
                  {v.emails &&
                    v.emails.map((email) => <li key={email}>{email}</li>)}
                </td>
                <td>
                  {v.analyticsTables &&
                    v.analyticsTables.map((analyticsTable) => (
                      <li key={analyticsTable}>
                        {translatedAnalyticsTable[analyticsTable]}
                      </li>
                    ))}
                </td>
                <td>
                  <CustomerLink
                    className="btn btn-sm btn-outline-primary"
                    to={"/analyticsExportScheduledJobs/edit/" + v.id}
                  >
                    Éditer
                  </CustomerLink>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default AnalyticsExportScheduledJobsList;
