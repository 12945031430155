import ApplicationUser from "../../models/ApplicationUser";
import { EditPage } from "../../resources/components/EditPage";
import EditApplicationUserForm from "./components/EditApplicationUserForm";
import { useResolver } from "./hooks/useResolver";

const EditApplicationUserPage = () => {
  const res = useResolver();
  return (
    <EditPage<ApplicationUser> {...res}>
      <EditApplicationUserForm mode="edit" />
    </EditPage>
  );
};

export default EditApplicationUserPage;
