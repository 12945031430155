import React from "react";
import Alert from "react-bootstrap/esm/Alert";
import Form from "react-bootstrap/esm/Form";
import FormGroup from "react-bootstrap/esm/FormGroup";
import { useFormContext } from "react-hook-form";
import AnimationMedia from "../../../models/AnimationMedia";
import AnimationMediasCampaign from "../../../models/AnimationMediasCampaign";
import Campaign from "../../../models/Campaign";
import { useResourceParams } from "../../../resources/hooks/useResourceParams";
import AnimationMediasWorkflow from "./AnimationMediasWorkflow";
import EditOrCreateCampaign from "./EditOrCreateCampaign";

type IEditOrCreateAnimationMediasCampaignContext = {
  notifyAnimationMediaCampaignSaved?: (c: Campaign) => void;
};
export const EditOrCreateAnimationMediasCampaignContext =
  React.createContext<IEditOrCreateAnimationMediasCampaignContext>({});
export interface EditOrCreateAnimationMediasCampaignProps {
  editModalTitle?: string;
  createModalTitle?: string;
  addItemButtonMessage?: string;
}
const arrayName = "campaignAnimationMedias";
const EditOrCreateAnimationMediasCampaign = <T extends AnimationMedia>(
  props: EditOrCreateAnimationMediasCampaignProps
) => {
  const formMethods = useFormContext<AnimationMediasCampaign<T>>();
  const {
    formState: { errors },
  } = formMethods;
  const { id } = useResourceParams();

  return (
    <>
      <EditOrCreateCampaign />
      {id != null && (
        <FormGroup>
          <Form.Label>Contenu de la campagne</Form.Label>
          {(errors as any)[arrayName]?.message && (
            <Alert variant="danger">
              {(errors.campaignAnimationMedias as any)?.message}
            </Alert>
          )}
          <AnimationMediasWorkflow {...props} />
        </FormGroup>
      )}
    </>
  );
};

export default EditOrCreateAnimationMediasCampaign;
