import { CreatePage } from "../../resources/components/CreatePage";
import EditTransporterForm from "./EditTransporterForm";

const CreateTransporterPage = () => {
  return (
    <CreatePage>
      <EditTransporterForm />
    </CreatePage>
  );
};

export default CreateTransporterPage;
