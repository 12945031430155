import PowerBIEmbedClientParameters from "../../models/PowerBIEmbedClientParameters";
import Customer from "../../models/Customer";
import useFetchForCustomer from "../swr/useSWRFetchForCustomer";

const usePowerBIParameters = function (
    customer?: Customer,
  ) {
    const res = useFetchForCustomer(
      "/api/analytics/powerbiembedinfo",
      customer
    );
    return { ...res, powerBIParameters: res.data as PowerBIEmbedClientParameters };
  };
  
  export default usePowerBIParameters;
  
