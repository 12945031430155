import { EditPage } from "../../resources/components/EditPage";
import EditTransporterForm from "./EditTransporterForm";

const EditTransporterPage = () => {
  return (
    <EditPage>
      <EditTransporterForm />
    </EditPage>
  );
};

export default EditTransporterPage;
