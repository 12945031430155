import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";

export const useLogout = function () {
  const { instance } = useMsal();
  const logout = useCallback(() => {
    instance?.logoutRedirect().catch((e: any) => {
      console.error(e);
    });
  }, [instance]);

  return logout;
};
