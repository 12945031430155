import { useCallback } from "react";
import { Path, To } from "react-router-dom";
import { useCurrentCustomer } from "../LoggedInBackOffice";

export const useCustomerLinkTo = () => {
  const customer = useCurrentCustomer();
  const customerId = customer?.id;

  const customerLinkUrl = useCallback(
    (to: To) => {
      if (to != null && customerId != null) {
        let params: URLSearchParams;
        let pathname: string | undefined;

        if (typeof to === "string") {
          //relative url
          const url = new URL("https://mock.mock" + to);
          params = url.searchParams;
          pathname = url.pathname;
        } else {
          params = new URLSearchParams(to.search);
          const url = new URL(
            "https://mock.mock" + to.pathname + "?" + params.toString()
          );
          pathname = url.pathname;
        }
        params.set("customerId", customerId);

        return {
          pathname,
          search: "?" + params.toString(),
        } as Partial<Path>;
      }
      return to;
    },
    [customerId]
  );

  return { customerLinkUrl };
};
