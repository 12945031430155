import { ListView } from "../../../resources/components/lists/ListView";
import { ResourceTable } from "../../../resources/components/lists/ResourceTable";
import { ResourceName } from "../../../resources/types/resourceName";
import { useArticlesColumns } from "../hooks/useArticlesColumns";

type ArticlesProps = {
  parentId: number;
};

export const Articles = (props: ArticlesProps) => {
  const { parentId } = props;
  const { columns } = useArticlesColumns();

  return (
    <ListView<ResourceName>
      defaultFilters={{ parentId }}
      resourceName="articles"
      defaultPageSize={10}
    >
      <ResourceTable columns={columns} />
    </ListView>
  );
};
