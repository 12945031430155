import { EditPage } from "../../resources/components/EditPage";
import EditDropShippingProviderForm from "./EditDropShippingProviderForm";

const EditDropShippingProviderPage = () => {
  return (
    <EditPage>
      <EditDropShippingProviderForm />
    </EditPage>
  );
};

export default EditDropShippingProviderPage;
