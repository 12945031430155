import moment from "moment";
type DateString = string | Date | undefined;

export const getISO8601Date = (d: DateString) => {
  if (d == null) return d;
  return moment(d).format("YYYY-MM-DD");
};

export const getDateTime = (d: DateString) => {
  if (d == null) return d;
  return moment(d).format("L LTS");
};

export const getDate = (d: DateString) => {
  if (d == null) return d;
  return moment(d).format("L");
};

export const getShortTextMonthnameDayofmonthYearDate = (d: DateString) => {
  if (d == null) return d;
  return moment(d).format("ll");
};

export const getShortMonthnameDayofmonthYearTimeDate = (d: DateString) => {
  if (d == null) return d;
  return moment(d).format("lll");
};

export const getTimeWithoutSecondsDate = (d: DateString) => {
  if (d == null) return d;
  return moment(d).format("LT");
};

export const getTimeWithSecondsDate = (d: DateString) => {
  if (d == null) return d;
  return moment(d).format("LTS");
};
