import Button from "react-bootstrap/esm/Button";
import Spinner from "react-bootstrap/esm/Spinner";
import { ApiError } from "../../../models/ApiError";

type SaveResourceButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isBusy?: boolean;
  mode: "create" | "edit";
  submitError?: ApiError;
};
export const SaveResourceButton = (props: SaveResourceButtonProps) => {
  const { isBusy, mode, disabled, submitError, ...restProps } = props;
  return (
    <span className="d-inline-flex flex-wrap flex-row align-items-center gap-2">
      <Button type="submit" disabled={isBusy || disabled} {...restProps}>
        {isBusy && <Spinner className="mx-1" animation="border" size="sm" />}
        {mode === "create" ? "Créer" : "Sauvegarder"}
      </Button>
      {submitError && (
        <small className="text-danger">
          Une erreur est survenue. Veuillez réessayer.
        </small>
      )}
    </span>
  );
};
