import { useCallback, useMemo, useState } from "react";
import ToggleButton from "react-bootstrap/esm/ToggleButton";
import { useFieldArray, useFormContext } from "react-hook-form";
import { MdAdd, MdDelete } from "react-icons/md";
import AdMedia from "../../../models/AdMedia";
import DigitalSignageCampaign from "../../../models/DigitalSignageCampaign";
import { ListCondition } from "../../../models/ListCondition";
import { AnimationMediasFilters } from "../../../models/filters/AnimationMediasFilters";
import { AutoListResource } from "../../../resources/components/lists/AutoListResource";
import { ListView } from "../../../resources/components/lists/ListView";
import { PaginationListResource } from "../../../resources/components/lists/PaginationListResource";
import { ResourceName } from "../../../resources/types/resourceName";
import CampaignAnimationMediasHiddenInputs from "./CampaignAnimationMediasHiddenHiputs";
import SortWorkflow from "./SortWorkflow";
import Workflow from "./Workflow";
import { WorkflowItem } from "./WorkflowListItem";

const MediaWorkflow = () => {
  // Component state
  const [isAddingMedias, setIsAddingMedias] = useState(false);
  const { control } = useFormContext<DigitalSignageCampaign>();

  const { fields, append, remove, replace } = useFieldArray({
    control: control,
    name: "campaignAnimationMedias",
    // keyName: "key",
  });

  // "Methods"
  let addMediaInWorkflow = useCallback(
    (mediaId: number) => {
      append({ animationMediaId: mediaId });
    },
    [append]
  );

  let removeMediaFromWorkflow = useCallback(
    (mediaId: number) => {
      // on trouve l'index du media dans la liste
      const mediaIndex = fields.findIndex(
        (f) => f.animationMediaId === mediaId
      );
      remove(mediaIndex);
    },
    [remove, fields]
  );

  let goBackOrAddButton = (
    <ToggleButton
      type="checkbox"
      value="1"
      id="toggle-check"
      variant="secondary"
      checked={isAddingMedias}
      onChange={() => {
        setIsAddingMedias((i) => !i);
      }}
    >
      {isAddingMedias && <>Retour</>}
      {!isAddingMedias && <>Ajouter une publicité</>}
    </ToggleButton>
  );

  // Provide the action items.
  const mediaActionsFactory = useCallback(
    (item: WorkflowItem) => {
      return (
        <>
          {isAddingMedias && (
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => addMediaInWorkflow(item.id)}
            >
              <MdAdd />
            </button>
          )}
          {!isAddingMedias && (
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => removeMediaFromWorkflow(item.id)}
            >
              <MdDelete />
            </button>
          )}
        </>
      );
    },
    [addMediaInWorkflow, isAddingMedias, removeMediaFromWorkflow]
  );

  // Sort list on sort.
  const onSortEnd = useCallback(
    (oldIndex: number, newIndex: number) => {
      // switch d'index dans fields
      const item1 = fields.find((f) => f.index === oldIndex);
      const item2 = fields.find((f) => f.index === newIndex);

      if (item1 == null || item2 == null) return;

      item1.index = newIndex;
      item2.index = oldIndex;

      replace(fields);
    },
    [fields, replace]
  );

  const ids = useMemo(() => {
    return fields
      .toSorted((a, b) => (a.index ?? 0) - (b.index ?? 0))
      .filter((f) => f.animationMediaId != null)
      .map((f) => f.animationMediaId!);
    //   .toSorted((a, b) => a - b);
  }, [fields]);

  return (
    <div>
      {goBackOrAddButton}
      <CampaignAnimationMediasHiddenInputs<AdMedia>
        fields={fields}
        control={control}
      />
      {!isAddingMedias && (
        <ListView<ResourceName, AdMedia, AnimationMediasFilters>
          resourceName="animations/ads"
          defaultPageSize={30}
          defaultFilters={{ ids }}
        >
          <AutoListResource<AdMedia>
            resources={(items) => {
              return (
                <SortWorkflow
                  itemsCount={items.length}
                  fields={fields}
                  items={items}
                  actionFactory={mediaActionsFactory}
                  IsSortable={true}
                  onSortEnd={onSortEnd}
                />
              );
            }}
          />
        </ListView>
      )}
      {isAddingMedias && (
        <ListView<ResourceName, AdMedia, AnimationMediasFilters>
          resourceName="animations/ads"
          defaultPageSize={30}
          defaultFilters={{
            ids,
            listCondition: ListCondition.Not,
          }}
        >
          <PaginationListResource<AdMedia>
            resources={(resource) => {
              return (
                <Workflow
                  itemsCount={resource.data.totalItemsCount}
                  Items={resource.data.items as WorkflowItem[]}
                  actionFactory={mediaActionsFactory}
                  IsSortable={false}
                  onSortEnd={onSortEnd}
                />
              );
            }}
          />
        </ListView>
      )}
    </div>
  );
};

export default MediaWorkflow;
