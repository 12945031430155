import { useMemo } from "react";
import useCustomerApiFetch from "../../hooks/useCustomerApiFetch";
import { getResourceRestDataProvider } from "../../tools/getResourceRestDataProvider";
import { useResourceApiPathResolverContext } from "./useResourceApiPathResolverContext";

export const useResourceRestDataProvider = <
  TResourceName extends string = string,
  TResourceType = any
>() => {
  const apiFetcher = useCustomerApiFetch();
  const { resourceApiPathResolver } = useResourceApiPathResolverContext();
  return useMemo(
    () =>
      getResourceRestDataProvider<TResourceName, TResourceType>(
        apiFetcher,
        resourceApiPathResolver
      ),
    [apiFetcher, resourceApiPathResolver]
  );
};
