import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import { loginRequest } from "../authConfig";

export const useLogin = function () {
  const { instance } = useMsal();
  const login = useCallback(() => {
    instance?.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }, [instance]);

  return login;
};
