import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export type StateValueType = number | boolean | string | Date | undefined;
export type SupportedParamsTypes = "number" | "string" | "boolean" | "Date";
const SUPPORTED_PARAMS_TYPES = ["number", "string", "boolean", "Date"];

export function useQueryAsState<T extends Record<string, StateValueType>>(
  types?: Record<string, SupportedParamsTypes>,
  defaultValues?: T,
  removeDefaultValuesFromUrl?: boolean
): [T, (updatedParams: Partial<T>) => void] {
  if (types) validateTypes(types);

  const { pathname, search } = useDecodedLocation(types);
  const navigate = useNavigate();

  const encodedDefaultValues = useMemo(() => {
    if (defaultValues !== undefined) {
      return encodeValues(defaultValues);
    }
  }, [defaultValues]);

  const updateQuery = useCallback(
    (updatedParams: Partial<T>) => {
      navigate(
        pathname +
          objectToQueryParams(
            encodeValues({ ...search, ...updatedParams }),
            removeDefaultValuesFromUrl !== undefined &&
              removeDefaultValuesFromUrl,
            encodedDefaultValues
          ),
        {
          //  replace: true,
        }
      );
    },
    [
      encodedDefaultValues,
      navigate,
      pathname,
      removeDefaultValuesFromUrl,
      search,
    ]
  );

  const queryWithDefault = useMemo(
    () =>
      defaultValues !== undefined
        ? Object.assign({}, defaultValues, removeUndefined(search))
        : removeUndefined(search),
    [defaultValues, search]
  );

  return [queryWithDefault as T, updateQuery];
}

const useDecodedLocation = (types?: Record<string, string>) => {
  const { search, ...rest } = useLocation();

  const decodedSearch = useMemo(
    () => decodeValues(getQueryParamsAsObject(search), types),
    [search, types]
  );

  return { search: decodedSearch, ...rest };
};

const decodeValues = <T extends Record<string, string>>(
  obj: T,
  types?: Record<string, string>
) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]:
        value &&
        (parseValue(key, decodeURIComponent(value), types) as StateValueType),
    }),
    {} as T
  ) as Record<string, StateValueType>;

const encodeValues = <T extends Record<string, StateValueType>>(obj: T) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    const formattedValue = formatValue(value);
    return {
      ...acc,
      [key]: formattedValue && encodeURIComponent(formattedValue),
    };
  }, {} as T);

const getQueryParamsAsObject = (search: string) => {
  const params: Record<string, string> = {};

  new URLSearchParams(search).forEach((value, key) => (params[key] = value));

  return params;
};

export const removeUndefined = <T extends Record<string, StateValueType>>(
  obj: T
) =>
  Object.entries(obj)
    .filter(([, value]) => value !== undefined)
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {} as Partial<T>
    );

export const objectToQueryParams = <T extends Record<string, StateValueType>>(
  obj: Record<string, StateValueType>,
  removeDefaultValuesFromUrl: boolean,
  defaultValues?: T
) =>
  "?" +
  Object.entries(obj)
    .filter(([key, value]) => {
      if (value == null) {
        return false;
      } else if (
        removeDefaultValuesFromUrl &&
        defaultValues != null &&
        defaultValues[key] != null &&
        defaultValues[key] === value
      ) {
        return false;
      }
      return true;
    })
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

const booleanValues: Record<string, boolean> = {
  true: true,
  false: false,
};
function parseValue(
  key: string,
  value: string,
  types?: Record<string, string>
) {
  if (!types) return value as unknown as StateValueType;
  const type = types[key];

  if (type === "number") {
    return Number(value);
  }
  if (type === "boolean") {
    return booleanValues[value];
  }
  if (type === "string") {
    return value;
  }
  if (type === "Date") {
    const date = new Date(value);
    if (!isNaN(date.getDate())) {
      return date;
    }
  }

  return value;
}
function validateTypes(types?: Record<string, string>) {
  if (types) {
    const isValidTypes = Object.values(types).every((type) =>
      SUPPORTED_PARAMS_TYPES.includes(type)
    );

    if (!isValidTypes) {
      throw new Error(
        `Unsupported param types. Must be one of [${SUPPORTED_PARAMS_TYPES.map(
          (item) => item
        ).join(", ")}]`
      );
    }
  }
}
function formatValue(
  value: StateValueType
): string | number | boolean | undefined {
  if (value instanceof Date) {
    return (value as Date).toISOString();
  }
  return value;
}
