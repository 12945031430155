import { useFormContext } from "react-hook-form";
import { InputNumberSelect } from "../../../../components/InputSelect";
import ReactSelectOption from "../../../../components/ReactSelectOption";
import TargetInput from "../../../../components/TargetInput";
import AdMedia from "../../../../models/AdMedia";
import {
  AnimationMediaTargetType,
  MediaFileType,
} from "../../../../models/AnimationMedia";
import { ResourceFileInput } from "../../../../resources/components/inputs/ResourceFileInput";
import { ResourceHiddenTextInput } from "../../../../resources/components/inputs/ResourceHiddenTextInput";
import { ResourceInput } from "../../../../resources/components/inputs/ResourceInput";

const mediaOptions: ReactSelectOption<number>[] = [
  { value: MediaFileType.Image, label: "Image" },
  { value: MediaFileType.Video, label: "Vidéo" },
];

const EditOrCreateAdMediaForm = () => {
  const {
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const mediaType = parseInt(watch("mediaType") as unknown as string);

  const isBusy = isSubmitting;

  return (
    <>
      <ResourceHiddenTextInput<AdMedia> name="thumbnailUrl" />
      <ResourceHiddenTextInput<AdMedia> name="mediaUrl" />
      <ResourceInput<AdMedia>
        label="Nom de la publicité*"
        name="label"
        options={{
          required: { message: "Le nom est obligatoire", value: true },
          maxLength: {
            message:
              "Le nom est obligatoire ne peut pas dépasser 50 caractères",
            value: 50,
          },
        }}
      />

      {mediaType === MediaFileType.Image && (
        <>
          <ResourceInput<AdMedia>
            label="Texte de la publicité"
            name="header"
            options={{
              maxLength: {
                message:
                  "Le texte de la publicité ne peut pas dépasser 255 caractères",
                value: 255,
              },
            }}
            infoMessage="Ce texte (optionnel) s'affiche au-dessus de l'image de la publicité."
          />
          <ResourceInput<AdMedia>
            label="Libellé du bouton"
            name="buttonLabel"
            options={{
              maxLength: {
                message:
                  "Le libellé du bouton ne peut pas dépasser 255 caractères",
                value: 255,
              },
            }}
            infoMessage="Lorsque ce champ est renseigné un bouton apparait au-dessus de l’image avec le texte spécifié. (Ex : Voir les produits)"
          />
        </>
      )}

      <TargetInput
        targetOptions={[
          AnimationMediaTargetType.Home,
          AnimationMediaTargetType.Category,
          AnimationMediaTargetType.Brand,
          AnimationMediaTargetType.Product,
          AnimationMediaTargetType.Search,
          AnimationMediaTargetType.InteractiveCatalog,
          AnimationMediaTargetType.ArticlesSelection,
          AnimationMediaTargetType.WebUrl,
        ]}
        control={control}
        errors={errors}
        disabled={isBusy}
      />

      <InputNumberSelect
        label="Type de média*"
        name="mediaType"
        control={control}
        rules={{ required: true }}
        disabled={isBusy}
        options={mediaOptions}
      />

      <div className="d-flex flex-row gap-2 align-items-start">
        <div className="d-flex flex-column gap-2">
          <>
            {mediaType === MediaFileType.Image ? (
              <ResourceFileInput<AdMedia>
                accept=".jpg,.jpeg,.jfif,.png"
                label="Image*"
                name="mediaUrl"
                mediaType="image"
                rules={{
                  required: "L'image est obligatoire",
                }}
              />
            ) : (
              <ResourceFileInput<AdMedia>
                accept=".mp4"
                label="Vidéo*"
                mediaType="video"
                name="mediaUrl"
                rules={{
                  required: "La vidéo est obligatoire",
                }}
              />
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default EditOrCreateAdMediaForm;
