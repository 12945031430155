import { useCallback } from "react";
import { useNavigate } from "react-router";
import { useCurrentCustomer } from "../../../LoggedInBackOffice";
import { GoBackButton } from "../../../components/buttons/GoBackButton";
import { AnalyticsExportScheduledJob } from "../../../models/AnalyticsExportScheduledJob";
import { PageTitle } from "../../../resources/components/PageTitle";
import EditAnalyticsExportScheduledJobForm from "../shared/EditAnalyticsExportScheduledJobForm";

const CreateAnalyticsExportScheduledJobPage = () => {
  const navigate = useNavigate();

  const currentCustomer = useCurrentCustomer();
  const onSaveRedirectToEdit = useCallback(
    (analyticsExportScheduledJob: AnalyticsExportScheduledJob) => {
      navigate(
        `/analyticsExportScheduledJobs/edit/${analyticsExportScheduledJob.id}?customerId=${currentCustomer?.id}`,
        { replace: true }
      );
    },
    [navigate, currentCustomer?.id]
  );

  return (
    <>
      <GoBackButton />
      <PageTitle label="Planifications d'export de statistiques" />
      <hr />
      <EditAnalyticsExportScheduledJobForm
        mode="create"
        onSaveCallback={onSaveRedirectToEdit}
      />
    </>
  );
};

export default CreateAnalyticsExportScheduledJobPage;
