import { EditPage } from "../../resources/components/EditPage";
import EditAnimationGroupForm from "./components/EditAnimationGroupForm";

const EditAnimationGroupPage = () => {
  return (
    <EditPage>
      <EditAnimationGroupForm />
    </EditPage>
  );
};

export default EditAnimationGroupPage;
