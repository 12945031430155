import {
  Link,
  LinkProps,
  NavLink,
  NavLinkProps,
  Navigate,
  NavigateProps,
} from "react-router-dom";
import { useCustomerLinkTo } from "../../hooks/useCustomerLinkTo";

export type CustomerLinkProps = LinkProps;

export type CustomerNavLinkProps = NavLinkProps;

export type CustomerRedirectProps = NavigateProps;

export const CustomerLink = (props: CustomerLinkProps) => {
  const { customerLinkUrl } = useCustomerLinkTo();
  return <Link {...props} to={customerLinkUrl(props.to)} />;
};

export const CustomerNavLink = (props: CustomerNavLinkProps) => {
  const { customerLinkUrl } = useCustomerLinkTo();
  return <NavLink {...props} to={customerLinkUrl(props.to)} />;
};

export const CustomerRedirect = (props: CustomerRedirectProps) => {
  const { customerLinkUrl } = useCustomerLinkTo();

  return <Navigate {...props} to={customerLinkUrl(props.to)} replace />;
};
