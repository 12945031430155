import { useEffect, useState } from "react";
import { ElasticGetArticlesResponse } from "../tools/elasticsearch/models/ElasticGetArticlesResponse";
import { SearchResult } from "../tools/elasticsearch/models/SearchResult";
import { nameof } from "../tools/nameof";
import useCustomerApiFetch from "./useCustomerApiFetch";
import { search } from "../tools/elasticsearch/search";
import { Article } from "../tools/elasticsearch/models/Article";
import { getMustQuery } from "../tools/elasticsearch/queryHelper";
import { Reference } from "../tools/elasticsearch/models/Reference";
import { ElasticQuery } from "../tools/elasticsearch/models/ElasticQuery";

type UseElasticSearchArticlesByReferenceProps = {
  id?: string;
  reference?: string;
  barcode?: string;
  name?: string;
  strict?: boolean;
};
export function useElasticSearchArticlesByReference(
  props: UseElasticSearchArticlesByReferenceProps
) {
  const { id, reference, strict = false, barcode, name } = props;
  const [elasticResponse, setElasticResponse] =
    useState<ElasticGetArticlesResponse>();
  const apiFetcher = useCustomerApiFetch();

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (
      id !== undefined ||
      reference !== undefined ||
      barcode !== undefined ||
      name !== undefined
    ) {
      const hitFrom = 0;
      const hitsSize = 20;
      const idQuery = getTerm(
        nameof<Article>((a) => a.id),
        id
      );
      const referenceQuery = strict
        ? getReferenceTerm(reference)
        : getReferenceMatch(reference);

      const referencesQuery = getNested(
        nameof<Article>((a) => a.references),
        strict
          ? getReferenceValueTerm(barcode)
          : getReferenceValueMatch(barcode)
      );

      const nameQuery = strict
        ? getTerm(
            nameof<Article>((a) => a.name),
            name
          )
        : getMatch(
            nameof<Article>((a) => a.name),
            name
          );

      setIsLoading(true);
      search(
        hitFrom,
        hitsSize,
        {
          query: getMustQuery(
            [idQuery, referenceQuery, referencesQuery, nameQuery].filter(
              (q) => q !== undefined
            )
          ),
        },
        apiFetcher
      )
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            throw new Error("Unable to get products");
          }
        })
        .then((r: SearchResult) => {
          setElasticResponse({ searchResult: r, error: undefined });
          setIsLoading(false);
        })
        .catch((error) => {
          setElasticResponse({ searchResult: undefined, error });
          setIsLoading(false);
        });
    } else {
      setElasticResponse(undefined);
      setIsLoading(false);
    }
  }, [apiFetcher, barcode, id, name, reference, strict]);
  return { elasticResponse, isLoading };
}

function getReferenceMatch(reference: string | undefined) {
  if (reference === undefined) {
    return;
  }
  return getMatch(
    nameof<Article>((a) => a.reference),
    reference
  );
}

function getReferenceTerm(reference: string | undefined) {
  if (reference === undefined) {
    return;
  }
  return getTerm(nameof<Article>((a) => a.reference) + ".keyword", reference);
}

function getReferenceValueTerm(reference: string | undefined) {
  if (reference === undefined) {
    return;
  }
  return getTerm(
    nameof<Article>((a) => a.references) +
      "." +
      nameof<Reference>((r) => r.referenceValue) +
      ".keyword",
    reference
  );
}

function getReferenceValueMatch(reference: string | undefined) {
  if (reference === undefined) {
    return;
  }
  return getMatch(
    nameof<Article>((a) => a.references) +
      "." +
      nameof<Reference>((r) => r.referenceValue),
    reference
  );
}

function getMatch(path: string, query: string | undefined) {
  if (query === undefined) {
    return;
  }
  return {
    match: {
      [path]: { query: query },
    },
  };
}

function getTerm(path: string, value: string | undefined) {
  if (value === undefined) {
    return;
  }
  return {
    term: {
      [path]: {
        value: value,
      },
    },
  };
}

function getNested(path: string, query: ElasticQuery | undefined) {
  if (query === undefined) {
    return;
  }
  return {
    nested: {
      path: path,
      query: query,
    },
  };
}
