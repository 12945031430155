import { useEffect, useRef } from "react";

//see: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
/**
 * 
 * @param value Keep track of the previous value.
 */
const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;