import { useCallback } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { ListCondition } from "../../models/ListCondition";
import StockLocation from "../../models/StockLocation";
import { StockLocationsFilters } from "../../models/filters/StockLocationsFilters";
import {
  ControlledResourcesSelect,
  ControlledResourcesSelectProps,
  ReactSelectProps,
  ResourcesSelect,
} from "../../resources/components/selects/ResourcesSelect";
import { ResourceName } from "../../resources/types/resourceName";
import ReactSelectOption from "../ReactSelectOption";

const useStockLocationsSelectController = <
  TStockLocation extends StockLocation = StockLocation,
  TNumber extends number = number,
  TFilters extends StockLocationsFilters = StockLocationsFilters
>() => {
  const convertToOption = useCallback(
    (
      value: TStockLocation | undefined
    ): ReactSelectOption<TNumber> | undefined =>
      value != null
        ? {
            label: `${value.code} - ${value.name}`,
            value: value.id as TNumber,
          }
        : undefined,
    []
  );
  const transformToFilters = useCallback(
    (search: string) =>
      ({
        name: search,
        code: search,
        listCondition: ListCondition.Or,
      } as TFilters),
    []
  );
  const resourceName: ResourceName = "stock-locations";
  return { convertToOption, resourceName, transformToFilters };
};

type StockLocationsSelectProps<IsMulti extends boolean> = ReactSelectProps<
  number,
  IsMulti
>;

export const StockLocationsSelect = <IsMulti extends boolean>(
  props: StockLocationsSelectProps<IsMulti>
) => {
  const c = useStockLocationsSelectController();
  return (
    <ResourcesSelect<
      StockLocation,
      number,
      IsMulti,
      ResourceName,
      StockLocationsFilters
    >
      {...c}
      {...props}
    />
  );
};

type ControlledStockLocationsSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledResourcesSelectProps<
  StockLocationsSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledStockLocationsSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledStockLocationsSelectProps<TFieldValues, TName, IsMulti>
) => {
  const c = useStockLocationsSelectController();

  return (
    <ControlledResourcesSelect<
      StockLocation,
      number,
      IsMulti,
      ResourceName,
      StockLocationsFilters,
      TFieldValues,
      TName
    >
      {...c}
      {...props}
      name={props.name}
    />
  );
};
