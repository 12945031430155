import Device from "../../models/Device";
import { EditPage } from "../../resources/components/EditPage";
import EditDeviceForm from "./components/EditDeviceForm";

const EditDevicePage = () => {
  return (
    <EditPage<Device>>
      <EditDeviceForm mode="edit" />
    </EditPage>
  );
};

export default EditDevicePage;
