import { useCallback, useMemo, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import {
  FieldValues,
  UseFieldArrayProps,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { MdAdd, MdDeleteForever, MdEdit } from "react-icons/md";
import InteractiveCatalog, {
  InteractivePage,
} from "../../../../models/InteractiveCatalog";
import { ResourceHiddenNumberInput } from "../../../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceInput } from "../../../../resources/components/inputs/ResourceInput";
import Workflow from "../../../Campaigns/components/Workflow";
import { WorkflowItem } from "../../../Campaigns/components/WorkflowListItem";
import InteractivePageModalForm from "./InteractivePageModalForm";

/**
 * Same as 'useFieldArray' but provide workflowItem objs and 'onSortEnd' function
 */
function useWorkflowFieldArray(
  useFieldArgs: UseFieldArrayProps<InteractiveCatalog>,
  mapCallback: (f: Partial<FieldValues>) => WorkflowItem
) {
  const fArrayRes = useFieldArray({ ...useFieldArgs, keyName: "_id" });
  // const fArrayRes = useFieldArray(useFieldArgs);
  const { move, fields } = fArrayRes;

  const workflowItems = useMemo(() => {
    return fields.map(mapCallback);
  }, [fields, mapCallback]);

  // move on sort end.
  const onSortEnd = useCallback(
    (oldIndex: number, newIndex: number) => {
      move(oldIndex, newIndex);
    },
    [move]
  );

  return { ...fArrayRes, onSortEnd, workflowItems, fields };
}

const EditInteractiveCatalogForm = () => {
  const { getValues, control, reset } = useFormContext<InteractiveCatalog>();
  const catalog = getValues();
  const { remove, workflowItems, onSortEnd, append, update, fields } =
    useWorkflowFieldArray(
      {
        control: control,
        name: "interactivePages",
      },
      useCallback((m) => {
        return {
          id: m.id as unknown as number,
          thumbnailUrl: m.thumbnailUrl,
        } as WorkflowItem;
      }, [])
    );

  const [currentFormPageInfos, setCurrentFormPageInfos] = useState<{
    isModalOpen: boolean;
    isNew: boolean;
    page: InteractivePage;
  }>({
    isModalOpen: false,
    isNew: true,
    page: new InteractivePage(),
  });

  const addOrEditPage = useCallback(
    (pageId?: number) => {
      const page = fields?.find((p) => p.id === pageId);
      const isPageNew = page === undefined;

      setCurrentFormPageInfos({
        isModalOpen: true,
        isNew: isPageNew,
        page: (page as InteractivePage) ?? new InteractivePage(),
      });
    },
    [fields]
  );

  const onCancelAddOrEditPage = useCallback(() => {
    setCurrentFormPageInfos({
      isModalOpen: false,
      isNew: true,
      page: new InteractivePage(),
    });
  }, []);

  const onPageSave = useCallback(
    (page: InteractivePage) => {
      // Edit the workflow list
      let existingPageIndex = 0;
      const isNew =
        workflowItems.find((w: WorkflowItem, i: number) => {
          const iN = w.id === page.id;
          if (iN) {
            existingPageIndex = i;
          }
          return iN;
        }) === undefined;

      if (isNew) {
        append(page);
      } else {
        update(existingPageIndex, page);
        // remove(existingPageIndex);
        // insert(existingPageIndex, page);
      }

      // save the page (visible if the form is reseted)
      let iPages = fields.map((v) => {
        if (v.id === page.id) {
          return page;
        }

        return v;
      });

      if (isNew) {
        iPages = [...iPages, page];
      }
      reset({ ...catalog, interactivePages: iPages });

      setCurrentFormPageInfos({
        isModalOpen: false,
        isNew: true,
        page: new InteractivePage(),
      });
    },
    [append, catalog, fields, reset, update, workflowItems]
  );

  return (
    <>
      <ResourceInput<InteractiveCatalog>
        label="Nom"
        name="label"
        options={{
          required: "Le nom du catalogue est obligatoire",
          maxLength: {
            message: "Le nom du catalogue ne peut pas dépasser 50 caractères",
            value: 50,
          },
        }}
      />

      <Button
        variant="outline-primary"
        className="align-self-start"
        onClick={() => addOrEditPage()}
      >
        <MdAdd />
        Ajouter une page
      </Button>
      <Workflow
        itemsCount={workflowItems.length}
        Items={workflowItems}
        IsSortable={true}
        onSortEnd={onSortEnd}
        actionFactory={(item: WorkflowItem, index: number) => (
          <>
            <Button
              type="button"
              variant="outline-secondary"
              onClick={() => remove(index)}
            >
              <MdDeleteForever />
            </Button>
            <ResourceHiddenNumberInput<InteractiveCatalog>
              name={`interactivePages.${index}.id` as const}
            />

            <Button
              type="button"
              variant="outline-secondary"
              onClick={() => addOrEditPage(item.id)}
            >
              <MdEdit />
            </Button>
          </>
        )}
      />
      <InteractivePageModalForm
        showModal={currentFormPageInfos.isModalOpen}
        editOrCreate={currentFormPageInfos.isNew ? "create" : "edit"}
        currentPage={currentFormPageInfos.page}
        catalogId={catalog.id}
        onCancel={onCancelAddOrEditPage}
        onSaveCallback={onPageSave}
      />
    </>
  );
};

export default EditInteractiveCatalogForm;
