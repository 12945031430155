import { EditPage } from "../../resources/components/EditPage";
import EditDeliveryZoneForm from "./EditDeliveryZoneForm";

const EditDeliveryZonePage = () => {
  return (
    <EditPage>
      <EditDeliveryZoneForm />
    </EditPage>
  );
};

export default EditDeliveryZonePage;
