import { EditPage } from "../../resources/components/EditPage";
import EditStockLocationForm from "./EditStockLocationForm";

const EditStockLocationPage = () => {
  return (
    <EditPage canDelete={false}>
      <EditStockLocationForm />
    </EditPage>
  );
};

export default EditStockLocationPage;
