import CreateCampaignPage from "../components/CreateCampaignPage";
import EditOrCreateAnimationMediasCampaign from "../components/EditOrCreateAnimationMediasCampaign";

const CreateHomePanelsCampaignPage = () => {
  return (
    <CreateCampaignPage>
      <EditOrCreateAnimationMediasCampaign />
    </CreateCampaignPage>
  );
};

export default CreateHomePanelsCampaignPage;
