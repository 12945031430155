import { useCallback } from "react";
import WorkflowListItem, { WorkflowItem } from "./WorkflowListItem";

export interface WorkflowProps {
  Items: WorkflowItem[];
  itemsCount: number;
  IsSortable: boolean;
  actionFactory: (item: WorkflowItem, index: number) => JSX.Element;
  onSortEnd?: (oldIndex: number, newIndex: number) => void;
}

const Workflow = (props: WorkflowProps) => {
  const items = props.Items;
  const itemsCount = props.itemsCount;
  const isSortable = props.IsSortable;
  const onSortEnd = props.onSortEnd;

  const onSortLeft = useCallback(
    (item: WorkflowItem) => {
      const oldIndex = item.index ?? items.indexOf(item);
      if (oldIndex == null) return;
      const newIndex = oldIndex - 1;

      if (onSortEnd && newIndex >= 0) {
        onSortEnd(oldIndex, newIndex);
      }
    },
    [items, onSortEnd]
  );

  const onSortRight = useCallback(
    (item: WorkflowItem) => {
      const oldIndex = item.index ?? items.indexOf(item);

      const newIndex = oldIndex + 1;

      if (onSortEnd && newIndex < itemsCount) {
        onSortEnd(oldIndex, newIndex);
      }
    },
    [items, itemsCount, onSortEnd]
  );

  return (
    <div className="row g-2 row-cols-2 row-cols-sm-3  row-cols-md-4 row-cols-lg-5 row-cols-xl-6  ">
      {items.map((value, index) => (
        <div key={value.id} className="col g-3 ">
          <WorkflowListItem
            canSortLeft={(value.index ?? index) !== 0 && isSortable}
            canSortRight={
              (value.index ?? index) !== itemsCount - 1 && isSortable
            }
            onSortLeft={onSortLeft}
            onSortRight={onSortRight}
            Item={value}
            Actions={props.actionFactory(value, index)}
          />
        </div>
      ))}
    </div>
  );
};

export default Workflow;
