import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import Order from "../../models/Order";
import { OrderStatus, orderStatusToText } from "../../models/OrderStatus";
import { OrdersFilters } from "../../models/filters/OrdersFilters";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { useAddEditButton } from "../../resources/hooks/useAddEditButton";
import { getDate } from "../../tools/dateUtils";

/**
 * Liste les commandes active du client.
 */
const OrdersPage = () => {
  const filters: FilterFieldData<OrdersFilters>[] = useMemo(
    () => [
      {
        label: "Numéro",
        fieldName: "reference",
        type: "text",
        placeholder: "Numéro de commande",
      },
      {
        label: "Magasin",
        fieldName: "storeId",
        type: "stores-select",
        defaultValue: undefined,
        placeholder: "Magasin",
      },
      {
        label: "Statut",
        fieldName: "orderStatus",
        type: "number-select",
        defaultValue: undefined,
        options: [
          {
            label: orderStatusToText(OrderStatus.Registred),
            value: OrderStatus.Registred,
          },
          {
            label: orderStatusToText(OrderStatus.Paid),
            value: OrderStatus.Paid,
          },
          {
            label: orderStatusToText(OrderStatus.Canceled),
            value: OrderStatus.Canceled,
          },
        ],
      },
      {
        label: "Date de début",
        fieldName: "from",
        type: "date",
      },
      {
        label: "Date de fin",
        fieldName: "to",
        type: "date",
      },
      {
        label: "Nom",
        fieldName: "lastName",
        type: "text",
        placeholder: "Nom",
      },
      {
        label: "Email",
        fieldName: "email",
        type: "text",
        placeholder: "Email",
      },
      {
        label: "Code postal",
        fieldName: "postalCode",
        type: "text",
        placeholder: "Code postal",
      },
      {
        label: "Ville",
        fieldName: "city",
        type: "text",
        placeholder: "Ville",
      },
    ],
    []
  );

  const columns: ResourceColumn<Order>[] = useMemo(
    () => [
      {
        header: "Numéro",
        accessor: "reference",
      },
      {
        header: "Date",
        accessor: "orderDate",
        getCell: (o) => getDate(o.orderDate),
      },
      {
        header: "Nom",
        accessor: (o) => o.guestCustomer?.lastName,
      },
      {
        header: "Prénom",
        accessor: (o) => o.guestCustomer?.firstName,
      },
      {
        header: "Email",
        accessor: (o) => o.guestCustomer?.email,
      },
      {
        header: "Statut",
        accessor: "orderStatus",
        getCell: (o) => <>{orderStatusToText(o.orderStatus)}</>,
      },
      {
        header: "Total",
        accessor: "totalIncludingTaxes",
        getCell: (_o, value) => <>{value}€</>,
      },
    ],
    []
  );

  const { columnsWithButton } = useAddEditButton({
    columns,
  });

  return (
    <ListPage filterFieds={filters} showChildWithoutFilters>
      <ResourceTable columns={columnsWithButton} />
    </ListPage>
  );
};

export default OrdersPage;
