import Category from "../models/Category";

const uploadCategoryImage = async function (
  categoryId: number,
  apiFetcher: (
    requestInfo: string,
    requestInit?: RequestInit
  ) => Promise<any>,
  file?: File
) {
  let formData = new FormData();
  if (file) {
    formData.append("File", file);
  }

  const response = await apiFetcher(
    `/api/Categories/${categoryId}/UploadNewImage`,
    {
      method: "POST",
      body: formData,
    }
  );

  const catRes = await response.json();
  return catRes as Category;
};

export default uploadCategoryImage;
