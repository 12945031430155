import { useCallback } from "react";
import DownloadFileButton from "../../../components/buttons/DownloadFileButton";
import useCustomerApiFetch from "../../../hooks/useCustomerApiFetch";
import { ImportFileReception } from "../../../models/Synchronization";
import TemporaryUrl from "../../../models/TemporaryUrl";
import { getDateTime } from "../../../tools/dateUtils";
import getImportFileTypeFriendlyName from "../../../tools/getImportFileTypeFriendlyName";

export interface ImportFileReceptionListProps {
  receivedFiles: ImportFileReception[];
  jobId: string;
}

const ImportFileReceptionList = (props: ImportFileReceptionListProps) => {
  const { receivedFiles, jobId } = props;
  const apiFetcher = useCustomerApiFetch();

  const fetchTemporaryUrl = useCallback(
    (fileName: string) => {
      return apiFetcher(
        "/api/Synchronizations/" +
          jobId +
          "/GetImportFileDownloadUrl?importFileName=" +
          encodeURIComponent(fileName),
        {
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
        }
      )
        .then((d) => {
          if (!d.ok) {
            throw new Error(d.statusText);
          }
          return d.json();
        })
        .then((d) => {
          const t = d as TemporaryUrl;
          return t;
        });
    },
    [apiFetcher, jobId]
  );

  return (
    <div className="card">
      <div className="card-header d-flex">
        <span className="flex-fill d-flex align-items-center">Fichiers</span>
        <DownloadFileButton
          fetch={async () => {
            const a = receivedFiles.map((f) =>
              fetchTemporaryUrl(f.fileInfo.fileName)
            );
            const d = await Promise.all(a);
            return d;
          }}
        />
      </div>
      <table className="table  table-sm table-hover">
        <thead>
          <tr>
            <th>Nom du fichier</th>
            <th>Date</th>
            <th>Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {receivedFiles.map((f) => {
            return (
              <tr key={f.fileInfo.fileName}>
                <td>{f.fileInfo.fileName}</td>
                <td>{getDateTime(f.fileInfo.date)}</td>
                <td>{getImportFileTypeFriendlyName(f.importFileType)}</td>
                <td>
                  <DownloadFileButton
                    fetch={async () => {
                      const d = await fetchTemporaryUrl(f.fileInfo.fileName);
                      return [d];
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ImportFileReceptionList;
