import { useEffect, useState } from "react";
import { isNullOrUndefined } from "../../../../tools/isNullOrUndefined";

export type MediaType = "image" | "video";
export interface MediaPreviewerProps {
  mediaUrl: string | undefined;
  mediaType: MediaType;
}

const MediaPreviewer = ({ mediaUrl, mediaType }: MediaPreviewerProps) => {
  if (isNullOrUndefined(mediaUrl) || mediaUrl === "") {
    return null;
  }
  if (mediaType === "video") {
    return (
      <div className="embed-responsive embed-responsive-16by9">
        <video src={mediaUrl} controls className="embed-responsive-item w-25" />
      </div>
    );
  }

  return (
    <img
      // style={{ maxHeight: "2em" }}
      src={mediaUrl}
      alt="..."
      className="img-fluid w-25"
    />
  );
};

/** Retrun a tmp local url for the not yet uploaded file */
export const useFileUrlPreview = (files?: FileList | null) => {
  const [previewMediaFileUrl, setPreviewMediaFileUrl] = useState<string>();
  useEffect(() => {
    if (files && files[0]) {
      const objectUrl = URL.createObjectURL(files[0]);
      setPreviewMediaFileUrl(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    } else {
      setPreviewMediaFileUrl(undefined);
    }
  }, [files]);
  return previewMediaFileUrl;
};

export default MediaPreviewer;
