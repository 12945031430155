import { useCallback, useState } from "react";

import React from "react";
import { useFormContext } from "react-hook-form";
import { PropsValue } from "react-select";
import BroadcastList from "../../models/BroadcastList";
import Store from "../../models/Store";
import { StoresFilters } from "../../models/filters/StoresFilters";
import { ResourceHiddenNumberInput } from "../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceInput } from "../../resources/components/inputs/ResourceInput";
import { ResourcesSelect } from "../../resources/components/selects/ResourcesSelect";
import { ResourceName } from "../../resources/types/resourceName";

const EditBroadcastListForm = () => {
  const { getValues } = useFormContext<BroadcastList>();
  const broadcastListStores = getValues("broadcastListStores");

  const [storeIds, setStoreIds] = useState<number[] | undefined>(
    broadcastListStores
      ?.filter((bls) => bls.storeId != null)
      .map((bls) => bls.storeId!)
  );

  const onSearchSuggestChanged = useCallback(
    (s: PropsValue<number> | undefined) => {
      if (s) {
        if (Array.isArray(s)) {
          setStoreIds(s as number[]);
        } else {
          setStoreIds([s as number]);
        }
      } else {
        setStoreIds(undefined);
      }
    },
    []
  );

  return (
    <>
      {storeIds &&
        storeIds.map((storeId, index) => (
          <React.Fragment key={index}>
            {storeId && (
              <ResourceHiddenNumberInput
                name={`broadcastListStores.${index}.storeId`}
                options={{
                  valueAsNumber: true,
                }}
                value={storeId}
              />
            )}
          </React.Fragment>
        ))}

      <ResourceHiddenNumberInput name="id" options={{ valueAsNumber: true }} />
      <ResourceInput
        name="name"
        options={{
          required: "Le nom de la liste de diffusion est obligatoire",
          maxLength: {
            value: 50,
            message:
              "Le nom de la liste de diffusion ne doit pas dépasser 50 caractères",
          },
        }}
      />
      <ResourcesSelect<Store, number, true, ResourceName, StoresFilters>
        resourceName="stores"
        label="Magasins"
        isMulti
        idPropsValue={storeIds}
        onIdPropsValueChanged={onSearchSuggestChanged}
        transformToFilters={(search) => ({
          name: search,
        })}
        convertToOption={(value) =>
          value != null ? { label: value.name, value: value.id } : undefined
        }
      />
    </>
  );
};

export default EditBroadcastListForm;
