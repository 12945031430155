import { useCallback, useMemo } from "react";
import { Form, FormGroup } from "react-bootstrap";
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  useWatch,
} from "react-hook-form";
import { default as ReactSelect } from "react-select";
import { AnimationMediaTargetType } from "../models/AnimationMedia";
import Input from "./Input";
import ReactSelectOption from "./ReactSelectOption";
import SearchInput from "./SearchInput";
import { ControlledArticlesSelect } from "./selects/ArticlesSelect";
import { ControlledArticlesSelectionsSelect } from "./selects/ArticlesSelectionsSelect";
import { ControlledBrandsSelect } from "./selects/BrandsSelect";
import { ControlledCategoriesSelect } from "./selects/CategoriesSelect";
import { ControlledInteractiveCatalogsSelect } from "./selects/InteractiveCatalogsSelect";

interface TargetInputProps<T extends FieldValues> {
  control: Control<T>;
  errors?: { [id: string]: any };
  disabled?: boolean;
  targetOptions: AnimationMediaTargetType[];
  namePrefix?: string;
  targetTypeDefaultValue?: AnimationMediaTargetType;
}

const targetOptionsLabelValue: Record<
  AnimationMediaTargetType,
  ReactSelectOption<number>
> = {
  0: { value: AnimationMediaTargetType.Home, label: "Accueil" },
  1: { value: AnimationMediaTargetType.Search, label: "Rercherche" },
  2: { value: AnimationMediaTargetType.Category, label: "Catégorie" },
  3: { value: AnimationMediaTargetType.Product, label: "Produit" },
  4: { value: AnimationMediaTargetType.Brand, label: "Marque" },
  5: {
    value: AnimationMediaTargetType.InteractiveCatalog,
    label: "Catalogue interactif",
  },
  6: {
    value: AnimationMediaTargetType.ArticlesSelection,
    label: "Sélection de produits",
  },
  7: { value: AnimationMediaTargetType.WebUrl, label: "URL web" },
};

const TargetInput = function <T extends FieldValues>({
  control,
  errors,
  disabled,
  targetOptions,
  namePrefix: namePrefixOrUndefined,
  targetTypeDefaultValue,
}: TargetInputProps<T>) {
  const namePrefix = useMemo(() => {
    return namePrefixOrUndefined ?? "";
  }, [namePrefixOrUndefined]);

  const reactSelectOptions = useMemo(
    () =>
      targetOptions.map((key) => {
        return targetOptionsLabelValue[key];
      }),
    [targetOptions]
  );

  const targetTypeField = `${namePrefix}targetType` as FieldPath<T>;
  const currentTargetOption = useWatch({
    control,
    name: targetTypeField,
  });

  const isInvalid = errors && errors[targetTypeField];

  const noOptionsMessage = useCallback(() => "Pas d'option", []);
  const loadingMessage = useCallback(() => "Chargement...", []);
  return (
    <>
      <FormGroup>
        <Form.Label>Type d'action*</Form.Label>
        <Controller
          control={control}
          name={targetTypeField}
          rules={{ required: true }}
          defaultValue={
            targetTypeDefaultValue as PathValue<T, Path<T>> | undefined
          }
          render={({ field }) => (
            <ReactSelect
              {...field}
              className={`${isInvalid ? "is-invalid" : ""}`}
              isSearchable={false}
              placeholder="Sélectionner"
              loadingMessage={loadingMessage}
              noOptionsMessage={noOptionsMessage}
              value={reactSelectOptions.find(
                (o: ReactSelectOption<number>) => o.value === field.value
              )}
              options={reactSelectOptions}
              isDisabled={disabled}
              onChange={(o) => field.onChange(o?.value)}
            />
          )}
        />
        {isInvalid && (
          <Form.Control.Feedback type="invalid">
            Veuillez sélectionner le type d’action.
          </Form.Control.Feedback>
        )}
      </FormGroup>
      {currentTargetOption === AnimationMediaTargetType.Category && (
        <ControlledCategoriesSelect
          label="Catégorie cible"
          control={control as Control}
          rules={{ required: "Veuillez sélectionner une catégorie" }}
          name={`${namePrefix}categoryId`}
        />
      )}
      {currentTargetOption === AnimationMediaTargetType.Product && (
        <ControlledArticlesSelect
          control={control as Control}
          rules={{ required: "Veuillez sélectionner un article" }}
          name={`${namePrefix}productId`}
          label="Produit cible"
        />
      )}
      {currentTargetOption === AnimationMediaTargetType.Search && (
        <SearchInput
          control={control as Control}
          isInvalid={errors && errors[`searchTerms`]}
          disabled={disabled}
          name={`${namePrefix}searchTerms`}
        />
      )}
      {currentTargetOption === AnimationMediaTargetType.Brand && (
        <ControlledBrandsSelect
          label="Marque cible"
          rules={{ required: "Veuillez sélectionner une marque" }}
          control={control as Control}
          name={`${namePrefix}brandId`}
        />
      )}
      {currentTargetOption === AnimationMediaTargetType.InteractiveCatalog && (
        <ControlledInteractiveCatalogsSelect
          control={control as Control}
          label="Catalogue interactif cible"
          name={`${namePrefix}interactiveCatalogId`}
          rules={{ required: "Veuillez sélectionner un catalogue intéractif" }}
        />
      )}
      {currentTargetOption === AnimationMediaTargetType.ArticlesSelection && (
        <ControlledArticlesSelectionsSelect
          control={control as Control}
          label="Sélection d'articles cible"
          rules={{ required: "Veuillez sélectionner une sélection d'articles" }}
          name={`${namePrefix}articlesSelectionId`}
        />
      )}
      {currentTargetOption === AnimationMediaTargetType.WebUrl && (
        <Controller
          control={control as Control}
          rules={{
            required: "L'URL web est obligatoire",
            pattern: {
              value: /^(http|https):\/\/[^ "]+$/,
              message: "Veuillez saisir une URL valide",
            },
            maxLength: {
              value: 255,
              message: "L'URL web ne peut pas dépasser 255 caractères",
            },
          }}
          name={`${namePrefix}webUrl`}
          render={({ field }) => (
            <Input
              {...field}
              label="URL web cible"
              name={`${namePrefix}webUrl`}
              type="url"
              placeholder="https://www.example.com"
              isInvalid={errors && errors[`webUrl`]}
              errorMessage={
                errors && errors[`webUrl`]?.message
                  ? errors[`webUrl`].message
                  : ""
              }
              disabled={disabled}
            />
          )}
        />
      )}
    </>
  );
};
export default TargetInput;
