import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import Nullable from "../Nullable";
import { getTokenSilent } from "./getTokenSilent";

const authFetch = async function (
  requestInfo: Nullable<RequestInfo>,
  instance: Nullable<IPublicClientApplication>,
  account: Nullable<AccountInfo>
) {
  if (requestInfo == null) {
    throw new Error("apiFetcher : requestInfo can not be null");
  }

  if (instance == null) {
    throw new Error("apiFetcher : instance can not be null");
  }

  if (account == null) {
    throw new Error("apiFetcher : account can not be null");
  }

  const accessToken = await getTokenSilent(instance, account);
  if (requestInfo && typeof requestInfo != "string") {
    requestInfo.headers.set("Authorization", "Bearer " + accessToken ?? "");

    return await fetch(requestInfo);
  }
  return await fetch(requestInfo, {
    headers: new Headers({ Authorization: "Bearer " + accessToken ?? "" }),
  });
};
export default authFetch;
