import { Alert } from "react-bootstrap";
import { Thumbnail } from "../../components/Thumbnail";
import { CustomerLink } from "../../components/navigation/CustomerLink";
import Category from "../../models/Category";
import { PaginationListResource } from "../../resources/components/lists/PaginationListResource";
import { useResourceUrlPathResolver } from "../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../resources/types/resourceName";

const CategoriesList = () => {
  return (
    <PaginationListResource<Category>
      resources={(data) => (
        <>
          {data?.data?.items && (
            <>
              <div className="row row-cols-3">
                {data.data.items.map((c) => (
                  <CategoryItem key={c.id} c={c} />
                ))}
              </div>
              {data.data.items.length === 0 && (
                <Alert variant="info">Il n'y a pas de sous-catégories</Alert>
              )}
            </>
          )}
        </>
      )}
    />
  );
};

const CategoryItem = (props: { c: Category }) => {
  const { c } = props;
  const {
    resourceUrlPathResolver: { display, edit },
  } = useResourceUrlPathResolver<ResourceName>();
  return (
    <div className="col mt-4">
      <div className="card h-100">
        <div className="row no-gutters">
          <div className="col-md-auto">
            <Thumbnail
              url={c.imageUrl}
              className="card-img-left card-thumbnails-left"
            />
          </div>
          <div className="col-md">
            <div className="card-body  py-2 px-3">
              <h6 className="card-title">{c.label}</h6>
              <p className="card-text">
                <small className="text-muted">{c.code}</small>
              </p>
              <CustomerLink
                className="card-link btn btn-sm btn-outline-primary"
                to={display("categories", c.id)}
              >
                Ouvrir
              </CustomerLink>
              <CustomerLink
                className="card-link btn btn-sm btn-outline-primary"
                to={edit("categories", c.id)}
              >
                Éditer
              </CustomerLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesList;
