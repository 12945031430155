import { useMemo } from "react";
import { AssociatedArticle } from "../../../models/AssociatedArticle";
import { AssociatedArticlesFilters } from "../../../models/filters/AssociatedArticlesFilters";

import { ListView } from "../../../resources/components/lists/ListView";
import {
  ResourceColumn,
  ResourceTable,
} from "../../../resources/components/lists/ResourceTable";
import { useResourceUrlPathResolver } from "../../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../../resources/types/resourceName";

type ArticleAssociationsProps = {
  articleId?: number;
};

export const ArticleAssociations = ({
  articleId,
}: ArticleAssociationsProps) => {
  const {
    resourceUrlPathResolver: { display },
  } = useResourceUrlPathResolver<ResourceName>();

  const columns: ResourceColumn<AssociatedArticle>[] = useMemo(
    () => [
      {
        accessor: "type",
        header: "Type",
      },
      {
        accessor: "linkedArticleId",
        header: "Article lié (Id)",
        navigationPath: (_o, value) =>
          value != null ? display("articles", value) : undefined,
      },

      {
        accessor: "lastChange",
        header: "Date dernier changement",
      },
    ],
    [display]
  );
  return (
    <ListView<ResourceName, AssociatedArticle, AssociatedArticlesFilters>
      defaultFilters={{ articleId }}
      resourceName="associated-articles"
    >
      <ResourceTable<AssociatedArticle> columns={columns} />
    </ListView>
  );
};
