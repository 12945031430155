import { useMemo } from "react";
import useSWR from "swr";
import { useCurrentCustomer } from "../../LoggedInBackOffice";
import { UseCustomerProps } from "../../hooks/useCustomerProps";
import { Resource } from "../../resources/types";
import { ResourceIdentifier } from "../../resources/types/resourceIdentifier";
import { ReadProps } from "../types/dataProvider";
import { useReadResource } from "./useReadResource";

type UseSWRReadResourceProps<TResourceName extends string = string> = Omit<
  ReadProps<TResourceName>,
  "id"
> &
  UseCustomerProps & {
    id?: ResourceIdentifier;
  };
export const useSWRReadResource = <
  TResourceName extends string = string,
  TResource extends Resource = Resource
>({
  id,
  resourceName,
  customer: customerProps,
}: UseSWRReadResourceProps<TResourceName>) => {
  const { read } = useReadResource<TResourceName, TResource>({
    canThrow: true,
  });
  const customer = useCurrentCustomer();
  const finalCustomer = useMemo(
    () => customerProps ?? customer,
    [customer, customerProps]
  );
  const key = useMemo(
    () => resourceName + "/" + finalCustomer?.id + "/" + id,
    [finalCustomer?.id, id, resourceName]
  );
  return useSWR(key, async () => {
    if (id != null) {
      return await read({ id, resourceName });
    }
  });
};
