import { AsyncHandler } from "../../../components/AsyncHandler";
import { Paginator } from "../../../components/Paginator";
import { Loading } from "../../../components/types/loading";
import { Resource } from "../../../resources/types";
import { ListReturn } from "../../../resources/types/dataProvider";
import { usePaginationListResource } from "./usePaginationListResource";

type PaginationListResourceProps<TResource extends Resource> = {
  resources: (resource: ListReturn<TResource>) => JSX.Element;
};

export const PaginationListResource = <TResource extends Resource>(
  props: PaginationListResourceProps<TResource>
) => {
  const { resources } = props;
  const {
    data,
    gotoPage,
    canNextPage,
    canPreviousPage,
    pageIndex,
    isLoading,
    error,
    mutate,
  } = usePaginationListResource<TResource>();

  return (
    <AsyncHandler
      error={error}
      refresh={mutate}
      loading={Loading.Circle}
      isLoading={isLoading}
    >
      {resources && data && resources(data)}
      {data != null && data.data && pageIndex != null && (
        <div className="mt-1">
          <Paginator
            canNextPage={!!canNextPage}
            canPreviousPage={!!canPreviousPage}
            pageCount={data?.data.pageCount}
            requestedPageIndex={gotoPage}
            pageIndex={pageIndex}
          />
        </div>
      )}
    </AsyncHandler>
  );
};
