import { EditPage } from "../../resources/components/EditPage";
import EditNotificationForm from "./EditNotificationForm";

const EditNotificationPage = () => {
  return (
    <EditPage>
      <EditNotificationForm />
    </EditPage>
  );
};

export default EditNotificationPage;
