import { useCallback, useState } from "react";
import Order from "../../models/Order";
import useCustomerApiFetch from "../useCustomerApiFetch";

const useChangeOrderStatus = function useChangeOrderStatus(
  status: string,
  confirmPopupMessage: string,
  onSuccessCallback: (order: Order) => void
) {
  const [isBusy, setIsBusy] = useState(false);
  const [errors, setErrors] = useState<unknown>();
  const apiFetcher = useCustomerApiFetch();

  const changeStatus = useCallback(
    async (orderId?: number) => {
      if (window.confirm(confirmPopupMessage)) {
        setIsBusy(true);
        setErrors(undefined);

        try {
          const response = await apiFetcher(
            `/api/Orders/bo/${orderId}/status/${status}`,
            {
              method: "PUT",
              headers: new Headers({
                Accept: "application/json",
                "Content-Type": "application/json",
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Unable to change the state");
          }

          setIsBusy(false);
          onSuccessCallback((await response.json()) as Order);
        } catch (ex) {
          setErrors(ex);
          setIsBusy(false);
        }
      }
    },
    [apiFetcher, confirmPopupMessage, onSuccessCallback, status]
  );

  return { changeStatus, errors, isBusy };
};

export default useChangeOrderStatus;
