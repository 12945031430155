import { useMemo, useCallback, useState, useEffect } from "react";
import { FieldErrorsImpl } from "react-hook-form";
import { InteractivePage } from "../../../../models/InteractiveCatalog";

/** Areas indexes with form validation error */
const useAreasIndexesWithValidationError = (
  errors: Partial<FieldErrorsImpl<InteractivePage>>
) => {
  return useMemo(() => {
    const e = errors;
    if (e !== undefined && e.interactiveAreas !== undefined) {
      return (e as Partial<InteractivePage>)
        .interactiveAreas!.map((v, i) => {
          if (v) {
            return i;
          }
          return undefined;
        })
        .filter((v) => v !== undefined) as number[];
    }
    return [];
  }, [errors]);
};

/** Manage currentPage state */
const useCurrentPageState = (page: InteractivePage) => {
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const onImageChange = useCallback(
    (w: number, h: number) => {
      setCurrentPage({
        ...currentPage,
        imageHeight: h,
        imageWidth: w,
      });
    },
    [currentPage]
  );

  return { currentPage, onImageChange };
};

export { useAreasIndexesWithValidationError, useCurrentPageState };
