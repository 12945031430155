import CreateCampaignPage from "../components/CreateCampaignPage";
import EditOrCreateHomeCatalogsCampaign from "../components/EditOrCreateHomeCatalogsCampaign";

const CreateHomeCatalogsCampaignPage = () => {
  return (
    <CreateCampaignPage>
      <EditOrCreateHomeCatalogsCampaign />
    </CreateCampaignPage>
  );
};

export default CreateHomeCatalogsCampaignPage;
