import { PropsWithChildren, useCallback } from "react";

import { Button, Spinner } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useCustomerLinkTo } from "../../../hooks/useCustomerLinkTo";
import useDeleteResources from "../../../hooks/useDeleteResources";
import { useResourceApiPathResolverContext } from "../../hooks/useResourceApiPathResolverContext";
import { useResourceContext } from "../../hooks/useResourceContext";
import { useResourceName } from "../../hooks/useResourceName";
import { useResourceUrlPathResolver } from "../../hooks/useResourceUrlPathResolver";

type DeleteResourceButtonProps = PropsWithChildren & {
  askForDeletion?: () => void;
  isBusyDeleting?: boolean;
  deletionErrors?: unknown;
  resource?: any;
  url?: string;
};
export const DeleteResourceButtonOld = (props: DeleteResourceButtonProps) => {
  const {
    askForDeletion: askForDeletionProps,
    isBusyDeleting: isBusyDeletingProps,
    deletionErrors: deletionErrorsProps,
    children,
    resource: resourceProps,
    url,
  } = props;

  const { resource } = useResourceContext({ resource: resourceProps });
  const { resourceName: name } = useResourceName();

  const navigate = useNavigate();

  const { resourceApiPathResolver: { deleteUrl } = {} } =
    useResourceApiPathResolverContext();

  const {
    resourceUrlPathResolver: { list },
  } = useResourceUrlPathResolver();
  const { customerLinkUrl } = useCustomerLinkTo();
  const {
    askForDeletion,
    errors: deletionErrors,
    isBusy: isBusyDeleting,
  } = useDeleteResources(
    url ?? (deleteUrl && resource && deleteUrl(name ?? "", resource.id)) ?? "",
    "Voulez vous vraiment supprimer ?",
    useCallback(() => {
      navigate(customerLinkUrl(list(name ?? "")), {
        replace: true,
      });
    }, [customerLinkUrl, list, name, navigate])
  );

  const finalAskForDeletion = askForDeletionProps ?? askForDeletion;
  const finalDeletionErrors = deletionErrorsProps ?? deletionErrors;
  const finalIsBusyDeleting = isBusyDeletingProps ?? isBusyDeleting;

  if (resource == null) {
    return <></>;
  }

  return (
    <div className="float-right text-end">
      <Button
        variant="outline-danger"
        onClick={finalAskForDeletion}
        disabled={finalIsBusyDeleting}
      >
        <>
          {finalIsBusyDeleting ? (
            <Spinner animation="border" size="sm" className="mx-1" />
          ) : (
            ""
          )}
        </>
        <MdDeleteForever className="me-1" />
        <>{children != null ? children : "Supprimer"}</>
      </Button>
      <>
        {finalDeletionErrors && (
          <div className="text-danger">
            Une erreur est survenue lors de la suppression.
          </div>
        )}
      </>
    </div>
  );
};
