import { CreatePage } from "../../resources/components/CreatePage";
import EditDropShippingProviderForm from "./EditDropShippingProviderForm";

const CreateDropShippingProviderPage = () => {
  return (
    <CreatePage>
      <EditDropShippingProviderForm />
    </CreatePage>
  );
};

export default CreateDropShippingProviderPage;
