import { useCallback } from "react";
import { DataProvider, ListProps } from "../types/dataProvider";
import { ResourceFilters } from "../types/resourceFilters";
import { useResourceSafeActionCaller } from "./useResourceSafeActionCaller";

type UseListResourceProps<TResourceFilters extends ResourceFilters> = {
  canThrow?: boolean;
  filters?: TResourceFilters;
};
export const useListResource = <
  TResourceName extends string = string,
  TResourceT = any,
  TResourceFilters extends ResourceFilters = ResourceFilters
>({
  canThrow = false,
  filters,
}: UseListResourceProps<TResourceFilters>) => {
  const unsafeAction = useCallback(
    async (
      dataProvider: DataProvider<TResourceName, TResourceT>,
      props: ListProps<TResourceName>
    ) => await dataProvider.list({ ...props, ...filters }),
    [filters]
  );
  const { safeAction: list, errors } = useResourceSafeActionCaller({
    unsafeAction,
    canThrow,
  });

  return { list, errors };
};
