import { ImportFileType } from "../models/ImportFileType";

const getImportFileTypeFriendlyName = function (t: ImportFileType) {
  switch (t) {
    case ImportFileType.Articles:
      return "Articles";
    case ImportFileType.ArticlesAssocies:
      return "Articles associes";
    case ImportFileType.Attributs:
      return "Attributs";
    case ImportFileType.AttributsArticles:
      return "Attributs articles";
    case ImportFileType.Categories:
      return "Categories";
    case ImportFileType.CategoriesArticles:
      return "Categories articles";
    case ImportFileType.Dimensions:
      return "Dimensions";
    case ImportFileType.Images:
      return "Images";
    case ImportFileType.Prix:
      return "Prix";
    case ImportFileType.References:
      return "References";
    case ImportFileType.StockInfo:
      return "Informations stock";
    case ImportFileType.Taxes:
      return "Taxes";
    default:
      return t;
  }
};
export default getImportFileTypeFriendlyName;
