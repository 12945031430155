import { AnalyticsExportScheduledJob } from "../../models/AnalyticsExportScheduledJob";
import Customer from "../../models/Customer";
import useFetchForCustomer from "../swr/useSWRFetchForCustomer";

const useAnalyticsExportScheduledJob = function (
  customer?: Customer,
  analyticsExportScheduledJobId?: number
) {
  const res = useFetchForCustomer(
    analyticsExportScheduledJobId !== undefined &&
      analyticsExportScheduledJobId !== null
      ? "/api/analyticsexportscheduledjobs/" + analyticsExportScheduledJobId
      : undefined,
    customer
  );
  return {
    ...res,
    analyticsExportScheduledJob: res.data as AnalyticsExportScheduledJob,
  };
};
export default useAnalyticsExportScheduledJob;
