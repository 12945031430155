import { useMemo } from "react";
import { DisplayNull } from "../../../components/DisplayNull";
import { Media } from "../../../models/Media";
import { MediasFilters } from "../../../models/filters/MediasFilters";
import { ListView } from "../../../resources/components/lists/ListView";
import {
  ResourceColumn,
  ResourceTable,
} from "../../../resources/components/lists/ResourceTable";
import { ResourceName } from "../../../resources/types/resourceName";

type MediasProps = {
  articleId?: number;
};

export const ArticleMedias = ({ articleId }: MediasProps) => {
  const columns: ResourceColumn<Media>[] = useMemo(
    () => [
      {
        accessor: "type",
        header: "Type",
      },
      {
        accessor: "url",
        getCell: (_m: Media, url: any) => <ImgViewer url={url} />,
        header: "Url",
      },
      {
        accessor: "synchroUrl",
        getCell: (_m: Media, url: any) => <ImgViewer url={url} />,

        header: "Url synchronisée",
      },
      //jamais remplie jusqu'à présent
      // {
      //   accessor: "thumbnailUrl",
      //   getCell: (_m: Media, url: string) => <ImgViewer url={url} />,
      //   header: "Url vignette",
      // },
      {
        accessor: "synchroThumbnailUrl",
        getCell: (_m: Media, url: any) => <ImgViewer url={url} />,
        header: "Url vignette synchronisée",
      },
      {
        accessor: "lastChange",
        header: "Date dernier changement",
      },
    ],
    []
  );
  return (
    <ListView<ResourceName, Media, MediasFilters>
      defaultFilters={{ articleId }}
      resourceName="medias"
    >
      <ResourceTable<Media> columns={columns} />
    </ListView>
  );
};

type ImgViewerProps = {
  url?: string;
};
const ImgViewer = ({ url }: ImgViewerProps) => (
  <>
    {url != null && (
      <div className="d-flex flex-column gap-2 ImgViewer">
        <a
          href={url}
          target="_blank"
          className="align-self-center"
          rel="noreferrer"
        >
          <img className="img-thumbnail" src={url} alt={url} />
        </a>
        <small className="text-center">{url}</small>
      </div>
    )}
    {url == null && <DisplayNull />}
  </>
);
