import { useCallback } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import InteractiveCatalog from "../../models/InteractiveCatalog";
import { ListCondition } from "../../models/ListCondition";
import { InteractiveCatalogsFilters } from "../../models/filters/InteractiveCatalogsFilters";
import {
  ControlledResourcesSelect,
  ControlledResourcesSelectProps,
  ReactSelectProps,
  ResourcesSelect,
} from "../../resources/components/selects/ResourcesSelect";
import { ResourceName } from "../../resources/types/resourceName";
import ReactSelectOption from "../ReactSelectOption";

const useInteractiveCatalogsSelectController = <
  TInteractiveCatalog extends InteractiveCatalog = InteractiveCatalog,
  TNumber extends number = number,
  TFilters extends InteractiveCatalogsFilters = InteractiveCatalogsFilters
>() => {
  const convertToOption = useCallback(
    (
      value: TInteractiveCatalog | undefined
    ): ReactSelectOption<TNumber> | undefined =>
      value != null
        ? {
            label: `${value.label}`,
            value: value.id as TNumber,
          }
        : undefined,
    []
  );
  const transformToFilters = useCallback(
    (search: string) =>
      ({
        label: search,
        listCondition: ListCondition.Or,
      } as TFilters),
    []
  );
  const resourceName: ResourceName = "animations/interactives-catalogs";
  return { convertToOption, resourceName, transformToFilters };
};

type InteractiveCatalogsSelectProps<IsMulti extends boolean> = ReactSelectProps<
  number,
  IsMulti
>;

export const InteractiveCatalogsSelect = <IsMulti extends boolean>(
  props: InteractiveCatalogsSelectProps<IsMulti>
) => {
  const c = useInteractiveCatalogsSelectController();
  return (
    <ResourcesSelect<
      InteractiveCatalog,
      number,
      IsMulti,
      ResourceName,
      InteractiveCatalogsFilters
    >
      {...c}
      {...props}
    />
  );
};

type ControlledInteractiveCatalogsSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledResourcesSelectProps<
  InteractiveCatalogsSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledInteractiveCatalogsSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledInteractiveCatalogsSelectProps<TFieldValues, TName, IsMulti>
) => {
  const c = useInteractiveCatalogsSelectController();

  return (
    <ControlledResourcesSelect<
      InteractiveCatalog,
      number,
      IsMulti,
      ResourceName,
      InteractiveCatalogsFilters,
      TFieldValues,
      TName
    >
      {...c}
      {...props}
      name={props.name}
    />
  );
};
