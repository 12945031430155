import { useMemo } from "react";
import { ReferenceArticle } from "../../../models/ReferenceArticle";
import { ReferenceArticlesFilters } from "../../../models/filters/ReferenceArticlesFilters";
import { ListView } from "../../../resources/components/lists/ListView";
import {
  ResourceColumn,
  ResourceTable,
} from "../../../resources/components/lists/ResourceTable";
import { ResourceName } from "../../../resources/types/resourceName";

type ArticleReferencesProps = {
  articleId?: number;
};
export const ArticleReferences = ({ articleId }: ArticleReferencesProps) => {
  const columns: ResourceColumn<ReferenceArticle>[] = useMemo(
    () => [
      {
        accessor: "type",
        header: "Type",
      },
      {
        accessor: "reference",
        header: "Référence",
      },
      {
        accessor: "isPrincipal",
        header: "Principale",
      },
    ],
    []
  );
  return (
    <ListView<ResourceName, ReferenceArticle, ReferenceArticlesFilters>
      defaultFilters={{ articleId }}
      resourceName="reference-articles"
    >
      <ResourceTable<ReferenceArticle> columns={columns} />
    </ListView>
  );
};
