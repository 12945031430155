import React from "react";
import { useRolesContext } from "../../LoggedInBackOffice";

type NotTenantAdminTemplateProps = {
  children: React.ReactNode;
};
const NotTenantAdminTemplate = (props: NotTenantAdminTemplateProps) => {
  const { roles } = useRolesContext();
  return (
    <>
      {roles &&
        roles.length > 0 &&
        !roles.includes("Bo.TenantAdmin") &&
        !roles.includes("Server.Admin") &&
        props.children}
    </>
  );
};
export default NotTenantAdminTemplate;
