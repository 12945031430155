import { useCallback } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import BroadcastList from "../../models/BroadcastList";
import { ListCondition } from "../../models/ListCondition";
import { BroadcastListsFilters } from "../../models/filters/BroadcastListsFilters";
import {
  ControlledResourcesSelect,
  ControlledResourcesSelectProps,
  ReactSelectProps,
  ResourcesSelect,
} from "../../resources/components/selects/ResourcesSelect";
import { ResourceName } from "../../resources/types/resourceName";
import ReactSelectOption from "../ReactSelectOption";

const useBroadcastListsSelectController = <
  TBroadcastList extends BroadcastList = BroadcastList,
  TNumber extends number = number,
  TBroadcastListsFilters extends BroadcastListsFilters = BroadcastListsFilters
>() => {
  const convertToOption = useCallback(
    (
      value: TBroadcastList | undefined
    ): ReactSelectOption<TNumber> | undefined =>
      value != null
        ? {
            label: `${value.name}`,
            value: value.id as TNumber,
          }
        : undefined,
    []
  );
  const transformToFilters = useCallback(
    (search: string) =>
      ({
        name: search,
        listCondition: ListCondition.Or,
      } as TBroadcastListsFilters),
    []
  );
  const resourceName: ResourceName = "broadcast-lists";
  return { convertToOption, resourceName, transformToFilters };
};

type BroadcastListsSelectProps<IsMulti extends boolean> = ReactSelectProps<
  number,
  IsMulti
>;

export const BroadcastListsSelect = <IsMulti extends boolean>(
  props: BroadcastListsSelectProps<IsMulti>
) => {
  const c = useBroadcastListsSelectController();
  return (
    <ResourcesSelect<
      BroadcastList,
      number,
      IsMulti,
      ResourceName,
      BroadcastListsFilters
    >
      {...c}
      {...props}
    />
  );
};

type ControlledBroadcastListsSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledResourcesSelectProps<
  BroadcastListsSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledBroadcastListsSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledBroadcastListsSelectProps<TFieldValues, TName, IsMulti>
) => {
  const c = useBroadcastListsSelectController();

  return (
    <ControlledResourcesSelect<
      BroadcastList,
      number,
      IsMulti,
      ResourceName,
      BroadcastListsFilters,
      TFieldValues,
      TName
    >
      {...c}
      {...props}
      name={props.name}
    />
  );
};
