import { useCallback } from "react";
import { Resource } from "../../resources/types";
import { DataProvider, DeleteProps } from "../types/dataProvider";
import { useResourceSafeActionCaller } from "./useResourceSafeActionCaller";

export const useDeleteResource = <
  TResourceName extends string = string,
  TResourceType extends Resource = Resource
>() => {
  const unsafeAction = useCallback(
    async (
      dataProvider: DataProvider<TResourceName, TResourceType>,
      props: DeleteProps<TResourceName>
    ) => await dataProvider.delete(props),
    []
  );
  const { safeAction: deleteAction, errors } = useResourceSafeActionCaller({
    unsafeAction,
  });

  return { deleteAction, errors };
};
