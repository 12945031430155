import { useMemo } from "react";
import { AddressType } from "../models/AddressType";
import CustomerInfo from "../models/CustomerInfo";
import DeliveryAddress from "../models/DeliveryAddress";

interface AddressProps {
  fullName: string;
  address: string;
  postalCode: string;
  city: string;
  email: string;
  phoneNumber: string;
  mobilePhoneNumber: string;
  country?: string;
}

const isStringNotEmpty = (str?: string) => str && str !== "";

export const AddressElement = (props: AddressProps) => {
  //Formated postalcode, city
  const city = useMemo(() => {
    const hasPostalCode = isStringNotEmpty(props.postalCode);
    const hasCity = isStringNotEmpty(props.city);

    if (hasPostalCode && hasCity) {
      return `${props.postalCode}, ${props.city}`;
    } else if (hasPostalCode) {
      return props.postalCode;
    } else if (hasCity) {
      return props.city;
    }
  }, [props.city, props.postalCode]);

  return (
    <address>
      <table className="table table-sm table-borderless key-value-table">
        <tbody>
          <tr>
            <th>Adresse :</th>
            <td>
              <div style={{ lineHeight: 1.4 }}>
                <strong>{props.fullName}</strong> <br />
                {isStringNotEmpty(props.address) && (
                  <>
                    {props.address}
                    <br />
                  </>
                )}
                {isStringNotEmpty(city) && (
                  <>
                    {city}
                    <br />
                  </>
                )}
                {props.country && <>{props.country}</>}
              </div>
            </td>
          </tr>
          {isStringNotEmpty(props.email) && (
            <tr>
              <th>Email de contact :</th>
              <td>
                <>{props.email}</>
              </td>
            </tr>
          )}
          {(isStringNotEmpty(props.mobilePhoneNumber) ||
            isStringNotEmpty(props.phoneNumber)) && (
            <tr>
              <th>Numéros de contact :</th>
              <td>
                {isStringNotEmpty(props.mobilePhoneNumber) && (
                  <>
                    {props.mobilePhoneNumber} <br />
                  </>
                )}
                {isStringNotEmpty(props.phoneNumber) && (
                  <>
                    {props.phoneNumber} <br />
                  </>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </address>
  );
};

export const DeliveryAddressElement = ({
  address,
}: {
  address?: DeliveryAddress;
}) => {
  if (!address) {
    return null;
  }

  return <DefinedDeliveryAddressElement address={address} />;
};
const DefinedDeliveryAddressElement = ({
  address,
}: {
  address: DeliveryAddress;
}) => {
  const fullName = useMemo(() => {
    if (address.type === AddressType.Store) {
      return address.socialReason;
    }
    return `${address.gender} ${address.lastName} ${address.firstName}`;
  }, [
    address.firstName,
    address.gender,
    address.lastName,
    address.socialReason,
    address.type,
  ]);

  return (
    <AddressElement
      fullName={fullName}
      address={address.address}
      postalCode={address.postalCode}
      city={address.city}
      email={address.email}
      mobilePhoneNumber={address.mobilePhoneNumber}
      phoneNumber={address.phoneNumber}
      country={address.country?.name}
    />
  );
};

export const CustomerAddressElement = ({
  customer,
}: {
  customer: CustomerInfo;
}) => {
  if (!customer) {
    return null;
  }

  return <DefinedCustomerAddressElement customer={customer} />;
};

const DefinedCustomerAddressElement = ({
  customer,
}: {
  customer: CustomerInfo;
}) => {
  const fullName = useMemo(() => {
    return `${customer.gender} ${customer.lastName} ${customer.firstName}`;
  }, [customer.firstName, customer.gender, customer.lastName]);
  return (
    <AddressElement
      fullName={fullName}
      address={customer.address}
      postalCode={customer.postalCode}
      city={customer.city}
      country={customer.country?.name}
      email={customer.email}
      mobilePhoneNumber={customer.mobilePhoneNumber}
      phoneNumber={customer.phoneNumber}
    />
  );
};
