import { DisplayNull } from "../../components/DisplayNull";
import { Loading } from "../../components/types/loading";
import { Resource } from "../../resources/types";
import { ReadResource, ReadResourceProps } from "./ReadResource";
import { ReadView, ReadViewProps } from "./ReadView";

type ReadSimpleResourceProps<
  TResourceName extends string,
  TResource extends Resource
> = ReadResourceProps<TResource> &
  Omit<ReadViewProps<TResourceName>, "children">;

export const ReadSimpleResource = <
  TResourceName extends string = string,
  TResource extends Resource = Resource
>(
  props: ReadSimpleResourceProps<TResourceName, TResource>
) => {
  const { resource, ...otherProps } = props;
  return (
    <ReadView<TResourceName> loading={Loading.Linear} {...otherProps}>
      <ReadResource<TResource>
        resource={(r) => <>{(resource && resource(r)) ?? <DisplayNull />}</>}
      />
    </ReadView>
  );
};
