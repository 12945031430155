import React from "react";
import image from "../assets/cataleeze-160x184.png";

const Home = () => {
  return (
    <div className="d-flex h-100 justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img alt="Cataleeze" src={image} className="img-fluid mb-5" />
        <h3>Cataleeze</h3>
      </div>
    </div>
  );
};

export default Home;
