import { useMemo } from "react";
import { FilterFieldData } from "../../../components/DynamicFieldData";

import InteractiveCatalog from "../../../models/InteractiveCatalog";
import { InteractiveCatalogsFilters } from "../../../models/filters/InteractiveCatalogsFilters";
import { CreateResourceButton } from "../../../resources/components/buttons/CreateResourceButton";
import { ListPage } from "../../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../../resources/components/lists/ResourceTable";
import { useAddEditButton } from "../../../resources/hooks/useAddEditButton";

const InteractivesCatalogsPage = () => {
  const filters: FilterFieldData<InteractiveCatalogsFilters>[] = useMemo(
    () => [
      {
        fieldName: "name",
        type: "text",
        label: "Nom",
        placeholder: "Nom",
      },
    ],
    []
  );

  const columns: ResourceColumn<InteractiveCatalog>[] = useMemo(
    () => [
      {
        header: "Nom",
        accessor: "label",
      },
    ],
    []
  );
  const { columnsWithButton } = useAddEditButton({ columns });

  return (
    <ListPage
      filterFieds={filters}
      actions={<CreateResourceButton />}
      showChildWithoutFilters
    >
      <ResourceTable columns={columnsWithButton} />
    </ListPage>
  );
};

export default InteractivesCatalogsPage;
