import { useMemo } from "react";
import { FilterFieldData } from "../../../components/DynamicFieldData";
import Campaign from "../../../models/Campaign";
import { CampaignPeriodType } from "../../../models/CampaignPeriodType";
import { CampaignState } from "../../../models/CampaignState";
import { CampaignsFilters } from "../../../models/filters/CampaignsFilters";
import { CreateResourceButton } from "../../../resources/components/buttons/CreateResourceButton";
import { ListPage } from "../../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../../resources/components/lists/ResourceTable";
import { useAddEditButton } from "../../../resources/hooks/useAddEditButton";
import { getCampaignTargetType } from "../../../tools/getCampaign";
import getCampaignDate from "../../../tools/getCampaignDate";

export const CampaignsPage = () => {
  const filters: FilterFieldData<CampaignsFilters>[] = useMemo(
    () => [
      {
        fieldName: "name",
        type: "text",
        label: "Nom",
        placeholder: "Nom",
      },
      {
        fieldName: "periodType",
        type: "number-select",
        label: "Type",
        placeholder: "Type",
        options: [
          { label: "Permanentes", value: CampaignPeriodType.Permanent },
          { label: "Planifiées", value: CampaignPeriodType.Planified },
        ],
      },
      {
        fieldName: "state",
        type: "number-select",
        label: "État",
        placeholder: "État",
        options: [
          { label: "Actives", value: CampaignState.Active },
          { label: "Passées", value: CampaignState.Passed },
        ],
      },
      {
        fieldName: "targetStoreId",
        type: "stores-select",
        label: "Magasin",
        placeholder: "Magasin",
      },
      {
        fieldName: "targetAnimationGroupId",
        type: "animation-groups-select",
        label: "Groupe d'animation",
        placeholder: "Groupe d'animation",
      },
    ],
    []
  );

  const columns: ResourceColumn<Campaign>[] = useMemo(
    () => [
      {
        header: "Nom",
        accessor: "label",
        getCell: (v) => (
          <>
            {v.isDraft && <b>[Brouillon] </b>}
            {v.label}
          </>
        ),
      },
      {
        header: "Dates",
        accessor: (v) => v,
        getCell: (v) => getCampaignDate(v),
      },
      {
        header: "Cible",
        accessor: (v) => v,
        getCell: (v) => (
          <>
            {getCampaignTargetType(v) === "all" && <>Tous les périphériques</>}
            {getCampaignTargetType(v) === "store" && (
              <>
                Magasin{" "}
                {v.targetStore &&
                  v.targetStore.code + " - " + v.targetStore.name}
              </>
            )}
            {getCampaignTargetType(v) === "animation-group" && (
              <>
                Groupe d'animation{" "}
                {v.targetAnimationGroup && v.targetAnimationGroup.label}
              </>
            )}
          </>
        ),
      },
    ],
    []
  );
  const { columnsWithButton } = useAddEditButton({ columns });

  return (
    <ListPage
      filterFieds={filters}
      actions={<CreateResourceButton />}
      showChildWithoutFilters
    >
      <ResourceTable columns={columnsWithButton} />
    </ListPage>
  );
};
