import { useCallback, useMemo } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { MdClear, MdSearch } from "react-icons/md";
import ReactSelect from "react-select";
import Input from "../../../components/Input";
import ReactSelectOption from "../../../components/ReactSelectOption";
import { AnalyticsExportPeriodicity } from "../../../models/AnalyticsExportPeriodicity";
import { AnalyticsTable } from "../../../models/AnalyticsTable";
import { useAnalyticsTableOptions } from "./useAnalyticsTableOptions";
import { useTranslatedAnalyticsExportPeriodicity } from "./useTranslatedAnalyticsExportPeriodicity";

export type Filters = {
  periodicity?: AnalyticsExportPeriodicity;
  email?: string;
  analyticsTable?: AnalyticsTable;
};

type FiltersInputs = Filters & {
  periodicity?: AnalyticsExportPeriodicity | string;
  analyticsTable?: AnalyticsTable | string;
};

type FiltersFormProps = {
  onChange: (filters: Filters) => void;
  canReset: boolean;
  initialFilters?: Filters;
};

const emptyFilters: Filters = {
  periodicity: Number.NEGATIVE_INFINITY,
  email: "",
  analyticsTable: Number.NEGATIVE_INFINITY,
};

export const FiltersForm = (props: FiltersFormProps) => {
  const { initialFilters, onChange, canReset } = props;
  const defaultFilters = useMemo(() => {
    const copyInitialFilters = { ...initialFilters };
    const copyEmptyFilters = { ...emptyFilters };
    return {
      defaultPeriodicity:
        copyInitialFilters?.periodicity ?? copyEmptyFilters.periodicity,
      defaultAnalyticsTable:
        copyInitialFilters?.analyticsTable ?? copyEmptyFilters.analyticsTable,
      defaultEmail: copyInitialFilters?.email ?? copyEmptyFilters.email,
    };
  }, [initialFilters]);

  const { register, handleSubmit, control, formState, reset } =
    useForm<FiltersInputs>({
      defaultValues: {
        periodicity: defaultFilters.defaultPeriodicity,
        email: defaultFilters.defaultEmail,
        analyticsTable: defaultFilters.defaultAnalyticsTable,
      },
    });
  const { errors } = formState;

  const onSubmit = useCallback(
    (data: FiltersInputs) => {
      // reset(data);

      let filter = { ...data };
      if (filter.analyticsTable !== undefined) {
        if (typeof filter.analyticsTable === "string") {
          filter.analyticsTable = Number(filter.analyticsTable);
        }
        if (filter.analyticsTable === emptyFilters.analyticsTable) {
          filter.analyticsTable = undefined;
        }
      }
      if (filter.periodicity !== undefined) {
        if (typeof filter.periodicity === "string") {
          filter.periodicity = Number(filter.periodicity);
        }
        if (filter.periodicity === emptyFilters.periodicity) {
          filter.periodicity = undefined;
        }
      }
      if (filter.email !== undefined)
        if (filter.email === emptyFilters.email) {
          filter.email = undefined;
        }

      onChange(filter);
    },
    [onChange]
  );

  const onClear = useCallback(() => {
    onSubmit(emptyFilters);
    reset(emptyFilters);
  }, [onSubmit, reset]);

  const analyticsTableOptions = useAnalyticsTableOptions();

  const extendedAnalyticsTableOptions = useMemo(
    () =>
      analyticsTableOptions
        ? [
            {
              value: Number.NEGATIVE_INFINITY,
              label: "Aucun",
            } as ReactSelectOption<number>,
          ].concat(analyticsTableOptions)
        : undefined,
    [analyticsTableOptions]
  );
  const translatedAnalyticsExportPeriodicity =
    useTranslatedAnalyticsExportPeriodicity();
  const periodicityOptions = useMemo(() => {
    return [
      Number.NEGATIVE_INFINITY,
      AnalyticsExportPeriodicity.Week,
      AnalyticsExportPeriodicity.Month,
    ]?.map((c) => {
      return {
        value: c,
        label:
          c === Number.NEGATIVE_INFINITY
            ? "Aucune"
            : translatedAnalyticsExportPeriodicity[
                c as AnalyticsExportPeriodicity
              ],
      } as ReactSelectOption<number>;
    });
  }, [translatedAnalyticsExportPeriodicity]);
  return (
    <Form
      className="d-flex align-items-end row"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Form.Group as={Col} md="3" controlId="filterperiodicity">
        <Form.Label>Fréquence</Form.Label>
        {periodicityOptions.map((p) => (
          // <Controller
          //   control={control}
          //   name="periodicity"
          //   render={({ field }) => (
          //     <Form.Check
          //       {...field}
          //       type="radio"
          //       id={p.value?.toString()}
          //       label={p.label}
          //     />
          //   )}
          // />
          <div key={p.value} className="form-check">
            <input
              className="form-check-input"
              {...register("periodicity", { valueAsNumber: true })}
              type="radio"
              id={p.value?.toString()}
              value={p.value?.toString()}
              defaultChecked={defaultFilters.defaultPeriodicity === p.value}
            />
            <label className="form-check-label" htmlFor={p.value?.toString()}>
              {p.label}
            </label>
          </div>
        ))}
      </Form.Group>
      <Form.Group as={Col} md="3" controlId="filterEmail">
        <Input
          type="text"
          label="Email"
          {...register("email")}
          placeholder="Email"
        />
      </Form.Group>
      <Form.Group as={Col} md="3" controlId="filterAnalyticsTable">
        <Form.Label>Fichier</Form.Label>
        <Controller
          control={control}
          name="analyticsTable"
          defaultValue={defaultFilters.defaultAnalyticsTable}
          render={({ field: { onChange, value, ref } }) => (
            <ReactSelect
              className={`${
                errors && errors?.analyticsTable ? "is-invalid" : ""
              }`}
              options={extendedAnalyticsTableOptions}
              ref={ref}
              value={
                extendedAnalyticsTableOptions &&
                extendedAnalyticsTableOptions.filter((c) => value === c.value)
              }
              onChange={(val) =>
                onChange((val as ReactSelectOption<number>).value)
              }
              placeholder="Sélectionner un fichier"
              isLoading={
                extendedAnalyticsTableOptions === undefined ||
                extendedAnalyticsTableOptions.length === 0
              }
            />
          )}
        />
      </Form.Group>

      <Form.Group as={Col} md="3" className="d-flex gap-2">
        <Button type="submit" className="px-4">
          <MdSearch />
        </Button>
        <Button
          type="button"
          className="px-3"
          variant="outline-secondary"
          onClick={onClear}
          disabled={!canReset}
        >
          <MdClear />
        </Button>
      </Form.Group>
    </Form>
  );
};
