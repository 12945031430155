import { useMemo } from "react";
import { useIsUserInTenantAdminRole } from "../../../hooks/useIsUserInTenantAdminRole";
import { ArticleAttribute } from "../../../models/ArticleAttribute";
import { ArticleAttributesFilters } from "../../../models/filters/ArticleAttributesFilters";
import { ListView } from "../../../resources/components/lists/ListView";
import {
  ResourceColumn,
  ResourceTable,
} from "../../../resources/components/lists/ResourceTable";
import { useResourceUrlPathResolver } from "../../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../../resources/types/resourceName";

type ArticleAttributesProps = {
  attributeId?: number;
};
export const ArticleAttributes = ({ attributeId }: ArticleAttributesProps) => {
  const {
    resourceUrlPathResolver: { display },
  } = useResourceUrlPathResolver<ResourceName>();
  const { isAllowed } = useIsUserInTenantAdminRole();
  const columns: ResourceColumn<ArticleAttribute>[] = useMemo(
    () => [
      {
        accessor: (o) => o.articleId,
        header: "Article (Réference)",
        expand: "articles",
        getCell: (d, v, expandedList) => {
          const s = expandedList?.data.items.find((a) => a.id === d.articleId);
          return s?.reference;
        },
        navigationPath: (o, value) =>
          isAllowed ? display("articles", o.articleId) : undefined,
      },
      {
        accessor: (o) => o.articleId,
        header: "Article (Nom)",
        expand: "articles",
        getCell: (d, v, expandedList) => {
          const s = expandedList?.data.items.find((a) => a.id === d.articleId);
          return s?.name;
        },
      },
      {
        accessor: "value",
        header: "Valeur",
      },
    ],
    [display, isAllowed]
  );
  return (
    <ListView<ResourceName, ArticleAttribute, ArticleAttributesFilters>
      defaultFilters={{ attributeId }}
      resourceName="article-attributes"
    >
      <ResourceTable<ArticleAttribute> columns={columns} />
    </ListView>
  );
};
