import { useCallback, useMemo } from "react";
import { useResourceListContext } from "../../../resources/hooks/useResourceListContext";
import { Resource } from "../../../resources/types";

export const usePaginationListResource = <TResource extends Resource>() => {
  const r = useResourceListContext<TResource>();
  const { setPageIndex, data } = r;
  const pagedItems = data?.data;

  const gotoPage = useCallback(
    (index: number) => {
      if (setPageIndex) {
        setPageIndex(index);
      }
    },
    [setPageIndex]
  );

  const canNextPage = useMemo(
    () => pagedItems && pagedItems.currentPageIndex < pagedItems.pageCount - 1,
    [pagedItems]
  );

  const canPreviousPage = useMemo(
    () => pagedItems && pagedItems.currentPageIndex > 0,
    [pagedItems]
  );
  return { ...r, gotoPage, canNextPage, canPreviousPage };
};
