import { PropsWithChildren } from "react";
import { CreatePage } from "../../../resources/components/CreatePage";
import { useCampaignController } from "../hooks/useCampaignController";

const CreateCampaignPage = ({ children }: PropsWithChildren) => {
  const res = useCampaignController();
  return <CreatePage {...res}>{children}</CreatePage>;
};

export default CreateCampaignPage;
