import { useMemo } from "react";
import { AnalyticsExportPeriodicity } from "../../../models/AnalyticsExportPeriodicity";

export const useTranslatedAnalyticsExportPeriodicity = () => {
  return useMemo(() => {
    return {
      [AnalyticsExportPeriodicity.Month]: "Mensuelle",
      [AnalyticsExportPeriodicity.Week]: "Hebdomadaire",
    };
  }, []);
};
