import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UseFormReturn } from "react-hook-form";
import { useCurrentCustomer } from "../../LoggedInBackOffice";
import { AsyncHandler } from "../../components/AsyncHandler";
import { GoBackButton } from "../../components/buttons/GoBackButton";
import { Loading } from "../../components/types/loading";
import { useIsUserInServerAdminRole } from "../../hooks/useIsUserInServerAdminRole";
import { useIsUserInTenantAdminRole } from "../../hooks/useIsUserInTenantAdminRole";
import { useNotificationsContext } from "../../hooks/useNotificationsContext";
import { ResourceContextProvider } from "../hooks/useResourceContext";
import { ResourceEditContextProvider } from "../hooks/useResourceEditContext";
import { useResourceName } from "../hooks/useResourceName";
import { useResourceParams } from "../hooks/useResourceParams";
import { useSWRReadResource } from "../hooks/useSWRReadResource";
import { useUpdateResource } from "../hooks/useUpdateResource";
import { Resource } from "../types";
import ErrorBoundary from "./ErrorBoundary";
import { PageTitle } from "./PageTitle";
import { PublicResourceFormProps, ResourceForm } from "./ResourceForm";

type EditPageProps<TResource extends Resource, TContext> = PropsWithChildren &
  PublicResourceFormProps<TResource, TContext> & {
    onSuccess?: (item: TResource) => void;
    transformRead?: (item: TResource) => TResource;
    canDelete?: boolean;
  };

export const EditPage = <TResource extends Resource = Resource, TContext = any>(
  props: EditPageProps<TResource, TContext>
) => {
  const {
    children,
    transformRead,
    onSuccess: onSuccessProps,
    transformAfterSubmit: transformAfterSubmitProps,
    canDelete: canDeleteProps = true,
    ...otherProps
  } = props;
  const customer = useCurrentCustomer();
  const { id } = useResourceParams();

  const { resourceName } = useResourceName();
  const { data, error, mutate, isLoading } = useSWRReadResource<
    string,
    TResource
  >({
    id,
    customer,
    resourceName,
  });
  const { data: resourceData } = data ?? {};

  const resource = useMemo(() => {
    return transformRead != null && resourceData != null
      ? transformRead(resourceData)
      : resourceData;
  }, [resourceData, transformRead]);

  const onSuccess = useCallback(
    (r?: TResource) => {
      if (r != null) {
        mutate({ data: r }, { revalidate: false });
      }
    },
    [mutate]
  );

  const { update, errors: submitError } = useUpdateResource<
    string,
    TResource
  >();

  const { addNotification } = useNotificationsContext();

  useEffect(() => {
    if (submitError == null) return;
    addNotification({
      title: "Une erreur est survenue",
      variant: "danger",
    });
  }, [addNotification, submitError]);

  const onSubmit = useCallback(
    async (r?: TResource) => {
      if (id != null && r != null) {
        const c = await update({
          id: id,
          data: r,
          resourceName,
        });
        return c?.data;
      }
      return undefined;
    },
    [id, resourceName, update]
  );

  const transformAfterSubmit = useCallback(
    async (
      updatedResource: TResource,
      form: UseFormReturn<TResource, TContext>
    ) => {
      let finalResource;
      if (updatedResource != null && transformAfterSubmitProps != null) {
        finalResource = await transformAfterSubmitProps(updatedResource, form);
      } else {
        finalResource = updatedResource;
      }
      onSuccess(finalResource);
      addNotification({ title: "Sauvegarde réussie", variant: "success" });
      return finalResource;
    },
    [addNotification, onSuccess, transformAfterSubmitProps]
  );

  //état peut sauvegarder via useState
  const [canSave, setCanSave] = useState<boolean>(true);
  //état peut supprimer via useState
  const [canDelete, setCanDelete] = useState<boolean>(canDeleteProps);

  // récupération si ServerAdminRole
  const { isAllowed: isAllowedServerAdmin } = useIsUserInServerAdminRole();
  //récupération si TenantAdminRole
  const { isAllowed: isAllowedTenantAdmin } = useIsUserInTenantAdminRole();

  // callback d'erreur
  const onError = useCallback(
    (error: Error, errorInfo: React.ErrorInfo) => {
      setCanSave(false);
      // si ServerAdminRole ou TenantAdminRole, alors peut supprimer
      if (isAllowedServerAdmin || isAllowedTenantAdmin) {
        setCanDelete(true);
      }
      console.error("Erreur capturée :", error, errorInfo);
      addNotification({
        title: "Une erreur est survenue",
        variant: "danger",
      });
    },
    [addNotification, isAllowedServerAdmin, isAllowedTenantAdmin]
  );
  return (
    <>
      <GoBackButton />
      <PageTitle />
      <hr />
      <AsyncHandler
        error={error}
        errorMessage="Une erreur est survenue"
        refresh={mutate}
        isLoading={isLoading}
        loading={Loading.Circle}
      >
        <ResourceContextProvider value={{ resource, error, mutate, isLoading }}>
          <ResourceForm<TResource, TContext>
            resource={resource!}
            canDelete={canDelete}
            canSave={canSave}
            onSubmit={onSubmit}
            submitError={submitError}
            transformAfterSubmit={transformAfterSubmit}
            {...otherProps}
          >
            <ResourceEditContextProvider<TResource>
              value={{ onSuccess /*, submit: onSubmit*/ }}
            >
              <ErrorBoundary onError={onError}> {children}</ErrorBoundary>
            </ResourceEditContextProvider>
          </ResourceForm>
        </ResourceContextProvider>
      </AsyncHandler>
    </>
  );
};
