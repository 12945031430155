import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import ServiceExtensionDefinition, {
  ExtensionModes,
  ExtensionType,
} from "../../models/ServiceExtensionDefinition";
import { ServiceExtensionDefinitionsFilters } from "../../models/filters/ServiceExtensionDefinitionsFilters";
import { CreateResourceButton } from "../../resources/components/buttons/CreateResourceButton";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";

const ServiceExtensionDefinitionsPage = () => {
  const filters: FilterFieldData<ServiceExtensionDefinitionsFilters>[] =
    useMemo(
      () => [
        {
          fieldName: "fullyQualifiedClassName",
          type: "text",
          label: "FullyQualifiedJobClassName",
          placeholder: "FullyQualifiedJobClassName",
        },
      ],
      []
    );

  const columns: ResourceColumn<ServiceExtensionDefinition>[] = useMemo(
    () => [
      {
        header: "FullyQualifiedJobClassName",
        accessor: "fullyQualifiedClassName",
      },
      {
        header: "ExtensionType",
        accessor: "extensionType",
        getCell: (s) => getExtensionTypeLabel(s.extensionType),
      },
      {
        header: "ExtensionModes",
        accessor: "extensionModes",
        getCell: (s) => getExtensionModesLabel(s.extensionModes),
      },
    ],
    []
  );

  return (
    <ListPage
      filterFieds={filters}
      actions={<CreateResourceButton />}
      showChildWithoutFilters
    >
      <ResourceTable columns={columns} />
    </ListPage>
  );
};

export default ServiceExtensionDefinitionsPage;

function getExtensionTypeLabel(extensionType: ExtensionType): React.ReactNode {
  return ExtensionType[extensionType];
}

function getExtensionModesLabel(
  extensionModes: ExtensionModes
): React.ReactNode {
  const list = Array<string>();
  if (flagEnumContains(extensionModes, ExtensionModes.PostExtension)) {
    list.push("PostExtension");
  }
  if (flagEnumContains(extensionModes, ExtensionModes.PreExtension)) {
    list.push("PreExtension");
  }
  if (flagEnumContains(extensionModes, ExtensionModes.ReplaceExtension)) {
    list.push("ReplaceExtension");
  }

  return list.join(" ");
}

const flagEnumContains = (
  extensionModes: ExtensionModes,
  single: ExtensionModes
) => (extensionModes & single) === single;
