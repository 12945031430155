import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import { Article } from "../../models/Article";
import { StockInfo } from "../../models/StockInfo";
import { StockInfosFilters } from "../../models/filters/StockInfosFilters";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { useResourceUrlPathResolver } from "../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../resources/types/resourceName";

const StockInfosPage = () => {
  const filters: FilterFieldData<StockInfosFilters>[] = useMemo(
    () => [
      {
        fieldName: "stockLocationId",
        type: "stock-locations-select",
        label: "Dépôt",
        placeholder: "Dépôt",
      },
      {
        fieldName: "fromAvailableQuantity",
        type: "number",
        label: "Quantité disponible supérieure ou égale à",
        placeholder: "Quantité disponible",
      },
    ],
    []
  );
  const {
    resourceUrlPathResolver: { display },
  } = useResourceUrlPathResolver<ResourceName>();

  const columns: ResourceColumn<StockInfo>[] = useMemo(
    () => [
      {
        header: "Article (référence)",
        accessor: "articleId",
        expand: "articles",
        getCell: (d, v, expandedList) => {
          const article = expandedList?.data.items.find(
            (a) => a.id === d.articleId
          );
          return article?.reference;
        },
        navigationPath: (o) => display("articles", o.articleId),
      } as ResourceColumn<StockInfo, Article, ResourceName>,
      {
        header: "Dépôt",
        accessor: "stockLocationId",
        expand: "stock-locations",
        getCell: (d, v, expandedList) => {
          const s = expandedList?.data.items.find(
            (a) => a.id === d.stockLocationId
          );
          return s && `${s.name} (${s.code})`;
        },
        navigationPath: (o) => display("stock-locations", o.stockLocationId),
      },
      {
        header: "Type de disponibilité",
        accessor: "availabilityType",
      },
      {
        header: "Quantité disponible",
        accessor: "availableQuantity",
      },
      {
        header: "Quantité réservée",
        accessor: "reservedQuantity",
      },
    ],
    [display]
  );

  return (
    <ListPage filterFieds={filters} showChildWithoutFilters>
      <ResourceTable columns={columns} />
    </ListPage>
  );
};

export default StockInfosPage;
