import { useCallback } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { Attribute } from "../../models/Attribute";
import { ListCondition } from "../../models/ListCondition";
import { AttributesFilters } from "../../models/filters/AttributesFilters";
import {
  ControlledResourcesSelect,
  ControlledResourcesSelectProps,
  ReactSelectProps,
  ResourcesSelect,
} from "../../resources/components/selects/ResourcesSelect";
import { ResourceIdentifier } from "../../resources/types/resourceIdentifier";
import { ResourceName } from "../../resources/types/resourceName";
import ReactSelectOption from "../ReactSelectOption";

type UseAttributesSelectControllerProps = {
  excludedIds?: ResourceIdentifier[];
};
const useAttributesSelectController = <
  TAttribute extends Attribute = Attribute,
  TNumber extends number = number,
  TAttributesFilters extends AttributesFilters = AttributesFilters
>(
  props: UseAttributesSelectControllerProps
) => {
  const { excludedIds } = props;
  const convertToOption = useCallback(
    (value: TAttribute | undefined): ReactSelectOption<TNumber> | undefined =>
      value != null
        ? {
            label: `${value.code} - ${value.name}`,
            value: value.id as TNumber,
          }
        : undefined,
    []
  );
  const transformToFilters = useCallback(
    (search: string) =>
      ({
        excludedIds,
        name: search,
        code: search,
        listCondition: ListCondition.Or,
      } as TAttributesFilters),
    [excludedIds]
  );
  const resourceName: ResourceName = "attributes";
  return { convertToOption, resourceName, transformToFilters };
};

type AttributesSelectProps<IsMulti extends boolean> =
  UseAttributesSelectControllerProps & ReactSelectProps<number, IsMulti>;

export const AttributesSelect = <IsMulti extends boolean>(
  props: AttributesSelectProps<IsMulti>
) => {
  const c = useAttributesSelectController(props);
  return (
    <ResourcesSelect<
      Attribute,
      number,
      IsMulti,
      ResourceName,
      AttributesFilters
    >
      {...c}
      {...props}
    />
  );
};

type ControlledAttributesSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledResourcesSelectProps<
  AttributesSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledAttributesSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledAttributesSelectProps<TFieldValues, TName, IsMulti>
) => {
  const c = useAttributesSelectController(props);

  return (
    <ControlledResourcesSelect<
      Attribute,
      number,
      IsMulti,
      ResourceName,
      AttributesFilters,
      TFieldValues,
      TName
    >
      {...c}
      {...props}
      name={props.name}
    />
  );
};
