import { useMemo } from "react";
import useQuery from "../../../../hooks/useQuery";
import {
  ArticlesSelection,
  ArticlesSelectionType,
} from "../../../../models/ArticlesSelection";
import { useUploadMediaController } from "../../../../resources/hooks/useUploadMediaController";

export const useArticlesSelectionController = () => {
  const q = useQuery();
  const type = useMemo(() => {
    const typeParamString = q.get("type");
    return typeParamString !== null
      ? (parseInt(typeParamString) as ArticlesSelectionType)
      : undefined;
  }, [q]);

  const { transformAfterSubmit, transformBeforeSubmit } =
    useUploadMediaController<ArticlesSelection>({
      imageField: "imageUrl",
    });

  return {
    defaultResource: { type } as ArticlesSelection,
    transformAfterSubmit,
    transformBeforeSubmit,
  };
};
