import { useCallback, useMemo } from "react";
import {
  FieldArray,
  FieldArrayPath,
  FieldValues,
  UseFieldArrayProps,
  useFieldArray,
} from "react-hook-form";
import { PropsValue } from "react-select";
import { ReactSelectOptionValueType } from "../../../../components/ReactSelectOption";

type UseResourcesSelectFieldArrayProps<
  TReactSelectOptionValueType extends ReactSelectOptionValueType
> = {
  convertToResource: (p: TReactSelectOptionValueType) => any;
  convertToResourceId: (p: any) => TReactSelectOptionValueType;
};

export const useResourcesSelectFieldArray = <
  TResource extends FieldValues,
  TReactSelectOptionValueType extends ReactSelectOptionValueType,
  TFieldArrayName extends FieldArrayPath<TResource> = FieldArrayPath<TResource>,
  TKeyName extends string = "id"
>(
  props: UseResourcesSelectFieldArrayProps<TReactSelectOptionValueType> &
    UseFieldArrayProps<TResource, TFieldArrayName, TKeyName>
) => {
  const { convertToResource, convertToResourceId, ...otherProps } = props;

  const { fields, replace } = useFieldArray<
    TResource,
    TFieldArrayName,
    TKeyName
  >(otherProps);

  const replaceResources = useCallback(
    (s: PropsValue<TReactSelectOptionValueType> | undefined) => {
      if (Array.isArray(s)) {
        const a = s?.map(convertToResource);
        replace(a as FieldArray<TResource, TFieldArrayName>[]);
      }
    },
    [convertToResource, replace]
  );
  const resourceIds = useMemo(
    () => fields?.map(convertToResourceId),
    [convertToResourceId, fields]
  );
  return { replaceResources, fields, resourceIds };
};
