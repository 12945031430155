import { useCallback, useEffect, useMemo, useState } from "react";
import {
  SupportedParamsTypes,
  useQueryAsState,
} from "../hooks/useQueryAsState";
import { FilterFieldData } from "./DynamicFieldData";
import { HorizontalDynamicForm } from "./HorizontalDynamicForm";

export interface FilterProps {
  fields: FilterFieldData<any>[];
  onSubmit: (d: any) => void;
}

export const Filter = (props: FilterProps) => {
  const { fields, onSubmit } = props;
  const finalFields = useMemo(
    () =>
      fields.map((f) => {
        if (f.defaultValue === undefined) {
          switch (f.type) {
            case "number":
            case "date":
            case "text":
              f.defaultValue = "";
              break;
          }
        }
        return f;
      }),
    [fields]
  );
  const defaultFields = useMemo(() => {
    let result: Record<string, any> = {};
    for (const field of finalFields) {
      result[field.fieldName] = field.defaultValue;
    }
    return result;
  }, [finalFields]);
  const types = useMemo(() => {
    let result: Record<string, SupportedParamsTypes> = {};
    for (const field of finalFields) {
      let stateType: SupportedParamsTypes | undefined = undefined;
      switch (field.type) {
        case "hidden":
        case "text":
        case "string-select":
        case "devices-select":
          stateType = "string";
          break;
        case "date":
          // stateType = "Date";
          stateType = "string";
          break;
        case "number":
        case "number-select":
        case "stock-locations-select":
        case "stores-select":
        case "animation-groups-select":
        case "device-types-select":
        case "import-job-definitions-select":
        case "logistic-operation-statuses-select":
        case "logistic-operation-types-select":
          stateType = "number";
          break;

        case "boolean":
          stateType = "boolean";
          break;
      }
      result[field.fieldName] = stateType;
    }
    return result;
  }, [finalFields]);
  const [params, updateQueryParams] = useQueryAsState(
    types,
    defaultFields,
    true
  );
  const fieldNames = useMemo(() => {
    return finalFields.map((f) => f.fieldName);
  }, [finalFields]);
  const cleanParams = useMemo(() => {
    for (const [key, value] of Object.entries(params)) {
      if (defaultFields[key] === value || !fieldNames.includes(key)) {
        delete params[key];
      }
    }
    return params;
  }, [defaultFields, fieldNames, params]);

  const [previousCleanParams, setPreviousCleanParams] = useState();

  useEffect(() => {
    if (
      previousCleanParams === undefined ||
      cleanParams === undefined ||
      !shallowEqual(previousCleanParams, cleanParams)
    ) {
      setPreviousCleanParams(Object.assign(cleanParams));
      onSubmit(cleanParams);
    }
  }, [cleanParams, onSubmit, previousCleanParams]);
  const onFormSubmit = useCallback(
    (d: Partial<Record<string, any>>) => {
      updateQueryParams(d);
    },
    [updateQueryParams]
  );
  return (
    <HorizontalDynamicForm
      fields={finalFields}
      value={params}
      onSubmit={onFormSubmit}
    />
  );
};

function shallowEqual(object1: any, object2: any) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}
