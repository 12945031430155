import { CreatePage } from "../../resources/components/CreatePage";
import EditAnimationGroupForm from "./components/EditAnimationGroupForm";

const CreateAnimationGroupPage = () => {
  return (
    <CreatePage>
      <EditAnimationGroupForm />
    </CreatePage>
  );
};

export default CreateAnimationGroupPage;
