import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { MdAdd } from "react-icons/md";
import { PropsValue } from "react-select";
import { BrandsSelect } from "../../../../components/selects/BrandsSelect";
import { ExtendedAutomaticArticlesSelection } from "../types/ExtendedAutomaticArticlesSelection";
import { FilterContent } from "../types/FilterContent";
import { AddRowProps } from "./AddRowProps";

export function AddBrandRow({ append }: AddRowProps<FilterContent>) {
  const [brand, setBrand] = useState<string | undefined>();
  const onIdPropsValueChanged = useCallback(
    (s: PropsValue<string> | undefined) => {
      if (Array.isArray(s)) {
        const [first] = s;
        setBrand(first);
      } else {
        setBrand(s as string);
      }
    },
    []
  );
  const appendBrand = useCallback(() => {
    if (brand) {
      append({ value: brand /*, label: brand*/ });
      setBrand(undefined);
    }
  }, [append, brand]);
  const { watch } = useFormContext<ExtendedAutomaticArticlesSelection>();
  const brands = watch("brands");
  const excludedIds = useMemo(() => brands, [brands]);
  return (
    <tr>
      <td>
        <BrandsSelect
          onIdPropsValueChanged={onIdPropsValueChanged}
          idPropsValue={brand}
          excludedIds={excludedIds?.map((i) => i.value)}
        />
      </td>
      <td className="min">
        {brand && (
          <button
            className="btn btn-outline-primary"
            type="button"
            onClick={appendBrand}
          >
            <MdAdd />
          </button>
        )}
      </td>
    </tr>
  );
}
