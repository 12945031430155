import { useMemo } from "react";
import { ResourceIdentifier } from "../../resources/types/resourceIdentifier";
import { convertToURLSearchParams } from "../../tools/convertToURLSearchParams";

export const useResourceUrlPathResolver = <
  TResourceName extends string = string
>() => {
  const resourceUrlPathResolver = useMemo(
    () => ({
      list: (resourceName: TResourceName) => {
        return `/${resourceName}`;
      },
      edit: (resourceName: TResourceName, id: ResourceIdentifier) => {
        return `/${resourceName}/edit/${id}`;
      },
      create: (resourceName: TResourceName, params?: Record<string, any>) => {
        return (
          `/${resourceName}/create` +
          (params != null
            ? `?${convertToURLSearchParams(params)!.toString()}`
            : "")
        );
      },
      display: (resourceName: TResourceName, id: ResourceIdentifier) => {
        return `/${resourceName}/${id}`;
      },
    }),
    []
  );
  return { resourceUrlPathResolver };
};
