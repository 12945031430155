import {
  FieldValues,
  Path,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import { FormControlElement } from "../../../components/Input";
import { Resource } from "../../../resources/types";

export type ResourceHiddenInputProps<TResource extends Resource> =
  React.InputHTMLAttributes<FormControlElement> & {
    name: Path<TResource>;
    options?: RegisterOptions<FieldValues, Path<TResource>> | undefined;
  };
export const ResourceHiddenInput = <TResource extends Resource>(
  props: ResourceHiddenInputProps<TResource>
) => {
  const { name, options, ...otherProps } = props;

  const { register } = useFormContext();

  return (
    <input
      readOnly
      type="hidden"
      {...register(name, options)}
      {...otherProps}
    />
  );
};
