import Notification from "../../models/Notification";
import { CreatePage } from "../../resources/components/CreatePage";
import EditNotificationForm from "./EditNotificationForm";

const CreateNotificationPage = () => {
  return (
    <CreatePage<Notification> defaultResource={{ htmlContent: " " }}>
      <EditNotificationForm />
    </CreatePage>
  );
};

export default CreateNotificationPage;
