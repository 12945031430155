import {
  AccountInfo,
  IPublicClientApplication,
  SilentRequest,
} from "@azure/msal-browser";
import { apiRequest } from "../authConfig";

export const getTokenSilent = async function (
  instance: IPublicClientApplication,
  account: AccountInfo
) {
  const request = {
    ...apiRequest,
    account: account,
  } as SilentRequest;
  // Silently acquires an access token which is then attached to a request for access_as_user data
  try {
    let response = await instance.acquireTokenSilent(request);
    return response.accessToken;
  } catch (error) {
    let response = await instance.acquireTokenPopup(request);
    return response.accessToken;
  }
};
