import { ProviderProps, createContext, useContext } from "react";
import { Resource } from "../../resources/types";

type ResourceEditContextValue<TResource = any> = {
  onSuccess?: (d: TResource) => void;
  // submit: (t: TResource) => Promise<void>;
};

const ResourceEditContext = createContext<ResourceEditContextValue>({
  // submit: function (t: any): Promise<void> {
  //   throw new Error("Function not implemented.");
  // },
});

export const ResourceEditContextProvider = <TResource,>({
  children,
  value,
}: ProviderProps<ResourceEditContextValue<TResource>>) => (
  <>
    {value ? (
      <ResourceEditContext.Provider
        value={value as ResourceEditContextValue<Resource>}
      >
        {children}
      </ResourceEditContext.Provider>
    ) : (
      children
    )}
  </>
);

export type UseResourceEditProps<T> = ResourceEditContextValue<T>;

export const useResourceEditContext = <TResource,>(
  props?: UseResourceEditProps<TResource>
) => {
  const context = useContext(ResourceEditContext);
  return (
    props != null && Object.entries(props).length ? props : context
  ) as ResourceEditContextValue<TResource>;
};
