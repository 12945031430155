import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button, Collapse, Nav, Navbar } from "react-bootstrap";
import { MdExpandMore } from "react-icons/md";
import { To, useLocation } from "react-router";
import { CustomerNavLink } from "../navigation/CustomerLink";
import { MainMenu } from "./MainMenu";
import ServerAdminTemplate from "./ServerAdminTemplate";
import "./Sidebar.css";
import TenantAdminTemplate from "./TenantAdminTemplate";

export const Sidebar = () => {
  useEffect(() => {
    document
      .querySelector(".nav-link.active:last-child")
      ?.scrollIntoView({ block: "center" });
  }, []);

  return (
    <Navbar expand="md" className="px-md-0">
      <Navbar.Toggle />
      <Navbar.Collapse className="d-md-block">
        <Nav>
          <ul className="navbar-nav mx-1 ps-0 flex-column">
            <MainMenu />
          </ul>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

type SidebarProps = {
  addPath: (path: string) => void;
  removePath: (path: string) => void;
};
export const SidebarContext = createContext<SidebarProps>({
  addPath: () => {},
  removePath: () => {},
});

type SidebarSubElementProps = {
  title: string;
  icon: JSX.Element;
  to: To;
  isTenantAdmin?: boolean;
  isServerAdmin?: boolean;
};
export const SidebarSubElement = ({
  title,
  icon,
  to,
  isTenantAdmin = false,
  isServerAdmin = false,
}: SidebarSubElementProps) => {
  const content = (
    <li className="nav-item">
      <CustomerNavLink to={to} className="nav-link d-inline-flex">
        <span className="me-2 my-auto">{icon}</span>
        <span className="flex-fill">{title}</span>
      </CustomerNavLink>
    </li>
  );

  const { addPath, removePath } = useContext(SidebarContext);

  useEffect(() => {
    addPath(toUrlString(to));
    return () => {
      removePath(toUrlString(to));
    };
  }, [addPath, removePath, to]);

  return (
    <>
      {!(isTenantAdmin || isServerAdmin) && content}
      {isTenantAdmin && <TenantAdminTemplate>{content}</TenantAdminTemplate>}
      {isServerAdmin && <ServerAdminTemplate>{content}</ServerAdminTemplate>}
    </>
  );
};

type SidebarElementProps = PropsWithChildren & {
  title: string;
  icon: JSX.Element;
};

export const SidebarElement = ({
  title,
  children,
  icon,
}: SidebarElementProps) => {
  const [open, setOpen] = useState(false);
  const [pathes, setPathes] = useState(new Array<string>());
  const onClick = useCallback(() => {
    setOpen((o) => !o);
  }, []);

  const addPath = useCallback((path: string) => {
    setPathes((p) => {
      p.push(path);
      return p;
    });
  }, []);
  const removePath = useCallback((path: string) => {
    setPathes((p) => {
      const index = p.indexOf(path);
      p.splice(index, 1);
      return p;
    });
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    const isActive = pathes.some((p) => pathname.startsWith(p));
    setOpen(isActive);
  }, [pathes, pathname]);

  return (
    <li className="nav-item mb-1 d-grid gap-2">
      <Button
        variant="light"
        className="nav-link btn-toggle root-item d-inline-flex align-items-center rounded border-0"
        onClick={onClick}
        aria-expanded={open}
      >
        <span className="me-2 my-auto">
          {open && <MdExpandMore />}
          {!open && icon}
        </span>
        <span className="flex-fill">{title}</span>
      </Button>
      <SidebarContext.Provider
        value={{
          addPath,
          removePath,
        }}
      >
        <Collapse in={open}>
          <ul className="menu-sub-list btn-toggle-nav list-unstyled fw-normal pb-1">
            {children}
          </ul>
        </Collapse>
      </SidebarContext.Provider>
    </li>
  );
};
function toUrlString(to: To): string {
  return typeof to === "string" ? to : to.pathname ?? "";
}
