import EditCampaignPage from "../components/EditCampaignPage";
import EditOrCreateAnimationMediasCampaign from "../components/EditOrCreateAnimationMediasCampaign";

const EditHomeAdsCampaignPage = function () {
  return (
    <EditCampaignPage>
      <EditOrCreateAnimationMediasCampaign
        createModalTitle="Créer un Home Ad"
        editModalTitle="Editer un Home Ad"
        addItemButtonMessage="Ajouter un Home Ad"
      />
    </EditCampaignPage>
  );
};
export default EditHomeAdsCampaignPage;
