import React, { useCallback, useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import { NotificationsContainer } from "./components/NotificationsContainer";
import ProtectedRoute from "./components/layout/LayoutRoute";
import { Sidebar } from "./components/layout/Sidebar";
import { TopBar } from "./components/layout/TopBar";
import useRoles from "./hooks/security/useRoles";
import useAreAnalysticsEnabled from "./hooks/statistics/useAreAnalysticsEnabled";
import useCustomers from "./hooks/useCustomers";
import { NotificationsContextProvider } from "./hooks/useNotificationsContext";
import useQuery from "./hooks/useQuery";
import Customer from "./models/Customer";
import Error401Page from "./pages/Errors/Error401Page";
import Error404Page from "./pages/Errors/Error404Page";
import Home from "./pages/Home";
import { Resources } from "./pages/Resources";
import { isNullOrUndefined } from "./tools/isNullOrUndefined";

export interface LoggedInUserData {
  canManageCustomers: Customer[];
  isLoadingManageableCustomers: boolean;

  currentCustomer?: Customer;
  changeCurrentCustomer: (id?: string) => void;
  isShowingTenantAdminMode: boolean;
  toogleTenantAdminMode: () => void;
  isShowingServerAdminMode: boolean;
  toogleServerAdminMode: () => void;
  canChangeCustomer: boolean;
  setCanChangeCustomer: (
    isAllowingUserToChangeCurrentCustomer: boolean
  ) => void;
  roles: Array<string>;
  areAnalysticsEnabled: boolean;
}

const defaultLoggedInUserData: LoggedInUserData = {
  canManageCustomers: [],
  isLoadingManageableCustomers: true,
  changeCurrentCustomer: () => {},
  isShowingTenantAdminMode: true,
  toogleTenantAdminMode: () => {},
  isShowingServerAdminMode: true,
  toogleServerAdminMode: () => {},
  canChangeCustomer: false,
  setCanChangeCustomer: () => {},
  roles: [],
  areAnalysticsEnabled: false,
};

export const LoggedInUserContext = React.createContext<LoggedInUserData>(
  defaultLoggedInUserData
);

/** Use the current customer or throw if no customer currently set. */
export const useCurrentCustomer = () => {
  const { currentCustomer } = useContext(LoggedInUserContext);
  // if (!currentCustomer) {
  //   throw new Error('Unable to get the current customer.');
  // }

  return currentCustomer;
};

/** Use the current use roles */
export const useRolesContext = () => {
  const { roles } = useContext(LoggedInUserContext);

  return { roles };
};

const LoggedInBackOffice = () => {
  const [canManageCustomers, isLoadingManageableCustomers] = useCustomers();
  const [currentCustomer, setCurrentCustomer] = useState<Customer>();
  const { roles } = useRoles();
  const navigate = useNavigate();
  const location = useLocation();

  const [isShowingTenantAdminMode, setIsShowingTenantAdminMode] = useState(
    defaultLoggedInUserData.isShowingTenantAdminMode
  );
  const toogleTenantAdminMode = useCallback(() => {
    setIsShowingTenantAdminMode((b) => !b);
  }, []);

  const [isShowingServerAdminMode, setIsShowingServerAdminMode] = useState(
    defaultLoggedInUserData.isShowingServerAdminMode
  );
  const toogleServerAdminMode = useCallback(() => {
    setIsShowingServerAdminMode((b) => !b);
  }, []);

  // Redirect to the customer page (add customerId query param.)
  const changeCurrentCustomer = useCallback(
    (id?: string) => {
      const param = new URLSearchParams(location.search);
      if (id) {
        param.set("customerId", id);
      } else {
        param.delete("customerId");
      }

      navigate({
        pathname: location.pathname,
        search: "?" + param.toString(),
      });
    },
    [location.pathname, location.search, navigate]
  );

  useEffect(() => {
    // If there is only one customer: auto select this one.
    if (
      !isNullOrUndefined(canManageCustomers) &&
      canManageCustomers.length === 1 &&
      currentCustomer !== canManageCustomers[0]
    ) {
      changeCurrentCustomer(canManageCustomers[0].id);
    }
  }, [canManageCustomers, changeCurrentCustomer, currentCustomer]);

  const query = useQuery();
  useEffect(() => {
    const id = query.get("customerId")?.toLowerCase();
    if (id) {
      setCurrentCustomer(canManageCustomers.find((c) => c.id === id));
    } else {
      setCurrentCustomer(undefined);
    }
  }, [query, canManageCustomers]);

  const [
    isAllowingUserToChangeCurrentCustomer,
    setIsAllowingUserToChangeCurrentCustomer,
  ] = useState(false);
  const { areAnalysticsEnabled } = useAreAnalysticsEnabled(currentCustomer);

  return (
    <NotificationsContextProvider>
      <LoggedInUserContext.Provider
        value={{
          canManageCustomers,
          isLoadingManageableCustomers,
          currentCustomer,
          changeCurrentCustomer,
          isShowingTenantAdminMode,
          toogleTenantAdminMode,
          isShowingServerAdminMode,
          toogleServerAdminMode,
          roles,
          areAnalysticsEnabled,
          canChangeCustomer: isAllowingUserToChangeCurrentCustomer,
          setCanChangeCustomer: setIsAllowingUserToChangeCurrentCustomer,
        }}
      >
        <div className="position-relative vh-100 d-flex flex-column">
          <TopBar />
          <section className="container-fluid flex-grow-1">
            <div className="row flex-md-nowrap h-100 ">
              <aside className="col-md-2 px-0 bg-light">
                <Sidebar />
              </aside>
              <main className="col-md-10 py-3 ">
                <Routes>
                  <Route
                    index
                    element={
                      <ProtectedRoute
                        canChangeCustomer
                        isCurrentCustomerMandatory={false}
                      >
                        <Home />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/" errorElement={<Error404Page />}>
                    <Route path="*" element={<Resources />}></Route>
                  </Route>
                  <Route path="/unauthorized" element={<Error401Page />} />
                </Routes>
              </main>
            </div>
          </section>
        </div>
        <div
          className="position-fixed bottom-0 start-50 p-3"
          style={{ zIndex: 11 }}
        >
          <NotificationsContainer />
        </div>
      </LoggedInUserContext.Provider>
    </NotificationsContextProvider>
  );
};

export default LoggedInBackOffice;
