import { useCallback } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { ListCondition } from "../../models/ListCondition";
import { AttributeValuesFilters } from "../../models/filters/AttributeValuesFilters";
import {
  ControlledResourcesSelect,
  ControlledResourcesSelectProps,
  ReactSelectProps,
  ResourcesSelect,
} from "../../resources/components/selects/ResourcesSelect";
import { ResourceName } from "../../resources/types/resourceName";
import ReactSelectOption from "../ReactSelectOption";

type UseAttributeValuesSelectControllerProps = {
  attributeId: number;
};
const useAttributeValuesSelectController = <
  TAttributeValuesFilters extends AttributeValuesFilters = AttributeValuesFilters
>(
  props: UseAttributeValuesSelectControllerProps
) => {
  const { attributeId } = props;
  const convertToOption = useCallback(
    (value: string | undefined): ReactSelectOption<string> | undefined =>
      value != null
        ? {
            label: `${value}`,
            value: value as string,
          }
        : undefined,
    []
  );
  const transformToFilters = useCallback(
    (search: string) =>
      ({
        attributeId,
        value: search,
        listCondition: ListCondition.And,
      } as TAttributeValuesFilters),
    [attributeId]
  );
  const resourceName: ResourceName = "attribute-values";
  return { convertToOption, resourceName, transformToFilters };
};

type AttributeValuesSelectProps<IsMulti extends boolean> = ReactSelectProps<
  string,
  IsMulti
>;

export const AttributeValuesSelect = <IsMulti extends boolean>(
  props: AttributeValuesSelectProps<IsMulti> &
    UseAttributeValuesSelectControllerProps
) => {
  const c = useAttributeValuesSelectController(props);
  return (
    <ResourcesSelect<
      string,
      string,
      IsMulti,
      ResourceName,
      AttributeValuesFilters
    >
      {...c}
      {...props}
    />
  );
};

type ControlledAttributeValuesSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledResourcesSelectProps<
  AttributeValuesSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledAttributeValuesSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledAttributeValuesSelectProps<TFieldValues, TName, IsMulti> &
    UseAttributeValuesSelectControllerProps
) => {
  const c = useAttributeValuesSelectController(props);

  return (
    <ControlledResourcesSelect<
      string,
      string,
      IsMulti,
      ResourceName,
      AttributeValuesFilters,
      TFieldValues,
      TName
    >
      {...c}
      {...props}
      name={props.name}
    />
  );
};
