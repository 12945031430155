import { AnimationMediaTargetType } from "./AnimationMedia";

export default interface InteractiveCatalog {
  id: number;
  label: string;
  interactivePages: InteractivePage[];
}

export class InteractivePage {
  constructor() {
    this.id = new Date().getTime().toString(); //temp local id
    this.interactiveAreas = [];
  }
  public id?: number | string;
  public index?: number;
  public mediaUrl?: string;
  public thumbnailUrl?: string;
  public imageWidth?: number;
  public imageHeight?: number;
  public interactiveAreas: InteractiveArea[];
}

export class InteractiveArea {
  constructor(
    public id: number | string, // Number come from api | string is a temp ui id.,
    public originX: number,
    public originY: number,
    public width: number,
    public height: number
  ) {
    this.targetType = AnimationMediaTargetType.Home;
  }

  public targetType?: number;
  public categoryId?: number;
  public productId?: number;
  public brandId?: number;
  public searchTerms?: string;
  public webUrl?: string;
}
