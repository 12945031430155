import { useCallback } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import DeliveryZone from "../../models/DeliveryZone";
import { ListCondition } from "../../models/ListCondition";
import { DeliveryZonesFilters } from "../../models/filters/DeliveryZonesFilters";
import {
  ControlledResourcesSelect,
  ControlledResourcesSelectProps,
  ReactSelectProps,
  ResourcesSelect,
} from "../../resources/components/selects/ResourcesSelect";
import { ResourceName } from "../../resources/types/resourceName";
import ReactSelectOption from "../ReactSelectOption";

const useDeliveryZonesSelectController = <
  TDeliveryZone extends DeliveryZone = DeliveryZone,
  TNumber extends number = number,
  TFilters extends DeliveryZonesFilters = DeliveryZonesFilters
>() => {
  const convertToOption = useCallback(
    (
      value: TDeliveryZone | undefined
    ): ReactSelectOption<TNumber> | undefined =>
      value != null
        ? {
            label: `${value.name}`,
            value: value.id as TNumber,
          }
        : undefined,
    []
  );
  const transformToFilters = useCallback(
    (search: string) =>
      ({
        name: search,
        listCondition: ListCondition.Or,
      } as TFilters),
    []
  );
  const resourceName: ResourceName = "deliveryzones";
  return { convertToOption, resourceName, transformToFilters };
};

type DeliveryZonesSelectProps<IsMulti extends boolean> = ReactSelectProps<
  number,
  IsMulti
>;

export const DeliveryZonesSelect = <IsMulti extends boolean>(
  props: DeliveryZonesSelectProps<IsMulti>
) => {
  const c = useDeliveryZonesSelectController();
  return (
    <ResourcesSelect<
      DeliveryZone,
      number,
      IsMulti,
      ResourceName,
      DeliveryZonesFilters
    >
      {...c}
      {...props}
    />
  );
};

type ControlledDeliveryZonesSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledResourcesSelectProps<
  DeliveryZonesSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledDeliveryZonesSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledDeliveryZonesSelectProps<TFieldValues, TName, IsMulti>
) => {
  const c = useDeliveryZonesSelectController();

  return (
    <ControlledResourcesSelect<
      DeliveryZone,
      number,
      IsMulti,
      ResourceName,
      DeliveryZonesFilters,
      TFieldValues,
      TName
    >
      {...c}
      {...props}
      name={props.name}
    />
  );
};
