import { useCallback } from "react";
import { Resource } from "../../resources/types";
import { CreateProps, DataProvider } from "../types/dataProvider";
import { useResourceSafeActionCaller } from "./useResourceSafeActionCaller";

export const useCreateResource = <
  TResourceName extends string = string,
  TResourceType extends Resource = Resource
>() => {
  const unsafeAction = useCallback(
    async (
      dataProvider: DataProvider<TResourceName, TResourceType>,
      props: CreateProps<TResourceName, TResourceType>
    ) => await dataProvider.create(props),
    []
  );
  const { safeAction: create, errors } = useResourceSafeActionCaller({
    unsafeAction,
  });

  return { create, errors };
};
