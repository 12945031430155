import { useControlledQueryPagination } from "../../hooks/useControlledQueryPagination";
import { Resource } from "../../resources/types";
import {
  UseControlledPaginationSWRListResourceProps,
  UseControlledPaginationSWRListResourceReturn,
} from "./useControlledPaginationSWRListResource";
import { useSWRListResource } from "./useSWRListResource";

export const useControlledQueryPaginationSWRListResource = <
  TResourceName extends string = string,
  TResource extends Resource = Resource
>(
  props: UseControlledPaginationSWRListResourceProps<TResourceName>
): UseControlledPaginationSWRListResourceReturn<TResource> => {
  const { filters, resourceName, defaultPageIndex, defaultPageSize } = props;
  const { pageIndex, pageSize, setPageIndex, setPageSize } =
    useControlledQueryPagination({
      defaultPageIndex,
      defaultPageSize,
    });
  const { data, error, mutate } = useSWRListResource<TResourceName, TResource>({
    resourceName,
    pageIndex,
    pageSize,
    filters,
  });
  return {
    pageIndex,
    pageSize,
    setPageIndex,
    setPageSize,
    data,
    error,
    mutate,
  };
};
