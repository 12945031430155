import CreateCampaignPage from "../components/CreateCampaignPage";
import EditOrCreateDigitalSignageCampaign from "../components/EditOrCreateDigitalSignageCampaign";

const CreateDigitalSignageCampaignPage = () => {
  return (
    <CreateCampaignPage>
      <EditOrCreateDigitalSignageCampaign />
    </CreateCampaignPage>
  );
};

export default CreateDigitalSignageCampaignPage;
