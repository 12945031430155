import { useMemo } from "react";
import ApplicationUser from "../../models/ApplicationUser";
import { CreateResourceButton } from "../../resources/components/buttons/CreateResourceButton";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { useAddEditButton } from "../../resources/hooks/useAddEditButton";

const ApplicationUsersPage = () => {
  const columns: ResourceColumn<ApplicationUser>[] = useMemo(
    () => [
      {
        header: "Email",
        accessor: "email",
      },
    ],
    []
  );

  const { columnsWithButton } = useAddEditButton({
    columns,
  });

  return (
    <ListPage
      // filterFieds={fields}
      actions={<CreateResourceButton />}
      showChildWithoutFilters
    >
      <ResourceTable columns={columnsWithButton} />
    </ListPage>
  );
};

export default ApplicationUsersPage;
