import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, FormGroup } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { AutomaticArticlesSelection } from "../../../../models/ArticlesSelection";
import { ResourceHiddenNumberInput } from "../../../../resources/components/inputs/ResourceHiddenNumberInput";
import { isValidNumber } from "../../../../tools/isValidNumber";
import { AddAttributeRow } from "./AddAttributeRow";
import { AddBrandRow } from "./AddBrandRow";
import { AddCategoryRow } from "./AddCategoryRow";

import { ResourceCheckInput } from "../../../../resources/components/inputs/ResourceCheckInput";
import { ResourceHiddenTextInput } from "../../../../resources/components/inputs/ResourceHiddenTextInput";
import { ResourceInput } from "../../../../resources/components/inputs/ResourceInput";
import { useResourceContext } from "../../../../resources/hooks/useResourceContext";
import { AttributeFilterContent } from "../types/AttributeFilterContent";
import { ExtendedAutomaticArticlesSelection } from "../types/ExtendedAutomaticArticlesSelection";
import { FilterContent } from "../types/FilterContent";
import { ArticlesPreviewerModal } from "./ArticlesPreviewerModal";
import FilterCard from "./FilterCard";
import FilterGroup from "./FilterGroup";
import { ListFilter } from "./ListFilter";
import { RenderSelectionFilterInputs } from "./RenderSelectionFilterInputs";
import { ShowAttribute } from "./ShowAttribute";
import { ShowCategory } from "./ShowCategory";
import { getAutomaticSelection, getQuery } from "./utils";

const arrayName = "query.filters";

const AutomaticArticlesSelectionForm = () => {
  const formMethods = useFormContext<ExtendedAutomaticArticlesSelection>();

  const { getValues, setValue, watch } = formMethods;
  const { resource: { query: initQuery } = {} } =
    useResourceContext<AutomaticArticlesSelection>();

  const [firstTime, setFirstTime] = useState(true);

  const currentInitAutomaticSelection = useMemo(() => {
    return firstTime ? getAutomaticSelection(initQuery?.filters) : undefined;
  }, [firstTime, initQuery?.filters]);

  useEffect(() => {
    if (firstTime && currentInitAutomaticSelection != null) {
      setValue("ex_attributes", currentInitAutomaticSelection.attributes);
      setValue("brands", currentInitAutomaticSelection.brands);
      setValue("categories", currentInitAutomaticSelection.categories);
      setValue(
        "isCentralOrderable",
        currentInitAutomaticSelection.isCentralOrderable
      );
      setValue(
        "isInStoreOrderable",
        currentInitAutomaticSelection.isInStoreOrderable
      );
      setValue("minimum", currentInitAutomaticSelection.minimum);
      setValue("maximum", currentInitAutomaticSelection.maximum);
      setFirstTime(false);
    }
  }, [currentInitAutomaticSelection, firstTime, setValue]);

  const [isShowing, setIsShowing] = useState(false);
  const toggle = useCallback(() => setIsShowing((i) => !i), []);

  const isCentralOrderable = watch("isCentralOrderable");
  const isInStoreOrderable = watch("isInStoreOrderable");
  const attributs = watch("ex_attributes");
  const categories = watch("categories");
  const brands = watch("brands");
  const minimum = watch("minimum");
  const maximum = watch("maximum");

  useEffect(() => {
    if (attributs != null) {
      const values = getValues();
      const q = getQuery(values);
      setValue("query", q);
    }
  }, [attributs, getValues, setValue]);

  useEffect(() => {
    if (categories != null) {
      const q = getQuery(getValues());
      setValue("query", q);
    }
  }, [categories, getValues, setValue]);

  useEffect(() => {
    if (brands != null) {
      const q = getQuery(getValues());
      setValue("query", q);
    }
  }, [brands, getValues, setValue]);

  useEffect(() => {
    if (isCentralOrderable != null) {
      const q = getQuery(getValues());
      setValue("query", q);
    }
  }, [isCentralOrderable, getValues, setValue]);

  useEffect(() => {
    if (isInStoreOrderable != null) {
      const q = getQuery(getValues());
      setValue("query", q);
    }
  }, [isInStoreOrderable, getValues, setValue]);

  useEffect(() => {
    if (minimum != null) {
      const q = getQuery(getValues());
      setValue("query", q);
    }
  }, [minimum, getValues, setValue]);

  useEffect(() => {
    if (maximum != null) {
      const q = getQuery(getValues());
      setValue("query", q);
    }
  }, [maximum, getValues, setValue]);

  const query = watch("query");
  const needAnyFilters = {
    needAnyFilters: (v: any) => (getValues()?.query?.filters?.length ?? 0) > 0,
  };
  return (
    <>
      {query && (
        <>
          <ResourceHiddenNumberInput<AutomaticArticlesSelection> name="query.operator" />

          {query.filters &&
            query.filters.map((f, i) => {
              return (
                <RenderSelectionFilterInputs
                  key={i}
                  selectionFilter={f}
                  index={i}
                  arrayName={arrayName}
                />
              );
            })}
        </>
      )}
      <FormGroup>
        <Form.Label>
          Afficher les articles correspondants à tous ces critères*
        </Form.Label>
        <div className="row">
          <FilterGroup>
            <FilterCard header="Disponibilités">
              Les articles disponibles
              <ResourceCheckInput<ExtendedAutomaticArticlesSelection>
                type="checkbox"
                name="isCentralOrderable"
                label="commandable en centrale"
                options={{
                  validate: {
                    ...needAnyFilters,
                  },
                }}
              />
              <ResourceCheckInput<ExtendedAutomaticArticlesSelection>
                type="checkbox"
                name="isInStoreOrderable"
                label="commandable en magasin"
                options={{
                  validate: {
                    ...needAnyFilters,
                  },
                }}
              />
            </FilterCard>
          </FilterGroup>
          <FilterGroup>
            <FilterCard header="Prix">
              <div className="row">
                <div className="col-6">
                  <ResourceInput<ExtendedAutomaticArticlesSelection>
                    label="Minimum"
                    name="minimum"
                    onClear={() =>
                      setValue("minimum", undefined, { shouldDirty: true })
                    }
                    options={{
                      setValueAs: (v) => {
                        let number = parseFloat(v);
                        return Number.isNaN(number) ? v : number;
                      },
                      validate: {
                        ...needAnyFilters,
                        isInteger: (v) =>
                          v == null ||
                          v === "" ||
                          Number.isInteger(v) ||
                          "Le prix doit être un entier",
                        positive: (v) =>
                          v == null || v >= 0 || "Le prix doit être positif",
                        lessThanOrEqualsMaximum: (v) => {
                          let max = getValues("maximum");
                          return (
                            v == null ||
                            !isValidNumber(v) ||
                            max == null ||
                            !isValidNumber(max) ||
                            Number.isNaN(max) ||
                            v <= max ||
                            "Le prix doit être inférieur au prix maximum"
                          );
                        },
                      },
                    }}
                    min={0}
                    type="number"
                  />
                </div>
                <div className="col-6">
                  <ResourceInput<ExtendedAutomaticArticlesSelection>
                    label="Maximum"
                    name="maximum"
                    onClear={() =>
                      setValue("maximum", undefined, { shouldDirty: true })
                    }
                    options={{
                      setValueAs: (v) => {
                        let number = parseFloat(v);
                        return Number.isNaN(number) ? v : number;
                      },
                      validate: {
                        ...needAnyFilters,
                        // isNumber: (v) => v === undefined || !Number.isNaN(v),
                        isInteger: (v) =>
                          v == null ||
                          v === "" ||
                          Number.isInteger(v) ||
                          "Le prix doit être un entier",
                        positive: (v) =>
                          v == null || v >= 0 || "Le prix doit être positif",
                        lessThanOrEqualsMaximum: (v) => {
                          let min = getValues("minimum");
                          return (
                            v == null ||
                            !isValidNumber(v) ||
                            min == null ||
                            !isValidNumber(min) ||
                            min <= v ||
                            "Le prix doit être supérieur au prix minimum"
                          );
                        },
                      },
                    }}
                    min={0}
                    type="number"
                  />
                </div>
              </div>
            </FilterCard>
          </FilterGroup>
          <FilterGroup>
            <ListFilter<FilterContent>
              header="Catégories"
              text="Les articles appartenant à l'une de ces catégories"
              arrayName="categories"
              renderAddRow={(append) => <AddCategoryRow append={append} />}
              renderInputCells={(c, index) => {
                return <ShowCategory categoryIndex={index} c={c} />;
              }}
            />
          </FilterGroup>
          <FilterGroup>
            <ListFilter<FilterContent>
              header="Marques"
              text="Les articles appartenant à l'une de ces marques"
              renderInputCells={(s, index) => {
                return (
                  <td>
                    <ResourceHiddenTextInput<ExtendedAutomaticArticlesSelection>
                      name={`brands.${index}.value` as const}
                    />
                    {s.value}
                  </td>
                );
              }}
              arrayName="brands"
              renderAddRow={(append) => <AddBrandRow append={append} />}
            />
          </FilterGroup>
          <FilterGroup>
            <ListFilter<AttributeFilterContent>
              header="Attributs"
              text="Les articles ayant un de ces couples d'attributs / valeurs"
              arrayName="ex_attributes"
              renderInputCells={(s, index) => {
                return <ShowAttribute s={s} index={index} />;
              }}
              renderAddRow={(append) => <AddAttributeRow append={append} />}
            />
          </FilterGroup>
        </div>
      </FormGroup>
      <>
        {query && isShowing && (
          <ArticlesPreviewerModal isShowing={isShowing} hide={toggle} />
        )}

        <Button
          className="align-self-start"
          type="button"
          variant="outline-primary"
          onClick={toggle}
        >
          Prévisualiser la sélection d'articles
        </Button>
      </>
    </>
  );
};
export default AutomaticArticlesSelectionForm;
