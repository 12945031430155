import React, { useCallback } from "react";
import { Control, FieldArrayWithId, FieldErrors } from "react-hook-form";
import { Button } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";
import TargetInput from "../../../../components/TargetInput";
import { AnimationMediaTargetType } from "../../../../models/AnimationMedia";
import {
  InteractiveArea,
  InteractivePage,
} from "../../../../models/InteractiveCatalog";

type InteractiveAreaFormProps = {
  interactiveArea: InteractiveArea;
  interactivePageFieldArrayWithId: FieldArrayWithId<
    InteractivePage,
    "interactiveAreas",
    "id"
  >;
  index: number;
  currentEditingAreaId: string | number | undefined;
  control: Control<InteractivePage, any>;
  errors: FieldErrors<InteractivePage>;
  isBusy: boolean;
  removeInteractiveArea: (index: number) => void;
};

const InteractiveAreaForm: React.FC<InteractiveAreaFormProps> = ({
  interactiveArea,
  interactivePageFieldArrayWithId,
  index,
  currentEditingAreaId,
  control,
  errors,
  isBusy,
  removeInteractiveArea,
}) => {
  const onDeleteArea = useCallback(() => {
    removeInteractiveArea(index);
  }, [removeInteractiveArea, index]);

  return (
    <div
      key={interactiveArea.id}
      className={
        currentEditingAreaId === interactivePageFieldArrayWithId.id
          ? "area-form d-flex flex-column gap-2 "
          : "area-form d-flex flex-column gap-2 d-none"
      }
    >
      <TargetInput
        targetOptions={[
          AnimationMediaTargetType.Home,
          AnimationMediaTargetType.Category,
          AnimationMediaTargetType.Brand,
          AnimationMediaTargetType.Product,
          AnimationMediaTargetType.Search,
          AnimationMediaTargetType.ArticlesSelection,
          AnimationMediaTargetType.WebUrl,
        ]}
        control={control}
        namePrefix={`interactiveAreas.${index}.`}
        targetTypeDefaultValue={interactiveArea.targetType}
        errors={
          errors.interactiveAreas ? errors.interactiveAreas[index] : undefined
        }
        disabled={isBusy}
      />
      <Button variant="outline-danger align-self-start" onClick={onDeleteArea}>
        <MdDeleteForever /> Supprimer cette zone
      </Button>
    </div>
  );
};

export default InteractiveAreaForm;
