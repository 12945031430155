import Customer from "../../models/Customer";
import DeviceConnectionLog from "../../models/DeviceConnectionLog";
import useFetchForCustomer from "../swr/useSWRFetchForCustomer";

const useDeviceConnectionLog = (customer?: Customer, deviceId?: string) => {
  const res = useFetchForCustomer(
    deviceId ? `/api/devices/connectionlogs?deviceId=${deviceId}` : undefined,
    customer
  );
  const d = res.data as DeviceConnectionLog;
  if (d) {
    if (typeof d.lastConnection === "string") {
      d.lastConnection = new Date(d.lastConnection);
    }
  }
  return { ...res, deviceConnectionLog: res.data as DeviceConnectionLog };
};

export default useDeviceConnectionLog;
