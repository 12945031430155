import { ArticlesSelection } from "../../../../models/ArticlesSelection";
import { ResourceFileInput } from "../../../../resources/components/inputs/ResourceFileInput";
import { ResourceHiddenNumberInput } from "../../../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceHiddenTextInput } from "../../../../resources/components/inputs/ResourceHiddenTextInput";
import { ResourceInput } from "../../../../resources/components/inputs/ResourceInput";

export const ArticlesSelectionForm = () => {
  return (
    <>
      <ResourceHiddenNumberInput<ArticlesSelection> name="id" />

      <ResourceInput<ArticlesSelection>
        label="Nom de la sélection d'articles*"
        name="label"
        options={{
          required: "Le nom de la sélection d'articles est obligatoire",
          maxLength: {
            message:
              "Le nom de la sélection d'articles ne peut pas dépasser 50 caractères",
            value: 50,
          },
        }}
      />
      <ResourceHiddenTextInput<ArticlesSelection> name="code" />
      <ResourceHiddenNumberInput<ArticlesSelection> name="type" />

      <ResourceHiddenTextInput<ArticlesSelection> name="thumbnailUrl" />
      <ResourceHiddenTextInput<ArticlesSelection> name="imageUrl" />

      <ResourceFileInput<ArticlesSelection>
        accept=".jpg,.jpeg,.jfif,.png"
        label="Image*"
        name="imageUrl"
        mediaType="image"
        rules={{
          required: "L'image est obligatoire",
        }}
      />
    </>
  );
};
