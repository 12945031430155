import { useCallback } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { ListCondition } from "../../models/ListCondition";
import Transporter from "../../models/Transporter";
import { TransportersFilters } from "../../models/filters/TransportersFilters";
import {
  ControlledResourcesSelect,
  ControlledResourcesSelectProps,
  ReactSelectProps,
  ResourcesSelect,
} from "../../resources/components/selects/ResourcesSelect";
import { ResourceName } from "../../resources/types/resourceName";
import ReactSelectOption from "../ReactSelectOption";

const useTransportersSelectController = <
  TTransporter extends Transporter = Transporter,
  TNumber extends number = number,
  TFilters extends TransportersFilters = TransportersFilters
>() => {
  const convertToOption = useCallback(
    (value: TTransporter | undefined): ReactSelectOption<TNumber> | undefined =>
      value != null
        ? {
            label: `${value.name}`,
            value: value.id as TNumber,
          }
        : undefined,
    []
  );
  const transformToFilters = useCallback(
    (search: string) =>
      ({
        name: search,
        listCondition: ListCondition.Or,
      } as TFilters),
    []
  );
  const resourceName: ResourceName = "transporters";
  return { convertToOption, resourceName, transformToFilters };
};

type TransportersSelectProps<IsMulti extends boolean> = ReactSelectProps<
  number,
  IsMulti
>;

export const TransportersSelect = <IsMulti extends boolean>(
  props: TransportersSelectProps<IsMulti>
) => {
  const c = useTransportersSelectController();
  return (
    <ResourcesSelect<
      Transporter,
      number,
      IsMulti,
      ResourceName,
      TransportersFilters
    >
      {...c}
      {...props}
    />
  );
};

type ControlledTransportersSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledResourcesSelectProps<
  TransportersSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledTransportersSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledTransportersSelectProps<TFieldValues, TName, IsMulti>
) => {
  const c = useTransportersSelectController();

  return (
    <ControlledResourcesSelect<
      Transporter,
      number,
      IsMulti,
      ResourceName,
      TransportersFilters,
      TFieldValues,
      TName
    >
      {...c}
      {...props}
      name={props.name}
    />
  );
};
