import { AttributeType } from "../../models/AttributeType";

export const displayAttributeType = (type: AttributeType): string => {
  return type === AttributeType.Text
    ? "Texte"
    : type === AttributeType.Boolean
    ? "Booléen"
    : type === AttributeType.Numeric
    ? "Numérique"
    : "";
};
