import EditCampaignPage from "../components/EditCampaignPage";
import EditOrCreateDigitalSignageCampaign from "../components/EditOrCreateDigitalSignageCampaign";

const EditDigitalSignageCampaignPage = () => {
  return (
    <EditCampaignPage>
      <EditOrCreateDigitalSignageCampaign />
    </EditCampaignPage>
  );
};

export default EditDigitalSignageCampaignPage;
