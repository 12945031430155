import { EditPage } from "../../../resources/components/EditPage";
import { EditOrCreateArticlesSelectionForm } from "./components/EditArticlesSelectionForm";
import { useArticlesSelectionController } from "./hooks/useArticlesSelectionController";

const EditArticlesSelectionPage = () => {
  const res = useArticlesSelectionController();
  return (
    <EditPage {...res}>
      <EditOrCreateArticlesSelectionForm />
    </EditPage>
  );
};

export default EditArticlesSelectionPage;
