import { Control, FieldArrayWithId } from "react-hook-form"; // Assurez-vous d'importer les bons types
import AnimationMedia from "../../../models/AnimationMedia";
import AnimationMediasCampaign from "../../../models/AnimationMediasCampaign";

type CampaignAnimationMediasHiddenInputsProps<T extends AnimationMedia> = {
  control: Control<AnimationMediasCampaign<T>>; // Remplacez 'any' par le type de données que vous enregistrez
  fields: FieldArrayWithId<
    AnimationMediasCampaign<T>,
    "campaignAnimationMedias"
  >[]; // Remplacez 'any' par les types appropriés
};

const CampaignAnimationMediasHiddenInputs = <T extends AnimationMedia>({
  control,
  fields,
}: CampaignAnimationMediasHiddenInputsProps<T>) => {
  const { register } = control;
  return (
    <>
      {fields.map((m, index) => (
        <div key={m.id}>
          <input
            type="hidden"
            {...register(`campaignAnimationMedias.${index}.animationMediaId`, {
              required: true,
              valueAsNumber: true,
            })}
            value={m.animationMediaId}
          />
        </div>
      ))}
    </>
  );
};

export default CampaignAnimationMediasHiddenInputs;
