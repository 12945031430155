import { useCallback, useEffect, useMemo, useState } from "react";
import Form from "react-bootstrap/esm/Form";
import ReactSelect from "react-select";
import Customer from "../../models/Customer";
import ReactSelectOption from "../ReactSelectOption";

export interface CustomerSelectProps {
  onChange: (id?: string) => void;
  customers: Customer[] | undefined;
  value?: Customer;
  disabled?: boolean;
}

const CustomerSelect = ({
  customers,
  value,
  disabled,
  onChange,
}: CustomerSelectProps) => {
  const customerOptions = useMemo(() => {
    return customers?.map((c) => {
      return { value: c.id, label: c.name } as ReactSelectOption<string>;
    });
  }, [customers]);
  const [customerOption, setCustomerOption] =
    useState<ReactSelectOption<string>>();
  useEffect(() => {
    if (value?.id && customerOptions) {
      setCustomerOption(
        customerOptions.find(
          (c: ReactSelectOption<string>) => c.value === value.id
        )
      );
    }
  }, [customerOptions, value]);

  const noOptionsMessage = useCallback(() => "Pas d'option", []);
  const loadingMessage = useCallback((s: any) => "Chargement...", []);
  return (
    <Form>
      <div className="row">
        <div className="col">
          <ReactSelect
            styles={{
              menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
            }}
            options={customerOptions}
            placeholder="Sélectionner un client"
            defaultValue={customerOption}
            loadingMessage={loadingMessage}
            noOptionsMessage={noOptionsMessage}
            value={customerOption}
            isDisabled={disabled}
            isLoading={
              customerOptions === undefined || customerOptions.length === 0
            }
            onChange={(e: any) => {
              if (
                customerOption === undefined ||
                e.value !== customerOption.value
              ) {
                setCustomerOption(customerOption);
                onChange(e.value);
              }
            }}
          />
        </div>
      </div>
    </Form>
  );
};

export default CustomerSelect;
