import { PropsWithChildren, useMemo } from "react";
import { MdAdd } from "react-icons/md";
import { CustomerLink } from "../../../components/navigation/CustomerLink";
import { useResourceName } from "../../hooks/useResourceName";
import { useResourceUrlPathResolver } from "../../hooks/useResourceUrlPathResolver";

type CreateResourceButtonProps = PropsWithChildren & {
  to?: string;
  resourceName?: string;
  params?: Record<string, any>;
};

export const CreateResourceButton = (props: CreateResourceButtonProps) => {
  const { to, children, params } = props;
  const { resourceName } = useResourceName(props);
  const {
    resourceUrlPathResolver: { create },
  } = useResourceUrlPathResolver();

  let finalTo = useMemo(() => {
    return to != null ? to : create(resourceName, params);
  }, [create, params, resourceName, to]);

  return (
    <CustomerLink className="btn btn-sm btn-outline-primary" to={finalTo}>
      <MdAdd className="me-1" />
      {children != null ? children : "Créer"}
    </CustomerLink>
  );
};
