import { useCallback } from "react";
import { useNavigate } from "react-router";
import { useCurrentCustomer } from "../../../LoggedInBackOffice";
import { AsyncHandler } from "../../../components/AsyncHandler";
import { GoBackButton } from "../../../components/buttons/GoBackButton";
import { Loading } from "../../../components/types/loading";
import useAnalyticsExportScheduledJob from "../../../hooks/analyticsExportScheduledJob/useAnalyticsExportScheduledJob";
import useDeleteResources from "../../../hooks/useDeleteResources";
import { PageTitle } from "../../../resources/components/PageTitle";
import { DeleteResourceButtonOld } from "../../../resources/components/buttons/DeleteResourceButtonOld";
import { useNumberResourceParams } from "../../../resources/hooks/useResourceParams";
import EditAnalyticsExportScheduledJobForm from "../shared/EditAnalyticsExportScheduledJobForm";

const EditAnalyticsExportScheduledJobPage = () => {
  const navigate = useNavigate();
  const currentCustomer = useCurrentCustomer();

  const { id } = useNumberResourceParams();

  const {
    analyticsExportScheduledJob,
    error,
    mutate: refresh,
    mutate,
    isLoading,
  } = useAnalyticsExportScheduledJob(currentCustomer, id);

  const {
    askForDeletion,
    errors: deletionErrors,
    isBusy: isBusyDeleting,
  } = useDeleteResources(
    `/api/AnalyticsExportScheduledJobs/${id}`,
    "Voulez vous vraiment supprimer cette planification ?",
    useCallback(() => {
      navigate(
        `/analyticsExportScheduledJobs?customerId=${currentCustomer?.id}`,
        { replace: true }
      );
    }, [currentCustomer?.id, navigate])
  );

  return (
    <>
      <GoBackButton />
      <PageTitle label="Planifications d'export de statistiques" />
      <hr />
      <AsyncHandler
        error={error}
        errorMessage="Une erreur est survenue lors de la récupération de cette planification."
        refresh={refresh}
        isLoading={isLoading}
        loading={Loading.Circle}
      >
        <EditAnalyticsExportScheduledJobForm
          analyticsExportScheduledJob={analyticsExportScheduledJob}
          mode="edit"
          onSaveCallback={mutate}
        />
        <hr />

        <DeleteResourceButtonOld
          resource={analyticsExportScheduledJob}
          askForDeletion={askForDeletion}
          deletionErrors={deletionErrors}
          isBusyDeleting={isBusyDeleting}
        />
      </AsyncHandler>
    </>
  );
};

export default EditAnalyticsExportScheduledJobPage;
