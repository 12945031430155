import { useMemo } from "react";
import { AnalyticsTable } from "../../../models/AnalyticsTable";

export const useTranslatedAnalyticsTable = () => {
  return useMemo(() => {
    return {
      [AnalyticsTable.AbondonedOrders]: "Commandes abandonnées",
      [AnalyticsTable.BrowsedArticles]: "Articles consultés",
      [AnalyticsTable.BrowsedCategories]: "Catégories consultées",
      [AnalyticsTable.Orders]: "Commandes",
      [AnalyticsTable.OrderedArticles]: "Articles commandés",
      [AnalyticsTable.StartedUserSessions]: "Sessions commencées",
      [AnalyticsTable.NotifiedArticles]: "Articles notifiés",
      [AnalyticsTable.AddedArticles]: "Articles ajoutés",
      [AnalyticsTable.SharedCarts]: "Paniers Partagés",
      [AnalyticsTable.Searches]: "Recherches",
      [AnalyticsTable.SharedProducts]: "Articles partagés",
      [AnalyticsTable.SalesAssistantCalls]: "Appels vendeur",
      [AnalyticsTable.NotifiedSearches]: "Recherches notifiées",
      [AnalyticsTable.NotifiedCategories]: "Categories notifiées",
      [AnalyticsTable.NotifiedSelections]: "Sélections notifiées",
    };
  }, []);
};
