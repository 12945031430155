import { PropsWithChildren, isValidElement } from "react";
import { CustomerRedirect } from "../navigation/CustomerLink";
import Layout from "./Layout";
import NotTenantAdminTemplate from "./NotTenantAdminTemplate";
import ServerAdminTemplate from "./ServerAdminTemplate";
import TenantAdminTemplate from "./TenantAdminTemplate";

export type ProtectedRouteProps = PropsWithChildren & {
  canChangeCustomer: boolean;
  isCurrentCustomerMandatory: boolean;
  isTenantAdminRoleMandatory?: boolean;
  isServerAdminRoleMandatory?: boolean;
};

// Wrap 'Route' objet with the layout...
const ProtectedRoute = ({
  children,
  canChangeCustomer,
  isCurrentCustomerMandatory,
  isTenantAdminRoleMandatory,
  isServerAdminRoleMandatory,
}: ProtectedRouteProps) => {
  const unauthorized = (
    <NotTenantAdminTemplate>
      <CustomerRedirect to="/unauthorized" />
    </NotTenantAdminTemplate>
  );
  return (
    <Layout
      canChangeCustomer={canChangeCustomer}
      isCurrentCustomerMandatory={isCurrentCustomerMandatory}
    >
      <>
        {children && isValidElement(children) && (
          <>
            {isTenantAdminRoleMandatory ? (
              <>
                <TenantAdminTemplate>{children}</TenantAdminTemplate>
                {unauthorized}
              </>
            ) : isServerAdminRoleMandatory ? (
              <>
                <ServerAdminTemplate>{children}</ServerAdminTemplate>
                {unauthorized}
              </>
            ) : (
              <>{children}</>
            )}
          </>
        )}
      </>
    </Layout>
  );
};
export default ProtectedRoute;
