import { useEffect, useMemo } from "react";
import { useCurrentCustomer } from "../../LoggedInBackOffice";
import { useResourceLabel } from "../hooks/useResourceLabel";

type PageTitleProps = {
  label?: React.ReactNode;
};

export const PageTitle = (props: PageTitleProps) => {
  //récupération du currentCustomer
  const currentCustomer = useCurrentCustomer();

  const { label } = useResourceLabel({ label: props.label });
  const { label: resourceLabel } = useResourceLabel();

  const title = useMemo(() => {
    let title = "";
    if (label) {
      if (typeof label === "string") {
        title += label;
      } else if (resourceLabel) {
        if (typeof resourceLabel === "string") {
          title += resourceLabel;
        }
      }
    }
    return title;
  }, [label, resourceLabel]);

  const customerName = useMemo(() => {
    let title = "";

    if (currentCustomer) {
      const threshold = 9;
      if (
        currentCustomer.name.length < threshold ||
        currentCustomer.name.indexOf(" ") === -1
      ) {
        //ajouter les 6 premiers caractères du nom du customer
        title += currentCustomer.name.slice(0, threshold);
        // ajouter un . si le nom du customer fait plus de 6 caractères
        if (currentCustomer.name.length > threshold) {
          title += ".";
        }
      } else {
        const words = currentCustomer.name.split(" ");
        // ajouter les premiers caractères de chaque mot séparé par un espace du nom du customer en majuscule
        words.forEach((word) => {
          // Ajouter le premier caractère de chaque mot en majuscule et 2 suivants en minuscule
          title +=
            word.charAt(0).toUpperCase() + word.slice(1, 3).toLowerCase();
        });
      }
    }
    return title;
  }, [currentCustomer]);
  // modifier le title de la page en dès que label change
  useEffect(() => {
    let title = "";
    if (currentCustomer) {
      title += customerName;
    }
    if (label) {
      if (typeof label === "string") {
        title += ` - ${label}`;
      } else if (resourceLabel) {
        if (typeof resourceLabel === "string") {
          title += ` - ${resourceLabel}`;
        }
      }
    }
    title += " - Cataleeze";
    document.title = title;
  }, [label, resourceLabel, customerName, currentCustomer]);

  return <>{label && <h1 className="h4">{title}</h1>}</>;
};
