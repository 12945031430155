import { useCallback } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { ListCondition } from "../../models/ListCondition";
import Store from "../../models/Store";
import { StoresFilters } from "../../models/filters/StoresFilters";
import {
  ControlledResourcesSelect,
  ControlledResourcesSelectProps,
  ReactSelectProps,
  ResourcesSelect,
} from "../../resources/components/selects/ResourcesSelect";
import { ResourceName } from "../../resources/types/resourceName";
import ReactSelectOption from "../ReactSelectOption";

const useStoresSelectController = <
  TStore extends Store = Store,
  TNumber extends number = number,
  TFilters extends StoresFilters = StoresFilters
>() => {
  const convertToOption = useCallback(
    (value: TStore | undefined): ReactSelectOption<TNumber> | undefined =>
      value != null
        ? {
            label: `${value.code} - ${value.name}`,
            value: value.id as TNumber,
          }
        : undefined,
    []
  );
  const transformToFilters = useCallback(
    (search: string) =>
      ({
        name: search,
        code: search,
        listCondition: ListCondition.Or,
      } as TFilters),
    []
  );
  const resourceName: ResourceName = "stores";
  return { convertToOption, resourceName, transformToFilters };
};

type StoresSelectProps<IsMulti extends boolean> = ReactSelectProps<
  number,
  IsMulti
>;

export const StoresSelect = <IsMulti extends boolean>(
  props: StoresSelectProps<IsMulti>
) => {
  const c = useStoresSelectController();
  return (
    <ResourcesSelect<Store, number, IsMulti, ResourceName, StoresFilters>
      {...c}
      {...props}
    />
  );
};

type ControlledStoresSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledResourcesSelectProps<
  StoresSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledStoresSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledStoresSelectProps<TFieldValues, TName, IsMulti>
) => {
  const c = useStoresSelectController();

  return (
    <ControlledResourcesSelect<
      Store,
      number,
      IsMulti,
      ResourceName,
      StoresFilters,
      TFieldValues,
      TName
    >
      {...c}
      {...props}
      name={props.name}
    />
  );
};
