import { Alert } from "react-bootstrap";
import { ControlledStoresSelect } from "../../components/selects/StoresSelect";
import StockLocation from "../../models/StockLocation";
import { ReadResource } from "../../resources/components/ReadResource";
import { ResourceHiddenNumberInput } from "../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceInput } from "../../resources/components/inputs/ResourceInput";

const EditStockLocationForm = () => {
  return (
    <ReadResource<StockLocation>
      resource={() => {
        return (
          <>
            <ResourceHiddenNumberInput name="id" />
            <ResourceInput
              label="Code*"
              name="code"
              options={{
                required: "Le code du dépôt est obligatoire",
                maxLength: {
                  value: 50,
                  message:
                    "Le code du dépôt ne doit pas dépasser 50 caractères",
                },
              }}
            />
            <ResourceInput
              label="Nom*"
              name="name"
              options={{
                required: "Le nom du dépôt est obligatoire",
                maxLength: {
                  value: 50,
                  message: "Le nom du dépôt ne doit pas dépasser 50 caractères",
                },
              }}
            />
            <Alert variant="warning">
              Attention, pour le stock WEB, ne pas attribuer de magasin
            </Alert>
            <ControlledStoresSelect<StockLocation>
              label="Magasin"
              name="storeId"
            />
          </>
        );
      }}
    />
  );
};

export default EditStockLocationForm;
