import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import { useConfigurationContext } from "../BackOffice";
import { useCurrentCustomer } from "../LoggedInBackOffice";
import Nullable from "../Nullable";
import Customer from "../models/Customer";
import authFetch from "../tools/authFetch";

const useCustomerApiFetch = () => {
  const { InternalServicesUrl: apiBaseUrl } = useConfigurationContext();
  const { instance, accounts } = useMsal();
  const customer = useCurrentCustomer();
  return useCallback(
    async (requestInfo: RequestInfo | URL, requestInit?: RequestInit) => {
      let customerRequestInfo: Request;
      if (typeof requestInfo === "string") {
        const apiUrl = new URL(requestInfo, apiBaseUrl);
        appendCustomer(apiUrl, customer);
        customerRequestInfo = new Request(apiUrl.href, requestInit);
        return authFetch(customerRequestInfo, instance, accounts[0]);
      } else if (requestInfo instanceof URL) {
        throw Error("Not implemented");
        // appendCustomer(requestInfo, customer);
        // customerRequestInfo = new Request(requestInfo.href, requestInit);
        // return authFetch(customerRequestInfo, instance, accounts[0]);
      } else {
        throw Error("Not implemented");
        // return authFetch(requestInfo, instance, accounts[0]);
      }
    },
    [accounts, apiBaseUrl, customer, instance]
  );
};
function appendCustomer(apiUrl: URL, customer: Nullable<Customer>) {
  if (customer && !apiUrl.searchParams.get("customerId")) {
    apiUrl.searchParams.append("customerId", customer.id);
  }
}
export default useCustomerApiFetch;
