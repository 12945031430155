export interface DeviceParameters {
  paymentIsEnabled: boolean;
  paymentProvider: PaymentProvider;
  payPlugParameters: PayPlugParameters;
  neptingParameters: NeptingParameters;
}

export enum PaymentProvider {
  None = 0,
  PayPlug = 1,
  Nepting = 2,
  Test = 99,
}
export interface PayPlugParameters {
  tpeIpAddress: string;
  port: number;
  poiId: string;
}
export interface NeptingParameters {
  url: string;
  merchantCode: string;
}
