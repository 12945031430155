import { useContext, useMemo } from "react";
import { LoggedInUserContext, useRolesContext } from "../LoggedInBackOffice";

export type UseIsAdminRoleProps = {
  ignoreIsShowingServerAdminMode?: boolean;
};
export const useIsUserInServerAdminRole = (props?: UseIsAdminRoleProps) => {
  const { ignoreIsShowingServerAdminMode = false } = props ?? {};
  const { isShowingServerAdminMode } = useContext(LoggedInUserContext);

  const { roles } = useRolesContext();
  const isAllowed = useMemo(
    () =>
      (ignoreIsShowingServerAdminMode || isShowingServerAdminMode) &&
      roles &&
      roles.length > 0 &&
      roles.includes("Server.Admin"),
    [ignoreIsShowingServerAdminMode, isShowingServerAdminMode, roles]
  );
  return { isAllowed };
};
