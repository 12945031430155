import React from "react";
import { useLogin } from "../hooks/useLogin";
import { storeCurrentPath } from "./../tools/LocalRedirectUrlStorage";
import image from "../assets/cataleeze-160x184.png";

const Authentication = () => {
  const login = useLogin();
  storeCurrentPath();
  return (
    <div className="d-flex h-100 justify-content-center align-items-center text-center">
      <main>
        <img alt="Cataleeze" src={image} className="img-fluid mb-5" />
        <h1>Cataleeze</h1>

        <p className="lead">Connectez vous pour démarrer votre session</p>
        <p>
          <button className="btn btn-lg btn-primary" onClick={login}>
            Connexion
          </button>
        </p>
      </main>
    </div>
  );
};

export default Authentication;
