import { useMemo } from "react";
import OrderLine from "../../../models/OrderLine";
import { OrderLinesFilters } from "../../../models/filters/OrderLinesFilters";
import { ListView } from "../../../resources/components/lists/ListView";
import {
  ResourceColumn,
  ResourceTable,
} from "../../../resources/components/lists/ResourceTable";
import { useResourceUrlPathResolver } from "../../../resources/hooks/useResourceUrlPathResolver";
import { ResourceName } from "../../../resources/types/resourceName";

type ArticleOrdersProps = {
  articleId?: number;
};
export const ArticleOrderLines = ({ articleId }: ArticleOrdersProps) => {
  const {
    resourceUrlPathResolver: { edit },
  } = useResourceUrlPathResolver<ResourceName>();

  const columns: ResourceColumn<OrderLine>[] = useMemo(
    () => [
      {
        accessor: "orderId",
        header: "Commande (Id)",
        navigationPath: (o, value) =>
          value != null ? edit("orders", value) : undefined,
      },
      {
        accessor: (ol) => `${ol.barCode} (${ol.barCodeType})`,
        header: "Référence",
      },
      {
        accessor: "label",
        header: "Label",
      },
      {
        accessor: "quantity",
        header: "Quantité",
      },
    ],
    [edit]
  );
  return (
    <ListView<ResourceName, OrderLine, OrderLinesFilters>
      defaultFilters={{ articleId }}
      resourceName="order-lines"
    >
      <ResourceTable<OrderLine> columns={columns} />
    </ListView>
  );
};
