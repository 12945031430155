import { useEffect } from "react";
import Form from "react-bootstrap/esm/Form";
import { useForm } from "react-hook-form";
import { useCurrentCustomer } from "../../LoggedInBackOffice";
import { AsyncHandler } from "../../components/AsyncHandler";
import { Loading } from "../../components/types/loading";
import useTenantConfiguration from "../../hooks/tenantConfiguration/useTenantConfiguration";
import { useEditOrCreateSubmitForm } from "../../hooks/useSubmitForm";
import TenantConfiguration from "../../models/TenantConfiguration";
import { PageTitle } from "../../resources/components/PageTitle";
import { SaveResourceButton } from "../../resources/components/buttons/SaveResourceButton";
import styles from "./EditParametersPage.module.css";

const EditParametersPage = () => {
  const currentCustomer = useCurrentCustomer();

  const {
    tenantConfiguration,
    error,
    mutate: refresh,
    mutate,
    isLoading,
  } = useTenantConfiguration(currentCustomer);

  const { register, handleSubmit, watch, reset } = useForm<TenantConfiguration>(
    {
      mode: "onBlur",
      defaultValues: tenantConfiguration,
    }
  );

  useEffect(() => {
    if (tenantConfiguration) {
      reset(tenantConfiguration);
    }
  }, [tenantConfiguration, reset]);

  const {
    isBusy,
    onSubmit,
    errors: submitError,
  } = useEditOrCreateSubmitForm(
    "/api/customers/configuration",
    undefined,
    mutate
  );

  const useOrderProcess = watch("useOrderProcess");

  return (
    <>
      <PageTitle label="Catalogue & Application" />
      <hr />
      <AsyncHandler
        error={error}
        errorMessage="Une erreur est survenue lors de la récupération des paramètres."
        refresh={refresh}
        isLoading={isLoading}
        loading={Loading.Circle}
      >
        {tenantConfiguration && (
          <Form
            className="d-flex flex-column gap-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="card">
              <div className="card-header">Affichage</div>
              <div className="card-body">
                <table
                  className={`${styles["key-value-table"]} table table-sm m-0 mb-2`}
                >
                  <tbody>
                    <tr>
                      <th>Afficher les prix</th>
                      <td>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="priceDisplay1"
                            value={"IncludingTaxes"}
                            {...register("priceDisplay")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="priceDisplay1"
                          >
                            TTC
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="priceDisplay2"
                            value={"ExcludingTaxes"}
                            {...register("priceDisplay", { required: true })}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="priceDisplay2"
                          >
                            HT
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Afficher les produits</th>
                      <td>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="allAvailableInput"
                            value={"All"}
                            {...register("showAvailableProducts")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="allAvailableInput"
                          >
                            Tous
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inStockInput"
                            value={"InStock"}
                            {...register("showAvailableProducts", {
                              required: true,
                            })}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inStockInput"
                          >
                            Disponibles à la commande ou en magasin
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Afficher uniquement les produits génériques</th>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            {...register("showOnlyParentProducts")}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card">
              <div className="card-header">Tunnel de commande</div>
              <div className="card-body">
                <table
                  className={`${styles["key-value-table"]} table table-sm m-0 mb-2`}
                >
                  <tbody>
                    <tr>
                      <th>Activer le tunnel de commande</th>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            {...register("useOrderProcess")}
                          />
                        </div>
                      </td>
                    </tr>
                    {useOrderProcess && useOrderProcess === true && (
                      <>
                        <tr>
                          <th>Tunnel de commande</th>
                          <td>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="anonymousInput"
                                value={"Anonymous"}
                                {...register("orderProcessType")}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="anonymousInput"
                              >
                                Anonyme
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="nameOnlyInput"
                                value={"NameOnly"}
                                {...register("orderProcessType")}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="nameOnlyInput"
                              >
                                Nom
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="guestInput"
                                value={"Guest"}
                                defaultChecked={
                                  tenantConfiguration.orderProcessType === null
                                    ? true
                                    : false
                                }
                                {...register("orderProcessType", {
                                  required: true,
                                })}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="guestInput"
                              >
                                Invité
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            Vérifier les stocks à l'ouverture de la fiche
                            produit
                          </th>
                          <td>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                {...register("checkForAvailabilities")}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>Activer l'ajout de produit à la commande</th>
                          <td>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                {...register("centralOrderProcessEnable")}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            Activer l'ajout de produit à retirer en magasin
                          </th>
                          <td>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                {...register("inStoreOrderProcessEnable")}
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card">
              <div className="card-header">Filtres</div>
              <div className="card-body">
                <table
                  className={`${styles["key-value-table"]} table table-sm m-0 mb-2`}
                >
                  <tbody>
                    <tr>
                      <th>Pouvoir filtrer par disponibilité à la commande</th>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            {...register("addCentralAvailableFilter")}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Pouvoir filtrer par disponibilité en magasin</th>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            {...register("addInStoreAvailableFilter")}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Pouvoir filtrer par catégories</th>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            {...register("addCategoriesFilter")}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Pouvoir filtrer par marques</th>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            {...register("addBrandsFilter")}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Pouvoir filtrer par prix</th>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            {...register("addPriceFilter")}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {tenantConfiguration.defaultSortOption.indexOf("Attr") === -1 && (
              <div className="card">
                <div className="card-header">Tris</div>
                <div className="card-body">
                  <table
                    className={`${styles["key-value-table"]} table table-sm m-0 mb-2`}
                  >
                    <tbody>
                      <tr>
                        <th>Par défault, trier les produits par</th>
                        <td>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="priceDescInput"
                              value={"PriceDesc"}
                              {...register("defaultSortOption", {
                                required: true,
                              })}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="priceDescInput"
                            >
                              Prix décroissant
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="priceAscInput"
                              value={"PriceAsc"}
                              {...register("defaultSortOption")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="priceAscInput"
                            >
                              Prix croissant
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="promotionInput"
                              value={"Promotion"}
                              {...register("defaultSortOption")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="promotionInput"
                            >
                              Promotion
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <div className="card">
              <div className="card-header">Application Mobile Vendeur</div>
              <div className="card-body">
                <table
                  className={`${styles["key-value-table"]} table table-sm m-0 mb-2`}
                >
                  <tbody>
                    <tr>
                      <th>Fiche produit, afficher les stocks</th>
                      <td>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="nearbyStoresStockInput"
                            value={"Nearby"}
                            {...register("vendorOtherStoresStocksDisplay", {
                              required: true,
                            })}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="nearbyStoresStockInput"
                          >
                            Des magasins aux alentours
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="allStoresStockInput"
                            value={"All"}
                            {...register("vendorOtherStoresStocksDisplay")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="allStoresStockInput"
                          >
                            De tous les magasins
                          </label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card">
              <div className="card-header">Texte de consentement RGPD</div>
              <div className="card-body">
                <textarea
                  className="form-control"
                  id="gdpRConsentInput"
                  rows={5}
                  {...register("gdpRConsentText")}
                />
              </div>
            </div>
            <SaveResourceButton
              isBusy={isBusy}
              submitError={submitError}
              mode="edit"
            />
          </Form>
        )}
      </AsyncHandler>
    </>
  );
};

export default EditParametersPage;
