import { useCallback } from "react";
import { Resource } from "../../resources/types";
import { DataProvider, UpdateProps } from "../types/dataProvider";
import { useResourceSafeActionCaller } from "./useResourceSafeActionCaller";

export const useUpdateResource = <
  TResourceName extends string = string,
  TResourceType extends Resource = Resource
>() => {
  const unsafeAction = useCallback(
    async (
      dataProvider: DataProvider<TResourceName, TResourceType>,
      props: UpdateProps<TResourceName, TResourceType>
    ) => await dataProvider.update(props),
    []
  );
  const { safeAction: update, errors } = useResourceSafeActionCaller({
    unsafeAction,
  });

  return { update, errors };
};
