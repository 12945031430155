import { useCallback, useMemo } from "react";
import { UseControllerProps, useFormContext } from "react-hook-form";
import { FilterFieldData, FilterFieldValues } from "./DynamicFieldData";
import Input from "./Input";
import ReactSelectOption, {
  ReactSelectOptionValueType,
} from "./ReactSelectOption";
import { ControlledSimpleSelect } from "./SimpleSelect";
import { ControlledAnimationGroupsSelect } from "./selects/AnimationGroupsSelect";
import { ControlledDeviceTypesSelect } from "./selects/DeviceTypesSelect";
import { ControlledDevicesSelect } from "./selects/DevicesSelect";
import { ControlledImportJobDefinitionEntitiesSelect } from "./selects/ImportJobDefinitionsSelect";
import { ControlledLogisticOperationStatusesSelect } from "./selects/LogisticOperationStatusesSelect";
import { ControlledLogisticOperationTypesSelect } from "./selects/LogisticOperationTypesSelect";
import { ControlledStockLocationsSelect } from "./selects/StockLocationsSelect";
import { ControlledStoresSelect } from "./selects/StoresSelect";

export const DynamicControl = <T extends FilterFieldValues>({
  label,
  type: inputType,
  fieldName,
  defaultValue,
  placeholder,
  isClearable = true,
  options = [],
  config = {},
}: FilterFieldData<T>) => {
  const { register, control, setValue } = useFormContext();

  const onInputTextClear = useCallback(
    () => setValue(fieldName, defaultValue as any, { shouldDirty: true }),
    [defaultValue, fieldName, setValue]
  );

  const r = useMemo(() => {
    switch (inputType) {
      case "hidden":
        return (
          <input
            type={inputType}
            {...register(fieldName, config)}
            defaultValue={defaultValue}
          />
        );
      case "import-job-definitions-select":
        return (
          <ControlledImportJobDefinitionEntitiesSelect
            label={label}
            isClearable={isClearable}
            name={fieldName}
            placeholder={placeholder}
          />
        );
      case "device-types-select":
        return (
          <ControlledDeviceTypesSelect
            label={label}
            isClearable={isClearable}
            name={fieldName}
            placeholder={placeholder}
          />
        );
      case "animation-groups-select":
        return (
          <ControlledAnimationGroupsSelect
            label={label}
            isClearable={isClearable}
            name={fieldName}
            placeholder={placeholder}
          />
        );
      case "stores-select":
        return (
          <ControlledStoresSelect
            label={label}
            isClearable={isClearable}
            name={fieldName}
            placeholder={placeholder}
          />
        );
      case "devices-select":
        return (
          <ControlledDevicesSelect
            label={label}
            isClearable={isClearable}
            name={fieldName}
            placeholder={placeholder}
          />
        );
      case "stock-locations-select":
        return (
          <ControlledStockLocationsSelect
            label={label}
            isClearable={isClearable}
            name={fieldName}
            placeholder={placeholder}
          />
        );
      case "logistic-operation-types-select":
        return (
          <ControlledLogisticOperationTypesSelect
            label={label}
            isClearable={isClearable}
            name={fieldName}
            placeholder={placeholder}
          />
        );
      case "logistic-operation-statuses-select":
        return (
          <ControlledLogisticOperationStatusesSelect
            label={label}
            isClearable={isClearable}
            name={fieldName}
            placeholder={placeholder}
          />
        );
      case "boolean":
        return (
          <BooleanSelect
            label={label}
            isClearable={isClearable}
            name={fieldName}
            placeholder={placeholder}
          />
        );

      case "string-select":
      case "number-select":
        return (
          <ControlledSimpleSelect<any, ReactSelectOptionValueType>
            label={label}
            control={control}
            name={fieldName}
            rules={config}
            options={options}
            placeholder={placeholder}
            isClearable={isClearable}
          />
        );

      default:
        return (
          <Input
            type={inputType}
            label={label}
            {...register(fieldName, config)}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onClear={isClearable ? onInputTextClear : undefined}
          />
        );
    }
  }, [
    config,
    control,
    defaultValue,
    fieldName,
    inputType,
    isClearable,
    label,
    onInputTextClear,
    options,
    placeholder,
    register,
  ]);
  return r;
};

type FormGroupBooleanSelectProps = UseControllerProps & {
  label: string;
  isClearable?: boolean;
  className?: string;
  placeholder?: string;
};
const BooleanSelect = (props: FormGroupBooleanSelectProps) => {
  const options: Array<ReactSelectOption<boolean>> = useMemo(() => {
    return [
      { label: "Oui", value: true },
      { label: "Non", value: false },
    ];
  }, []);
  const { className, ...otherProps } = props;
  return (
    <ControlledSimpleSelect<any, boolean>
      isSearchable={false}
      options={options}
      {...otherProps}
    />
  );
};
