import {
  UseAddButtonControllerProps,
  useAddButtonController,
} from "./useAddButtonController";
import { useResourceUrlPathResolver } from "./useResourceUrlPathResolver";

type UseAddEditButtonProps<TResourceName extends string> =
  UseAddButtonControllerProps<TResourceName>;

export const useAddEditButton = <TResourceName extends string = string>(
  props: UseAddEditButtonProps<TResourceName>
) => {
  const {
    resourceUrlPathResolver: { edit },
  } = useResourceUrlPathResolver<TResourceName>();
  const label = "Éditer";

  return useAddButtonController({ ...props, label, resourceUrlPath: edit });
};
