import { ReactNode } from "react";

export type LoadingMessageProps = {
  loadingMessage?: string;
};

type LoadingProps = {
  loading: ReactNode;
};

type PrivateLoadingProps = LoadingMessageProps & LoadingProps;
export const Loading = (props: PrivateLoadingProps) => {
  const { loadingMessage, loading } = props;
  return (
    <div className="d-flex h-100 justify-content-center align-items-center">
      {loading}
      {loadingMessage && <div className="ps-2">{loadingMessage}</div>}
    </div>
  );
};
