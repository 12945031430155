import { useCallback, useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import DownloadFileButton from "../../components/buttons/DownloadFileButton";
import ServerAdminTemplate from "../../components/layout/ServerAdminTemplate";
import useCustomerApiFetch from "../../hooks/useCustomerApiFetch";
import ReceptionFile from "../../models/ReceptionFile";
import TemporaryUrl from "../../models/TemporaryUrl";
import { ReceptionFilesFilters } from "../../models/filters/ReceptionFilesFilters";
import { DeleteAllResourcesButton } from "../../resources/components/buttons/DeleteAllResourcesButton";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { getShortMonthnameDayofmonthYearTimeDate } from "../../tools/dateUtils";

const ReceptionFilesPage = () => {
  const filters: FilterFieldData<ReceptionFilesFilters>[] = useMemo(
    () => [
      {
        fieldName: "name",
        type: "text",
        label: "Nom",
        placeholder: "Nom",
      },
    ],
    []
  );

  const apiFetcher = useCustomerApiFetch();
  const fetchTemporaryUrl = useCallback(
    (fileName: string) => {
      return apiFetcher(
        `/api/ReceptionFiles/${encodeURIComponent(fileName)}/GetDownloadUrl`,
        {
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
        }
      )
        .then((d) => {
          if (!d.ok) {
            throw new Error(d.statusText);
          }
          return d.json();
        })
        .then((d) => {
          const t = d as TemporaryUrl;
          return t;
        });
    },
    [apiFetcher]
  );

  const columns: ResourceColumn<ReceptionFile>[] = useMemo(
    () => [
      {
        header: "Nom",
        accessor: "name",
      },
      {
        header: "Dernière modification",
        accessor: "date",
        getCell: (r) => getShortMonthnameDayofmonthYearTimeDate(r.date),
      },
      {
        header: "Taille",
        accessor: "contentLength",
        getCell: (r) => r.contentLength && bytesToSize(r.contentLength),
      },
      {
        header: " ",
        accessor: (r) => r.name,
        getCell: (r) => (
          <DownloadFileButton
            fetch={async () => {
              const d = await fetchTemporaryUrl(r.name!);
              return [d];
            }}
          />
        ),
      },
    ],
    [fetchTemporaryUrl]
  );
  return (
    <ListPage filterFieds={filters} showChildWithoutFilters>
      <ServerAdminTemplate>
        <DeleteAllResourcesButton />
      </ServerAdminTemplate>
      <ResourceTable columns={columns} />
    </ListPage>
  );
};

export default ReceptionFilesPage;

function bytesToSize(bytes: number) {
  var sizes = ["B", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  var i = Math.floor(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return bytes + " " + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
}
