import { Resource } from "../../../resources/types";
import {
  ResourceHiddenInput,
  ResourceHiddenInputProps,
} from "./ResourceHiddenInput";

export const ResourceHiddenDateInput = <TResource extends Resource>(
  props: ResourceHiddenInputProps<TResource>
) => (
  <ResourceHiddenInput
    options={{ valueAsDate: true }}
    defaultValue=""
    {...props}
  />
);
