import { uniq } from "lodash";
import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { Spinner } from "react-bootstrap";
import { CellProps, Column, TableState } from "react-table";
import { AsyncHandler } from "../../../components/AsyncHandler";
import { DisplayBoolean } from "../../../components/DisplayBoolean";
import { DisplayNull } from "../../../components/DisplayNull";
import { LinearLoading } from "../../../components/LinearLoading";
import PaginationTable from "../../../components/PaginationTable";
import { CustomerLink } from "../../../components/navigation/CustomerLink";
import { Loading } from "../../../components/types/loading";
import { ListResourceFilters } from "../../../models/filters/ListResourceFilters";
import {
  UseResourceListProps,
  useResourceListContext,
} from "../../../resources/hooks/useResourceListContext";
import { useSWRListResource } from "../../../resources/hooks/useSWRListResource";
import { Resource } from "../../../resources/types";
import { ListReturn } from "../../../resources/types/dataProvider";
import { getDateTime } from "../../../tools/dateUtils";

type GetCellProps<
  TResource,
  TKeyOfResource extends keyof TResource,
  TExpandResource
> = {
  getCell?: (
    resource: TResource,
    value: TResource[TKeyOfResource],
    expandedList?: ListReturn<TExpandResource>
  ) => ReactNode;
};
export type ResourceColumn<
  TResource,
  TExpandResource = any,
  TExpandResourceName extends string = string,
  TKeyOfResource extends keyof TResource = keyof TResource
> = GetCellProps<TResource, TKeyOfResource, TExpandResource> & {
  id?: string;
  header: string;
  accessor: TKeyOfResource | ((resource: TResource) => any);
  navigationPath?: (resource: TResource, value: any) => string | undefined;
  expand?: TExpandResourceName;
};

export type ResourceTableProps<TResource> = UseResourceListProps<TResource> & {
  columns?: (ResourceColumn<TResource> | undefined)[];
};

export const ResourceTable = <TResource extends object>({
  columns,
  ...otherProps
}: ResourceTableProps<TResource>) => {
  const {
    setPageIndex,
    data,
    setPageSize,
    pageIndex = 0,
    pageSize = 15,
    error,
    mutate,
    isLoading,
  } = useResourceListContext<TResource>(otherProps);

  const requestedPageIndex = useCallback(
    (index: number) => {
      if (setPageIndex != null) {
        setPageIndex(index);
      }
    },
    [setPageIndex]
  );
  const onPageSizeChanged = useCallback(
    (index: number) => {
      if (setPageSize != null) {
        setPageSize(index);
      }
    },
    [setPageSize]
  );

  const initialState: Partial<TableState<TResource>> = {
    pageIndex: pageIndex,
    pageSize: pageSize,
  };
  const pagedItems = useMemo(() => data?.data, [data?.data]);

  const memoColumns = useMemo(() => {
    return columns?.filter(notNull).map(
      (c: ResourceColumn<TResource>) =>
        ({
          Header: c.header,
          accessor: c.accessor,
          Cell: ({ value, row: { original } }: CellProps<TResource>) => {
            let resource = original as TResource;
            const navigationPathValue =
              c.navigationPath != null && c.navigationPath(original, value);
            const finalRender = (
              <>
                {!navigationPathValue ? (
                  <DisplayCell
                    getCell={c.getCell}
                    resource={resource}
                    value={value}
                  />
                ) : (
                  <CustomerLink to={navigationPathValue}>
                    <DisplayCell
                      getCell={c.getCell}
                      resource={resource}
                      value={value}
                    />
                  </CustomerLink>
                )}
              </>
            );
            return (
              <>
                {c.expand && pagedItems ? (
                  <ListLoader
                    resourceName={c.expand}
                    accessor={c.accessor}
                    items={pagedItems.items}
                  >
                    {finalRender}
                  </ListLoader>
                ) : (
                  finalRender
                )}
              </>
            );
          },
        } as Column<TResource>)
    );
  }, [columns, pagedItems]);
  const hasAnyPagedItem = (pagedItems?.totalItemsCount ?? 0) > 0;

  return (
    <AsyncHandler
      error={error}
      refresh={mutate}
      isLoading={isLoading}
      loading={Loading.Circle}
    >
      {!pagedItems && (
        <div className="my-2 d-flex flex-column justify-items-center">
          <div className="mx-auto">
            <Spinner className="m-2" animation="border" variant="primary" />
          </div>
          <div className="mx-auto">chargement</div>
        </div>
      )}
      {pagedItems && (
        <>
          {!hasAnyPagedItem && (
            <div className="my-2 d-flex flex-column justify-items-center">
              <div className="mx-auto">
                <DisplayNull size={60} />
              </div>
              <div className="mx-auto">Aucun élément</div>
            </div>
          )}

          {hasAnyPagedItem && memoColumns != null && (
            <PaginationTable
              responsive
              size="sm"
              hover
              columns={memoColumns}
              data={pagedItems.items}
              desiredPageIndex={pagedItems.currentPageIndex}
              pageCount={pagedItems.pageCount}
              totalItemsCount={pagedItems.totalItemsCount}
              pageSizeChanged={onPageSizeChanged}
              initialState={initialState}
              requestedPageIndex={requestedPageIndex}
            />
          )}
        </>
      )}
    </AsyncHandler>
  );
};

type DisplayCellProps<
  TResource,
  TKeyOfResource extends keyof TResource,
  TExpandResource
> = GetCellProps<TResource, TKeyOfResource, TExpandResource> & {
  resource: TResource;
  value: TResource[TKeyOfResource];
};

type ListLoaderProps<
  TResource,
  TResourceName extends string
> = PropsWithChildren & {
  items: TResource[];
  resourceName: TResourceName;
  accessor: keyof TResource | ((resource: TResource) => any);
};
const ListLoader = <TResource, TResourceName extends string>(
  props: ListLoaderProps<TResource, TResourceName>
) => {
  const { accessor, items, resourceName, children } = props;
  const ids = useMemo(() => {
    return uniq(
      items
        .map((item) => {
          if (typeof accessor === "string") {
            return item[accessor];
          }
          const a = accessor as (resource: TResource) => any;

          return a(item);
        })
        .filter(notNull)
    );
  }, [accessor, items]);
  const { data, isLoading } = useSWRListResource<
    TResourceName,
    Resource,
    ListResourceFilters
  >({ resourceName, filters: { ids } });

  return (
    <ExpandContext.Provider value={{ isLoading, list: data }}>
      {children}
    </ExpandContext.Provider>
  );
};
type ExpandContextValue<TResource = any> = {
  isLoading: boolean;
  list: ListReturn<TResource> | undefined;
};
const ExpandContext = React.createContext<ExpandContextValue>({
  isLoading: false,
  list: undefined,
});

function DisplayCell<
  TResource,
  TKeyOfResource extends keyof TResource,
  TExpandResource
>({
  getCell,
  resource,
  value,
}: DisplayCellProps<TResource, TKeyOfResource, TExpandResource>) {
  const { isLoading, list } = useContext(
    ExpandContext
  ) as ExpandContextValue<TExpandResource>;

  if (isLoading) return <LinearLoading />;

  return (
    <>
      {getCell ? (
        getCell(resource, value, list) ?? <DisplayNull />
      ) : (
        <>
          {value == null ? (
            <DisplayNull />
          ) : (
            <>
              {typeof value === "object" ? (
                <>
                  {value instanceof Date ? getDateTime(value) : String(value)}
                </>
              ) : (
                <>
                  {typeof value === "boolean" ? (
                    <DisplayBoolean isTrue={value} />
                  ) : (
                    String(value)
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

function notNull<TValue>(value: TValue | null | undefined): value is TValue {
  return value != null;
}
