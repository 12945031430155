import { Spinner } from "react-bootstrap";
import { Attribute } from "../../../../models/Attribute";
import { AttributeType } from "../../../../models/AttributeType";
import { ResourceHiddenTextInput } from "../../../../resources/components/inputs/ResourceHiddenTextInput";
import { ExtendedAutomaticArticlesSelection } from "../types/ExtendedAutomaticArticlesSelection";
import { FilterContent } from "../types/FilterContent";

type ShowAttributeValueProps = {
  f: FilterContent;
  attributeIndex: number;
  attributeValueIndex: number;
  attribute?: Attribute;
};
export const ShowAttributeValue = (props: ShowAttributeValueProps) => {
  const { f, attribute, attributeIndex, attributeValueIndex } = props;

  return (
    <li>
      <ResourceHiddenTextInput<ExtendedAutomaticArticlesSelection>
        name={
          `ex_attributes.${attributeIndex}.filterAttributeValues.${attributeValueIndex}.value` as const
        }
      />
      {!attribute && <Spinner className="mx-1" animation="border" size="sm" />}

      {attribute != null && f?.value != null && (
        <>
          {attribute.type === AttributeType.Boolean
            ? f?.value && JSON.parse(f?.value)
              ? "Oui"
              : "Non"
            : f?.value ?? ""}
        </>
      )}
    </li>
  );
};
