import { Spinner } from "react-bootstrap";
import { Loading, LoadingMessageProps } from "./Loading";

export const CircleLoading = (props: LoadingMessageProps) => {
  return (
    <Loading
      {...props}
      loading={<Spinner animation="border" variant="primary" />}
    />
  );
};
