import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

// import moment from 'moment';
import moment from "moment-timezone";
import "moment/locale/fr";
moment.locale("fr");

moment.relativeTimeThreshold("ss", 0);
moment.tz.setDefault(moment.tz.guess());

const nullableBaseUrl = document
  .getElementsByTagName("base")[0]
  .getAttribute("href");
const baseUrl = nullableBaseUrl == null ? undefined : nullableBaseUrl;

const container = document.getElementById("root");

const root = createRoot(container!);
root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
