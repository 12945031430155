import { SelectionFilter } from "../../../../models/ArticlesSelection";
import { ResourceHiddenNumberInput } from "../../../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceHiddenTextInput } from "../../../../resources/components/inputs/ResourceHiddenTextInput";

type RenderSelectionFilterInputsProps = {
  index: number;
  arrayName: string;
  selectionFilter: SelectionFilter;
};
export const RenderSelectionFilterInputs = ({
  index,
  arrayName = "query.filters",
  selectionFilter,
}: RenderSelectionFilterInputsProps) => (
  <>
    {selectionFilter != null && selectionFilter.query == null && (
      <>
        <ResourceHiddenTextInput name={`${arrayName}.${index}.attributeId`} />
        <ResourceHiddenTextInput
          name={`${arrayName}.${index}.attributeValue`}
        />
        <ResourceHiddenTextInput name={`${arrayName}.${index}.brandId`} />
        <ResourceHiddenTextInput name={`${arrayName}.${index}.categoryId`} />
        <ResourceHiddenNumberInput
          name={`${arrayName}.${index}.minimumValue`}
        />
        <ResourceHiddenNumberInput
          name={`${arrayName}.${index}.maximumValue`}
        />
        <ResourceHiddenNumberInput name={`${arrayName}.${index}.type`} />
      </>
    )}
    {selectionFilter != null && selectionFilter.query != null && (
      <>
        <ResourceHiddenNumberInput
          name={`${arrayName}.${index}.query.operator`}
        />

        {selectionFilter.query.filters &&
          selectionFilter.query.filters.map((f, i) => {
            return (
              <RenderSelectionFilterInputs
                key={i}
                selectionFilter={f}
                index={i}
                arrayName={`${arrayName}.${index}.query.filters`}
              />
            );
          })}
      </>
    )}
  </>
);
