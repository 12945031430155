import { useCallback, useState } from "react";
import useCustomerApiFetch from "./useCustomerApiFetch";

/**
 * Confirm then delete a resource (via api)
 * @param resourceUrl Url of the resource to delete ex: /api/Stores/1
 * @param confirmPopupMessage The delete confirmation message ex: Are you sur ?
 * @param onSuccessCallback action on success (redirect, ...)
 * @param customerId The customerId associated with this ressource
 */
const useDeleteResources = function useDeleteResources(
  resourceUrl: string,
  confirmPopupMessage: string,
  onSuccessCallback?: () => void
) {
  const [isBusy, setIsBusy] = useState(false);
  const [errors, setErrors] = useState<unknown>();
  const apiFetcher = useCustomerApiFetch();

  const askForDeletion = useCallback(async () => {
    if (window.confirm(confirmPopupMessage)) {
      setIsBusy(true);
      setErrors(undefined);

      try {
        const response = await apiFetcher(resourceUrl, {
          method: "DELETE",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
        });

        if (!response.ok) {
          throw new Error("Unable to delete");
        }

        setIsBusy(false);
        if (onSuccessCallback !== undefined) {
          onSuccessCallback();
        }
      } catch (ex) {
        setErrors(ex);
        setIsBusy(false);
      }
    }
  }, [confirmPopupMessage, apiFetcher, resourceUrl, onSuccessCallback]);

  return { askForDeletion, errors, isBusy };
};

export default useDeleteResources;
