import { ResourceHiddenNumberInput } from "../../resources/components/inputs/ResourceHiddenNumberInput";
import { ResourceInput } from "../../resources/components/inputs/ResourceInput";

const EditTransporterForm = () => {
  return (
    <>
      <ResourceHiddenNumberInput name="id" />
      <ResourceInput
        label="Nom*"
        name="name"
        options={{
          required: "Le nom du transporteur est obligatoire",
          maxLength: {
            value: 50,
            message:
              "Le nom du transporteur ne doit pas dépasser 50 caractères",
          },
        }}
      />
    </>
  );
};

export default EditTransporterForm;
