export enum ImportFileType {
  Articles,
  Prix,
  Categories,
  StockInfo,
  Taxes,
  References,
  CategoriesArticles,
  Dimensions,
  Images,
  Attributs,
  AttributsArticles,
  ArticlesAssocies,
}
