import { PropsWithChildren, useCallback, useState } from "react";

import { Button, Spinner } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useCustomerLinkTo } from "../../../hooks/useCustomerLinkTo";
import { useDeleteResource } from "../../../resources/hooks/useDeleteResource";
import { useResourceContext } from "../../../resources/hooks/useResourceContext";
import { useResourceName } from "../../../resources/hooks/useResourceName";
import { useResourceUrlPathResolver } from "../../../resources/hooks/useResourceUrlPathResolver";

type DeleteResourceButtonProps = PropsWithChildren & {
  askForDeletion?: () => void;
  isBusyDeleting?: boolean;
  deletionErrors?: unknown;
  resource?: any;
  confirmPopupMessage?: string;
};
export const DeleteResourceButton = (props: DeleteResourceButtonProps) => {
  const {
    askForDeletion: askForDeletionProps,
    isBusyDeleting: isBusyDeletingProps,
    deletionErrors: deletionErrorsProps,
    children,
    resource: resourceProps,
    confirmPopupMessage = "Voulez vous vraiment supprimer ?",
  } = props;

  const { resource } = useResourceContext({ resource: resourceProps });
  const { resourceName } = useResourceName();

  const navigate = useNavigate();

  const {
    resourceUrlPathResolver: { list },
  } = useResourceUrlPathResolver();
  const { customerLinkUrl } = useCustomerLinkTo();
  const { deleteAction, errors } = useDeleteResource();

  const [isBusy, setIsBusy] = useState(false);

  const onSuccessCallback = useCallback(() => {
    navigate(customerLinkUrl(list(resourceName)), {
      replace: true,
    });
  }, [customerLinkUrl, list, resourceName, navigate]);

  const askForDeletion = useCallback(async () => {
    if (resource != null && window.confirm(confirmPopupMessage)) {
      setIsBusy(true);

      await deleteAction({ id: resource.id, resourceName });

      setIsBusy(false);
      onSuccessCallback();
    }
  }, [
    resource,
    confirmPopupMessage,
    deleteAction,
    resourceName,
    onSuccessCallback,
  ]);

  const finalAskForDeletion = askForDeletionProps ?? askForDeletion;
  const finalDeletionErrors = deletionErrorsProps ?? errors;
  const finalIsBusyDeleting = isBusyDeletingProps ?? isBusy;

  if (resource == null) {
    return <></>;
  }

  return (
    <div className="float-right text-end">
      <Button
        variant="outline-danger"
        onClick={finalAskForDeletion}
        disabled={finalIsBusyDeleting}
      >
        <>
          {finalIsBusyDeleting ? (
            <Spinner animation="border" size="sm" className="mx-1" />
          ) : (
            ""
          )}
        </>
        <MdDeleteForever className="me-1" />
        <>{children != null ? children : "Supprimer"}</>
      </Button>
      <>
        {finalDeletionErrors && (
          <div className="text-danger">
            Une erreur est survenue lors de la suppression.
          </div>
        )}
      </>
    </div>
  );
};
