import { Route, Routes } from "react-router";
import { ResourceOptionsContextProvider } from "../../resources/hooks/ResourceOptionsContextProvider";
import ProtectedRoute from "./LayoutRoute";

type ResourceProps<T extends string> = {
  name: T;
  label?: React.ReactNode;
  create?: JSX.Element;
  edit?: JSX.Element;
  list?: JSX.Element;
  display?: JSX.Element;
};

export const Resource = <T extends string>({
  name,
  label,
  create,
  edit,
  list,
  display,
}: ResourceProps<T>) => {
  return (
    <ResourceOptionsContextProvider value={{ resourceName: name, label }}>
      <Routes>
        <Route path={name}>
          <Route
            index
            element={
              <>
                {list && (
                  <ProtectedRoute
                    isCurrentCustomerMandatory={true}
                    canChangeCustomer={true}
                  >
                    {list}
                  </ProtectedRoute>
                )}
              </>
            }
          />

          <Route
            path="create"
            element={
              <>
                {create && (
                  <ProtectedRoute
                    canChangeCustomer={false}
                    isCurrentCustomerMandatory={true}
                  >
                    {create}
                  </ProtectedRoute>
                )}
              </>
            }
          />
          <Route
            path="edit/:id"
            element={
              <>
                {edit && (
                  <ProtectedRoute
                    canChangeCustomer={false}
                    isCurrentCustomerMandatory={true}
                  >
                    {edit}
                  </ProtectedRoute>
                )}
              </>
            }
          />
          <Route
            path=":id"
            element={
              <>
                {display && (
                  <ProtectedRoute
                    canChangeCustomer={false}
                    isCurrentCustomerMandatory={true}
                  >
                    {display}
                  </ProtectedRoute>
                )}
              </>
            }
          />
        </Route>
      </Routes>
    </ResourceOptionsContextProvider>
  );
};
