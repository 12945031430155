import { CollapseDisplayGroup } from "../../components/CollapseDisplayGroup";
import Category from "../../models/Category";
import { CategoriesFilters } from "../../models/filters/CategoriesFilters";
import { ReadPage } from "../../resources/components/ReadPage";
import { ReadResource } from "../../resources/components/ReadResource";
import { ListView } from "../../resources/components/lists/ListView";
import { ResourceName } from "../../resources/types/resourceName";
import CategoriesList from "./CategoriesList";
import { BreadCrumb } from "./components/BreadCrumb";
import { CategoryArticles } from "./components/CategoryArticles";

const CategoryPage = () => {
  return (
    <ReadPage>
      <ReadResource<Category>
        resource={(category) => {
          return (
            <ListView<ResourceName, Category, CategoriesFilters>
              defaultPageSize={30}
              defaultFilters={{ parentId: category.id }}
            >
              <BreadCrumb category={category} />
              <CategoriesList />
              <CollapseDisplayGroup
                label="Articles"
                canHide={false}
                addBodyMargin={false}
              >
                <CategoryArticles categoryId={category.id} />
              </CollapseDisplayGroup>
            </ListView>
          );
        }}
      />
    </ReadPage>
  );
};

export default CategoryPage;
