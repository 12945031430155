import { useMsal } from "@azure/msal-react";
import { useMemo } from "react";
import { useConfigurationContext } from "../../BackOffice";
import Customer from "../../models/Customer";
import Nullable from "../../Nullable";

const useSWRKeyInterfaceForCustomer = function (
  api: Nullable<string>,
  customer: Nullable<Customer>
) {
  const { InternalServicesUrl: apiBaseUrl } = useConfigurationContext();
  const { instance, accounts } = useMsal();

  const apiUrl = useMemo(() => {
    if (api === undefined || api === null) {
      return null;
    }
    if (!customer) {
      return null;
    }
    const apiUrl = new URL(api, apiBaseUrl);
    apiUrl.searchParams.append("customerId", customer.id);
    return apiUrl.href;
  }, [api, customer, apiBaseUrl]);

  const [mainAccount] = accounts;
  if (apiUrl == null || instance == null || mainAccount == null) {
    return null;
  }
  return [apiUrl, instance, mainAccount];
};
export default useSWRKeyInterfaceForCustomer;
