import { useMemo } from "react";
import { FilterFieldData } from "../../components/DynamicFieldData";
import { Attribute } from "../../models/Attribute";
import { AttributeType } from "../../models/AttributeType";
import { AttributeVisibility } from "../../models/AttributeVisibility";
import { ListPage } from "../../resources/components/lists/ListPage";
import {
  ResourceColumn,
  ResourceTable,
} from "../../resources/components/lists/ResourceTable";
import { displayAttributeType } from "./displayAttributeType";

type Filters = {
  code?: string;
  name?: string;
  isFacet?: boolean;
  isDifferantiating?: boolean;
  visibility?: number;
  type?: number;
};

const AttributesPage = () => {
  const fields: FilterFieldData<Filters>[] = useMemo(
    () => [
      {
        fieldName: "code",
        type: "text",
        label: "Code",
        placeholder: "Code",
        defaultValue: "",
        isClearable: true,
      },
      {
        fieldName: "name",
        type: "text",
        label: "Libellé",
        placeholder: "Libellé",
        defaultValue: "",
        isClearable: true,
      },
      {
        fieldName: "isFacet",
        type: "boolean",
        label: "Filtre de recherche",
        placeholder: "Filtre de recherche",
        defaultValue: "",
        isClearable: true,
      },
      {
        fieldName: "isDifferantiating",
        type: "boolean",
        label: "Attribut de déclinaison",
        placeholder: "Attribut de déclinaison",
        defaultValue: "",
        isClearable: true,
      },
      {
        fieldName: "visibility",
        type: "number-select",
        label: "Visiblité",
        placeholder: "Visiblité",
        defaultValue: "",
        isClearable: true,
        options: [
          { label: "Aucune", value: AttributeVisibility.None },
          { label: "Vendeur", value: AttributeVisibility.Vendor },
          { label: "Publique", value: AttributeVisibility.Public },
        ],
      },
      {
        fieldName: "type",
        type: "number-select",
        label: "Type",
        placeholder: "Type",
        defaultValue: "",
        isClearable: true,
        options: [
          { label: "Texte", value: AttributeType.Text },
          { label: "Booléen", value: AttributeType.Boolean },
          { label: "Numérique", value: AttributeType.Numeric },
        ],
      },
    ],
    []
  );

  const columns: ResourceColumn<Attribute>[] = useMemo(
    () => [
      {
        header: "Code",
        accessor: "code",
      },
      {
        header: "Libellé",
        accessor: "name",
      },
      {
        header: "Ordre",
        accessor: "sortOrder",
      },
      {
        header: "Attribut de déclinaison",
        accessor: "isDifferantiating",
      },
      {
        header: "Filtre de recherche",
        accessor: "isFacet",
      },
      {
        header: "Visiblité",
        accessor: "visibility",
        getCell: (a) => (
          <>
            {a.visibility === AttributeVisibility.None
              ? "Aucune"
              : a.visibility === AttributeVisibility.Vendor
              ? "Vendeur"
              : a.visibility === AttributeVisibility.Public
              ? "Publique"
              : ""}
          </>
        ),
      },
      {
        header: "Type",
        accessor: "type",
        getCell: (a) => displayAttributeType(a.type),
      },
      {
        header: " ",
        accessor: "id",
        getCell: () => "Détails",
        navigationPath: (_o, value) =>
          value != null ? "/attributes/edit/" + value : undefined,
      },
    ],
    []
  );

  return (
    <ListPage filterFieds={fields} showChildWithoutFilters>
      <ResourceTable columns={columns} />
    </ListPage>
  );
};

export default AttributesPage;
