import { useCallback } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import ImportJobDefinitionEntity from "../../models/ImportJobDefinitionEntity";
import { ListCondition } from "../../models/ListCondition";
import { ImportJobDefinitionEntitiesFilters } from "../../models/filters/ImportJobDefinitionEntitiesFilters";
import {
  ControlledResourcesSelect,
  ControlledResourcesSelectProps,
  ReactSelectProps,
  ResourcesSelect,
} from "../../resources/components/selects/ResourcesSelect";
import { ResourceName } from "../../resources/types/resourceName";
import getFriendlyJobName from "../../tools/getFriendlyJobName";
import ReactSelectOption from "../ReactSelectOption";

const useImportJobDefinitionEntitiesSelectController = <
  TImportJobDefinitionEntity extends ImportJobDefinitionEntity = ImportJobDefinitionEntity,
  TNumber extends number = number,
  TFilters extends ImportJobDefinitionEntitiesFilters = ImportJobDefinitionEntitiesFilters
>() => {
  const convertToOption = useCallback(
    (
      value: TImportJobDefinitionEntity | undefined
    ): ReactSelectOption<TNumber> | undefined => {
      return value != null
        ? {
            label: getFriendlyJobName(
              value.importJobDefinition?.fullyQualifiedJobClassName
            ),
            value: value.id as TNumber,
          }
        : undefined;
    },
    []
  );
  const transformToFilters = useCallback(
    (search: string) =>
      ({
        name: search,
        listCondition: ListCondition.Or,
      } as TFilters),
    []
  );
  const resourceName: ResourceName = "import-job-definition-entities";
  return { convertToOption, resourceName, transformToFilters };
};

type ImportJobDefinitionEntitiesSelectProps<IsMulti extends boolean> =
  ReactSelectProps<number, IsMulti>;

export const ImportJobDefinitionEntitiesSelect = <IsMulti extends boolean>(
  props: ImportJobDefinitionEntitiesSelectProps<IsMulti>
) => {
  const c = useImportJobDefinitionEntitiesSelectController();
  return (
    <ResourcesSelect<
      ImportJobDefinitionEntity,
      number,
      IsMulti,
      ResourceName,
      ImportJobDefinitionEntitiesFilters
    >
      {...c}
      {...props}
    />
  );
};

type ControlledImportJobDefinitionEntitiesSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean
> = ControlledResourcesSelectProps<
  ImportJobDefinitionEntitiesSelectProps<IsMulti>,
  TFieldValues,
  TName
>;

export const ControlledImportJobDefinitionEntitiesSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>(
  props: ControlledImportJobDefinitionEntitiesSelectProps<
    TFieldValues,
    TName,
    IsMulti
  >
) => {
  const c = useImportJobDefinitionEntitiesSelectController();

  return (
    <ControlledResourcesSelect<
      ImportJobDefinitionEntity,
      number,
      IsMulti,
      ResourceName,
      ImportJobDefinitionEntitiesFilters,
      TFieldValues,
      TName
    >
      {...c}
      {...props}
      name={props.name}
    />
  );
};
