const uploadNewMedia = async function <T>(
  apiUrl: string,
  apiFetcher: (
    requestInfo: string,
    requestInit?: RequestInit
  ) => Promise<any>,
  file?: File
) {
  let formData = new FormData();
  if (file) {
    formData.append("File", file);
  }
  const options = {
    method: "POST",
    body: formData,
  };
  const response = await apiFetcher(apiUrl, options);

  if (!response.ok) {
    throw new Error("Unable to upload");
  }

  const adMEdia = await response.json();
  return adMEdia as T;
};
export default uploadNewMedia;
