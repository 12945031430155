import { useCallback, useMemo, useState } from "react";
import { ApiError } from "../models/ApiError";
import useCustomerApiFetch from "./useCustomerApiFetch";

export type HttpMethod = "POST" | "PUT";

function useSubmitForm<T>(
  api: string,
  method: HttpMethod,
  successCallback?: (item: T) => void
) {
  const [isBusy, setIsBusy] = useState(false);
  const [errors, setErrors] = useState<ApiError>();
  const apiFetcher = useCustomerApiFetch();

  const onSubmit = useCallback(
    async (data: T) => {
      setIsBusy(true);
      setErrors(undefined);
      try {
        const response = await apiFetcher(api, {
          method: method,
          body: JSON.stringify(data),
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
        });

        if (!response.ok) {
          let apiException: ApiError;
          try {
            apiException = (await response.json()) as ApiError;
          } catch (error) {
            throw new Error("Unable to handle json error response");
          }
          setErrors(apiException);
        } else {
          const r = (await response.json()) as T;
          if (!r) {
            throw Error("Unable to handle json response");
          }

          successCallback && successCallback(r);
        }
      } catch (e) {
        console.error(e);
        setErrors(e as ApiError);
      } finally {
        setIsBusy(false);
      }
    },
    [api, method, apiFetcher, successCallback]
  );

  return { onSubmit, isBusy, errors };
}

/**
 * Handle api url creation. If resId is provided this will do a PUT on /baseApi/resId with the form data.
 */
export function useEditOrCreateSubmitForm<T>(
  apiUrl: string,
  resId?: string,
  successCallback?: (item: T) => void
) {
  const [formattedApiUrl, httpMode] = useMemo(() => {
    return resId
      ? [`${apiUrl}/${resId}`, "PUT" as HttpMethod]
      : [apiUrl, "POST" as HttpMethod];
  }, [apiUrl, resId]);

  return useSubmitForm<T>(formattedApiUrl, httpMode, successCallback);
}

export default useSubmitForm;
